import { Link } from 'react-router-dom';
import styles from './StatePagesTemplate/StatePagesTemplate.module.scss';

const texasFaq = [
  {
    o: 1,
    q: `How is Peekapak aligned to Texas’ Standards?`,
    a: (
      <>
        <p>
          Peekapak embeds the seven integrated strands of developing and
          sustaining foundational language skills. It also uses the four domains
          of language in its curriculum: speaking, listening, reading, and
          writing. This is the embodiment of Texas Essential Knowledge and
          Skills (TEKS) foundational language skills for English Language Arts.
        </p>
        <p>
          Peekapak’s curriculum has 4 reading levels along with 2 versions in
          Spanish to enable accessibility for English Learners. Peekapak offers
          curriculum to grades K through 8th. Each reading passage aligns with
          TEKS’ STAR Performance Level Indicators.
        </p>
      </>
    ),
  },
  {
    o: 2,
    q: `Are there funding options available to use towards Peekapak?`,
    a: (
      <>
        <p>Absolutely!</p>
        <p>
          The US government has provided COVID-19 relief funding through the
          CARES Act (ESSER I), CRRSAA (ESSER II) and the American Rescue Plan
          (ESSER III). These funds aim to support schools with COVID-19
          response, learning loss, mental health support and reintegration.
        </p>
        <p>
          There are also special funding sources available to the state of
          Texas. Learn more about funding resources available{' '}
          <Link to='/funding' className={styles.textLink}>
            here
          </Link>{' '}
          and how Peekapak aligns with each funding source.
        </p>
      </>
    ),
  },
  {
    o: 3,
    q: `What is the research behind Peekapak’s program development?`,
    a: (
      <>
        <p>
          Peekapak develops and builds critical social, emotional, and literacy
          skills in early childhood and elementary schools.
        </p>
        <p>
          Research has been incorporated into each step and element of
          Peekapak’s program design to ensure its overall effectiveness. Our 80
          classroom lessons and activities were designed based on external
          research and further vetted by Peekapak’s teacher advisors.
        </p>
        <p>
          Peekapak utilized 3rd party research and organizations like the
          Collaborative for Academic, Social, and Emotional Learning (CASEL),
          the Ontario Early Years Continuum of Development, and the Illinois
          Social Curriculum to guide our thinking.
        </p>
        <p>
          Peekapak identified 10 core topics educators and experts agree are
          critical to a young child’s character and social-emotional
          development. The 10 core topics include: Self-Regulation, Respect,
          Gratitude, Kindness, Perseverance, Teamwork, Empathy, Honesty,
          Optimism, and Courage.
        </p>
        <p>
          With Peekapak, you can rest assured that every base is covered when it
          comes to applicable research. In every unit’s introduction, each
          specific topic cites relevant research studies to accompany it.
        </p>
      </>
    ),
  },
  {
    o: 4,
    q: `What’s included in Peekapak’s curriculum?`,
    a: (
      <>
        <p>
          Peekapak’s social emotional learning (SEL) solutions serve both
          elementary (PreK-5) and secondary (6-12) students.
        </p>
        <p>
          Our elementary solution contains 80+ classroom lesson plans per grade
          level, along with in-class activities and at home reinforcements,
          requiring minimal prep. Each unit included 4 guided reading levels in
          English and Spanish, along with recorded narration. There’s also a
          game-based component that builds literacy and engagement. Students
          gain agency by creating personalized avatars. They also customize
          their experience through many quests and mini-games all relating to a
          specific SEL skill.
        </p>
        <p>
          Our secondary solution uses storytelling and guided reflection to
          instill SEL in students in grades 6-12 and support them in discovering
          and sharing their unique identities and stories. The program contains
          one year of curriculum per grade level, along with in-class activities
          and take-home challenges. Students engage with the materials through
          engaging and relatable human interest stories.
        </p>
        <p>
          Included in the curriculum are detailed analytics and reports.
          Teachers receive access to various analytical insights into student
          activity and in-class lessons. District administrators have a separate
          account where they can run analytic reports for entire schools and
          across their district.
        </p>
      </>
    ),
  },
  {
    o: 5,
    q: `How much does Peekapak cost?`,
    a: (
      <>
        <p>
          There are many funding options available to help you bring Peekapak to
          your school district.
        </p>
        <p>
          For school and district pricing, we offer custom-packages to ensure
          successful implementation.{' '}
          <a
            className={styles.textLink}
            target='_blank'
            href='mailto:hello@peekapak.com'
            rel='noreferrer'
          >
            Contact us to learn more.
          </a>
        </p>
      </>
    ),
  },
];

const californiaFaq = [
  {
    o: 1,
    q: `How is Peekapak aligned to California’s Standards?`,
    a: (
      <>
        <p>
          Peekapak embeds the College and Career Readiness Anchor Standards for
          Reading in its curriculum for grades K through 5. It also uses the
          four domains of language in its curriculum: speaking, listening,
          reading, and writing. This is the embodiment of foundational language
          skills for English Language Arts.
        </p>
        <p>
          Peekapak’s curriculum has 4 reading levels along with 2 versions in
          Spanish to enable accessibility for English Learners.
        </p>
      </>
    ),
  },
  {
    o: 2,
    q: `Are there funding options available to use towards Peekapak?`,
    a: (
      <>
        <p>Absolutely!</p>
        <p>
          The US government has provided COVID-19 relief funding through the
          CARES Act (ESSER I), CRRSAA (ESSER II) and the American Rescue Plan
          (ESSER III). These funds aim to support schools with COVID-19
          response, learning loss, mental health support and reintegration.
        </p>
        <p>
          There are also special funding sources available to the state of
          California. Learn more about funding resources available{' '}
          <Link to='/funding' className={styles.textLink}>
            here
          </Link>{' '}
          and how Peekapak aligns with each funding source.
        </p>
      </>
    ),
  },
  {
    o: 3,
    q: `What is the research behind Peekapak’s program development?`,
    a: (
      <>
        <p>
          Peekapak develops and builds critical social, emotional, and literacy
          skills in early childhood and elementary schools.
        </p>
        <p>
          Research has been incorporated into each step and element of
          Peekapak’s program design to ensure its overall effectiveness. Our 80
          classroom lessons and activities were designed based on external
          research and further vetted by Peekapak’s teacher advisors.
        </p>
        <p>
          Peekapak utilized 3rd party research and organizations like the
          Collaborative for Academic, Social, and Emotional Learning (CASEL),
          the Ontario Early Years Continuum of Development, and the Illinois
          Social Curriculum to guide our thinking.
        </p>
        <p>
          Peekapak identified 10 core topics educators and experts agree are
          critical to a young child’s character and social-emotional
          development. The 10 core topics include: Self-Regulation, Respect,
          Gratitude, Kindness, Perseverance, Teamwork, Empathy, Honesty,
          Optimism, and Courage.
        </p>
        <p>
          In every unit’s introduction, each specific topic cites relevant
          research studies to accompany it.
        </p>
        <p>
          Peekapak also referred to the ‘California Treasures, Scope and
          Sequence: Vocabulary’ list on the Macmillan/McGraw Hill. Vocabulary
          words were chosen using research-based frequency lists, such as:
          Living Word Vocabulary lists by grade level; Avril Coxhead’s list of
          High-Incidence Academic Words; and Andrew Biemiller’s Words Worth
          Teaching lists.
        </p>
        <p>
          With Peekapak, you can rest assured that every base is covered when it
          comes to applicable research.
        </p>
      </>
    ),
  },
  {
    o: 4,
    q: `What’s included in Peekapak’s curriculum?`,
    a: (
      <>
        <p>
          Peekapak’s social emotional learning (SEL) solutions serve both
          elementary (PreK-5) and secondary (6-12) students.
        </p>
        <p>
          Our elementary solution contains 80+ classroom lesson plans per grade
          level, along with in-class activities and at home reinforcements,
          requiring minimal prep. Each unit included 4 guided reading levels in
          English and Spanish, along with recorded narration. There’s also a
          game-based component that builds literacy and engagement. Students
          gain agency by creating personalized avatars. They also customize
          their experience through many quests and mini-games all relating to a
          specific SEL skill.
        </p>
        <p>
          Our secondary solution uses storytelling and guided reflection to
          instill SEL in students in grades 6-12 and support them in discovering
          and sharing their unique identities and stories. The program contains
          one year of curriculum per grade level, along with in-class activities
          and take-home challenges. Students engage with the materials through
          engaging and relatable human interest stories.
        </p>
        <p>
          Included in the curriculum are detailed analytics and reports.
          Teachers receive access to various analytical insights into student
          activity and in-class lessons. District administrators have a separate
          account where they can run analytic reports for entire schools and
          across their district.
        </p>
      </>
    ),
  },
  {
    o: 5,
    q: `How much does Peekapak cost?`,
    a: (
      <>
        <p>
          There are many funding options available to help you bring Peekapak to
          your school district.
        </p>
        <p>
          For school and district pricing, we offer custom-packages to ensure
          successful implementation.{' '}
          <a
            className={styles.textLink}
            target='_blank'
            href='mailto:hello@peekapak.com'
            rel='noreferrer'
          >
            Contact us to learn more.
          </a>
        </p>
      </>
    ),
  },
];

const oklahomaFaq = [
  {
    o: 1,
    q: `How is Peekapak aligned to Oklahoma’s Standards?`,
    a: (
      <>
        <p>
          Peekapak embeds the eight overarching standards of Oklahoma’s Academic
          Standards in English Language Arts. It also has college and career
          readiness in its curriculum, which is the embodiment of OAS’s
          foundational skills for English Language Arts.
        </p>
        <p>
          Peekapak offers curriculum to grades K through 8th. Each reading
          passage aligns with OAS’s guiding principles in Clarity, Coherence,
          and Purpose.
        </p>
      </>
    ),
  },
  {
    o: 2,
    q: `Are there funding options available to use towards Peekapak?`,
    a: (
      <>
        <p>Absolutely!</p>
        <p>
          The US government has provided COVID-19 relief funding through the
          CARES Act (ESSER I), CRRSAA (ESSER II) and the American Rescue Plan
          (ESSER III). These funds aim to support schools with COVID-19
          response, learning loss, mental health support and reintegration.
        </p>
        <p>
          There are also special funding sources available to the state of
          Oklahoma. Learn more about funding resources available{' '}
          <Link to='/funding' className={styles.textLink}>
            here
          </Link>{' '}
          and how Peekapak aligns with each funding source.
        </p>
      </>
    ),
  },
  {
    o: 3,
    q: `What is the research behind Peekapak’s program development?`,
    a: (
      <>
        <p>
          Peekapak develops and builds critical social, emotional, and literacy
          skills in early childhood and elementary schools.
        </p>
        <p>
          Research has been incorporated into each step and element of
          Peekapak’s program design to ensure its overall effectiveness. Our 80
          classroom lessons and activities were designed based on external
          research and further vetted by Peekapak’s teacher advisors.
        </p>
        <p>
          Peekapak utilized 3rd party research and organizations like the
          Collaborative for Academic, Social, and Emotional Learning (CASEL),
          the Ontario Early Years Continuum of Development, and the Illinois
          Social Curriculum to guide our thinking.
        </p>
        <p>
          Peekapak identified 10 core topics educators and experts agree are
          critical to a young child’s character and social-emotional
          development. The 10 core topics include: Self-Regulation, Respect,
          Gratitude, Kindness, Perseverance, Teamwork, Empathy, Honesty,
          Optimism, and Courage.
        </p>
        <p>
          In every unit’s introduction, each specific topic cites relevant
          research studies to accompany it.
        </p>
        <p>
          With Peekapak, you can rest assured that every base is covered when it
          comes to applicable research.
        </p>
      </>
    ),
  },
  {
    o: 4,
    q: `What’s included in Peekapak’s curriculum?`,
    a: (
      <>
        <p>
          With over 80+ classroom lesson plans per grade level, Peekapak offers
          in-class activities and at-home reinforcements in both English and
          Spanish with very minimal prep.
        </p>
        <p>
          Each lesson includes 4 guided reading levels in English and Spanish
          along with recorded narration. There’s also a game-based component
          that builds literacy and engagement. Students gain agency by creating
          personalized avatars. They also customize their experience through
          many quests and mini-games all relating to a specific SEL skill.
        </p>
        <p>
          The story-based learning follows Cody, Leo, Menka, and the other
          Peekapak Pals in Peekaville on their many adventures. Each lesson set
          explores topics like respect, empathy, and teamwork to build a
          school-wide culture of positivity.
        </p>
        <p>
          Included in the curriculum are detailed analytics and reports.
          Teachers receive access to various analytical insights into student
          activity and progress in myPeekaville and in-class lessons.
        </p>
        <p>
          myPeekaville allows students to check-in daily by selecting how
          they’re feeling, providing teachers with a moodboard of student
          sentiment data.
        </p>
        <p>
          District administrators have a separate account where they can run
          analytic reports for entire schools and across their district.
        </p>
      </>
    ),
  },
  {
    o: 5,
    q: `How much does Peekapak cost?`,
    a: (
      <>
        <p>
          There are many funding options available to help you bring Peekapak to
          your school district. Subscription-based options are available from
          Basic and Peekapak PRO.
        </p>
        <p>
          For school and district pricing, we offer custom-packages to ensure
          successful implementation.{' '}
          <a
            className={styles.textLink}
            target='_blank'
            href='mailto:hello@peekapak.com'
            rel='noreferrer'
          >
            Contact us to learn more
          </a>
          .
        </p>
      </>
    ),
  },
];

const sharedFaq = [
  {
    o: 6,
    q: `Is Peekapak COPPA compliant?`,
    a: (
      <>
        <p>Yes, Peekapak is COPPA compliant.</p>
        <p>
          Peekapak’s lessons are developed using evidence-based practices and
          have been researched in districts, Pre-K and after-school programs. We
          value student privacy and are iKeepSafe Certified (COPPA compliant).
        </p>
      </>
    ),
  },
];

export { texasFaq, californiaFaq, oklahomaFaq, sharedFaq };
