import React, { Component, createRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Overlay,
  Popover,
} from 'react-bootstrap';
import { getPublicMediaRoot } from '../../serviceAgent';
import {
  GuidedReadingScheme,
  getEnglishReadingLevels,
  getGuidedReadingLevel,
  getSpanishReadingLevels,
  isUserHasKey,
} from '../../GlobalFunctions';
import {
  ElementaryUnitName,
  LessonLanguage,
} from '../../../peekapak-types/LessonPlanTypes';
import Analytics from '../../Analytics';
import { FlipbookSlider } from './FlipbookSlider';
import { dismissForever, dismissTemporarily, isShouldShow } from '../core';
import { selectClassroom } from '../../Classrooms';
import Spotlight from '../../Spotlight';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import YesNoDialog from '../../components/YesNoDialog/YesNoDialog';
import styles from '../../SCSS/FlipbookHeader.module.scss';
import { useHistory } from 'react-router-dom';
import { isUserParentAccessStorybook } from '../../UserManagementHelpers';
import { RootState } from '../../ApplicationState';
import { History } from 'history';
import { StringLocale } from 'yup/lib/locale';

const HINT_NAME = 'ReadingLevelChangerHint';

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  classroom: selectClassroom(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  params: Record<string, string>;
  storyId: string;
  pagesDisplayed: number;
  autoPlay: boolean;
  language: string;
  setPauseState: (pauseState: boolean) => void;
  pauseState: boolean;
  history: History;
  pages: number;
  currentPage: number;
  changePage: (page: number) => void;
};

interface State {
  storyRoot: string;
  readingLevel: string | undefined;
  currentPage: number;
  isShowReadingLevelChangerHint: boolean;
  isReadyToShowHint: boolean;
  isShowUpgradePrompt: boolean;
  currentScheme: string | undefined;
  isShowClassroomGroupHint: boolean;
  startTime: Date;
  isComplete?: boolean;
}
class FlipbookHeader extends Component<Props, State> {
  hintTargetNode: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);

    this.hintTargetNode = createRef();
    const parts = this.props.storyId.split('-');

    const readingLevel = parts.pop();
    const storyRoot = parts.join('-');

    this.state = {
      storyRoot,
      readingLevel,
      currentPage: 1,
      isShowReadingLevelChangerHint:
        (isShouldShow(props.userProfile, HINT_NAME) &&
          isUserHasKey('KEY_CHANGE_GRADE_LEVEL')) ||
        isUserParentAccessStorybook(),
      isReadyToShowHint: false,
      isShowUpgradePrompt: false,
      currentScheme: undefined,
      isShowClassroomGroupHint: false,
      startTime: new Date(),
      isComplete: false,
    };
  }

  componentDidMount() {
    this.setupStorybookCloseEvent();
    this.setState({
      isReadyToShowHint: true,
    });
    if (this.props.language === 'es') {
      this.setState({ currentScheme: 'peekapakSpanish' });
    }
    Analytics.storybookOpenEvent(
      this.state.storyRoot as ElementaryUnitName,
      parseInt(this.state.readingLevel || '0'),
      this.props.language as LessonLanguage,
      this.props.userProfile!
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.userProfile &&
      this.props.userProfile !== prevProps.userProfile
    ) {
      this.setState({
        isShowReadingLevelChangerHint:
          (isShouldShow(this.props.userProfile, HINT_NAME) &&
            isUserHasKey('KEY_CHANGE_GRADE_LEVEL')) ||
          isUserParentAccessStorybook(),
        isComplete: false,
      });
      Analytics.storybookOpenEvent(
        this.state.storyRoot as ElementaryUnitName,
        parseInt(this.state.readingLevel || '0'),
        this.props.language as LessonLanguage,
        this.props.userProfile!
      );
    }
    if (this.props.currentPage === this.props.pages && !this.state.isComplete) {
      Analytics.storybookCompleteEvent(
        this.state.storyRoot as ElementaryUnitName,
        parseInt(this.state.readingLevel || '0'),
        this.props.language as LessonLanguage,
        this.props.userProfile!
      );
      this.setState({ isComplete: true });
    }
  }

  setupStorybookCloseEvent = () => {
    document.addEventListener('visibilitychange', () => {
      return this.sendStorybookClose();
    });
  };

  sendStorybookClose = async () => {
    if (document.visibilityState !== 'hidden') {
      const currentTime = new Date();
      const duration = currentTime.valueOf() - this.state.startTime.valueOf();
      await Analytics.storybookCloseEvent(
        this.state.storyRoot as ElementaryUnitName,
        parseInt(this.state.readingLevel || '0'),
        this.props.language as LessonLanguage,
        this.props.userProfile!,
        duration
      );
    }
  };

  componentWillUnmount(): void {
    document.removeEventListener(
      'visibilitychange',
      this.setupStorybookCloseEvent
    );
    this.sendStorybookClose();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.storyId !== this.props.storyId) {
      const parts = nextProps.storyId.split('-');
      const readingLevel = parts.pop();
      const storyRoot = parts.join('-');

      this.setState({
        storyRoot,
        readingLevel,
      });
    }
  }

  handleOnSelect = (eventKey: string) => {
    const setCurrentScheme = (unitName: string) => {
      if (
        parseInt(eventKey, 10) >= spanishRange[0] &&
        parseInt(eventKey, 10) <= spanishRange[1]
      ) {
        this.setState({ currentScheme: 'peekapakSpanish' });
      } else if (unitName === 'Pandemic-SEL') {
        this.setState({ currentScheme: 'peekapakEnglish' });
      } else {
        this.setState({
          currentScheme:
            (this.props.classroom &&
              this.props.classroom.readAloudLevelScheme) ||
            'fountasAndPinnell',
        });
      }
    };

    if (
      !isUserHasKey('KEY_CHANGE_GRADE_LEVEL') &&
      !isUserParentAccessStorybook()
    ) {
      this.setState({
        isShowUpgradePrompt: true,
      });
      return;
    }

    const readingLevels = getEnglishReadingLevels(this.state.storyRoot);
    const esReadingLevels = getSpanishReadingLevels(this.state.storyRoot);

    const fullEnglishUrlMap: Record<number, string> = {
      0: `/story/${this.state.storyRoot}-0`,
      1: `/story/${this.state.storyRoot}-1`,
      2: `/story/${this.state.storyRoot}-2`,
      3: `/story/${this.state.storyRoot}-3`,
      4: `/story/${this.state.storyRoot}-4`,
    };
    const fullSpanishUrlMap: Record<number, string> = {
      0: `/story/es/${this.state.storyRoot}-0`,
      1: `/story/es/${this.state.storyRoot}-1`,
      2: `/story/es/${this.state.storyRoot}-2`,
    };

    const englishUrlMap = readingLevels.reduce(
      (acc: string[], curr: number) => {
        return [...acc, fullEnglishUrlMap[curr]];
      },
      []
    );

    const englishRange = [0, englishUrlMap.length - 1];

    const spanishUrlMap = esReadingLevels.reduce((acc: string[], curr) => {
      return [...acc, fullSpanishUrlMap[curr]];
    }, []);

    const spanishRange = [
      englishRange[1] + 1,
      englishRange[1] + 1 + spanishUrlMap.length - 1,
    ];

    const storybookUrls = [...englishUrlMap, ...spanishUrlMap];
    const newUrl = storybookUrls[parseInt(eventKey, 10)];
    this.props.history.replace(newUrl);
    setCurrentScheme(this.state.storyRoot);
  };

  navigateBack = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.props.history.goBack();
  };

  hideHint = () => {
    this.setState({
      isShowReadingLevelChangerHint: false,
    });
  };

  hideHintForever = () => {
    this.hideHint();
    dismissForever(HINT_NAME, this.props.userProfile?.flags);
  };

  hideHintForSession = () => {
    this.hideHint();
    dismissTemporarily(HINT_NAME);
  };

  hideUpgradePrompt = () => {
    this.setState({
      isShowUpgradePrompt: false,
    });
  };

  goToPricingPage = () => {
    this.props.history.push('/features');
  };

  showHint = () => {
    this.setState({
      isShowClassroomGroupHint: true,
    });
  };

  render() {
    const { storyRoot, readingLevel, currentScheme } = this.state;
    const classroomScheme =
      (this.props.classroom && this.props.classroom.readAloudLevelScheme) ||
      'fountasAndPinnell';

    const HintPopover = (
      <Popover
        id='changeClassroomGroupHint'
        className={styles.hintPopover}
        onMouseEnter={this.showHint}
      >
        <Popover.Title>Hint</Popover.Title>
        <Popover.Content>
          <div style={{ paddingTop: '1em' }}>
            <p>
              Click here to switch the level of the storybook or access our
              books in Spanish. We’ve also mapped these to{' '}
              <a
                href={`${getPublicMediaRoot()}resources/Guided Reading Levels.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                guided reading levels
              </a>
              .
            </p>
          </div>
          <div style={{ textAlign: 'center', padding: '1em' }}>
            <PrimaryButton
              className={styles.popoverButton}
              onClick={this.hideHintForSession}
              small
            >
              Okay
            </PrimaryButton>
            {this.props.userProfile && (
              <PrimaryButton
                className={styles.popoverButton}
                onClick={this.hideHintForever}
                small
              >
                Dismiss
              </PrimaryButton>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <div id='FlipbookHeader' className={styles.FlipbookHeader}>
        <div className={styles.backArrow} onClick={this.navigateBack}>
          <i className='fa fa-angle-left' aria-hidden='true' />
        </div>
        <div className={styles.FlipbookControls}>
          <FlipbookSlider
            tipFormatter={null}
            min={1}
            max={this.props.pages}
            value={this.props.currentPage}
            onAfterChange={this.props.changePage}
          />
          <div className={`${styles.text}`}>
            {`${Math.round(
              (this.props.currentPage / this.props.pages) * 100
            )}% read`}
          </div>
        </div>
        <div className={styles.readingLevelDropdown} ref={this.hintTargetNode}>
          {getReadingLevelDropdown(this.handleOnSelect)}
        </div>
        {this.state.isShowReadingLevelChangerHint &&
          this.state.isReadyToShowHint && (
            <div className={styles.overlayWrapper}>
              <Overlay
                className={styles.navigatorOverlay}
                onHide={this.hideHint}
                target={this.hintTargetNode.current}
                show={this.state.isShowReadingLevelChangerHint}
                placement='bottom'
              >
                {HintPopover}
              </Overlay>
              <Spotlight target={this.hintTargetNode.current} />
            </div>
          )}
        <YesNoDialog
          isShow={this.state.isShowUpgradePrompt}
          onHide={this.hideUpgradePrompt}
          title='Would you like to upgrade?'
          onNoResponse={this.hideUpgradePrompt}
          onYesResponse={this.goToPricingPage}
        >
          BASIC account users have access to only one reading level of
          storybooks. Would you like to know more about upgrading your account?
        </YesNoDialog>
      </div>
    );

    function getReadingLevelDropdown(
      selectHandler: (eventKey: string) => void
    ) {
      const readingLevels = getEnglishReadingLevels(storyRoot);
      const esReadingLevels = getSpanishReadingLevels(storyRoot);
      const readingLevelDescription = getGuidedReadingLevel(
        storyRoot,
        Number(readingLevel),
        currentScheme || classroomScheme
      );

      return (
        <ButtonGroup>
          <DropdownButton
            title={`${
              GuidedReadingScheme[currentScheme || classroomScheme].shortForm
            } ${readingLevelDescription}`}
            id='readingLevelDropdown'
            drop='right'
            onSelect={selectHandler}
            variant='light'
          >
            {readingLevels.map((level, index) => (
              <Dropdown.Item key={index} eventKey={`${index}`}>
                {GuidedReadingScheme[classroomScheme].longForm}{' '}
                {getGuidedReadingLevel(storyRoot, level, classroomScheme)}
              </Dropdown.Item>
            ))}
            {esReadingLevels.map((level, index) => (
              <Dropdown.Item
                key={`es${index}`}
                eventKey={`${readingLevels.length + index}`}
              >
                Spanish Level {level}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </ButtonGroup>
      );
    }
  }
}

function ClassFunctionWrapper(props) {
  const history = useHistory();
  return <FlipbookHeader {...props} history={history} />;
}
export default connector(ClassFunctionWrapper);
