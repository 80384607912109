import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import MediaQuery from 'react-responsive';

import {
  scrollIntoViewHelper,
  validateEmailInput,
} from '../../GlobalFunctions';
import {
  getCountryOriginBasedOnIp,
  myMemoize,
  validatePassword as vp,
} from '../../GlobalFunctions';
import { validateLicenseCode as vl } from '../../BackendInterface';
import ConditionalWrapper from '../ConditionalWrapper';

import VisitorCollectorCard, { Content, Title } from '../VisitorCollectorCard';
import TextInput from '../TextInput';
import UsernameInput from '../UsernameInput/UsernameInput';
import LicenseCodeInput from '../LicenseCodeInput';
import SignUpLegalese from '../SignUpLegalese/SignUpLegalese';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import GoogleButton from '../ExternalProviderButtons/GoogleSignUpButton';
import CleverButton from '../ExternalProviderButtons/CleverSignUpButton';
import ClassLinkButton from '../ExternalProviderButtons/ClassLinkSignUpButton';
import SignUpEpilogue from '../SignUpEpilogue';
import peekaLogo from '../../images/logos/logo-peekapak-white.png';
import cx from 'classnames';
import styles from './SignUpForm.module.scss';
import { SignUpData } from '../../../peekapak-types/DataProtocolTypes';

interface Props {
  className?: string;
  formBannerStyle?: React.CSSProperties;
  formDescription?: string;
  trial?: boolean;
  curriculum: string;
  hasFormDescription: boolean;
  onSubmit(data: SignUpData): void;
  onGoogle?(redeemableCode: string, curriculum: string): void;
  onClever?(redeemableCode: string, curriculum: string): void;
  onClassLink?(redeemableCode: string, curriculum: string): void;
  onGoBack?(): void;
  districtName?: string;
  LicenseLevel?: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  optin: boolean;
  toupp: boolean;
  licenseCode: string;
}

const isFunction = function (obj: unknown) {
  return typeof obj === 'function';
};

/** @private is the given object an Object? */
const isObject = function (obj: unknown) {
  return obj !== null && typeof obj === 'object';
};

const isPromise = function (value: unknown) {
  return isObject(value) && isFunction(value.then);
};

const SignUpForm = ({
  className,
  formBannerStyle,
  formDescription,
  trial,
  curriculum,
  hasFormDescription,
  onSubmit,
  onGoogle,
  onClever,
  onClassLink,
  onGoBack,
}: Props): JSX.Element => {
  const [isCheckingCode, setIsCheckingCode] = useState(false); //license code checking
  const cache = useRef({});
  const [combinedState, setCombinedState] = useState({
    isStillChecking: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      optin: undefined,
      toupp: false,
      licenseCode: '',
    },
  });

  useEffect(() => {
    let mounted = true;

    getCountry();

    async function getCountry() {
      const country = await getCountryOriginBasedOnIp();
      const isUserFromUS = country === 'United States';
      if (mounted) {
        setCombinedState((prevState) => ({
          isStillChecking: false,
          initialValues: {
            ...prevState.initialValues,
            optin: isUserFromUS,
          },
        }));
      }
    }

    return () => (mounted = false);
  }, []);

  const handleScroll = () => {
    const el = document.getElementById('nextViewMobile');
    const topPos = el?.offsetTop;
    window.scrollTo({ top: topPos, behavior: 'smooth' });
  };

  const onSubmitIntercept = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (isCheckingCode) {
      return;
    }

    const { firstName, lastName, email, password, optin, toupp, licenseCode } =
      values;

    onSubmit({
      firstName,
      lastName,
      email,
      password,
      optin,
      toupp,
      redeemableCode: licenseCode.replace(/-/g, ''),
      curriculum,
    });
    setSubmitting(false);
  };

  const onGoogleIntercept = (values: FormValues, curriculum: string) => {
    onGoogle(values.licenseCode, curriculum);
  };

  const onCleverIntercept = (values: FormValues, curriculum: string) => {
    onClever(values.licenseCode, curriculum);
  };

  const onClassLinkIntercept = (values: FormValues, curriculum: string) => {
    onClassLink(values.licenseCode, curriculum);
  };

  const validatePassword = (password: string) => {
    const result = vp(password);
    return result;
  };

  const validateFirstName = (fname: string) => {
    if (!fname) return 'Please provide your first name';
    return '';
  };

  const validateLastName = (lname: string) => {
    if (!lname) return 'Please provide your last name';
    return '';
  };

  const validateTou = (toupp: boolean) => {
    if (toupp !== true)
      return `Please accept Peekapak’s Terms of Use and Privacy Policy`;

    return '';
  };

  const validateOptin = (optin: boolean) => '';

  async function _validateLicenseCode(code: string) {
    if (!code) return '';
    if (code.length < 14 && code.length > 0)
      return 'Please enter a complete license code';

    const cleanedCode = code.replace(/-/g, '');
    setIsCheckingCode(true);
    const valid = await vl(cleanedCode);
    setIsCheckingCode(false);

    if (valid) return '';
    return 'The license code entered is invalid';
  }

  const validateLicenseCode = myMemoize(cache.current, _validateLicenseCode);

  const validate = async (values: FormValues) => {
    // console.debug(
    //   `%cvalidate values = `,
    //   'background: black;color: red',
    //   values
    // );

    const errors: FormikErrors<FormValues> = {};

    const validators = {
      firstName: validateFirstName,
      lastName: validateLastName,
      email: validateEmailInput,
      password: validatePassword,
      toupp: validateTou,
      optin: validateOptin,
      licenseCode: validateLicenseCode,
    };

    for (const field of Object.keys(combinedState.initialValues)) {
      const data = values[field as keyof FormikErrors<FormValues>];
      const fn = validators[field as keyof FormikErrors<FormValues>];
      if (fn) {
        let validationResult;
        const maybePromise = fn(data);
        if (isPromise(maybePromise)) {
          validationResult = await maybePromise;
        } else {
          validationResult = maybePromise;
        }

        if (validationResult)
          errors[field as keyof FormikErrors<FormValues>] = validationResult;
      }
    }

    // console.debug(`%cError values`, 'background: violet; color: white', errors);
    return errors;
  };

  function merge(
    touched: FormikTouched<FormValues>,
    errors: FormikErrors<FormValues>
  ) {
    const mergedErrors = { ...errors };
    for (const fieldName of Object.keys(errors)) {
      if (!touched[fieldName as keyof FormikTouched<FormValues>]) {
        mergedErrors[fieldName as keyof FormikErrors<FormValues>] = '';
      }
    }

    return mergedErrors;
  }

  return (
    <ConditionalWrapper
      condition={trial}
      wrapper={(children) => (
        <VisitorCollectorCard className={className}>
          <Title titleAdditionalStyle={formBannerStyle}>
            Register for a Free Account
          </Title>
          <Content>{children}</Content>
        </VisitorCollectorCard>
      )}
    >
      {!trial && (
        <div className={styles.formTopSection}>
          <MediaQuery minWidth={1280}>
            <div className={styles.breadcrumb} onClick={onGoBack}>
              &lt; &nbsp; &nbsp; Back
            </div>
          </MediaQuery>
          <MediaQuery minWidth={707}>
            <h3 className={styles.formTitle}>Register Free Account</h3>
          </MediaQuery>
        </div>
      )}
      {!combinedState.isStillChecking ? (
        <Formik
          initialValues={combinedState.initialValues}
          validate={validate}
          onSubmit={onSubmitIntercept}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            /* and other goodies */
          }) => {
            const mergedErrors = merge(touched, errors);

            const handleLicenseCodeChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              const { name, value } = e.target;

              const toSetValue = (function () {
                let beautified = value.toUpperCase();

                if (beautified.length > 14) {
                  // user typed in max length, so no change
                  return beautified.substring(0, 14);
                } else if (beautified.length === 0) {
                  // reset to blank
                  return '';
                }

                beautified = beautified.replace(/-/g, '');

                if (beautified.length > 4) {
                  beautified = `${beautified.substring(
                    0,
                    4
                  )}-${beautified.substring(4)}`;
                }

                if (beautified.length > 9) {
                  beautified = `${beautified.substring(
                    0,
                    9
                  )}-${beautified.substring(9)}`;
                }

                return beautified;
              })();
              setFieldValue(name, toSetValue);
              setTimeout(() => setFieldTouched(name, true), 0);
            };

            const handleChangeImmediateFeedback = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              // store these values in the closure because the
              // synthetic event will be gone after this function
              // returns and the setTimeout handler won't be
              // able to access it anymore
              const { name, type, value, checked } = e.target;
              const toSetValue = (() => {
                if (type === 'checkbox') return checked;
                return value;
              })();
              setFieldValue(name, toSetValue);
              setTimeout(() => setFieldTouched(name, true), 0);
            };

            return (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <ConditionalWrapper
                  condition={!trial}
                  wrapper={(children) => (
                    <div id='nextViewMobile' className={styles.formColumn}>
                      {children}
                    </div>
                  )}
                >
                  <>
                    {hasFormDescription && (
                      <div className={styles.formDescription}>
                        {formDescription}
                      </div>
                    )}
                    {!trial && (
                      <MediaQuery maxWidth={706}>
                        <h3 className={styles.formTitle}>
                          Register Free Account
                        </h3>
                      </MediaQuery>
                    )}
                    <TextInput
                      placeholder='First Name'
                      type='text'
                      id='firstName'
                      name='firstName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <TextInput
                      placeholder='Last Name'
                      type='text'
                      id='lastName'
                      name='lastName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <UsernameInput
                      placeholder='Email'
                      name='email'
                      type='text'
                      validate={validateEmailInput}
                      bottomborder
                    />
                    <TextInput
                      placeholder='Password'
                      autoComplete='new-password'
                      type='password'
                      name='password'
                      id='password'
                      onChange={handleChangeImmediateFeedback}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {!trial && (
                      <LicenseCodeInput
                        id='licenseCode'
                        name='licenseCode'
                        onChange={handleLicenseCodeChange}
                        onBlur={handleBlur}
                        value={values.licenseCode}
                        isChecking={isCheckingCode}
                        className={styles.license0}
                        disabled={isCheckingCode}
                      />
                    )}
                    <SignUpLegalese
                      currentOptInState={values.optin}
                      currentTouPpState={values.toupp}
                      onOptInChange={handleChange}
                      onTouPpChange={handleChangeImmediateFeedback}
                      touPpValidationHint={mergedErrors.toupp || ''}
                    />
                    <PrimaryButton
                      className={cx(styles.ctaButton, {
                        [`${styles.secondaryTrialBtn}`]:
                          trial && curriculum !== 'elementary',
                      })}
                      disabled={isSubmitting}
                      type='submit'
                      onClick={() => scrollIntoViewHelper(errors)}
                    >
                      {trial ? 'register now' : 'Register Account'}
                    </PrimaryButton>
                    {!trial && (
                      <div className={styles.epilogueContainer}>
                        <SignUpEpilogue noPromoCode />
                      </div>
                    )}
                  </>
                </ConditionalWrapper>
                <ConditionalWrapper
                  condition={!trial}
                  wrapper={(children) => (
                    <div className={styles.registerButtonsColumn}>
                      {children}
                    </div>
                  )}
                >
                  <>
                    {!trial && (
                      <div className={styles.thirdPartySignInButtons}>
                        <GoogleButton
                          onClick={() => onGoogleIntercept(values, curriculum)}
                          disable={isCheckingCode || !!errors.licenseCode}
                        />
                        <CleverButton
                          onClick={() => onCleverIntercept(values, curriculum)}
                          disable={isCheckingCode || !!errors.licenseCode}
                        />
                        <ClassLinkButton
                          onClick={() =>
                            onClassLinkIntercept(values, curriculum)
                          }
                          disable={isCheckingCode || !!errors.licenseCode}
                        />
                      </div>
                    )}
                    {!trial && (
                      <MediaQuery maxWidth={706}>
                        <PrimaryButton
                          className={styles.regButton}
                          orange
                          capitalize
                          onClick={() => handleScroll()}
                        >
                          <img src={peekaLogo} alt='peekapak logo' />
                          Register with Email
                        </PrimaryButton>
                      </MediaQuery>
                    )}
                  </>
                </ConditionalWrapper>
              </form>
            );
          }}
        </Formik>
      ) : (
        <></>
      )}
    </ConditionalWrapper>
  );
};

export default SignUpForm;
