import { Component } from 'react';
import { getCookie, setLocalStorage } from '../aaa';
import { parseFullName } from 'parse-full-name';
import { Segment } from '../core/core';
import { getContentRequest } from '../serviceAgent';
import { sendMixpanelSignupEvent } from '../mixpanel';
import { logger } from '../error-tracker';
import { createTeacherAccount } from '../BackendInterface';
import StatusUpdate from '../components/StatusUpdate';
import ErrorDisplay from '../components/ErrorDisplay';
import { reactivateUserSessionIfExists } from '../UserManagementHelpers';
import styles from './SignUp.module.scss';
import utilityStyles from '../SCSS/common/utilityStyles.module.scss';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import Images from '../Images';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { sendUserToRoleHomePage } from '../GlobalFunctions';
import { getStore } from '../ApplicationState';

interface Props {
  history: History;
}

class SignUpStep2 extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isApiCallReturned: false,
      apiResponse: undefined,
    };

    this.handleOnNextStep = this.handleOnNextStep.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  handleOnNextStep() {
    const user = getStore().getState().user?.userProfile;
    if (user) sendUserToRoleHomePage(user, this.props.history, true, true);
    else this.props.history.push('/library');
  }

  getSalutation(gender) {
    switch (gender) {
      case 'male':
        return 'Mr';
      case 'female':
        return 'Ms';
      default:
        return 'Ms';
    }
  }

  componentDidMount() {
    const userInputInformation = { ...this.props.userInput };

    const nameBeingParsed = userInputInformation.name
      ? this.props.userInput.name
      : this.props.userInput.firstName + ' ' + this.props.userInput.lastName;

    const name = parseFullName(nameBeingParsed);
    userInputInformation.firstName = name.first;
    userInputInformation.lastName = name.last;

    const xhrPromise = new Promise((resolve, reject) => {
      getContentRequest(
        `https://api.genderize.io/?name=${name.first}`,
        (status, request) => {
          if (status === 200) {
            const responseJson = JSON.parse(request.responseText);
            resolve(responseJson);
          } else {
            reject(status);
          }
        }
      );
    });

    xhrPromise
      .then((responseJson) => {
        userInputInformation.salutation = this.getSalutation(
          responseJson.gender
        );
        registerNewUser.bind(this)();
      })
      .catch((reason) => {
        userInputInformation.salutation = 'Ms';
        registerNewUser.bind(this)();
      });

    async function registerNewUser() {
      const redeemableCode =
        userInputInformation.redeemableCode ||
        getCookie('peekapak.redeemableLicenseCode');
      const userRegistrationData = {
        ...userInputInformation,
        redeemableCode,
      };

      createTeacherAccount(
        userRegistrationData,
        async (resultObject) => {
          if (userRegistrationData.isOAuthSignUp) {
            try {
              await reactivateUserSessionIfExists();
            } catch (error) {
              logger.logException(error as Error);
              this.setState({
                isApiCallReturned: true,
                apiResponse: error.message,
              });
            }
            this.setState({
              isApiCallReturned: true,
            });
          } else {
            setLocalStorage('peekapak.username', resultObject.email, 365);
            this.props.history.push('/registrationConfirmation');
          }
        },
        (errorObject, request) => {
          logger.logException(
            new Error(
              `createTeacherAccount error for ${userRegistrationData.email}: ${
                errorObject.message || errorObject.error
              }`
            )
          );
          this.setState({
            isApiCallReturned: true,
            apiResponse: errorObject.message,
          });
        }
      );
    }
  }

  render() {
    if (!this.state.isApiCallReturned) {
      return (
        <div className={styles.statusUpdateContainer}>
          <StatusUpdate
            pkToEightBackground
            pictureUrl={Images.backpackPurpleBg}
            contentTitle='Creating your account...'
            contentSubtitle='Please give us a moment!'
            isShowSpinner={true}
          />
        </div>
      );
    } else if (this.state.apiResponse === undefined) {
      sendMixpanelSignupEvent(this.props.userInput);
      return (
        <Segment className={styles.signUpContainer}>
          <div className={styles['spacer-40']} />
          <div
            className={`${utilityStyles.absCentre} ${styles.signUpPrologue}`}
          >
            <div className={styles['spacer-40']} />
            <div>
              <div className={styles.content}>
                <div className={styles.contentPicture}>
                  <img alt='Success' src={Images.successSmall} />
                </div>
                <div className={styles.contentText}>
                  <div className={styles.contentTitle}>
                    We’re so excited you’ve joined the Peekapak family!
                  </div>
                  <div className={styles['spacer-40']} />
                  <div className={styles.contentSubtitle}>
                    If there’s anything we can do to help, please don’t hesitate
                    to email us at{' '}
                    <a href='mailto:hello@peekapak.com'>hello@peekapak.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['spacer-40']} />
          <div className={utilityStyles.absCentre}>
            <PrimaryButton onClick={this.handleOnNextStep}>
              Let&apos;s Go
            </PrimaryButton>
          </div>
          <div className={styles['spacer-40']} />
        </Segment>
      );
    }

    if (
      this.state.apiResponse.includes('User with this email already exists') ||
      this.state.apiResponse.includes(
        'User account already exists in old database'
      )
    ) {
      return (
        <ErrorDisplay
          contentTitle='Next steps...'
          contentSubtitle={this.state.apiResponse}
          buttonText='Go to Login'
          onButtonClick={() => {
            this.props.history.push('/login');
          }}
        />
      );
    }

    return (
      <ErrorDisplay
        contentTitle='Sorry, we had a problem creating your account:'
        contentSubtitle={this.state.apiResponse}
        buttonText='Start Over'
        onButtonClick={this.props.onStartOver}
      />
    );
  }
}

function ClassFunctionWrapper(props) {
  const history = useHistory();
  return <SignUpStep2 {...props} history={history} />;
}

export default ClassFunctionWrapper;
