import { useEffect, useRef, useCallback } from 'react';
import { getVideoURL } from '../../BackendInterface';
// Styles
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Modal } from 'react-bootstrap';
import styles from './MuxPlayer.module.scss';

interface IVideoPlayerProps {
  options?: videojs.PlayerOptions;
  videoId: string;
  isShow: boolean;
  onHide: () => void;
  title?: string;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
  width: 560,
  autoplay: true,
};

const MuxPlayer = ({
  options,
  videoId,
  isShow,
  onHide,
  title,
}: IVideoPlayerProps) => {
  const videoNode = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<videojs.Player | null>(null);

  const getMuxURL = useCallback(async () => {
    try {
      const muxURLResponse = await getVideoURL(videoId);
      playerRef.current = videojs(videoNode.current || '', {
        ...initialOptions,
        ...(options as object),
        sources: [{ src: muxURLResponse, type: 'application/x-mpegURL' }],
      }).ready(function () {
        // console.log('onPlayerReady', this);
      });
    } finally {
      // console.log('update finished');
    }
  }, [videoId]);

  useEffect(() => {
    getMuxURL();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [isShow]);

  return (
    <>
      <Modal
        dialogClassName={styles['modal-dialog']}
        className={styles.modal}
        show={isShow}
        onHide={onHide}
        size='lg'
        autoFocus
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles['modal-title']}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.vidContainer}>
            <video ref={videoNode} className='video-js' />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MuxPlayer;
