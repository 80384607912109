import React, { useEffect, useState } from 'react';
import { getSlideShowPointers } from './BackendInterface';
type SlideShowPointer = {
  url: string;
  thumbnailUrl: string;
};
let slideShowPointers: SlideShowPointer | null = null;

const useSlideShowPointers = (): [
  pointersLoadState: string,
  slideShowPointers: SlideShowPointer | null
] => {
  const [pointersLoadState, setPointerLoadState] = useState('none');
  useEffect(() => {
    if (!slideShowPointers) {
      setPointerLoadState('loading');
      loadSlideShowPointers();
    } else {
      setPointerLoadState('loaded');
    }

    async function loadSlideShowPointers() {
      if (!slideShowPointers) {
        slideShowPointers = await getSlideShowPointers();
        setPointerLoadState('loaded');
      }
    }
  }, []);
  return [pointersLoadState, slideShowPointers];
};

export default useSlideShowPointers;

export function withSlideShowPointers<T>(
  Component: typeof React.Component
): React.ReactNode {
  return (props: T) => {
    const pointers = useSlideShowPointers();
    return <Component slideShowPointers={pointers} {...props} />;
  };
}
