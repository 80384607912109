import { ReactNode } from 'react';
import cx from 'classnames';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import TextError from './TextError';
import styles from './FormikStyles.module.scss';

interface Props {
  bottomborder?: boolean;
  className?: string;
  fullborder?: boolean;
  icon?: ReactNode;
  label: string;
  name: string;
  novalue?: boolean;
  options: {
    key: string;
    value: string | number | readonly string[] | undefined;
  }[];
  isrequired?: boolean;
  onChange?: (value: string) => void | undefined;
}

function Select(props: Props): JSX.Element {
  const { label, name, options, novalue, onChange, ...rest } = props;

  const formik = useFormikContext();

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      const { value } = e.target;
      onChange(value);
    }
  };

  return (
    <div className={cx(styles.formControl, props.className)}>
      <label
        htmlFor={name}
        className={cx({ [`${styles.required}`]: props.isrequired })}
      >
        {label}
        {props.icon && <span>{props.icon}</span>}
      </label>
      <Field
        as='select'
        id={name}
        name={name}
        {...rest}
        className={cx({
          [`${styles.fullBorder}`]: props.fullborder,
          [`${styles.bottomBorder}`]: props.bottomborder,
        })}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          formik.handleChange(e);
          handleSelectChange(e);
        }}
      >
        {options.map((option) => {
          return (
            <option
              key={option.key}
              value={
                novalue
                  ? undefined
                  : option.value === 'placeholder'
                  ? ''
                  : option.value
              }
              hidden={option.value === 'disabled'}
            >
              {option.key}
            </option>
          );
        })}
      </Field>
      <div className={styles.errorHintContainer}>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
}

export default Select;
