import { useHistory } from 'react-router-dom';
import SectionHeader from '../components/SectionHeader';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import experts from '../images/experts-image-01.png';
import styles from '../SCSS/ExpertsSegment.module.scss';

const ExpertsSegment = (): JSX.Element => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <figure className={styles.content}>
        <img src={experts} alt='experts' />
        <figcaption className={styles.description}>
          <SectionHeader
            className={styles.sectionHeader}
            alignLeft
            preTitle='PEEKAPAK RESEARCH'
            title='Expert-backed Design Principles'
          />
          <p>
            Peekapak’s original stories and lessons were carefully designed with
            help from experts to support educators and parents in teaching SEL
            and literacy skills. Every detail is carefully crafted so students
            relate to, engage with, and learn from our content.
          </p>
          <div className={styles.buttonsContainer}>
            <PrimaryButton
              capitalize
              onClick={() => history.push('/researchElementary')}
            >
              Elementary Research
            </PrimaryButton>
            <PrimaryButton
              capitalize
              secondary
              onClick={() => history.push('/researchMiddleSchool')}
            >
              Secondary Research
            </PrimaryButton>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default ExpertsSegment;
