import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { FormValues } from '../ToDoQuestionAnswer';

type SaveHandlerType = (values: FormValues) => Promise<void>;

interface Props {
  debounceMs?: number;
  saveHandler: SaveHandlerType;
  dirty?: boolean;
}

const FormikAutosave = ({
  debounceMs = 1000,
  saveHandler,
  dirty,
}: Props): JSX.Element => {
  const formik = useFormikContext();
  const debouncedSaveHandler = useRef<_.DebouncedFunc<
    (arg0: FormValues) => void
  > | null>(null);

  if (!debouncedSaveHandler.current) {
    debouncedSaveHandler.current = _.debounce(saveHandler, debounceMs);
  }

  useEffect(() => {
    if (debouncedSaveHandler.current && dirty) {
      debouncedSaveHandler.current(formik.values);
    }
  }, [formik.values, dirty]);

  return null;
};

export const FormikOnChangeAutosave = ({ saveHandler }: Props): JSX.Element => {
  const { values } = useFormikContext();
  const [previousValues, setPreviousValues] = useState(values);

  useEffect(() => {
    if (haveValuesChanged(previousValues, values)) {
      setPreviousValues(values);
      saveHandler(values);
    }
  }, [saveHandler, values]);
  return <></>;

  function haveValuesChanged(prev, current) {
    return JSON.stringify(prev) !== JSON.stringify(current);
  }
};

export default FormikAutosave;
