import React, { Children, Component, cloneElement, createRef } from 'react';
import classNames from 'classnames';
import $ from 'jquery';
import '../../turn';
import { getStory } from '../context/contextHelpers';
import { logger } from '../../error-tracker';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

interface State {
  currentPage: number;
  story?: {
    bookLength: number;
    label: string;
    timeKeys: Record<string, unknown>;
    title: string;
    url: string;
  };
  isBookReady: boolean;
}
interface Props {
  params: Record<string, string>;
  storyId: string;
  pagesDisplayed: number;
  autoPlay: boolean;
  language: string;
  setPauseState: (newPause: boolean) => void;
  pauseState: boolean;
  currentPage?: number;
  background?: string;
  history: History;
  children: React.ReactNode;
  router?: unknown;
}
class InternalFlipbookFramework extends Component<Props, State> {
  frameworkDiv: React.RefObject<HTMLDivElement>;
  background: string;
  constructor(props: Props) {
    super(props);

    this.frameworkDiv = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      currentPage: this.props.currentPage ? this.props.currentPage : 1,
      story: undefined,
      // transitioning/loading the book
      isBookReady: false,
    };

    this.background = this.props.background
      ? this.props.background
      : 'background-grey';
  }

  autoFocusOnFrameworkDiv = () => {
    if (this.frameworkDiv.current) {
      this.frameworkDiv.current.focus();
    }
  };

  componentDidMount() {
    this.autoFocusOnFrameworkDiv();
    const { storyId, language } = this.props;
    const newStoryId = language ? `${language}-${storyId}` : storyId;

    getStory(newStoryId).then(
      (fulfillmentValue) => {
        const newState = {
          story: fulfillmentValue.value,
          isBookReady: true,
        };

        if (this.props.params.page !== undefined) {
          newState.currentPage = parseInt(this.props.params.page, 10);
        }

        this.setState(newState);
      },
      (rejectReason) => {
        logger.logException(rejectReason);
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.state.story === undefined ||
      nextProps.storyId !== this.props.storyId ||
      nextProps.language !== this.props.language
    ) {
      const { storyId, language } = nextProps;
      const newStoryId = language ? `${language}-${storyId}` : storyId;

      this.setState({
        isBookReady: false,
      });

      getStory(newStoryId).then(
        (fulfillmentValue) => {
          this.setState({
            story: fulfillmentValue.value,
            currentPage: 1,
            isBookReady: true,
          });
        },
        (rejectReason) => {
          // TODO: should use global modal error window or something
        }
      );
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.autoFocusOnFrameworkDiv();

    if (prevState.currentPage !== this.state.currentPage) {
      if (
        this.props.pagesDisplayed === 2 &&
        this.state.currentPage % 2 === 1 &&
        this.state.currentPage !== 1
      ) {
        this.setState({ currentPage: this.state.currentPage - 1 });
      }
    }
  }

  handleChange(page: number) {
    if (page !== undefined) {
      const language = this.props.language ? `/${this.props.language}` : '';

      this.setState({ currentPage: page });
      this.props.history.replace(
        `/story${language}/${this.props.storyId}/pg${page}`
      );
    }
  }

  render() {
    if (this.state.story === undefined || !this.state.isBookReady) {
      return null;
    }
    let ProcessedChildren;
    if (Object.prototype.hasOwnProperty.call(this.state.story, 'timeKeys')) {
      ProcessedChildren = Children.map(this.props.children, (child) => {
        if (this.state.story) {
          return cloneElement(child, {
            changePage: this.handleChange,
            currentPage: this.state.currentPage,
            pages: this.state.story.bookLength,
            url: this.state.story.url,
            storyId: this.props.storyId,
            language: this.props.language,
            timeKeys: this.state.story.timeKeys,
            router: this.props.router,
            background: this.background,
            isShowBook: this.state.isBookReady,
            goToNextPage: this.goToNextPage,
            goToPreviousPage: this.goToPreviousPage,
          });
        } else {
          return null;
        }
      });
    } else {
      ProcessedChildren = Children.map(this.props.children, (child) => {
        if (this.state.story) {
          return cloneElement(child, {
            changePage: this.handleChange,
            currentPage: this.state.currentPage,
            pages: this.state.story.bookLength,
            url: this.state.story.url,
            storyId: this.props.storyId,
            language: this.props.language,
            timeKeys: undefined,
            router: this.props.router,
            background: this.background,
            isShowBook: this.state.isBookReady,
            goToNextPage: this.goToNextPage,
            goToPreviousPage: this.goToPreviousPage,
          });
        } else {
          return null;
        }
      });
    }

    return (
      <div
        className={classNames('FlipbookFramework', this.background)}
        onKeyDown={this.handleOnKeyDown}
        tabIndex='0'
        ref={this.frameworkDiv}
      >
        {ProcessedChildren}
      </div>
    );
  }

  goToNextPage = () => {
    if (
      (this.props.autoPlay && !this.props.pauseState) ||
      (!this.props.autoPlay && !this.props.pauseState)
    ) {
      this.props.setPauseState(true);
    }
    $('#Flipbook').turn('next');
  };

  goToPreviousPage = () => {
    if (
      (this.props.autoPlay && !this.props.pauseState) ||
      (!this.props.autoPlay && !this.props.pauseState)
    ) {
      this.props.setPauseState(true);
    }
    $('#Flipbook').turn('previous');
  };

  handleOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.which) {
      case 37:
        this.goToPreviousPage();
        break;
      case 32:
      case 39:
        this.goToNextPage();
        break;
      default:
        // do nothing
        break;
    }
  };
}

export function FlipbookFramework(props: Props): JSX.Element {
  const history = useHistory();
  return <InternalFlipbookFramework {...props} history={history} />;
}
