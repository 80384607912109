import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { LoginState, LoginStateType } from './UserManagement';
import { signOutUser } from './UserManagementHelpers';
import { getCookie } from './aaa';
import StandalonePageWrapper from './StandalonePageWrapper';
import { logger } from './error-tracker';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { RootState } from './ApplicationState';

type Props = PropsFromRedux & {
  loginState: LoginStateType;
  history: History;
};

class Logout extends Component<Props> {
  componentDidMount = async () => {
    await signOutUser();
    this.props.history.replace('/');
  };

  render() {
    // console.debug(`%cLogout render`, 'background: blue; color: yellow');
    if (
      this.props.loginState === LoginState.loggedIn ||
      this.props.loginState === LoginState.authenticated
    ) {
      return (
        <StandalonePageWrapper>
          <h1>Logging out...</h1>
        </StandalonePageWrapper>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  cognitoProfile: state.user.cognitoProfile,
  loginState: state.user.loginState,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function ClassFunctionWrapper(props: Props) {
  const history = useHistory();
  return <Logout {...props} history={history} />;
}

export default connector(ClassFunctionWrapper);
