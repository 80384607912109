import { useQuery } from '../../CustomHooks';
import { landingPageComponents } from './tables';
import LandingPageRoute from './LandingPageRoute';

const LandingPageLayout = (): JSX.Element => {
  const query = useQuery();
  const variant = query.get('variant');
  const component = landingPageComponents.find(
    (component) => component.variant === variant
  );

  return component?.page ? (
    <LandingPageRoute>{component.page}</LandingPageRoute>
  ) : (
    <LandingPageRoute>{landingPageComponents[0].page}</LandingPageRoute>
  );
};

export default LandingPageLayout;
