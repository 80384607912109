import { useEffect, useState } from 'react';
import { getCountryOriginBasedOnIp } from '../GlobalFunctions';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../components/ExternalLink';
import Documents from '../Documents';
import styles from '../SCSS/Footer.module.scss';
import whiteLogo from '../images/pkp-backpack-name-logo.svg';

/*
Component: Footer

The Footer component generates a standardized footer
*/
const Footer = (): JSX.Element => {
  const [isUSAddress, setIsUSAddress] = useState(false);
  useEffect(() => {
    async function checkCountry() {
      const country = await getCountryOriginBasedOnIp();

      if (country === 'United States') {
        setIsUSAddress(true);
      } else {
        setIsUSAddress(false);
      }
    }

    checkCountry();
  }, []);
  return (
    <footer className={styles.footer}>
      <nav className={styles.navigation}>
        <section>
          <h1>SEL Resources</h1>
          <p>
            <Link to='/elementary'>Elementary SEL</Link>
          </p>
          <p>
            <Link to='/secondary'>Secondary SEL</Link>
          </p>
          <p>
            <Link to='/mypeekaville#top'>SEL Learning Game</Link>
          </p>
          <p>
            <ExternalLink href={Documents.shopSite} target='_blank'>
              SEL Print Books
            </ExternalLink>
          </p>
        </section>
        <section>
          <h1>About Us</h1>

          <p>
            <Link to='/about#top'>Our story</Link>
          </p>
          <p>
            <ExternalLink href={Documents.blogSite} target='_blank'>
              SEL Blog
            </ExternalLink>
          </p>
          <p>
            <Link to='/careers'>Careers</Link>
          </p>
          <p>
            <Link to='/press'>Press</Link>
          </p>
        </section>
        <section>
          <h1>Resources</h1>
          <p>
            <ExternalLink href='https://help.peekapak.com' target='_blank'>
              FAQs
            </ExternalLink>
          </p>
          <p>
            <Link to='/parents#top'>Parents</Link>
          </p>
        </section>
        <section>
          <h1>Legal</h1>
          <p>
            <Link to='/privacyPolicy#top' className={styles.privacyPolicy}>
              Privacy Policy
            </Link>
          </p>
          <p>
            <Link to='/termsOfUse#top'>Terms of Use</Link>
          </p>
        </section>
        <section className={styles.filler}></section>
        <section className={styles.filler}></section>
      </nav>
      <section className={cx(styles.contactCard, 'vcard')}>
        <figure>
          <object data={whiteLogo} type='image/svg+xml'>
            white backpack word logo
          </object>
          <figcaption>
            <p
              className='fn org'
              style={{
                display: 'none',
              }}
            >
              Peekapak Inc.
            </p>
            <p>
              <a className='email' href='mailto:hello@peekapak.com'>
                hello@peekapak.com
              </a>
              <br />
              {isUSAddress ? (
                <span className='adr'>
                  <span className='street-address'>
                    447 Broadway, 2nd Floor #1190
                  </span>
                  <br />
                  <span className='locality'>New York, NY 10013</span>
                </span>
              ) : (
                <span className='adr'>
                  <span className='street-address'>
                    192 Spadina Ave Suite 300,
                  </span>
                  <br />
                  <span className='locality'>Toronto</span>
                </span>
              )}
            </p>
            <a className='tel' href='tel:+18885136418'>
              1-888-513-6418
            </a>
          </figcaption>
        </figure>
        <figure>
          <ExternalLink href='http://facebook.com/peekapak' target='_blank'>
            <i className='fab fa-facebook-f' />
          </ExternalLink>
          <ExternalLink href='http://twitter.com/peekapak' target='_blank'>
            <i className='fab fa-twitter' />
          </ExternalLink>
        </figure>
      </section>
    </footer>
  );
};

export default Footer;
