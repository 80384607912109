import { useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { deleteCookie, setCookie } from '../../aaa';
import { isUserLoggedIn } from '../../UserManagementHelpers';
import RedeemLicenseCodeForm from '../RedeemLicenseCodeForm';
import PrimaryButton from '../PrimaryButton';
import sidePicElTheme from '../../images/redeem-purchase-el.png';
import sidePicMsTheme from '../../images/redeem-purchase-ms.png';
import cx from 'classnames';
import libraryStyles from '../../SCSS/Library.module.scss';
import styles from './RedeemOrPurchaseLicenseCode.module.scss';

interface Prop {
  className?: string;
}

const RedeemOrPurchaseLicenseCode = ({ className }: Prop): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const onClickLogin = () => {
    setCookie('peekapak.pathBeforeLoginRedirect', location.pathname, 7);
  };

  useEffect(() => {
    isUserLoggedIn();
    deleteCookie('peekapak.pathBeforeLoginRedirect');
  }, []);

  return (
    <section
      className={cx(styles.redeemOrPurchaseLicenseCode, {
        [`${className}`]: className,
      })}
    >
      <div className={cx(styles.track, styles.redeem)}>
        <div className={styles.trackCopyContainer}>
          <div className={styles.trackTitle}>Have a license code?</div>
          <div className={styles.trackContent}>
            {isUserLoggedIn() ? (
              <>
                <p>
                  To upgrade your account, please input the license code you
                  were provided into the space below.
                </p>
                <p>
                  The code will be validated, and if valid, you can click on the
                  ‘Upgrade’ button to upgrade your account.
                </p>
              </>
            ) : (
              <>
                <p>
                  To upgrade your account and enter your license code, you must
                  be logged in to Peekapak. Log in to your account using the
                  button below.
                </p>
                <p className={styles.nonMemeberTextLine}>
                  Not a member? Sign up{' '}
                  <Link to='/signup' className={libraryStyles.textLink}>
                    here
                  </Link>
                  .
                </p>
              </>
            )}
          </div>
        </div>
        {isUserLoggedIn() ? (
          <div className={styles.buttonContainer}>
            <RedeemLicenseCodeForm buttonText='Upgrade Me!' orange />
            <Link
              to='/subscribe/classroomPro'
              className={libraryStyles.textLink}
            >
              Purchase a license code
            </Link>
          </div>
        ) : (
          <Link
            to={{
              pathname: '/login',
              state: { nextPathname: location.pathname },
            }}
          >
            <PrimaryButton orange onClick={onClickLogin}>
              Log In
            </PrimaryButton>
          </Link>
        )}
        <img
          className={styles.sidePic}
          src={sidePicElTheme}
          alt={sidePicElTheme.toString()}
        />
      </div>
      <div className={cx(styles.track, styles.purchase)}>
        <div className={styles.trackCopyContainer}>
          <div className={styles.trackTitle}>Ready to buy a license code?</div>
          <div className={styles.trackContent}>
            <p>
              To obtain access to Peekapak’s PRO features, educators can
              purchase a license for up to 30 students via credit card or
              request a quote from our team for larger implementations.
            </p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            secondary
            onClick={() => history.push('/subscribe/classroomPro')}
          >
            Purchase License
          </PrimaryButton>
          <PrimaryButton onClick={() => history.push('/requestInfo')}>
            Request Quote
          </PrimaryButton>
        </div>
        <img
          className={styles.sidePic}
          src={sidePicMsTheme}
          alt={sidePicElTheme.toString()}
        />
      </div>
    </section>
  );
};

export default RedeemOrPurchaseLicenseCode;
