import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GeneralErrorMessage } from '../GeneralErrorMessage';

export interface ErrorObject {
  message: string;
  title: string;
  onOkay?: null | (() => void);
}

type onShowErrorFunctionType = (
  message: string,
  title?: string,
  onOkay?: () => void
) => void;

export interface ShowErrorInterface {
  onShowErrorMessage: onShowErrorFunctionType;
}

const ErrorStatusContext = createContext<any>(undefined);

export const EnableErrorMessagePopup = ({ children }) => {
  const history = useHistory();
  const [errorObject, setErrorObject] = useState<ErrorObject>();

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  useEffect(() => {
    // Listen for changes to the current location.
    // console.debug(
    //   `%cEnableErrorMessagePopup useEffect called`,
    //   'background: black; color: red'
    // );
    const unlisten = history.listen(() => setErrorObject(undefined));
    // cleanup the listener on unmount
    return unlisten;
  });

  // This is what the component will render. If it has an
  // errorStatusCode that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => {
    // console.debug(`%cEEMP.renderContent`, 'background: blue; color: yellow');
    if (errorObject) {
      const { message, title } = errorObject;
      return (
        <GeneralErrorMessage
          isShow={true}
          onHide={hideErrorMessage}
          messageText={message}
          titleText={title || 'There was a problem'}
          onOkay={handleOnOkay}
        />
      );
    }

    return children;
  };
  // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
  const contextPayload = useMemo(() => {
    // console.debug(`%cEEMP.contextPayload`, 'background: blue; color: yellow');
    return { setErrorObject };
  }, [setErrorObject]);

  return (
    <ErrorStatusContext.Provider value={onShowErrorMessage}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );

  function onShowErrorMessage(message, title, onOkay) {
    setErrorObject({
      message,
      title,
      onOkay,
    });
  }

  function handleOnOkay() {
    console.debug(`%cEEMP.handleOnOkay`, 'background: blue; color: yellow');
    if (errorObject && errorObject.onOkay) {
      return errorObject.onOkay();
    }

    return;
  }

  function hideErrorMessage() {
    console.debug(`%cEEMP.hideErrorMessage`, 'background: blue; color: yellow');
    setErrorObject(undefined);
  }
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useErrorStatus = () => useContext(ErrorStatusContext);

export const withErrorStatus = (Component: any) => {
  return (props: any) => {
    const { onShowErrorMessage } = useErrorStatus();

    return <Component onShowErrorMessage={onShowErrorMessage} {...props} />;
  };
};
export default EnableErrorMessagePopup;
