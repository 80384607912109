import { useParams } from 'react-router-dom';
import LessonPlanLayout from './LessonPlanLayout';
import MiddleSchoolLessonLayout from './MiddleSchoolUnitPage/MiddleSchoolLessonLayout';
import { History, Location } from 'history';

type urlParams = {
  grade: string;
};

interface Params {
  classroomName: string;
  unitId: string;
  subunitId: string | null | undefined;
  subunitNumber: string | null | undefined;
  lessonNumber: string | null | undefined;
  stepNumber: string | null | undefined;
}

interface Props {
  loginState: string;
  isAuthenticated: boolean;
  handleOpenGetStartedDialog: (event: React.MouseEvent<HTMLDivElement>) => void;
  history: History;
  location: Location;
  params: Params;
}

const UnitLessonRedirect = (props: Props): JSX.Element => {
  const { grade }: urlParams = useParams();

  const numGrade = parseInt(grade);

  const propagatingProps = {
    loginState: props.loginState,
    isAuthenticated: props.isAuthenticated,
    onShowGetStartedDialog: props.handleOpenGetStartedDialog,
    history: props.history,
    location: props.location,
    params: props.params,
  };

  return numGrade > 5 ? (
    <MiddleSchoolLessonLayout />
  ) : (
    <LessonPlanLayout {...propagatingProps} />
  );
};

export default UnitLessonRedirect;
