import { Link } from 'react-router-dom';
import MyHelmet from './MyHelmet';
import UnauthenticatedHeader from './components/UnauthenticatedHeader/UnauthenticatedHeader';
import Footer from './core/Footer';
import StandalonePageWrapper from './StandalonePageWrapper';
import Images from './Images';
import homeStyles from './SCSS/Home.module.scss';
import styles from './SCSS/NotFoundSoRedirect.module.scss';

export const NotFoundSoRedirect = (props) => (
  <div className={homeStyles.anonContainer} id='top'>
    <MyHelmet>
      <meta name='keywords' content='Social Emotional Learning Activities' />
      <meta
        name='description'
        content={`Social emotional learning activities that \
your students will love! Engaging guided reading books, ELA-aligned lessons and \
personalize learning experiences.`}
      />
      <title>404 Page Not Found | Peekapak</title>
    </MyHelmet>
    <UnauthenticatedHeader router={props.router} />
    <StandalonePageWrapper>
      <div className={styles.notFoundSoRedirectContainer}>
        <div className={styles.imageContainer}>
          <img src={Images.backpackPatch} alt='404 error' />
        </div>
        <div className={styles.messageContainer}>
          <p>
            <h1>Uh oh! We got a 404 error!</h1>
            Oops, something has gone wrong. The URL you entered{' '}
            <strong className={styles.urlLink}>
              {window.location.href}
            </strong>{' '}
            is not found on our servers. Please note our URLs are case
            sensitive. Double check what you submitted, or return to{' '}
            <Link to='/' className={styles.textLink}>
              Peekapak
            </Link>
            .
          </p>
          <p>
            If this continues to happen, please let us know by using the chat
            window in the bottom-right of this page, or by emailing us at{' '}
            <a href='mailto:hello@peekapak.com' className={styles.textLink}>
              hello@peekapak.com.
            </a>
          </p>
          <p className={styles.noMarginBottom}>Thanks!</p>
          <p>Your Friends at Peekapak</p>
        </div>
      </div>
    </StandalonePageWrapper>
    <Footer />
  </div>
);
