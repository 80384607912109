import { useEffect, useRef, useState } from 'react';
import { useDebouncedEffect } from '../../CustomHooks';
import styles from './ExpandableNoNaviContentCard.module.scss';
import { Icon } from '@iconify/react';
import bxsRightArrow from '@iconify-icons/bx/bxs-right-arrow';

export type Props = {
  expandable?: boolean;
  expanded: boolean;
  title: string;
  children: JSX.Element | JSX.Element[];
  titleClassName?: string;
};

const ExpandableNoNaviContentCard = ({
  expandable,
  expanded,
  title,
  children,
  titleClassName,
}: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [textHeight, setTextHeight] = useState('0px');

  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
      setTextHeight(
        isExpanded || contentRef.current === null
          ? '0px'
          : `${contentRef.current.scrollHeight}px`
      );
    }
  };

  const handleResize = () => {
    const newTextHeight =
      !isExpanded || contentRef.current === null
        ? `0px`
        : `${contentRef.current.scrollHeight}px`;
    setTextHeight(newTextHeight);
  };

  useDebouncedEffect(
    () => window.addEventListener('resize', handleResize),
    [handleResize],
    1000
  );

  useEffect(() => {
    if (expanded || isExpanded)
      setTextHeight(`${contentRef.current?.scrollHeight}px`);
  }, [children]);

  return (
    <div className={styles.accordionContainer}>
      <button onClick={toggleAccordion}>
        <h3 className={titleClassName ? titleClassName : styles.title}>
          {title}
        </h3>
        <div className={styles.iconContainer}>
          <Icon
            icon={bxsRightArrow}
            width={16}
            height={16}
            rotate={isExpanded ? -1 : 0}
            color='#00afd8'
          />
        </div>
      </button>
      <div
        className={styles.accordionText}
        style={{
          maxHeight: `${textHeight}`,
        }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableNoNaviContentCard;
