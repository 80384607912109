import { useState } from 'react';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../Button';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { FormikOnChangeAutosave } from '../FormikAutosave';
import { saveAssignment, submitAssignment } from '../../BackendInterface';
import { Icon } from '@iconify/react';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import styles from './ToDoSurvey.module.scss';

interface Props {
  data: {
    type: string;
    index: number;
    content: [{ type: string; index: number }];
  };
  studentWork: {
    userId: string;
    createdAt: number;
    work: { content: { answerIndex: number }[] };
  };
  updateAssignment: (assignment: any) => void;
}

interface FormValues {
  index: number;
  value: string;
}

const ToDoSurvey = ({
  data,
  studentWork,
  updateAssignment,
}: Props): JSX.Element => {
  const [isShowSpinner, setShowSpinner] = useState(false);

  const { activity, toDo, grade, language } = useParams();
  const history = useHistory();
  const isTeacher = isNaN(parseInt(toDo));

  const existingWork =
    studentWork?.work?.content !== undefined
      ? studentWork.work.content[0].content.filter((item) =>
          Number.isFinite(item.index)
        )
      : null;

  const studentChoices = !existingWork
    ? null
    : existingWork.map((object) => object.answerIndex);
  const initialFormValues: FormValues = data.content?.reduce(
    (acc, curr, currentIndex) => ({
      ...acc,
      [curr.index]:
        existingWork === null ||
        studentChoices === undefined ||
        studentChoices[currentIndex] === null ||
        studentChoices[currentIndex] === undefined
          ? ''
          : studentChoices[currentIndex].toString(),
    }),
    {}
  );

  const hasTextBlock = data?.content?.some((item) => item.type === 'textBlock');

  const multipleChoiceSchema = data.content?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.index]: Yup.string().required(
        'Please pick an answer for all questions'
      ),
    }),
    {}
  );

  const validationSchema = Yup.object().shape(multipleChoiceSchema);

  const handleSurveySubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setShowSpinner(true);

    try {
      const mcValueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          isNaN(parseInt(v))
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ],
        []
      );
      const multipleChoiceWork = {
        type: 'multipleChoiceAnswerGroup',
        index: data.index,
        content: mcValueArray,
      };

      const submitResponse = await submitAssignment(
        studentWork.userId,
        parseInt(toDo),
        [multipleChoiceWork]
      );
      console.info(`result = ${JSON.stringify(submitResponse, null, 2)}`);
      actions.setSubmitting(false);
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
    } finally {
      setShowSpinner(false);
      history.push(
        `/studentportal/activity/${activity}/${language}/${grade}/toDo/${toDo}/confirmation`
      );
    }
  };

  const handleMCSave = async (values: FormValues) => {
    try {
      const valueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          isNaN(parseInt(v))
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ],
        []
      );
      const multipleChoiceWork = {
        type: 'multipleChoiceAnswerGroup',
        index: data.index,
        content: valueArray,
      };
      await saveAssignment(studentWork.userId, parseInt(toDo), [
        multipleChoiceWork,
      ]);

      updateAssignment({
        userId: studentWork.userId,
        createdAt: parseInt(toDo),
        work: { content: [multipleChoiceWork] },
      });
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
    }
  };

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      <h3>{data.title}</h3>
      <Formik
        key='reflectionSurvey'
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSurveySubmit}
      >
        {({ errors, touched, isValid }) => {
          return (
            <Form key='reflectionSurvey form'>
              {data.content.map((question, ind) => {
                const questionIndex = question.index.toString();
                return question.type === 'textBlock' ? (
                  <div
                    key={`textBlock ${ind}`}
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                      __html: question.content,
                    }}
                  />
                ) : (
                  <div key={`question ${hasTextBlock ? ind : ind + 1}`}>
                    <div className={styles.question}>
                      {hasTextBlock ? ind : ind + 1}.&nbsp;
                      {question.prompt}
                    </div>
                    <div
                      id={question.index}
                      role='group'
                      aria-labelledby={question.index}
                      className={styles.answers}
                    >
                      {question.choices?.length > 0 &&
                        question.choices?.map(
                          (choice: string, aInd: number) => {
                            return (
                              <label
                                key={`question#: ${ind + 1} answer: ${
                                  aInd + 1
                                }`}
                              >
                                <Field
                                  type='radio'
                                  name={question.index}
                                  value={aInd.toString()}
                                />
                                &nbsp;&nbsp;{choice}
                              </label>
                            );
                          }
                        )}
                    </div>
                    {errors[questionIndex] && touched[questionIndex] && (
                      <div className={styles.errors}>
                        <Icon
                          icon={alertCircleFill}
                          color='#ff6319'
                          className={styles.icon}
                          width={18}
                        />
                        {errors[questionIndex]}
                      </div>
                    )}
                  </div>
                );
              })}
              <FormikOnChangeAutosave saveHandler={handleMCSave} />
              <Button type='submit' darkBlue thinText disabled={isTeacher}>
                Submit Your Responses&nbsp;&nbsp;&rarr;
              </Button>
              {!isValid &&
                Object.keys(touched).length ===
                  Object.keys(initialFormValues).length && (
                  <div className={styles.globalError}>
                    Unable to submit, please check your answers and try again
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ToDoSurvey;
