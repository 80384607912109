export const useScrollToSection = () => {
  const scrollToSection = (
    ref?: React.MutableRefObject<HTMLDivElement>,
    offsetNumber?: number
  ) => {
    if (!ref || !ref.current) {
      return;
    }
    window.scrollTo({
      top: offsetNumber
        ? ref.current.offsetTop + offsetNumber
        : ref.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };
  return { scrollToSection };
};
