import { Component } from 'react';
import { Button } from 'react-bootstrap';
import {
  CoreStandardsExpandable,
  Expandable,
  ExpandableGroup,
  LearningOutcomesExpandable,
  Resources,
} from './lessonPlanComponents';
import StepHeaderButton from './StepHeaderButton';
import { printLesson } from './UtilityFunctions';
import { ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';
import styles from '../SCSS/lpMain.module.scss';

interface Props {
  currentActivity: ElementaryUnit['value']['lessonPlan']['subUnits'][0]['lessons'][0]['activities'][0];
  isHomeActivity: boolean;
  isPreviewMode: boolean;
  onMarkStepComplete: (unitPosition: UnitPosition) => void;
  onResetStep: (unitPosition: UnitPosition) => void;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  unitPosition: UnitPosition;
}

class UnitClassActivityContent extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handlePrint = this.handlePrint.bind(this);
    this.getStepTitle = this.getStepTitle.bind(this);
  }

  handlePrint() {
    if (this.props.isPreviewMode) {
      return null;
    }
    const heading = `${
      this.props.currentActivity.contentLabel
    }: ${this.getStepTitle()}`;
    printLesson(heading);
  }

  getStepTitle() {
    return this.props.currentActivity.title;
  }

  render() {
    return (
      <div className={styles.greyBackgroundLessonViewer}>
        <div className={styles.expandableHolder}>
          <Expandable
            label='Overview'
            text={this.props.currentActivity.description.text}
            openByDefault
          />
          <Resources
            data={this.props.currentActivity.files}
            contentType='step'
          />
          <ExpandableGroup
            data={this.props.currentActivity.prologue}
            openByDefault={false}
          />
          <ExpandableGroup
            data={
              this.props.unitMeta.subUnits[this.props.unitPosition.subunit]
                .lessons[this.props.unitPosition.lesson].prologue
            }
            openByDefault={false}
          />
          <LearningOutcomesExpandable
            data={
              this.props.unitMeta.subUnits[this.props.unitPosition.subunit]
                .lessons[this.props.unitPosition.lesson].learningOutcomes
            }
            contentType='step'
          />
          <CoreStandardsExpandable
            label='ELA Standards'
            data={this.props.currentActivity.coreStandards}
          />
          <CoreStandardsExpandable
            label='Additional Standards'
            data={this.props.currentActivity.additionalStandards}
          />
        </div>
        <div className={styles.stepContentMenu}>
          <span className={styles.stepContentHeader}>
            {this.props.currentActivity.contentLabel}
          </span>
          <Button className={styles.stepPrintButton} onClick={this.handlePrint}>
            <i className='fa fa-print' aria-hidden='true' />
          </Button>
          <hr
            className={styles.underHeadingSeparator}
            style={{ marginLeft: '-10px', marginRight: '-10px' }}
          />
          <div
            className={styles.stepContentContent}
            id='stepContentContent'
            dangerouslySetInnerHTML={{
              __html: this.props.currentActivity.content.text,
            }}
          />
          <StepHeaderButton
            unitPosition={this.props.unitPosition}
            isPreviewMode={this.props.isPreviewMode}
            onMarkStepComplete={this.props.onMarkStepComplete}
            onResetStep={this.props.onResetStep}
            shareStep={this.props.isHomeActivity}
          />
        </div>
      </div>
    );
  }
  ////<CoreStandardsExpandable data={ this.props.activityMeta.coreStandards } openByDefault={ false } />
}

export default UnitClassActivityContent;
