import loadable from '@loadable/component';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './ApplicationState';
import MyHelmet from './MyHelmet';
import Footer from './core/Footer';
import styles from './SCSS/Home.module.scss';
import OauthLogin from './OauthLogin';
import OauthPreAuthorizedLogin from './OauthPreAuthorizedLogin';
import CleverLogin from './CleverLogin';
import { EnableErrorMessagePopup } from './components/EnableErrorMessagePopup';
import { SmartHeader } from './components/SmartHeader';
import { LoginState } from './UserManagement';
import { sendUserToRoleHomePage } from './GlobalFunctions';
import LandingPageLayout from './Landing/LandingPageRedirect/LandingPageLayout';

const OptinSuccessPage = loadable(() => import('./OptinSuccessPage'));
const TexasPage = loadable(() => import('./StatePages/TexasPage'));
const CaliforniaPage = loadable(() => import('./StatePages/CaliforniaPage'));
const OklahomaPage = loadable(() => import('./StatePages/OklahomaPage'));
const AboutUs = loadable(() => import('./AboutUs'));
const Parents = loadable(() => import('./Parents'));
const Elementary = loadable(() => import('./anonymous/Elementary'));
const FamilyWellBeing = loadable(() => import('./FamilyWellBeing'));
const EmployeeWellBeing = loadable(() => import('./EmployeeWellBeing'));
const DistrictLead = loadable(() => import('./DistrictLead'));
const FundingPage = loadable(() => import('./FundingPage'));
const GrantToolkit = loadable(() => import('./GrantToolkit'));
const MyPeekaville = loadable(() => import('./MyPeekavillePage'));
const ProductPage = loadable(() => import('./anonymous/ProductPage'));
const LandingRemoteLearning = loadable(() => import('./LandingRemoteLearning'));
const TermsOfUse = loadable(() => import('./TermsOfUse'));
const PrivacyPolicy = loadable(() => import('./PrivacyPolicy'));
const Resources = loadable(() => import('./Resources'));
const Features = loadable(() => import('./Features'));
const Pricing = loadable(() => import('./PricingPage'));
const RequestInfo = loadable(() => import('./RequestInfo'));
const RequestInfoSuccess = loadable(() => import('./RequestInfoSuccess'));
const SelectTrialLandingPage = loadable(
  () => import('./FourteenDayTrial/SelectTrialLandingPage')
);
const ElementaryTrialPage = loadable(
  () => import('./FourteenDayTrial/ElementaryTrialPage')
);
const MiddleSchoolTrialPage = loadable(
  () => import('./FourteenDayTrial/MiddleSchoolTrialPage')
);
const HighSchoolTrialPage = loadable(
  () => import('./FourteenDayTrial/HighSchoolTrialPage')
);
const RegistrationConfirmation = loadable(
  () => import('./RegistrationConfirmation')
);
const LoginNoRoleSelector = loadable(() => import('./LoginNoRoleSelector'));
const MiddleSchoolLanding = loadable(() => import('./MiddleSchoolLanding'));
const HighSchoolLanding = loadable(() => import('./HighSchoolLanding'));
const ResendNewPasswordConfirmation = loadable(
  () => import('./ResendNewPasswordConfirmation')
);
const NewPasswordConfirmation = loadable(
  () => import('./NewPasswordConfirmation')
);
const PasswordReset = loadable(() => import('./PasswordReset'));
const PasswordResetLinkSent = loadable(() => import('./PasswordResetLinkSent'));
const PasswordResetIntermediatePage = loadable(
  () => import('./PasswordResetIntermediatePage')
);
const RoleSelector = loadable(() => import('./RoleSelector'));
const SessionExpired = loadable(() => import('./SessionExpired'));
const Unauthorized = loadable(() => import('./Unauthorized'));
const ParentalInviteAcknowledge = loadable(
  () => import('./ParentalInviteAcknowledge')
);
const ParentDeclineAcknowledge = loadable(
  () => import('./ParentDeclineAcknowledge')
);
const ParentOptOutAcknowledge = loadable(
  () => import('./ParentOptOutAcknowledge')
);
const EUWaitlist = loadable(() => import('./EUWaitlist'));
const MembersOnlyIntermediatePage = loadable(
  () => import('./MembersOnlyIntermediatePage')
);
const ForStudentsIntermediatePage = loadable(
  () => import('./ForStudentsIntermediatePage')
);
const StorybookForParents = loadable(() => import('./StorybookForParents'));
const ResearchPageElementary = loadable(
  () => import('./Research/ResearchPageElementary')
);
const ResearchPageMiddleSchool = loadable(
  () => import('./Research/ResearchPageMiddleSchool')
);
const ResearchPageHighSchool = loadable(
  () => import('./Research/ResearchPageHighSchool')
);
const Press = loadable(() => import('./Press'));
const CaseStudyRoutes = loadable(() => import('./CaseStudies/CaseStudyRoutes'));
const WhatIsSELPage = loadable(() => import('./WhatIsSELPage'));
const WebinarPage = loadable(() => import('./WebinarPage'));
const SuccessStoriesPage = loadable(() => import('./SuccessStoriesPage'));
const DesignPrinciplesPage = loadable(() => import('./DesignPrinciplesPage'));
const ProfessionalDevelopmentPage = loadable(
  () => import('./ProfessionalDevelopmentPage')
);
const Educators = loadable(() => import('./AudiencesMarketingPage/Educators'));
const Counselors = loadable(
  () => import('./AudiencesMarketingPage/Counselors')
);
const WhoIsItForPage = loadable(() => import('./WhoIsItForPage'));
const Careers = loadable(() => import('./CareersPage'));
const Contact = loadable(() => import('./ContactUsPage'));
const LibraryEditionPage = loadable(() => import('./LibraryEditionPage'));
const TechSpecsPage = loadable(() => import('./TechSpecsPage'));

interface Props {
  noHeader?: boolean;
  noFooter?: boolean;
  headerBgColor?: string;
}

function UnauthenticatedParent({ noHeader, noFooter, headerBgColor }: Props) {
  const history = useHistory();
  const loginState = useSelector((state: RootState) => state.user.loginState);
  const userProfile = useSelector((state: RootState) => state.user.userProfile);

  return (
    <EnableErrorMessagePopup>
      <div className={styles.anonContainer} id='top'>
        <MyHelmet />
        {!noHeader && (
          <SmartHeader
            headerBgColor={headerBgColor ? headerBgColor : '#f9f9f9'}
          />
        )}
        <Switch>
          <Route
            exact
            path='/'
            render={() => {
              if (
                loginState &&
                loginState === LoginState.loggedIn &&
                userProfile
              ) {
                sendUserToRoleHomePage(userProfile, history, true, false);
              }
              return <LandingPageLayout />;
            }}
          ></Route>
          <Route path='/elementary'>
            <Elementary />
          </Route>
          <Route path='/secondary'>
            <MiddleSchoolLanding />
          </Route>
          <Route path='/highSchool'>
            <HighSchoolLanding />
          </Route>
          <Route path='/about'>
            <AboutUs />
          </Route>
          <Route path='/parents'>
            <Parents />
          </Route>
          <Route path='/districtLead'>
            <DistrictLead />
          </Route>
          <Route path='/myPeekaville'>
            <MyPeekaville />
          </Route>
          <Route path='/product'>
            <ProductPage />
          </Route>
          <Route path='/(remote|covid)'>
            <LandingRemoteLearning />
          </Route>
          <Route path='/requestInfo'>
            <RequestInfo />
          </Route>
          <Route path='/requestInfoSuccess'>
            <RequestInfoSuccess />
          </Route>
          <Route path='/termsOfUse'>
            <TermsOfUse />
          </Route>
          <Route path='/privacyPolicy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/resources'>
            <Resources
              displayBottomCTA={loginState !== LoginState.loggedIn}
              displayHero={loginState !== LoginState.loggedIn}
            />
          </Route>
          <Route path='/features'>
            <Features />
          </Route>
          <Route path='/pricing'>
            <Pricing />
          </Route>
          <Route path='/trial'>
            <SelectTrialLandingPage />
          </Route>
          <Route path='/elementaryTrial'>
            <ElementaryTrialPage />
          </Route>
          <Route path='/middleSchoolTrial'>
            <MiddleSchoolTrialPage />
          </Route>
          <Route path='/highSchoolTrial'>
            <HighSchoolTrialPage />
          </Route>
          <Route path='/redirect/:origin'>
            <RoleSelector />
          </Route>
          <Route path='/oauth/login'>
            <OauthLogin />
          </Route>
          <Route path={['/oauth/preauth/:providerId', '/preauth/:providerId']}>
            <OauthPreAuthorizedLogin />
          </Route>
          <Route path='/oauth/secondary/Clever'>
            <CleverLogin />
          </Route>
          <Route path='/newPasswordConfirmation'>
            <NewPasswordConfirmation />
          </Route>
          <Route path='/resendNewPasswordConfirmation'>
            <ResendNewPasswordConfirmation />
          </Route>
          <Route path='/passwordReset'>
            <PasswordReset />
          </Route>
          <Route path='/resetConfirmation'>
            <PasswordResetLinkSent />
          </Route>
          <Route path='/passwordResetRedirect'>
            <PasswordResetIntermediatePage />
          </Route>
          <Route path='/sessionExpired'>
            <SessionExpired />
          </Route>
          <Route path='/unauthorized'>
            <Unauthorized />
          </Route>
          <Route path='/parentalInviteAcknowledge'>
            <ParentalInviteAcknowledge />
          </Route>
          <Route path='/parentDecline'>
            <ParentDeclineAcknowledge />
          </Route>
          <Route path='/parentOptOut'>
            <ParentOptOutAcknowledge />
          </Route>
          <Route path='/researchElementary'>
            <ResearchPageElementary />
          </Route>
          <Route path='/researchMiddleSchool'>
            <ResearchPageMiddleSchool />
          </Route>
          <Route path='/researchHighSchool'>
            <ResearchPageHighSchool />
          </Route>
          <Route path='/waitlist'>
            <EUWaitlist />
          </Route>
          <Route path='/membersOnlyRedirect'>
            <MembersOnlyIntermediatePage />
          </Route>
          <Route path='/forStudents'>
            <ForStudentsIntermediatePage />
          </Route>
          <Route path='/storybookForParents'>
            <StorybookForParents />
          </Route>
          <Route path='/press'>
            <Press />
          </Route>
          <Route path='/familyWellBeing'>
            <FamilyWellBeing />
          </Route>
          <Route path='/employeeWellBeing'>
            <EmployeeWellBeing />
          </Route>
          <Route path='/successStories/:successStory'>
            <CaseStudyRoutes />
          </Route>
          <Route path='/whatIsSEL'>
            <WhatIsSELPage />
          </Route>
          <Route path='/webinars'>
            <WebinarPage />
          </Route>
          <Route path='/successStories'>
            <SuccessStoriesPage />
          </Route>
          <Route path='/funding'>
            <FundingPage />
          </Route>
          <Route path='/grantToolkit'>
            <GrantToolkit />
          </Route>
          <Route path='/designPrinciples'>
            <DesignPrinciplesPage />
          </Route>
          <Route path='/professionalDevelopment'>
            <ProfessionalDevelopmentPage />
          </Route>
          <Route path='/educators'>
            <Educators />
          </Route>
          <Route path='/counselors'>
            <Counselors />
          </Route>
          <Route path='/whoItsFor'>
            <WhoIsItForPage />
          </Route>
          <Route path='/careers'>
            <Careers />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/texas-SEL'>
            <TexasPage />
          </Route>
          <Route path='/california-SEL'>
            <CaliforniaPage />
          </Route>
          <Route path='/oklahoma-SEL'>
            <OklahomaPage />
          </Route>
          <Route path='/libraryEdition'>
            <LibraryEditionPage />
          </Route>
          <Route path='/techSpecs'>
            <TechSpecsPage />
          </Route>
        </Switch>
        {!noFooter && <Footer />}
      </div>
      <Route path='/login'>
        <LoginNoRoleSelector />
      </Route>
      <Route path='/registrationConfirmation'>
        <RegistrationConfirmation />
      </Route>
      <Route path='/optinSuccess'>
        <OptinSuccessPage />
      </Route>
    </EnableErrorMessagePopup>
  );
}

export default UnauthenticatedParent;
