import { logger } from './error-tracker';
import styles from './SCSS/Spotlight.module.scss';
type Prop = {
  target: Element | null | undefined;
};

const Spotlight = (props: Prop): JSX.Element | null => {
  const boundingRect = props.target && props?.target.getBoundingClientRect();

  if (!boundingRect) {
    return null;
  }

  if (props.target?.offsetParent?.nodeName !== 'BODY') {
    logger.logMessage(
      `Spotlight Warning: offsetParent is not Body but `,
      props.target?.offsetParent?.nodeName
    );
  }

  // in order for the spotlight to be positioned properly on the page
  // the target DOM node must have its offsetParent set to body, i.e.
  // the page, rather than a positioned element
  const x = props.target.offsetLeft + boundingRect.width / 2;
  const y = props.target.offsetTop + boundingRect.height / 2;

  /* preserve for potential debugging
  console.debug(`${props.targetName} Spotlight x = `, x, ` y = `, y);
  console.debug(
    `${props.targetName} Spotlight parent `,
    props.target.offsetParent
  );
  console.debug(`${props.targetName} Spotlight boundingRect `, boundingRect);
   */

  return (
    <div
      className={styles.spotlight}
      style={{
        backgroundImage: `radial-gradient(
          ellipse ${boundingRect.width}px ${
          boundingRect.height * 1.25
        }px at ${x}px ${y}px,
          transparent ${boundingRect.width * 0.5}px,
          rgba(0, 0, 0, 0.75) ${boundingRect.width * 1.1}px)`,
      }}
    ></div>
  );
};

export default Spotlight;
