import { expireSessionAndRedirect } from '../../aaa';
import { getContentRequest, postServiceRequest } from './../../serviceAgent';
import { getAppRoot } from './../../identity';
import { getAuthorizationToken } from '../../BackendInterface';
import { getUsersEmail } from '../../GlobalFunctions';
import { getStore } from '../../ApplicationState';
import { updatePageTracker } from '../../Session';
import {
  ElementaryUnit,
  PageTracker,
} from '../../../peekapak-types/DataProtocolTypes';

// Get functions
export const getUnit = (unitInfo: {
  keys: { name: string; version: string };
  lessonMeta: {};
  lastVersion: number;
}): Promise<ElementaryUnit> =>
  // POSETSERVREQ needs unit with proper capitalization and grade
  new Promise((resolve, reject) => {
    getAuthorizationToken().then((Authorization) => {
      postServiceRequest(
        `${getAppRoot()}api/getPublisherContent`,
        {
          keys: unitInfo.keys,
          lessonMeta: unitInfo.lessonMeta,
          lastVersion: unitInfo.lastVersion,
        },
        (status: Response['status'], request: XMLHttpRequest) => {
          if (
            status === 200 &&
            request.response !== '{}' &&
            JSON.parse(request.response).lessonPlan.title !== undefined
          ) {
            resolve({
              key: 'unit',
              value: JSON.parse(request.response),
            });
          } else {
            reject(status);
          }
        },
        (status: Response['status'], _: XMLHttpRequest) => {
          if (status === 401) {
            expireSessionAndRedirect();
          }
        },
        {
          Authorization,
        }
      );
    });
  });

export const getActivity = (activity: string) =>
  // POSETSERVREQ needs unit with proper capitalization and grade
  new Promise((resolve, reject) => {
    getAuthorizationToken().then((Authorization) => {
      postServiceRequest(
        `${getAppRoot()}api/getcontent`,
        { contentType: 'activity', url: activity },
        (status: Response['status'], request: XMLHttpRequest) => {
          if (status === 200) {
            resolve({
              key: 'activity',
              value: JSON.parse(request.response),
            });
          } else {
            reject(status);
          }
        },
        (status: Response['status'], _: XMLHttpRequest) => {
          if (status === 401) {
            expireSessionAndRedirect();
          }
        },
        {
          Authorization,
        }
      );
    });
  });

export const getCompletionStatus = (key: string) =>
  // POSETSERVREQ needs unit with proper capitalization and grade
  new Promise((resolve, reject) => {
    getAuthorizationToken().then((Authorization) => {
      postServiceRequest(
        `${getAppRoot()}api/getCompletionStatus`,
        key,
        (status: Response['status'], request: XMLHttpRequest) => {
          if (status === 200) {
            resolve({
              key: 'completionStatus',
              value: JSON.parse(request.response),
            });
          } else {
            reject(status);
          }
        },
        (status: Response['status'], _: XMLHttpRequest) => {
          if (status === 401) {
            expireSessionAndRedirect();
            reject(status);
          } else {
            console.error(
              `error getCompletionStatus key = ${JSON.stringify(key, null, 2)}`
            );
            reject(status);
          }
        },
        {
          Authorization,
        }
      );
    });
  });

export const getClassroomsList = () =>
  // POSETSERVREQ needs unit with proper capitalization and grade
  new Promise((resolve, reject) => {
    getAuthorizationToken().then((Authorization) => {
      const email = getUsersEmail();
      postServiceRequest(
        `${getAppRoot()}api/getClassroomsList`,
        { email },
        (status: Response['status'], request: XMLHttpRequest) => {
          if (status === 200) {
            resolve({
              key: 'classrooms',
              value: JSON.parse(request.response),
            });
          } else {
            reject(status);
          }
        },
        (status: Response['status'], _: XMLHttpRequest) => {
          if (status === 401) {
            expireSessionAndRedirect();
          }
        },
        {
          Authorization,
        }
      );
    });
  });

export const getStory = (storyId: string | number | boolean) =>
  // POSETSERVREQ needs unit with proper capitalization and grade
  new Promise((resolve, reject) => {
    postServiceRequest(
      `${getAppRoot()}api/story/${encodeURIComponent(storyId)}`,
      {},
      (status: Response['status'], request: XMLHttpRequest) => {
        if (status === 200) {
          resolve({
            key: 'story',
            value: JSON.parse(request.response),
          });
        } else {
          reject(status);
        }
      },
      (status: Response['status'], _: XMLHttpRequest) => {
        if (status === 401) {
          expireSessionAndRedirect();
        }
      }
    );
  });

export const updateUserObjectWithPageTrackingInfo = () => {
  const { pageTracker } = getStore().getState().session;
  const newPageTracker: PageTracker = {};

  if (pageTracker) {
    if (pageTracker.previousPage == null || pageTracker.timeCount == null) {
      newPageTracker.timeCount = 0;
    }

    if (pageTracker.timeEntered) {
      newPageTracker.timeSpentOnPreviousPage =
        Date.now() - pageTracker.timeEntered;
      newPageTracker.previousPage = pageTracker.currentPage;
      newPageTracker.timeCount += pageTracker.timeSpentOnPreviousPage;
    }

    newPageTracker.refreshCount = 0;
    newPageTracker.currentPage = window.location.pathname;
    newPageTracker.timeEntered = Date.now();
  } else {
    newPageTracker.timeCount = 0;
    newPageTracker.refreshCount = 0;
    newPageTracker.currentPage = window.location.pathname;
    newPageTracker.timeEntered = Date.now();
  }

  getStore().dispatch(updatePageTracker(newPageTracker));
};

export const getMessages = () =>
  new Promise((resolve, reject) => {
    getAuthorizationToken()
      .then((Authorization) => {
        const email = getUsersEmail();
        getContentRequest(
          `${getAppRoot()}api/getrecentmessages?email=${encodeURIComponent(
            email || ''
          )}`,
          (status: Response['status'], request: XMLHttpRequest) => {
            if (status === 200) {
              return resolve({
                key: 'messages',
                value: request.response ? JSON.parse(request.response) : {},
              });
            } else if (status === 401) {
              expireSessionAndRedirect();
            }
            return reject(status);
          },
          {
            Authorization,
          }
        );
      })
      .catch((error) => {
        if (error.code === 'NotAuthorizedException') {
          expireSessionAndRedirect();
        }
        reject(error);
      });
  });

export interface CoreStandard {
  sub: string;
  text: string;
  label: string;
}

export const getCoreStandardsObject = () =>
  new Promise<Record<string, CoreStandard>>((resolve, reject) => {
    postServiceRequest(
      `${getAppRoot()}api/coreStandards`,
      '{}',
      (status: Response['status'], request: XMLHttpRequest) => {
        if (status === 200) {
          const coreStandards = JSON.parse(request.response);
          return resolve(coreStandards);
        } else {
          return reject(status);
        }
      },
      (status: Response['status'], _: XMLHttpRequest) => {
        return reject(status);
      }
    );
  });
