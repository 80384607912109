import React, { useEffect, useState } from 'react';
import combineClasses from 'classnames';
import styles from './Tooltip.module.scss';
import CSS from 'csstype';
// to do: create dismissTooltipButton component

export interface Props {
  content: string;
  children?: React.ReactNode;
  direction: 'top' | 'bottom' | 'left' | 'right';
  /**
   * Only use 'right-align' and 'left-align' in combination with 'top' or 'bottom' direction. Only use 'top-align' or 'bottom-align' in combination with 'left' or 'right' direction
   */
  arrowPosition?:
    | 'right-align'
    | 'left-align'
    | 'top-align'
    | 'bottom-align'
    | undefined;
  color?: 'orange' | 'white' | 'blue';
  /**
   * Delay in ms for tooltip to appear on hover. Only works when showWithContent is set to false
   */
  delay?: number;
  /**
   * Tooltip with display automatically when content is not null-ish. For non-hover implementations.
   */
  showWithContent?: boolean;
  /**
   * Text to display in button to dismiss modal. Be careful when using when showWithContent = true as user will not be able to see tooltip once dismissed unless component reloads / content changes.
   */
  dismissButtonText?: string;
  /**
   * manually translate tooltip origin, takes valid css translate property as an object eg. { transform: 'translate(-22px, 0px)' }
   */
  translateTooltip?: CSS.Properties;
  /**
   * control tooltip content width with any css width value (eg. '200px', '4em')
   */
  maxWidth?: string;
}

const Tooltip: React.FunctionComponent<Props> = ({
  content,
  children,
  direction,
  arrowPosition,
  color,
  delay,
  showWithContent,
  dismissButtonText,
  translateTooltip,
  maxWidth,
}: Props) => {
  const [active, setActive] = useState(false);

  const combinedClasses = combineClasses({
    [`${styles.tooltipTip}`]: true,
    [`${styles.orange}`]: color === 'orange',
    [`${styles.white}`]: color === 'white',
    [`${styles.blue}`]: color === 'blue',
    [`${styles.top}`]: direction === 'top',
    [`${styles.left}`]: direction === 'left',
    [`${styles.right}`]: direction === 'right',
    [`${styles.bottom}`]: direction === 'bottom',
    [`${styles.rightAlign}`]: arrowPosition === 'right-align',
    [`${styles.leftAlign}`]: arrowPosition === 'left-align',
    [`${styles.topAlign}`]: arrowPosition === 'top-align',
    [`${styles.bottomAlign}`]: arrowPosition === 'bottom-align',
  });

  let timeout: ReturnType<typeof setTimeout>;

  const handleMouseEnter = () => {
    if (!showWithContent) {
      timeout = setTimeout(() => {
        setActive(true);
      }, delay || 0);
    }
  };

  const handleMouseLeave = () => {
    if (!showWithContent && !!dismissButtonText === false) {
      clearInterval(timeout);
      setActive(false);
    }
  };

  const handleDismiss = () => {
    setActive(false);
  };

  useEffect(() => {
    content && showWithContent ? setActive(true) : setActive(false);
  }, [content, showWithContent]);

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {active && (
        <div style={translateTooltip} className={styles.translateWrapper}>
          <div className={combinedClasses} style={{ maxWidth: maxWidth }}>
            {content}
            {!!dismissButtonText && (
              <button onClick={handleDismiss} className={styles.dismissButton}>
                {dismissButtonText}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  color: 'orange',
  direction: 'top',
  translateTooltip: { transform: 'translate(0%, 0%)' },
};

export default Tooltip;
