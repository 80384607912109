import { Children, Fragment, ReactNode, cloneElement } from 'react';
import { useHistory } from 'react-router-dom';
import useCalendly from '../../openCalendly';
import GenericCTAButton from '../CTAButton/CTAButton';
import cx from 'classnames';
import styles from './CTASegment.module.scss';
import React from 'react';

interface HeadingProps {
  children: ReactNode;
  className?: string;
}

interface CTASegmentProps {
  className?: string;
  children?: ReactNode;
  defaultCTAButtons?: boolean;
  greyBackground?: boolean;
}

interface ChildrenProp {
  children?: ReactNode;
  secondary?: boolean;
}

export const Heading = ({ children, className }: HeadingProps): JSX.Element => {
  return (
    <h3
      className={cx(styles.heading, {
        [`${styles.noheading}`]: children === '',
        [`${className}`]: className,
      })}
    >
      {children}
    </h3>
  );
};

export const Subheading = ({
  children,
  className,
}: HeadingProps): JSX.Element => {
  return (
    <p
      className={cx(styles.subheading, {
        [`${styles.noSubheading}`]: children === '',
        [`${className}`]: className,
      })}
    >
      {children}
    </p>
  );
};

export const OpenCalendlyCTA = ({ children, ...props }: ChildrenProp) => {
  const openCalendly = useCalendly();
  return (
    <GenericCTAButton onClick={() => openCalendly()} {...props}>
      {children ? children : 'book a demo'}
    </GenericCTAButton>
  );
};

export const RequestInfoCTA = ({ ...props }) => {
  const history = useHistory();
  return (
    <GenericCTAButton onClick={() => history.push('/requestInfo')} {...props}>
      request info
    </GenericCTAButton>
  );
};

const CTASegment = ({ ...props }: CTASegmentProps): JSX.Element => {
  const {
    className,
    children,
    defaultCTAButtons = true,
    greyBackground,
  } = props;

  const headingChild = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Heading) {
      return cloneElement(<>{child}</>);
    }
  });

  const subheadingChild = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Subheading) {
      return cloneElement(<>{child}</>);
    }
  });

  const ctaButtonsChild = Children.map(children, (child) => {
    if (
      React.isValidElement(child) &&
      (child.type === GenericCTAButton ||
        child.type === OpenCalendlyCTA ||
        child.type === RequestInfoCTA)
    ) {
      return cloneElement(<>{child}</>);
    }
  });

  return (
    <section
      className={cx(
        styles.ctaSegmentContainer,
        {
          [styles.greyBackground]: greyBackground,
        },
        className
      )}
    >
      {headingChild?.length ? (
        <Fragment>{headingChild}</Fragment>
      ) : (
        <h3 className={styles.heading}>
          Ready to bring Peekapak to your school or district?
        </h3>
      )}
      {subheadingChild?.length ? (
        <Fragment>{subheadingChild}</Fragment>
      ) : (
        <p className={styles.subheading}>
          Join thousands of educators that trust Peekapak to support student
          learning.
        </p>
      )}
      <nav
        className={cx(styles.buttonsContainer, {
          [`${styles.noButtons}`]: !defaultCTAButtons,
        })}
      >
        {ctaButtonsChild?.length ? ctaButtonsChild : null}
        {!ctaButtonsChild?.length && defaultCTAButtons && (
          <React.Fragment>
            <OpenCalendlyCTA />
            <RequestInfoCTA secondary />
          </React.Fragment>
        )}
      </nav>
    </section>
  );
};

export default CTASegment;
