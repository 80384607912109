import { Component, createElement } from 'react';
import { SignUpValidationErrorMessage } from '../SignUpValidationErrorMessage';
import { SignUpSteps } from './SignUpSteps';
import OauthLegaleseConfirmation from '../components/OauthLegaleseConfirmation';
import SpinnerOverlay from '../SpinnerOverlay';
import styles from './SignUp.module.scss';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { ShowErrorInterface, useErrorStatus } from '../components/EnableErrorMessagePopup/EnableErrorMessagePopup';
import { useQuery } from '../CustomHooks';

interface Props extends ShowErrorInterface {
  history: History;
}

class NewSignUp extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.userInput = {};

    this.state = {
      currentStep: 0,
      stepValidationStates: [false],
      isShowValidationErrorMessage: false,
    };

    this.handleShowValidationErrorMessage = this.handleShowValidationErrorMessage.bind(this);
    this.handleHideValidationErrorMessage = this.handleHideValidationErrorMessage.bind(this);
    this.handleOnNextStep = this.handleOnNextStep.bind(this);
    this.handleCopyUserInput = this.handleCopyUserInput.bind(this);
    this.handleOnStartOver = this.handleOnStartOver.bind(this);
  }

  handleCopyUserInput(newInputObject) {
    this.userInput = { ...this.userInput, ...newInputObject };
  }

  handleShowValidationErrorMessage() {
    this.setState({
      isShowValidationErrorMessage: true,
    });
  }

  handleHideValidationErrorMessage() {
    this.setState({
      isShowValidationErrorMessage: false,
    });
  }

  handleOnNextStep(currentStep, isStepValid) {
    if (!isStepValid) {
      this.props
        .onShowErrorMessage(`<p style='padding-bottom: 1em; text-align: left;'>There is either incomplete information or a problem with the information
              being provided.</p>
            <p style='text-align: left;'>The problematic areas are highlighted in red. Please
              correct the problems and try again.</p>`);
      return false;
    }

    if (currentStep + 1 < SignUpSteps.length) {
      this.setState({
        currentStep: currentStep + 1,
      });
      return true;
    }
    return false;
  }

  handleOnError = () => {
    this.setState({
      isErrorOccurred: true,
    });
  };

  handleOnStartOver() {
    this.setState({
      isErrorOccurred: false,
      currentStep: 0,
    });
  }

  onOAuthSignUp = (isStepValid) => {
    if (!isStepValid) {
      this.props
        .onShowErrorMessage(`<p style='padding-bottom: 1em; text-align: left;'>There is either incomplete information or a problem with the information
              being provided.</p>
            <p style='text-align: left;'>The problematic areas are highlighted in red. Please
              correct the problems and try again.</p>`);
      return false;
    }
    this.setState({
      currentStep: 'SignUpLegalesePanel',
    });
    return true;
  };

  createCurrentStep() {
    if (this.state.currentStep === 'SignUpLegalesePanel') {
      return createElement(OauthLegaleseConfirmation, {
        stepNumber: this.state.currentStep,
        isErrorOccurred: this.state.isErrorOccurred,
        onErrorOccurred: this.handleOnError,
        onNextStep: this.handleOnNextStep,
        onCopyUserInput: this.handleCopyUserInput,
        userInput: this.userInput,
        onStartOver: this.handleOnStartOver,
        onOAuthSignUp: this.onOAuthSignUp,
        ...this.props,
      });
    }

    if (this.state.currentStep < SignUpSteps.length) {
      return createElement(SignUpSteps[this.state.currentStep], {
        stepNumber: this.state.currentStep,
        isErrorOccurred: this.state.isErrorOccurred,
        onErrorOccurred: this.handleOnError,
        onStepValidationUpdate: this.handleStepValidationUpdate,
        onNextStep: this.handleOnNextStep,
        onCopyUserInput: this.handleCopyUserInput,
        userInput: this.userInput,
        onStartOver: this.handleOnStartOver,
        onOAuthSignUp: this.onOAuthSignUp,
        ...this.props,
      });
    }

    return null;
  }

  render() {
    return (
      <div className={styles.topContainer}>
        {this.createCurrentStep()}
        <SignUpValidationErrorMessage
          isShow={this.state.isShowValidationErrorMessage}
          onHide={this.handleHideValidationErrorMessage}
        />
      </div>
    );
  }
}

function ClassFunctionWrapper() {
  const history = useHistory();
  const onShowErrorMessage = useErrorStatus();
  const query = useQuery();

  return <NewSignUp history={history} onShowError={onShowErrorMessage} query={query} />;
}

export default ClassFunctionWrapper;
