import axios, { AxiosError } from 'axios';
import { convertAxiosError } from './BackendInterface';
import { UserProfileType, UserType } from '../peekapak-types/DataProtocolTypes';
import {
  ElementaryUnitName,
  LessonLanguage,
} from '../peekapak-types/LessonPlanTypes';
import {
  EventName,
  LoginEvent,
  LogEventRequest,
  AnalyticsEvent,
  StudentEvent,
  EventSource,
  NonStudentEvent,
  InteractionEndTrigger,
  JournalAssignmentOpenEvent,
  JournalAssignmentStoryOpenEvent,
  LessonsStoryOpenEvent,
  LessonsOpenedEvent,
  StorybookOpenEvent,
  StorybookCloseEvent,
  StorybookCompleteEvent,
  InteractionStartEvent,
  InteractionEndEvent,
} from '../peekapak-types/AnalyticsTypes';
import { getAuthorizationToken } from './BackendInterface';

const analyticsServicesRoot = (() => {
  if (import.meta.env.REACT_TEST_PROD_API) {
    return 'https://api.peekapak.com/analyticsservices';
  } else if (
    import.meta.env.MODE === 'development' ||
    import.meta.env.REACT_USE_DEV_API
  ) {
    return 'https://api.peekapak.com/analyticsservicesdev';
  } else {
    return 'https://api.peekapak.com/analyticsservices';
  }
})();

export const Analytics = {
  interactionStart: async (userProfile: UserProfileType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const interactionStartProperties: InteractionStartEvent = {
          name: EventName.INTERACTION_START,
          source: EventSource.PEEKAPAK,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
        };

        const properties = {
          ...interactionStartProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const interactionStartProperties: InteractionStartEvent = {
          name: EventName.INTERACTION_START,
          source: EventSource.PEEKAPAK,
          ...commonProperties,
          userType,
        };

        const properties = {
          ...interactionStartProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEvent(propertiesToLog);
  },
  interactionEnd: async (
    userProfile: UserProfileType,
    duration: number,
    trigger: InteractionEndTrigger
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const interactionEndProperties: InteractionEndEvent = {
          name: EventName.INTERACTION_END,
          source: EventSource.PEEKAPAK,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
          duration,
          trigger,
        };

        const properties = {
          ...interactionEndProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const interactionEndProperties: InteractionEndEvent = {
          name: EventName.INTERACTION_END,
          source: EventSource.PEEKAPAK,
          ...commonProperties,
          userType,
          duration,
          trigger,
        };

        const properties = {
          ...interactionEndProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEventWithFetchKeepalive(propertiesToLog);
  },
  journalAssignmentOpen: async (
    assignmentName: string,
    createdAt: number,
    userProfile: UserProfileType
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType !== UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const { grade, readingLevel, classroomId } = userProfile;
      const assignmentOpenProperties: JournalAssignmentOpenEvent = {
        name: EventName.JOURNAL_ASSIGNMENT_OPEN,
        source: EventSource.PEEKAPAK,
        grade,
        readingLevel,
        classroomId,
        ...commonProperties,
        userType,
        assignmentName,
        createdAt,
      };

      const properties = {
        ...assignmentOpenProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
  journalAssignmentStoryOpen: async (
    story: string,
    userProfile: UserProfileType
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType !== UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const { grade, readingLevel, classroomId } = userProfile;
      const assignmentStoryOpenProperties: JournalAssignmentStoryOpenEvent = {
        name: EventName.JOURNAL_STORY_OPEN,
        source: EventSource.PEEKAPAK,
        grade,
        readingLevel,
        classroomId,
        ...commonProperties,
        userType,
        story,
      };

      const properties = {
        ...assignmentStoryOpenProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
  lessonsStoryOpen: async (story: string, userProfile: UserProfileType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType === UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const lessonsStoryOpenProperties: LessonsStoryOpenEvent = {
        name: EventName.LESSONS_STORY_OPEN,
        source: EventSource.PEEKAPAK,
        ...commonProperties,
        userType,
        story,
      };

      const properties = {
        ...lessonsStoryOpenProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
  lessonsOpenedEvent: async (lesson: string, userProfile: UserProfileType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType === UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const lessonsOpenedProperties: LessonsOpenedEvent = {
        name: EventName.LESSONS_OPEN,
        source: EventSource.PEEKAPAK,
        ...commonProperties,
        userType,
        lesson,
      };

      const properties = {
        ...lessonsOpenedProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
  login: async (userProfile: UserProfileType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const loginProperties: LoginEvent<StudentEvent> = {
          name: EventName.LOGIN,
          source: EventSource.PEEKAPAK,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
          loginMethod: 'unknown',
        };

        const properties = {
          ...loginProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const loginProperties: LoginEvent<NonStudentEvent> = {
          name: EventName.LOGIN,
          source: EventSource.PEEKAPAK,
          ...commonProperties,
          userType,
          loginMethod: 'unknown',
        };

        const properties = {
          ...loginProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEvent(propertiesToLog);
  },
  pageView: async (
    url: string,
    pageName: string | null = null,
    userProfile: UserProfileType | null = null
  ) => {
    const userProperties = (() => {
      if (userProfile) {
        const commonProperties = extractCommonUserProperties(userProfile);
        const { userType, userId } = commonProperties;

        const propertiesToLog = (() => {
          if (userType === UserType.STUDENT) {
            const { grade, readingLevel, classroomId } = userProfile;
            const loginProperties: LoginEvent<StudentEvent> = {
              name: EventName.LOGIN,
              source: EventSource.PEEKAPAK,
              grade,
              readingLevel,
              classroomId,
              ...commonProperties,
              userType,
              loginMethod: 'unknown',
            };

            const properties = {
              ...loginProperties,
              distinct_id: userId,
            };

            return properties;
          } else {
            const loginProperties: LoginEvent<NonStudentEvent> = {
              name: EventName.LOGIN,
              source: EventSource.PEEKAPAK,
              ...commonProperties,
              userType,
              loginMethod: 'unknown',
            };

            const properties = {
              ...loginProperties,
              distinct_id: userId,
            };

            return properties;
          }
        })();
        return propertiesToLog;
      }

      return {};
    })();

    const propertiesToLog = {
      ...userProperties,
      name: EventName.PAGE_VIEW,
      source: EventSource.PEEKAPAK,
      url,
      pageName: pageName || 'none',
    };

    logEvent(propertiesToLog);
  },
  storybookCloseEvent: async (
    unit: ElementaryUnitName,
    readingLevel: number,
    language: LessonLanguage,
    userProfile: UserProfileType,
    duration: number
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType === UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const storybookCloseProperties: StorybookCloseEvent<NonStudentEvent> = {
        name: EventName.JOURNAL_BOOK_CLOSE,
        source: EventSource.PEEKAPAK,
        ...commonProperties,
        unit,
        readingLevel,
        language,
        userType,
        duration,
      };

      const properties = {
        ...storybookCloseProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEventWithFetchKeepalive(propertiesToLog);
  },
  storybookCompleteEvent: async (
    unit: ElementaryUnitName,
    readingLevel: number,
    language: LessonLanguage,
    userProfile: UserProfileType
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType === UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const storybookCompleteProperties: StorybookCompleteEvent<NonStudentEvent> =
        {
          name: EventName.JOURNAL_BOOK_COMPLETE,
          source: EventSource.PEEKAPAK,
          ...commonProperties,
          unit,
          readingLevel,
          language,
          userType,
        };

      const properties = {
        ...storybookCompleteProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
  storybookOpenEvent: async (
    unit: ElementaryUnitName,
    readingLevel: number,
    language: LessonLanguage,
    userProfile: UserProfileType
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;
    if (userType === UserType.STUDENT) return;

    const propertiesToLog = (() => {
      const storybookOpenProperties: StorybookOpenEvent<NonStudentEvent> = {
        name: EventName.JOURNAL_BOOK_OPEN,
        source: EventSource.PEEKAPAK,
        ...commonProperties,
        unit,
        readingLevel,
        language,
        userType,
      };

      const properties = {
        ...storybookOpenProperties,
        distinct_id: userId,
      };

      return properties;
    })();

    await logEvent(propertiesToLog);
  },
};

function extractCommonUserProperties(userProfile: UserProfileType) {
  const {
    type,
    userId,
    schoolId,
    district,
    account,
    seller,
    licenseLevel,
    licenseExpires,
  } = userProfile;

  return {
    userType: type as UserType,
    userId,
    schoolId: schoolId || 'none',
    district: district || 'none',
    account: account || 'none',
    seller: seller || 'none',
    licenseLevel,
    licenseExpires,
  };
}

async function logEvent(event: AnalyticsEvent) {
  const auth = await getAuthorizationToken();
  const endpoint = `${analyticsServicesRoot}/analytics/log`;
  const request: LogEventRequest = {
    event,
  };
  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: request,
      headers: {
        Authorization: auth,
      },
    });
  } catch (error) {
    console.error(
      `Error communicating with server: ${convertAxiosError(
        error as AxiosError
      )}`
    );
    throw convertAxiosError(error as AxiosError);
  }
}

//utilize fetch to allow for keepalive flag, not currently supported by axios
async function logEventWithFetchKeepalive(event: AnalyticsEvent) {
  const auth = await getAuthorizationToken();
  const endpoint = `${analyticsServicesRoot}/analytics/log`;
  const request: LogEventRequest = {
    event,
  };
  try {
    await fetch(endpoint, {
      keepalive: true,
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        Authorization: auth,
      },
    });
  } catch (error) {
    console.error(
      `Error communicating with server: ${convertAxiosError(
        error as AxiosError
      )}`
    );
    throw convertAxiosError(error as AxiosError);
  }
}

export default Analytics;
