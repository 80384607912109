import styles from './SCSS/OpenLockIcon.module.scss';
import openLockIcon from './images/pkp-openlock.svg';

export const OpenLockIcon = () => (
  <img
    alt='Open lock'
    src={openLockIcon}
    className={styles.responsiveOpenLockIcon}
  />
);
