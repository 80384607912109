import { Helmet } from 'react-helmet-async';

const defaultTitle = 'Peekapak Social Emotional Learning Curriculum for K-12';
const defaultDescription = `Peekapak infuses social emotional learning in K12 classrooms. We integrate into literacy curriculum to captivate students with engaging content.`;

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const MyHelmet = ({ children }: Props) => (
  <>
    <Helmet defaultTitle={defaultTitle}>
      <meta name='theme-color' content='#000000' />
      <meta name='description' content={defaultDescription} />
      <meta name='keywords' content='Social Emotional Curriculum' />
      <meta name='twitter:card' content='Peekapak' />
      <meta name='twitter:site' content='@Peekapak' />
      <meta name='twitter:title' content={defaultTitle} />
      <meta name='twitter:description' content={defaultDescription} />
      <meta name='twitter:creator' content='@Peekapak' />
      <meta
        name='twitter:image'
        content='https://s3.amazonaws.com/peekaplatform/media30/images/facebook.png'
      />
      <meta property='og:title' content={defaultTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Peekapak' />
      <meta property='og:url' content='https://www.peekapak.com/' />
      <meta
        property='og:image'
        content='https://s3.amazonaws.com/peekaplatform/media30/images/facebook.png'
      />
      <meta property='og:description' content={defaultDescription} />
      <meta property='og:site_name' content='Peekapak' />

      <link rel='canonical' href='https://peekapak.com/' />
      <link
        rel='shortcut icon'
        href='https://s3.amazonaws.com/peekaplatform/media/images/favicon.ico'
      />

      <title>{defaultTitle}</title>
    </Helmet>
    <Helmet>{children}</Helmet>
  </>
);

export default MyHelmet;
