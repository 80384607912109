import React, {
  Children,
  ReactNode,
  cloneElement,
  createContext,
  useEffect,
  useState,
} from 'react';
import { History, Location } from 'history';
import { useQuery } from '../CustomHooks';
import { getCountryOriginBasedOnIp, noWhiteSpace } from '../GlobalFunctions';
import { Default, IPadAndBigger } from '../components/MyMediaQuery';
import RoleCard from '../components/RoleCard/RoleCard';
import cx from 'classnames';
import nelsonRectangle from '../images/logo-nelson-rectangle.svg';
import styles from '../SCSS/HeroSegment.module.scss';

const DefaultRoleCardAccentColours = ['#ff6319', '#012a73', '#00afd8'];
const DefaultRoleCardClassNames = ['students', 'teachers', 'administrators'];

interface Props {
  children: ReactNode;
  history: History<Location> | History;
  heroImage: {
    image: string;
    backgroundPositions: {
      mobile: string;
      ipad: string;
    };
    gradient: string;
  };
  roleCards: {
    className?: string;
    title: string;
    description: string;
    prompt?: string;
    url: string;
    accentColour?: string;
  }[];
  roleCardsContainerClassName?: string;
}

interface HeroTitleProps {
  children: ReactNode | string;
  className?: string;
}

export const HeroTitle = ({
  children,
  className,
}: HeroTitleProps): JSX.Element => (
  <div className={cx(styles.title, { [`${className}`]: className })}>
    {children}
  </div>
);

export const HeroSubtitle = ({
  children,
  className,
}: HeroTitleProps): JSX.Element => (
  <div className={cx(styles.subtitle, { [`${className}`]: className })}>
    {children}
  </div>
);

export const RoleCardContext = createContext(RoleCard);

const HeroLanding = ({
  children,
  history,
  heroImage,
  roleCards,
  roleCardsContainerClassName,
}: Props): JSX.Element => {
  const [isShowDistributorLogo, setIsShowDistributorLogo] = useState(false);
  const query = useQuery();

  const heroTitle = Children.map(children, (child) => {
    return (
      React.isValidElement(child) &&
      child.type === HeroTitle &&
      cloneElement(<>{child}</>)
    );
  });

  const heroSubtitle = Children.map(
    children,
    (child) =>
      React.isValidElement(child) &&
      child.type === HeroSubtitle &&
      cloneElement(<>{child}</>)
  );

  useEffect(() => {
    async function checkCountry() {
      const country = await getCountryOriginBasedOnIp();
      const variant = query.get('variant');
      if (country === 'Canada' && !variant) {
        setIsShowDistributorLogo(true);
      }
    }
    checkCountry();
  }, []);

  const { image, backgroundPositions, gradient } = heroImage;
  const { mobile, ipad } = backgroundPositions;

  return (
    <div className={styles.content}>
      <Default>
        <div
          className={styles.heroImage}
          style={{
            backgroundImage: noWhiteSpace`url(${image}), linear-gradient(${gradient})`,
            backgroundPosition: mobile,
          }}
        />
      </Default>
      <IPadAndBigger>
        <div
          className={styles.heroImage}
          style={{
            backgroundImage: noWhiteSpace`url(${image}), linear-gradient(${gradient})`,
            backgroundPosition: ipad,
          }}
        />
      </IPadAndBigger>
      <div
        className={cx(styles.distributorLogo, {
          [`${styles.makeVisible}`]: isShowDistributorLogo,
        })}
      >
        <img src={nelsonRectangle} alt='Nelson Education' />
      </div>
      <div className={styles.text}>
        {heroTitle}
        {heroSubtitle}
      </div>
      <div className={cx(styles.cards, roleCardsContainerClassName)}>
        {roleCards.map((card, index) => {
          const accentColour = DefaultRoleCardAccentColours[index];
          const className = DefaultRoleCardClassNames[index];
          return (
            <RoleCard
              className={card.className || className}
              key={card.title}
              onClick={() => history.push(card.url)}
              title={card.title}
              description={card.description}
              footer={card.prompt || 'Learn More'}
              accentColour={card.accentColour || accentColour}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HeroLanding;
