import { Component } from 'react';
import { NormalHeader, Segment } from '../core/core';
import { reactivateUserSessionIfExists } from '../UserManagementHelpers';
import { getTierName } from '../components/TierPriceDisplay/TierPriceDisplay';
import SpinnerOverlay from '../SpinnerOverlay';
import styles from '../SCSS/Upgrade.module.scss';
import utilityStyles from '../SCSS/common/utilityStyles.module.scss';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import openLockIcon from '../images/pkp-openlock.svg';
import { useHistory } from 'react-router-dom';
import { UserProfileType } from '../../peekapak-types/DataProtocolTypes';
import { History } from 'history';

interface Props {
  stepNumber: number;
  onNextStep: (currentStep: number, isStepValid: boolean) => boolean;
  usersProfile: UserProfileType | null;
  tier: string;
  history: History;
}

interface State {
  tier: string;
  isDoneFetchNewCredentials: boolean;
  isProblemFetching: boolean;
}

class _UpgradeStep3 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const tier = props.tier && (props.tier || 'classroomPro');

    this.state = {
      tier,
      isDoneFetchNewCredentials: false,
      isProblemFetching: false,
    };

    this.handleOnNextStep = this.handleOnNextStep.bind(this);
  }

  handleOnNextStep() {
    this.props.history.push('/library');
  }

  async componentDidMount() {
    try {
      await reactivateUserSessionIfExists();
      this.setState({
        isDoneFetchNewCredentials: true,
      });
    } catch (error) {
      this.setState({
        isProblemFetching: true,
      });
    }
  }

  render() {
    if (!this.state.isDoneFetchNewCredentials) {
      return (
        <Segment className={styles.upgradeSegment}>
          <div className='spacer-40' />
          <div
            className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}
          >
            <img
              alt='Open Lock'
              src={openLockIcon}
              className={styles.openLockImg}
            />
            <div className='spacer-40' />
            <NormalHeader
              className={styles.title}
              text='Some final steps preparing your upgraded status...'
            />
            <div className='spacer-40' />
            <SpinnerOverlay isShow={true} />
          </div>
        </Segment>
      );
    } else if (this.state.isProblemFetching) {
      return (
        <Segment className={styles.upgradeSegment}>
          <div className='spacer-40' />
          <div
            className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}
          >
            <img
              alt='Open Lock'
              src={openLockIcon}
              className={styles.openLockImg}
            />
            <div className='spacer-40' />
            <NormalHeader
              className={styles.title}
              text='There was a problem getting your upgraded status'
            />
            <div className='spacer-20' />
            <p className={`${styles.subtitle} LargeTextNormal`}>
              Please try reloading your browser page for your upgraded status;
              look for the &apos;{getTierName(this.props.tier)}&apos; badge
              under your name in the header. If this continues to be a problem,
              please email us at{' '}
              <a href='mailto:hello@peekapak.com'>hello@peekapak.com</a>.
            </p>
            <div className='spacer-40' />
            <div className={utilityStyles.absCentre}>
              <PrimaryButton onClick={this.handleOnNextStep}>
                Go to Library
              </PrimaryButton>
            </div>
          </div>
        </Segment>
      );
    }

    return (
      <Segment className={styles.upgradeSegment}>
        <div className='spacer-40' />
        <div className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}>
          <img
            alt='Open Lock'
            src={openLockIcon}
            className={styles.openLockImg}
          />
          <div className='spacer-40' />
          <NormalHeader
            className={styles.title}
            text='Welcome to the Peekapak Family!'
          />
          <div className='spacer-40' />
          <p className={`${styles.subtitle} LargeTextNormal`}>
            We&apos;re so excited you&apos;ve upgraded to{' '}
            {getTierName(this.props.tier)}! If there&apos;s anything we can do
            to help, please don&apos;t hesitate to email us at{' '}
            <a href='mailto:hello@peekapak.com'>hello@peekapak.com</a>
          </p>
        </div>
        <div className='spacer-40' />
        <div className={utilityStyles.absCentre}>
          <PrimaryButton onClick={this.handleOnNextStep}>
            Let&apos;s Go
          </PrimaryButton>
        </div>
      </Segment>
    );
  }
}

export function UpgradeStep3(props: Props) {
  const history = useHistory();
  return <_UpgradeStep3 {...props} history={history} />;
}
