import { Component, createElement } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { SignUpValidationErrorMessage } from '../SignUpValidationErrorMessage';
import { UpgradeSteps } from './UpgradeSteps';
import { LoginState } from '../UserManagement';
import styles from '../SCSS/Upgrade.module.scss';
import { RootState } from '../ApplicationState';
import { History, Location } from 'history';

const mapStateToProps = (state: RootState) => ({
  loginState: state.user.loginState,
  userProfile: state.user.userProfile,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history: History;
  isAuthenticated: boolean;
  location: Location;
  onShowGetStartedDialog: () => void;
  params: {
    tier: string;
  };
};
interface State {
  currentStep: number;
  isShowValidationErrorMessage: boolean;
  stepValidationStates: boolean[];
}
class Upgrade extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: 0,
      stepValidationStates: [false],
      isShowValidationErrorMessage: false,
    };

    this.handleShowValidationErrorMessage =
      this.handleShowValidationErrorMessage.bind(this);
    this.handleHideValidationErrorMessage =
      this.handleHideValidationErrorMessage.bind(this);
    this.handleOnNextStep = this.handleOnNextStep.bind(this);
  }

  componentDidUpdate = (nextProps: Props) => {
    if (nextProps.loginState === LoginState.loggedOut) {
      this.props.history.push(`/`);
    }
  };

  /* handleCopyUserInput(newInputObject: Record<string, any>) {
    this.userInput = { ...this.userInput, ...newInputObject };
  }*/

  handleShowValidationErrorMessage() {
    this.setState({
      isShowValidationErrorMessage: true,
    });
  }

  handleHideValidationErrorMessage() {
    this.setState({
      isShowValidationErrorMessage: false,
    });
  }

  //
  // returns true if sequence is about to advance to next step
  // returns false if sequence is not going to advance, either
  //   because validation has failed or because there are no more
  //   steps
  handleOnNextStep(currentStep: number, isStepValid: boolean) {
    if (!isStepValid) {
      this.handleShowValidationErrorMessage();
      return false;
    }

    if (this.state.currentStep + 1 < UpgradeSteps.length) {
      this.setState({
        currentStep: this.state.currentStep + 1,
      });
      return true;
    }
    return false;
  }
  /*
  handleOnStartOver() {
    this.setState({
      currentStep: 0,
    });
  } */

  createCurrentStep() {
    if (this.state.currentStep < UpgradeSteps.length) {
      return createElement(UpgradeSteps[this.state.currentStep], {
        stepNumber: this.state.currentStep,
        onNextStep: this.handleOnNextStep,
        usersProfile: this.props.userProfile,
        tier: this.props.params.tier.toLowerCase(),
      });
    }

    return null;
  }

  render() {
    return (
      <div className={styles.upgradeContainer}>
        {this.createCurrentStep()}
        <SignUpValidationErrorMessage
          isShow={this.state.isShowValidationErrorMessage}
          onHide={this.handleHideValidationErrorMessage}
        />
      </div>
    );
  }
}

export default connector(Upgrade);
