import { ComponentType, useEffect, useState } from 'react';
import loadScript from 'load-script2';
import { loadCSS } from 'fg-loadcss';
import Documents from './Documents';

let calendlyLoadState = 'unloaded';

declare global {
  interface Window {
    Calendly: { showPopupWidget: (arg0: string) => void };
  }
}

type CalendlyHook = (onShowError?: (arg0: string) => void) => void;

const useCalendly = (): CalendlyHook => {
  const [myState, setMyState] = useState({
    loadCalendlyAttempts: 0,
  });

  useEffect(() => {
    if (calendlyLoadState === 'unloaded') {
      calendlyLoadState = 'loading';
      loadScript('https://calendly.com/assets/external/widget.js')
        .then(() => loadCSS('https://calendly.com/assets/external/widget.css'))
        .then(() => (calendlyLoadState = 'loaded'));
    }
  }, [myState, setMyState]);

  return openCalendly;

  function openCalendly(onShowError?: (arg0: string) => void) {
    if (calendlyLoadState === 'loaded') {
      window.Calendly.showPopupWidget(Documents.bookDemo);
    } else if (myState.loadCalendlyAttempts > 2) {
      setMyState({ loadCalendlyAttempts: 0 });
      calendlyLoadState = 'unloaded';
      if (onShowError) {
        onShowError(
          'Sorry, the booking calendar isn’t available at the moment. Please try again later.'
        );
      }
    } else {
      setTimeout(() => {
        setMyState({
          loadCalendlyAttempts: (myState.loadCalendlyAttempts += 1),
        });
        openCalendly(onShowError);
      }, 1000);
    }
  }
};

type CalendlyFunctionType = ReturnType<typeof useCalendly>;

export function withCalendly<T>(
  WrappedComponent: ComponentType<T>
): ComponentType<T & CalendlyFunctionType> {
  return (props: T) => {
    const onOpenCalendly = useCalendly();
    return <WrappedComponent {...props} onOpenCalendly={onOpenCalendly} />;
  };
}

export default useCalendly;
