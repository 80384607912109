import { Fragment } from 'react';
import { getMonthName, getReadableUnixTimeElements } from '../GlobalFunctions';
import { values } from '../CheckIn/checkInValues';
import Button from '../components/Button';
import cx from 'classnames';
import styles from './CheckIn.module.scss';

interface Props {
  isViewAssignmentSubmission?: boolean;
  teacherReview?: boolean;
  timeSubmitted: number;
  isViewDailyCheckIn?: boolean;
  studentWork?: string[];
  journalEntries?: string[];
  handleResetSubmission?: () => void;
  handleGoHome?: () => void;
}

const ReviewCheckIn = ({
  isViewAssignmentSubmission,
  teacherReview,
  timeSubmitted,
  isViewDailyCheckIn,
  studentWork,
  journalEntries,
  handleResetSubmission,
  handleGoHome,
}: Props) => {
  const getTextColor = (feeling: string | undefined) => {
    if (feeling === undefined) return '#86939e';
    if (values.findIndex((value) => value.subEmotions.includes(feeling)) < 2)
      return '#43484d';
    return '#fff';
  };

  const [year, month, date, hour, min, sec] =
    getReadableUnixTimeElements(timeSubmitted);

  const hourNumber = Number(hour);
  const minNumber = Number(min);

  const timeSubmittedString =
    timeSubmitted &&
    `${getMonthName(timeSubmitted)} ${date}, ${year} @ ${
      hourNumber > 12 ? hourNumber - 12 : hour
    }:${minNumber < 10 ? `0${min}` : `${min}`} ${
      hourNumber > 11 ? 'pm' : 'am'
    }`;

  return (
    <>
      {isViewAssignmentSubmission && (
        <div className={styles.assignmentSubmissionContainer}>
          {teacherReview ? (
            <h3 className={styles.teacherReviewHeading}>
              Student Check In Response
            </h3>
          ) : (
            <>
              <h4>You did It!</h4>
              <p>
                {`Thanks for sharing how you're feeling. You said you were
                  feeling`}
              </p>
            </>
          )}
          <div
            className={cx(styles.responseRow, {
              [styles.marginBottom]: teacherReview,
            })}
          >
            {studentWork &&
              studentWork.map((emotion, index) => {
                const emotionColor =
                  values.find((value) => value.subEmotions.includes(emotion))
                    ?.segmentColor || '#bbc2ca';
                return (
                  <div
                    key={`${emotion}${index} activity.id`}
                    className={styles.emotionContainer}
                    style={{
                      backgroundColor: emotionColor,
                    }}
                  >
                    <h3
                      className={styles.emotionText}
                      style={{
                        color: getTextColor(emotion),
                      }}
                    >
                      {emotion.replace('/', ' / ') || '-'}
                    </h3>
                  </div>
                );
              })}
          </div>
          {!teacherReview && timeSubmitted && (
            <p>You completed this assignment on {timeSubmittedString}.</p>
          )}
          {teacherReview && (
            <p>Student completed this assignment on {timeSubmittedString}.</p>
          )}
          {!teacherReview && (
            <div className={styles.buttonRow}>
              <Button thinText darkBlue small onClick={handleGoHome}>
                See ToDos
              </Button>
            </div>
          )}
        </div>
      )}
      {isViewDailyCheckIn && journalEntries && (
        <Fragment key={'text response'}>
          {journalEntries.length > 0 && (
            <>
              <h4>You did It!</h4>
              <p>
                {`Thanks for sharing how you're feeling. You said you were
                  feeling`}
              </p>
              <div className={styles.emotionRow}>
                {journalEntries &&
                  journalEntries.map((emotion, index) => {
                    const emotionColor =
                      values.find((value) =>
                        value.subEmotions.includes(emotion)
                      )?.segmentColor || '#bbc2ca';
                    return (
                      <div
                        key={`${emotion}${index} activity.id`}
                        className={styles.dataCell}
                        style={{
                          backgroundColor: emotionColor,
                        }}
                      >
                        <h4
                          style={{
                            color: getTextColor(emotion),
                          }}
                        >
                          {emotion.replace('/', ' / ') || '-'}
                        </h4>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          <p>
            Come back tomorrow to check in{' '}
            {journalEntries.length > 0 && 'again'} or click below to submit an
            {journalEntries.length > 0 && 'other'} emotion now.
          </p>
          <div className={styles.buttonRow}>
            <Button thinText upload small onClick={handleResetSubmission}>
              Submit {journalEntries.length > 0 && 'Another'} Emotion
            </Button>
            <Button thinText darkBlue small onClick={handleGoHome}>
              See ToDos
            </Button>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default ReviewCheckIn;
