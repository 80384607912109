import StatePagesTemplate from './StatePagesTemplate';
import { oklahomaFaq } from './expandableFAQContents';
import sheldon from '../images/logos/logo-sheldonisd.png';
import tulare from '../images/logos/logo-tulare.png';
import tulsa from '../images/logos/logo-tulsa.png';

const oklahomaPageData = {
  perfectMatchOneTitle:
    'When You Choose Peekapak, You Breathe Life Back Into The Classroom.',
  perfectMatchOneSubtitle: `As the administrator of your school, you want all students to succeed, but you’re seeing diverse learning needs, teacher burnout, increasing state mandates, and a lack of coping skills.`,
  perfectMatchTwoTitle: 'Why Peekapak and Oklahoma are a Perfect Match',
  whyChoosePeekapakLists: [
    'Fulfills Oklahoma’s SEL Mandates & OAS in ELA all at once',
    'Offers leveled versions to reach all learners',
    'Aligns to CASEL Standards',
    'Engages students through game-based learning',
  ],
  schoolLogos: [sheldon, tulsa, tulare],
};
const OklahomaPage = () => {
  return (
    <StatePagesTemplate
      state='Oklahoma'
      perfectMatchOneTitle={oklahomaPageData.perfectMatchOneTitle}
      perfectMatchOneSubtitle={oklahomaPageData.perfectMatchOneSubtitle}
      perfectMatchTwoTitle={oklahomaPageData.perfectMatchTwoTitle}
      whyChoosePeekapakLists={oklahomaPageData.whyChoosePeekapakLists}
      schoolLogos={oklahomaPageData.schoolLogos}
      stateSpecificFaq={oklahomaFaq}
    ></StatePagesTemplate>
  );
};

export default OklahomaPage;
