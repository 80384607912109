import { Component } from 'react';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { myBrowserHistory } from '../ApplicationState';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import { LockIcon } from './LockIcon';
import { isUserHasKey } from '../GlobalFunctions';
import styles from '../SCSS/BreakdownOverview.module.scss';
import checkmarkInCircleWhite from '../images/icons/pkp-checkmark-in-circle-white.svg';
import parentsGetInvolvedWhite from '../images/icons/pkp-parents-get-involved-white.svg';
import districtWhite from '../images/icons/pkp-district-white.svg';

class _BreakDownButton extends Component {
  getAndContentAccessKeys = () => this.props.andContentAccessKeys;

  getOrContentAccessKeys = () => this.props.orContentAccessKeys;

  navigate = () => {
    if (this.props.isPreviewMode) {
      this.props.onPreviewChange();
      return null;
    }
    if (!this.isLocked()) {
      window.scrollTo(0, 0);
      if (this.props.gotoUrl.includes('https')) {
        window.open(this.props.gotoUrl, '_blank');
      } else {
        myBrowserHistory.push(this.props.gotoUrl);
      }

      return;
    }

    return this.props.onClickLockedContent();
  };

  render() {
    const buttonClass = cx(
      styles.breakdownButton,
      !this.props.breakDownButtonType && styles.centreInFlex
    );

    const breakDownButtonImage = (() => {
      switch (this.props.breakDownButtonType) {
        case 'pkp-checkmark-in-circle-white.svg':
          return checkmarkInCircleWhite;
        case 'pkp-parents-get-involved-white.svg':
          return parentsGetInvolvedWhite;
        case 'pkp-district-white.svg':
          return districtWhite;
        default:
          return '';
      }
    })();

    return (
      <div className={styles.breakdownButtonContainer}>
        {(this.isLocked() ||
          (!isUserHasKey('KEY_HOME_LESSONS') &&
            this.props.breakDownButtonLabel
              .toLowerCase()
              .includes('home'))) && (
          <div className={styles.stepCardLock}>
            <LockIcon />
          </div>
        )}
        <Button
          className={buttonClass}
          style={this.props.style}
          onClick={this.navigate}
        >
          {this.props.breakDownButtonType && (
            <>
              <img
                alt='lesson button'
                className={styles.overviewButtonImage}
                src={breakDownButtonImage}
              />
            </>
          )}
          {this.props.breakDownButtonLabel}
        </Button>
      </div>
    );
  }
}

_BreakDownButton.propTypes = {
  gotoUrl: PropTypes.string.isRequired,
  breakDownButtonType: PropTypes.string,
  breakDownButtonLabel: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClickLockedContent: PropTypes.func,
  orContentAccessKeys: PropTypes.array.isRequired,
};

export const BreakDownButton = lockWithPreviewUnlessHasAny(
  'KEY_ALL_LESSON_PLANS',
  'KEY_MASTER_KEY'
)(_BreakDownButton);
