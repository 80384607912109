import Button from '../components/Button';
import { useMediaQuery } from 'react-responsive';
import styles from './UnitHeader.module.scss';
import { getPublicMediaRoot } from '../serviceAgent';

interface Props {
  featuredImage1?: string;
  featuredImage2?: string;
  displayName: string;
  number: number;
  caselInf: string;
  unitTime: string;
}

const UnitHeader = ({
  featuredImage1,
  featuredImage2,
  displayName,
  number,
  caselInf,
  unitTime,
}: Props) => {
  const isMedium = useMediaQuery({ minWidth: 577, maxWidth: 992 });
  const isSmall = useMediaQuery({ maxWidth: 576 });
  const imgPath1 = `${getPublicMediaRoot()}images/secondary/${featuredImage1}`;
  const imgPath2 = `${getPublicMediaRoot()}images/secondary/${featuredImage2}`;
  return (
    <div className={styles.unitHeaderContainer}>
      {!isMedium && !isSmall && (
        <div className={styles.imageContainer}>
          <img src={imgPath1} alt={featuredImage1} />
          <img src={imgPath2} alt={featuredImage2} />
        </div>
      )}
      {isMedium && (
        <img className={styles.mediumImg} src={imgPath1} alt={featuredImage1} />
      )}
      <div className={styles.headerText}>
        <h3>Unit {number}</h3>
        <h1>{displayName}</h1>
        <p>
          {/*{unitTime}&nbsp;&#8226;&nbsp;*/}
          {caselInf}
        </p>
        {/*<div className={styles.unitButton}>
          <Button>Start Next Lesson</Button>
        </div>*/}
      </div>
    </div>
  );
};

export default UnitHeader;
