import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { myBrowserHistory } from '../ApplicationState';
import styles from '../SCSS/lpMain.module.scss';
import libraryStyles from '../SCSS/Library.module.scss';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';

interface Props {
  isShow: boolean;
  onHide: () => void;
  content?: boolean;
  feature?: boolean;
}

export class LockedContentMessage extends Component<Props> {
  render() {
    let noun = 'lesson';

    if (this.props.content) {
      noun = 'content';
    } else if (this.props.feature) {
      noun = 'feature';
    }

    return (
      <Modal
        centered
        show={this.props.isShow}
        onHide={this.props.onHide}
        className={styles.lockedContentMessage}
      >
        <Modal.Header closeButton>This is a Peekapak PRO Feature</Modal.Header>
        <Modal.Body>
          <div
            className={`${styles.ModalDialogText} ${styles.lockedContentMessage}`}
          >
            This {noun} is only available with our Peekapak PRO plan. Want to
            learn more about it?
          </div>
          <div style={{ textAlign: 'center', padding: '2em' }}>
            <PrimaryButton
              onClick={() => myBrowserHistory.push('/requestInfo')}
            >
              Request Information
            </PrimaryButton>
            <div
              className={libraryStyles.textLink}
              style={{ marginTop: '1rem' }}
              onClick={() => myBrowserHistory.push('/redeemCode')}
            >
              or upgrade using a license code
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
