import cx from 'classnames';
import styles from '../SCSS/MuxPlaceholder.module.scss';
import videoIcon from '../images/pkp-video-icon.svg';

interface Props {
  graphicUrl: string;
  handleClick: () => void;
  otherThumbnailStyle?: string;
  iconCentered?: boolean;
  iconNone?: boolean;
  iconSize?: string;
}

const VimeoPlaceholder = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <img
        alt='Thumbnail'
        src={props.graphicUrl}
        className={cx(
          styles.videoThumbnailImage,
          `${props.otherThumbnailStyle}`
        )}
      />
      <img
        alt='Video'
        src={videoIcon}
        className={cx(styles.videoIconOverlay, {
          [`${styles.videoIconCentered}`]: props.iconCentered,
          [`${styles.videoIconNone}`]: props.iconNone,
        })}
        style={{ width: props.iconSize }}
        onClick={props.handleClick}
      />
    </div>
  );
};

export default VimeoPlaceholder;
