import PrimaryButton from '../PrimaryButton/PrimaryButton';
import Images from '../../Images';
import styles from './ErrorDisplay.module.scss';

interface Props {
  pictureUrl?: string | null;
  contentTitle?: string;
  contentSubtitle?: (() => void) | string;
  buttonText?: string | null;
  onButtonClick?: () => void;
  imageClassName?: string;
}

const ErrorDisplay = (props: Props): JSX.Element => {
  return (
    <div className={styles.content}>
      <div className={styles.contentPicture}>
        <img
          alt='Error occurred'
          src={
            props.pictureUrl ? props.pictureUrl : Images.backpackToolsGeneric
          }
          className={props.imageClassName}
        />
      </div>
      <div className={styles.contentText}>
        <div className={styles.contentTitle}>{props.contentTitle}</div>
        <div className={styles.contentSubtitle}>
          {typeof props.contentSubtitle === 'function'
            ? props.contentSubtitle()
            : props.contentSubtitle}
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton onClick={props.onButtonClick}>
            {props.buttonText}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ErrorDisplay;
