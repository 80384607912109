import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MyHelmet from '../../MyHelmet';
import Banner from '../../components/HeroBanner';
import HeroLanding, {
  HeroSubtitle,
  HeroTitle,
} from '../../anonymous/HeroLanding';
import UtahHighlightsSegment from './UtahHighlightsSegment';
import AwardSection from '../../components/AwardSection';
import IntegrationPartners from '../IntegrationPartners';
import { ReasonsGrid } from '../../anonymous/ReasonsSegment';
import ExpertsSegment from '../../anonymous/ExpertsSegment';
import TestimonialsSegment from '../../anonymous/TestimonialsSegment';
import CTASegment, { Heading } from '../../components/CTASegment';
import CTAButton from '../../components/CTAButton';
import heroImage from '../../images/hero/hero-utah.png';
import homeStyles from '../../SCSS/Home.module.scss';
import styles from './UtahLanding.module.scss';

const heroImageSettings = {
  image: heroImage,
  backgroundPositions: {
    mobile: 'top 0 left 60%',
    ipad: 'top 0 left 0',
    ipadpro: 'top 0 left 0',
    mdpi: 'top 25% left 75%',
  },
  gradient: `rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)`,
};

const roleCards = [
  {
    title: 'Elementary (PreK-Gr. 5)',
    description: `Features levelled readers and fully-planned lessons to help students develop life skills, while also building reading and writing skills.`,
    url: '/elementary',
    accentColour: '#ff6319',
  },
  {
    title: 'Administrators',
    description: `Empower educators, engage students and connect families, while collecting insights and real-time reports on your well being initiative.`,
    url: '/districtLead',
    accentColour: '#00afd8',
  },
];

const UtahHome = (): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: '/',
      search: '?variant=100',
    });
  }, []);
  return (
    <div className={homeStyles.anonContainer}>
      <MyHelmet>
        <meta name='keywords' content='Social Emotional Learning Activities' />
        <title>Peekapak Social Emotional Learning Curriculum for K-12</title>
        <meta
          name='description'
          content={`Peekapak infuses social emotional learning & well-being in K-12 classrooms. We integrate into literacy & ELA curriculums to captivate students with engaging, narrative-based content.`}
        />
      </MyHelmet>
      <Banner history={history} />
      <HeroLanding
        heroImage={heroImageSettings}
        history={history}
        roleCards={roleCards}
        roleCardsContainerClassName={styles.roleCardsContainer}
      >
        <HeroTitle>
          Empowering Utah Students with the Skills to be their Best Selves.
        </HeroTitle>
        <HeroSubtitle>
          A narrative based social emotional learning experience empowering
          educators to facilitate engaging life skills lessons in the classroom,
          students to take ownership of their learning, and families to
          reinforce wellbeing at home.
        </HeroSubtitle>
      </HeroLanding>
      <UtahHighlightsSegment />
      <AwardSection
        title='Award Winning Learning'
        subTitle='Peekapak empowers students learn, practice, and internalize CASEL’s five social-emotional learning competencies through our award-winning stories, lessons, and digital activities.'
      />
      <IntegrationPartners />
      <ReasonsGrid className={styles.reasonsGrid} />
      <ExpertsSegment />
      <TestimonialsSegment />
      <CTASegment>
        <Heading>Want to learn about our partnership with USBE?</Heading>
        <CTAButton secondary onClick={() => history.push('/signup')}>
          Sign up
        </CTAButton>
        <CTAButton externalLink='https://calendly.com/amishah/30min?back=1'>
          Learn More
        </CTAButton>
      </CTASegment>
    </div>
  );
};

export default UtahHome;
