import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import Spinner from 'react-spinkit';
import { setLocalStorage } from '../aaa';
import { RootState } from '../ApplicationState';
import { updateAllFields } from '../UserManagement';
import {
  getAuthorizationToken,
  getJob,
  importClassroom,
  updateUserProfile,
} from '../BackendInterface';
import { getStore } from '../ApplicationState';
import { logger } from '../error-tracker';
import { sendUserToRoleHomePage } from '../GlobalFunctions';
import {
  createClassroom,
  updateClassroom,
  State as ClassroomsState,
} from '../Classrooms';
import { postServiceRequest } from '../serviceAgent';
import { getAppRoot } from '../identity';
import styles from '../SCSS/Rostering.module.scss';
import libraryStyles from '../SCSS/Library.module.scss';
import PrimaryButton from '../components/PrimaryButton';
import RadioButtonInput from '../components/RadioButtonInput';
import {
  SyncSource,
  createComposers,
  createRetrievers,
  createUserPropertyAccessors,
  getSyncSourceFromClassroom,
} from '../RosteringUtilities';
import ButtonWithCountdown from '../components/ButtonWithCountdown';
import { createOrUpdateUserProfileFlag } from '../core/core';
import {
  ClassroomType,
  ExternalClassroomType,
  JobError,
  MergeClassroomRequest,
  NormalizedExternalSection,
  SyncClassroomRequest,
  UserProfileType,
  UserPropertyAccessor,
} from '../../peekapak-types/DataProtocolTypes';

const AUTOSYNC_CLASSROOMS_COMPLETED_OR_ERROR_TIMEOUT =
  import.meta.env.MODE === 'development' ? 5 : 1440;

enum SectionFetchState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

enum RosterState {
  unknown = 'unknown',
  signedIn = 'signed_in',
  checkingSignInStatus = 'checking_sign_in_status',
  autoSyncNoRosteredClassrooms = 'no_rostered_classrooms',
  autoSyncAtLeastOneRosteredClassroom = 'at_least_one_rostered_classroom',
  autoSyncAllClassroomsDone = 'all_classrooms_done',
  autoSyncAllClassroomsDoneWithMore = 'all_classrooms_done_with_more',
  autoSyncRedirectingToHomePage = 'redirecting_to_home_page',
  autoSyncSyncingStarted = 'login_sync_syncing_started',
  fetchingClassrooms = 'fetching_classrooms',
  manualSyncObtainedProviderClassrooms = 'obtained_provider_classrooms',
  manualSyncPickedClassroomForSyncing = 'picked_classroom_for_syncing',
  mergePickedSourceClassroomForMerging = 'picked_source_classroom_for_merging',
  mergingStarted = 'merging_started',
  syncingStarted = 'syncing_started',
  updatingClassroom = 'updating_classroom',
  syncingDone = 'syncing_done',
  mergingDone = 'merging_done',
  error = 'error,',
}

enum SelectorState {
  chooseExternalClassroom = 'choose_external_classroom',
  chooseExistingClassroom = 'choose_existing_classroom',
}

interface SelectedClassrooms {
  pickedExternalClassroomId: string;
  pickedExternalClassroomName: string;
  pickedMergeIntoClassroomId?: string;
}

interface SelectorValues extends SelectedClassrooms {
  selectorState: SelectorState;
}

interface SyncableClassroomId {
  id: string;
  modifiedAt: number;
}

interface Params {
  providerId: string;
  invokeSource: string;
}

interface ExternalClassroomsList {
  source: SyncSource;
  list: NormalizedExternalSection[];
}

const ExternalClassroomSelector = (props: {
  source: SyncSource;
  userProfile: UserProfileType;
  classroomsList: ExternalClassroomsList;
  existingClassroomsList: ClassroomType[];
  setRosterState: (arg0: RosterState) => void;
  rosterStateRef: React.MutableRefObject<RosterState>;
  onSubmit: (selectedClassrooms: SelectedClassrooms) => void;
}) => {
  const {
    userProfile,
    classroomsList,
    existingClassroomsList,
    source,
    setRosterState,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState<SelectorValues>({
    pickedExternalClassroomId: '',
    pickedExternalClassroomName: '',
    pickedMergeIntoClassroomId: '',
    selectorState: SelectorState.chooseExternalClassroom,
  });

  if (classroomsList.list.length === 0) {
    return (
      <>
        <p>No {source} sections found.</p>
        <PrimaryButton
          onClick={handleNoSectionsFound}
          className={styles.alignCentre}
          style={{ marginTop: '1rem' }}
        >
          Okay
        </PrimaryButton>
        <p
          onClick={skipAutoSync}
          className={cx(libraryStyles.textLink, styles.alignCentre)}
          style={{ marginTop: '1rem' }}
        >
          Skip checking for {source} sections
        </p>
      </>
    );
  }
  switch (state.selectorState) {
    case SelectorState.chooseExternalClassroom:
      return (
        <>
          <h2>
            <p>
              These are the classrooms available for you to add and autosync to
              keep your class roster updated on Peekapak. Please select one
              class and press the below button to start the syncing.
            </p>
          </h2>
          <Formik
            initialValues={{
              picked: '',
            }}
            onSubmit={async (values) => {
              const pickedExternalClassroomId = values.picked;
              const pickedExternalClassroomName =
                classroomsList.list.find((c) => c.id === values.picked)?.name ||
                '';

              // strictly speaking on a sync, we don't need the state, but we're
              // setting the state to the correct values so that the component
              // is internally consistent
              setState((prevState) => ({
                ...prevState,
                pickedExternalClassroomId,
                pickedExternalClassroomName,
              }));
              props.onSubmit({
                pickedExternalClassroomId,
                pickedExternalClassroomName,
              });
            }}
          >
            {({ values }) => {
              console.debug('Formik values = ', values);

              return (
                <Form>
                  <div
                    role='group'
                    aria-labelledby='my-radio-group'
                    className={styles.sectionTable}
                  >
                    <RadioButtonInput>
                      {classroomsList.list.map((classroom) => {
                        return (
                          <div key={classroom.id}>
                            <label
                              key={classroom.id}
                              className={styles.sectionTable}
                            >
                              <Field
                                type='radio'
                                name='picked'
                                value={classroom.id}
                              />
                              <h3 className={styles.sectionTable}>
                                {classroom.name}
                              </h3>
                            </label>
                            <p className={styles.sectionTable}>
                              {getStudentsList(classroom)}
                            </p>
                          </div>
                        );
                      })}
                    </RadioButtonInput>
                  </div>
                  <div className={styles.buttonContainer}>
                    <PrimaryButton type='submit' className={styles.alignCentre}>
                      Start Sync
                    </PrimaryButton>
                  </div>
                  <div>
                    <h2>Note:</h2>
                    <p>
                      <ul>
                        <li>
                          <em>
                            If you already have a Clever classroom set up on
                            Peekapk and want to update or add more students to
                            it, you will need to choose ‘merge’ below instead of
                            ‘sync’.{' '}
                          </em>
                        </li>
                        <li>
                          <em>
                            If you want to access the teaching resources only
                            and do not need to add students, click
                            &lsquo;Skip&rsquo; below.
                          </em>
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                      <span
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            pickedExternalClassroomId: values.picked,
                            pickedExternalClassroomName:
                              classroomsList.list.find(
                                (c) => c.id === values.picked
                              )?.name || '',
                            selectorState:
                              SelectorState.chooseExistingClassroom,
                          }));
                          setRosterState(
                            RosterState.mergePickedSourceClassroomForMerging
                          );
                          window.scrollTo(0, 0);
                        }}
                        className={cx(
                          libraryStyles.textLink,
                          styles.alignCentre
                        )}
                        style={{ marginTop: '1rem' }}
                      >
                        Merge
                      </span>{' '}
                      or{' '}
                      <span
                        onClick={skipAutoSync}
                        className={cx(
                          libraryStyles.textLink,
                          styles.alignCentre
                        )}
                        style={{ marginTop: '1rem' }}
                      >
                        Skip
                      </span>
                    </p>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      );
    case SelectorState.chooseExistingClassroom: {
      const c = classroomsList.list.find(
        (c) => c.id === state.pickedExternalClassroomId
      );

      return (
        <>
          <h2>
            If you already have a Clever classroom set up on Peekapk and want to
            update or add more students to it, please pick an existing Peekapak
            classroom from the list below. The students from{' '}
            <strong>
              <em>{state.pickedExternalClassroomName}</em>
            </strong>{' '}
            will be{' '}
            <strong>
              <em>merged</em>
            </strong>{' '}
            into the existing Peekapak classroom. Any students who are not
            already in the existing Peekapak classroom will be added, but no
            students will be removed.
          </h2>
          <Formik
            initialValues={{
              picked: '',
            }}
            onSubmit={async (values) => {
              props.onSubmit({
                pickedExternalClassroomId: state.pickedExternalClassroomId,
                pickedExternalClassroomName: state.pickedExternalClassroomName,
                pickedMergeIntoClassroomId: values.picked,
              });
            }}
          >
            {({ values }) => {
              console.debug('Formik values = ', values);

              return (
                <Form>
                  <div
                    role='group'
                    aria-labelledby='my-radio-group'
                    className={styles.sectionTable}
                  >
                    <RadioButtonInput>
                      {existingClassroomsList.map((classroom) => {
                        return (
                          <div key={classroom.classroomId}>
                            <label
                              key={classroom.classroomId}
                              className={styles.sectionTable}
                            >
                              <Field
                                type='radio'
                                name='picked'
                                value={classroom.classroomId}
                              />
                              <h3 className={styles.sectionTable}>
                                {classroom.className}
                              </h3>
                            </label>
                            <p className={styles.sectionTable}>
                              {getExistingStudentsList(classroom)}
                            </p>
                          </div>
                        );
                      })}
                    </RadioButtonInput>
                  </div>
                  <PrimaryButton
                    type='submit'
                    style={{ marginTop: '1rem' }}
                    className={styles.alignCentre}
                  >
                    Start Merge
                  </PrimaryButton>
                  <p style={{ textAlign: 'center' }}>
                    <span
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          selectorState: SelectorState.chooseExternalClassroom,
                        }));
                        window.scrollTo(0, 0);
                      }}
                      className={cx(libraryStyles.textLink, styles.alignCentre)}
                      style={{ marginTop: '1rem' }}
                    >
                      Go back
                    </span>
                  </p>
                </Form>
              );
            }}
          </Formik>
        </>
      );
    }
  }

  function handleNoSectionsFound() {
    noteLastSuccessfulSync();
    sendUserToRoleHomePage(userProfile, history);
  }

  async function skipAutoSync() {
    if (userProfile) {
      const newUserProfile = createOrUpdateUserProfileFlag(
        'isSkipAutoSync',
        true,
        userProfile
      );
      dispatch(updateAllFields(newUserProfile));
      await updateUserProfile({
        newUserData: {
          flags: { ...userProfile.flags, isSkipAutoSync: true },
        },
      });
    }

    sendUserToRoleHomePage(userProfile, history);
  }

  function getExistingStudentsList(classroom: ClassroomType) {
    if (
      !classroom.listOfStudentProxies ||
      classroom.listOfStudentProxies.length === 0
    ) {
      return `No students`;
    }

    const list = classroom.listOfStudentProxies.reduce(
      (acc, cur) => `${acc}${acc && ','} ${cur.firstName}`,
      ''
    );
    return list;
  }

  function getStudentsList(classroom: NormalizedExternalSection) {
    if (!classroom.students || classroom.students.length === 0) {
      return `No students`;
    }

    const list = classroom.students.reduce(
      (acc, cur) => `${acc}${acc && ','} ${cur.profile.name.givenName}`,
      ''
    );
    return list;
  }
};

export const CleverRostering = () => {
  const [rosterState, setRosterState] = useState<RosterState>(
    RosterState.unknown
  );
  const [sectionFetchState, setSectionFetchState] = useState<SectionFetchState>(
    SectionFetchState.NOT_STARTED
  );

  const [errorState, setErrorState] = useState<string | Error | undefined>(
    undefined
  );

  const [syncErrors, setSyncErrors] = useState<Error[]>([]);

  const [unmatchedClassrooms, setUnmatchedClassrooms] = useState<
    SyncableClassroomId[]
  >([]);

  const [classrooms, setClassrooms] = useState<ExternalClassroomsList>({
    source: SyncSource.UNDEFINED,
    list: [],
  });
  const [syncDonePercentage, setSyncDonePercentage] = useState<number>(-1);
  const [selectedClassroomForSyncing, setSelectedClassroomForSyncing] =
    useState<NormalizedExternalSection | undefined>(undefined);
  const [selectedMergeIntoClassroom, setSelectedMergeIntoClassroom] =
    useState<ClassroomType | null>(null);

  const redirectCountdown = useRef<number>(3);
  const prevExternalUserId = useRef<string>('');
  const prevSyncSource = useRef<SyncSource>(SyncSource.UNDEFINED);

  const location = useLocation();
  const history = useHistory();
  const params = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const isClassroomsLoaded = useSelector(
    (state: RootState) => state.classrooms.state === ClassroomsState.loaded
  );
  const existingClassrooms = useSelector(
    (state: RootState) => state.classrooms.classrooms
  );
  const dispatch = useDispatch();
  const rosterStateRef = useRef<RosterState>(RosterState.unknown);
  rosterStateRef.current = rosterState;

  const source = (() => {
    switch (params.providerId.toLowerCase()) {
      case 'clever':
        return SyncSource.CLEVER;
      case 'classlink':
        return SyncSource.CLASSLINK;
      case 'google':
        return SyncSource.GOOGLE;
      default:
        throw new Error(`Unrecognized import source`);
    }
  })();

  useEffect(() => {
    initialize();

    async function initialize() {
      switch (rosterState) {
        case RosterState.unknown: {
          setRosterState(RosterState.checkingSignInStatus);
          checkSignInStatusAndUpdate();
          break;
        }
        case RosterState.signedIn: {
          if (params.invokeSource === 'classroomSettings') {
            fetchSectionsForInteractiveImport();
            setRosterState(RosterState.fetchingClassrooms);
          } else if (params.invokeSource === 'authenticatedRoute') {
            if (sectionFetchState === SectionFetchState.NOT_STARTED) {
              fetchSections();
            } else {
              if (
                isClassroomsLoaded &&
                sectionFetchState === SectionFetchState.DONE
              ) {
                const syncable = existingClassrooms.filter(
                  (c) => getSyncSourceFromClassroom(c) === source
                );

                if (syncable.length === 0) {
                  setRosterState(RosterState.autoSyncNoRosteredClassrooms);
                } else {
                  const syncableClassroomIds: SyncableClassroomId[] =
                    syncable.map((c) => ({
                      id: (c as ExternalClassroomType).externalClassroomId,
                      modifiedAt: (c as ExternalClassroomType).modifiedAt,
                    }));
                  setRosterState(
                    RosterState.autoSyncAtLeastOneRosteredClassroom
                  );
                  syncExistingClassrooms(syncableClassroomIds);
                }
              }
            }
          }
          break;
        }
        case RosterState.syncingDone: {
          noteLastSuccessfulSync();
          break;
        }
        case RosterState.autoSyncAllClassroomsDone: {
          noteLastSuccessfulSync();
          setRosterState(RosterState.autoSyncRedirectingToHomePage);
          redirectCountdown.current = 3;
          redirectAfterCountdown();
          break;
        }
        case RosterState.autoSyncAllClassroomsDoneWithMore: {
          noteLastSuccessfulSync();
          break;
        }
        default:
        // do nothing
      }
    }

    async function redirectAfterCountdown() {
      while (redirectCountdown.current > 0) {
        redirectCountdown.current = redirectCountdown.current - 1;
        await yieldAndWait();
      }

      if (user?.userProfile && history) {
        sendUserToRoleHomePage(user.userProfile, history);
      }
    }

    async function fetchSectionsForInteractiveImport() {
      await fetchSections();
      setRosterState(RosterState.manualSyncObtainedProviderClassrooms);
    }

    async function checkSignInStatusAndUpdate() {
      const isSignedIn = checkSignInStatus();
      if (isSignedIn) {
        setRosterState(RosterState.signedIn);
      } else {
        setErrorState(`You are not signed in.`);
        setRosterState(RosterState.error);
      }
    }
  });

  async function fetchSections() {
    if (!user?.userProfile) {
      logger.logException(
        new Error(`fetchSections: userProfile doesn't exists`)
      );
      return;
    }

    // call factory to generate user property accessors
    const propertyAccessor = createUserPropertyAccessors(source, user);

    // TODO
    if (isNothingToDo(propertyAccessor)) {
      // console.debug( `%cFetching clever classrooms... nothing to do`, 'color: red; background: black');
      return;
    }

    // reset error state otherwise render will
    // render that instead
    setErrorState('');
    prevExternalUserId.current = propertyAccessor.getApiUserId() || '';
    setSectionFetchState(SectionFetchState.IN_PROGRESS);

    try {
      // call factory to generate retriever function
      const retrievers = createRetrievers(source);

      // call factory to generate composer function
      const composers = createComposers(source);

      const sections = await retrievers.retrieveSections(
        propertyAccessor.getApiUserId() || '',
        propertyAccessor.getApiAccessToken() || ''
      );

      const students = await retrievers.retrieveStudents(
        propertyAccessor.getApiUserId() || '',
        propertyAccessor.getApiAccessToken() || ''
      );

      const normalizedSections = composers.getNormalizedSections(
        sections,
        students
      );

      setClassrooms({
        source,
        list: normalizedSections,
      });
      setSectionFetchState(SectionFetchState.DONE);
    } catch (error) {
      logger.logException(error as Error);
      setErrorState(error as Error);
      setRosterState(RosterState.error);
      setSectionFetchState(SectionFetchState.ERROR);
    }
  }

  function isNothingToDo(userPropertyAccessor: UserPropertyAccessor) {
    if (!userPropertyAccessor.isUserSignedIn()) return true;

    const isDoneFetching =
      sectionFetchState === SectionFetchState.DONE ||
      sectionFetchState === SectionFetchState.ERROR;
    const isFetching = sectionFetchState === SectionFetchState.IN_PROGRESS;

    const noAssociatedCleverUser = () => !userPropertyAccessor.getApiUserId();

    const isUserHasntChanged = () =>
      prevExternalUserId.current === userPropertyAccessor.getApiUserId();

    const inStateOfError = () => !!errorState;

    const dataReadySameUser = () =>
      !errorState && isDoneFetching && isUserHasntChanged();

    const sourceHasntChanged = () => classrooms.source === SyncSource.CLEVER;

    const noErrorAndStillFetching = () => !errorState && isFetching;

    if (noAssociatedCleverUser()) return true;
    if (inStateOfError()) return true;
    if (dataReadySameUser()) return true;
    if (noErrorAndStillFetching() && sourceHasntChanged()) return true;
    return false;
  }

  return (
    <main className={styles.main}>
      <h1>{getTitle()}</h1>
      <article className={styles.article}>{getContent()}</article>
    </main>
  );

  function getTitle() {
    switch (rosterState) {
      case RosterState.signedIn:
        return `Checking current ${params.providerId} classrooms`;
      case RosterState.unknown:
        return `Checking ${params.providerId} account...`;
      case RosterState.autoSyncAllClassroomsDone:
      case RosterState.autoSyncRedirectingToHomePage:
        return `All connected ${params.providerId} classrooms have been synced`;
      case RosterState.autoSyncAllClassroomsDoneWithMore:
        return `Some connected ${params.providerId} classrooms have been synced`;
      case RosterState.autoSyncNoRosteredClassrooms:
        return `Choose ${params.providerId} Classroom to Sync`;
      case RosterState.autoSyncAtLeastOneRosteredClassroom:
        return `Syncing ${params.providerId} Classrooms`;
      case RosterState.fetchingClassrooms:
        return `Fetching list of ${params.providerId} classrooms`;
      case RosterState.checkingSignInStatus:
        return `Checking ${params.providerId} connection status`;
      case RosterState.manualSyncObtainedProviderClassrooms:
        return `Your ${params.providerId} Classrooms`;
      case RosterState.manualSyncPickedClassroomForSyncing:
      case RosterState.syncingStarted:
        return `Syncing from selected classroom...`;
      case RosterState.mergePickedSourceClassroomForMerging:
        return `Choose Existing Classroom to Merge Into`;
      case RosterState.autoSyncSyncingStarted:
        return `Syncing from connected classroom...`;
      case RosterState.mergingStarted:
        return `Merging classroom roster...`;
      case RosterState.syncingDone:
        return 'Synchronization Completed';
      case RosterState.mergingDone:
        return 'Merging Completed';
      case RosterState.updatingClassroom:
        return 'Updating classroom information';
      case RosterState.error:
        return 'Unable to complete syncing of classroom...';
      default:
        throw new Error(`Unexpected RosterState ${rosterState}`);
    }
  }

  function getContent() {
    if (!user.userProfile) return <Spinner name='three-bounce' />;
    switch (rosterState) {
      case RosterState.signedIn:
      case RosterState.unknown:
      case RosterState.fetchingClassrooms:
      case RosterState.checkingSignInStatus:
      case RosterState.autoSyncAtLeastOneRosteredClassroom:
        return <Spinner name='three-bounce' />;
      case RosterState.autoSyncNoRosteredClassrooms:
      case RosterState.manualSyncObtainedProviderClassrooms:
      case RosterState.mergePickedSourceClassroomForMerging:
        return (
          <>
            <ExternalClassroomSelector
              userProfile={user.userProfile}
              source={source}
              classroomsList={classrooms}
              existingClassroomsList={existingClassrooms}
              onSubmit={onSubmit}
              setRosterState={setRosterState}
              rosterStateRef={rosterStateRef}
            />
          </>
        );
      case RosterState.autoSyncAllClassroomsDone:
      case RosterState.autoSyncRedirectingToHomePage:
        return (
          <>
            <h2>
              All your connected {params.providerId} classrooms have been
              synced. Redirecting you to your home page momentarily.
            </h2>
          </>
        );
      case RosterState.autoSyncAllClassroomsDoneWithMore:
        return (
          <>
            <h2>
              Some connected {params.providerId} classrooms have been synced.
            </h2>
            {syncErrors.length > 0 && (
              <div>
                <h3>Errors</h3>
                <ul>
                  {syncErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            {unmatchedClassrooms.length > 0 && (
              <div>
                <h3>Unmatched Classrooms</h3>
                <ul>
                  {unmatchedClassrooms.map((classroom, index) => (
                    <li key={index}>{classroom.id}</li>
                  ))}
                </ul>
              </div>
            )}
            <ButtonWithCountdown
              initialCountdown={10}
              onClick={() => {
                if (user?.userProfile && history) {
                  sendUserToRoleHomePage(user.userProfile, history);
                } else if (history) {
                  history.push('/');
                } else {
                  throw new Error(
                    `Unexpected condition in Rostering, history object not available`
                  );
                }
              }}
              buttonMessage='Okay'
              countdownMessage='Redirecting in... '
              className={styles.alignCentre}
              style={{ marginTop: '2rem' }}
            />
          </>
        );
      case RosterState.manualSyncPickedClassroomForSyncing:
        return (
          <>
            <Spinner name='three-bounce' />
          </>
        );
      case RosterState.autoSyncSyncingStarted:
      case RosterState.syncingStarted:
        return (
          <>
            <Spinner
              name='line-scale'
              fadeIn='none'
              className={styles.progress}
              style={{ textAlign: 'center' }}
            ></Spinner>
            <p className={styles.progress}>{`${syncDonePercentage}%`}</p>
            <p className={styles.progress}>
              {`Classroom: ${selectedClassroomForSyncing?.name}`}
            </p>
          </>
        );
      case RosterState.mergingStarted:
        return (
          <>
            <Spinner
              name='line-scale'
              fadeIn='none'
              className={styles.progress}
              style={{ textAlign: 'center' }}
            ></Spinner>
            <p className={styles.progress}>{`${syncDonePercentage}%`}</p>
            <p className={styles.progress}>
              {`${source} classroom: ${selectedClassroomForSyncing?.name}`}
            </p>
            <p className={styles.progress}>will be merged into</p>
            <p className={styles.progress}>
              {`existing classroom: ${selectedMergeIntoClassroom?.className}`}
            </p>
          </>
        );
      case RosterState.updatingClassroom:
        return (
          <>
            <Spinner name='three-bounce' />
          </>
        );
      case RosterState.syncingDone:
        return (
          <>
            <h2>
              Synchronizing of classroom {selectedClassroomForSyncing?.name}{' '}
              completed!
            </h2>
            <ButtonWithCountdown
              initialCountdown={10}
              onClick={() => {
                if (user?.userProfile && history) {
                  sendUserToRoleHomePage(user.userProfile, history);
                } else if (history) {
                  history.push('/');
                } else {
                  throw new Error(
                    `Unexpected condition in Rostering, history object not available`
                  );
                }
              }}
              buttonMessage='Okay'
              countdownMessage='Redirecting in... '
              className={styles.alignCentre}
              style={{ marginTop: '2rem' }}
            />
          </>
        );
      case RosterState.mergingDone:
        return (
          <>
            <h2>
              Merging of classroom {selectedClassroomForSyncing?.name} into{' '}
              {selectedMergeIntoClassroom?.className} completed!
            </h2>
            <ButtonWithCountdown
              onClick={() => {
                if (user?.userProfile && history) {
                  sendUserToRoleHomePage(user.userProfile, history);
                } else if (history) {
                  history.push('/');
                } else {
                  throw new Error(
                    `Unexpected condition in Rostering, history object not available`
                  );
                }
              }}
              initialCountdown={10}
              buttonMessage='Okay'
              countdownMessage='Redirecting in... '
              className={styles.alignCentre}
              style={{ marginTop: '2rem' }}
            />
          </>
        );

      case RosterState.error:
        return (
          <>
            <p>
              Sorry, we couldn&rsquo;t complete your syncing at this time. There
              was a problem that prevented us from completing the syncing
              operation. Please make a copy of the following message and send it
              to Peekapak Support. You may also try this again later.
            </p>
            <p className={styles.error}>
              {errorState instanceof Error ? errorState.message : errorState}
            </p>
            <div className={styles.buttonContainer}>
              <PrimaryButton
                onClick={() => {
                  noteLastErroredSync();
                  if (user?.userProfile && history) {
                    sendUserToRoleHomePage(user.userProfile, history);
                  } else if (history) {
                    history.push('/');
                  } else {
                    throw new Error(
                      `Unexpected condition in Rostering, history object not available`
                    );
                  }
                }}
              >
                Skip for now
              </PrimaryButton>
            </div>
            <p style={{ textAlign: 'center' }}>
              <span
                onClick={() => {
                  setRosterState(RosterState.signedIn);
                }}
                className={cx(libraryStyles.textLink, styles.alignCentre)}
                style={{ marginTop: '1rem' }}
              >
                Retry
              </span>
            </p>
          </>
        );
      default:
        throw new Error(`Unexpected RosterState ${rosterState}`);
    }
  }

  async function syncExistingClassrooms(
    syncableClassroomIds: SyncableClassroomId[]
  ) {
    const syncHandlers = [];
    const matchedSyncableClassrooms = [];
    const unmatchedSyncableClassrooms = [];
    const syncErrors: Error[] = [];

    let i = 0;
    while (i < syncableClassroomIds.length) {
      const syncableClassroomId = syncableClassroomIds[i];
      const matchingExternalClassroom = classrooms.list.find(
        (c) =>
          c.id ===
          syncableClassroomId.id.slice(syncableClassroomId.id.indexOf('_') + 1)
      );

      if (matchingExternalClassroom) {
        matchedSyncableClassrooms.push({
          matchingExternalClassroom,
          syncableClassroomId,
        });
      } else {
        unmatchedSyncableClassrooms.push(syncableClassroomId);
      }

      i++;
    }

    for (const matchedSyncableClassroom of matchedSyncableClassrooms) {
      if (
        (matchedSyncableClassroom.matchingExternalClassroom.modifiedAt ?? 0) >
        matchedSyncableClassroom.syncableClassroomId.modifiedAt
      ) {
        console.debug(
          `toSyncClassroom = `,
          matchedSyncableClassroom.matchingExternalClassroom
        );
        const handler = createSyncHandler({
          source: classrooms.source,
          classroom: matchedSyncableClassroom.matchingExternalClassroom,
          setSyncStartedCallback: () => {
            return;
          },
          setSyncDoneProgressCallback: setSyncDonePercentage,
          setSyncingClassroomCallback: setSelectedClassroomForSyncing,
          setErrorCallback: (error) => {
            syncErrors.push(error as Error);
          },
          setNewClassroomObjectCallback: (newClassroom) =>
            onSyncDone(newClassroom, RosterState.autoSyncSyncingStarted),
          rosterStateRef,
        });

        syncHandlers.push(handler);
      }
    }

    setRosterState(RosterState.autoSyncSyncingStarted);

    for (const handler of syncHandlers) {
      await handler();
    }

    if (syncErrors.length > 0) {
      setSyncErrors(syncErrors);
      setRosterState(RosterState.autoSyncAllClassroomsDoneWithMore);
    } else if (unmatchedSyncableClassrooms.length > 0) {
      setUnmatchedClassrooms(unmatchedSyncableClassrooms);
      setRosterState(RosterState.autoSyncAllClassroomsDoneWithMore);
    } else {
      setRosterState(RosterState.autoSyncAllClassroomsDone);
    }

    console.debug(
      '%cAutoSync connected classrooms completed',
      'color: green; background-color: darkgrey;'
    );
  }

  async function onSubmit(selectedClassrooms: SelectedClassrooms) {
    console.debug('onSubmit');
    console.debug(selectedClassrooms);
    //
    // TODO: we're turning auto-sync back on, but there will not be any way to
    // turn it off again, unless we delete the classroom and get the page that
    // prompts for syncing a classroom again
    if (user?.userProfile?.flags?.isSkipAutoSync) {
      const newUserProfile = createOrUpdateUserProfileFlag(
        'isSkipAutoSync',
        false,
        user.userProfile
      );
      dispatch(updateAllFields(newUserProfile));
      const { isSkipAutoSync, ...rest } = newUserProfile.flags;

      await updateUserProfile({
        newUserData: {
          flags: { ...rest },
        },
      });
    }

    const selectedClassroom = classrooms.list.find(
      (c) => c.id === selectedClassrooms.pickedExternalClassroomId
    );

    if (!selectedClassroom) {
      setErrorState(
        `We couldn't find any data for this classroom or we couldn't find this classroom altogether. The ID of the selected classroom is ${selectedClassrooms.pickedExternalClassroomId}`
      );
      setRosterState(RosterState.error);
      return;
    }

    setSelectedClassroomForSyncing(selectedClassroom);
    const selectedMergeIntoClassroom = existingClassrooms.find(
      (c) => c.classroomId === selectedClassrooms.pickedMergeIntoClassroomId
    );

    if (selectedMergeIntoClassroom) {
      setSelectedMergeIntoClassroom(selectedMergeIntoClassroom);
    }

    const handler = (() => {
      if (selectedClassrooms.pickedMergeIntoClassroomId) {
        return createMergeHandler({
          source: classrooms.source,
          mergeIntoClassroomId: selectedClassrooms.pickedMergeIntoClassroomId,
          classroom: selectedClassroom,
          setSyncStartedCallback: (startedStatus) =>
            startedStatus && setRosterState(RosterState.mergingStarted),
          setSyncDoneProgressCallback: setSyncDonePercentage,
          setSyncingClassroomCallback: setSelectedClassroomForSyncing,
          setErrorCallback: (error) => {
            setErrorState(error);
            setRosterState(RosterState.error);
          },
          setNewClassroomObjectCallback: (newClassroomObject) =>
            onSyncDone(newClassroomObject, RosterState.mergingDone),
          rosterStateRef,
        });
      } else {
        return createSyncHandler({
          source: classrooms.source,
          classroom: selectedClassroom,
          setSyncStartedCallback: (startedStatus) =>
            startedStatus && setRosterState(RosterState.syncingStarted),
          setSyncDoneProgressCallback: setSyncDonePercentage,
          setSyncingClassroomCallback: setSelectedClassroomForSyncing,
          setErrorCallback: (error) => {
            setErrorState(error);
            setRosterState(RosterState.error);
          },
          setNewClassroomObjectCallback: (newClassroomObject) =>
            onSyncDone(newClassroomObject),
          rosterStateRef,
        });
      }
    })();
    handler();

    return;
  }

  function checkSignInStatus() {
    const propertyAccessor = createUserPropertyAccessors(source, user);
    const userSignedIn = propertyAccessor.isUserSignedIn();

    return userSignedIn;
    /* old code that forces a login; we might want to user
     * this later on, but in a generic way, since we have users from Clever,
     * Google and ClassLink
    if (!userSignedIn) {
      logger.logMessage(
        `Classroom page: detected Clever user not signed in, attempting to sign in now`
      );
      setLocalStorage(`CleverImportInvoked`, 'true');
      initiateCleverLogin(null, location.pathname);
      return false;
    }
     */
  }

  async function onSyncDone(
    newClassroomObject: ClassroomType | null,
    newSyncState: RosterState = RosterState.syncingDone
  ) {
    if (!newClassroomObject || Object.keys(newClassroomObject).length === 0) {
      const e = new Error('No classroom object returned from sync');
      console.error(e);
      setErrorState(e);
      setRosterState(RosterState.error);
      return;
    }

    setRosterState(RosterState.updatingClassroom);

    const Authorization = await getAuthorizationToken();

    if (newClassroomObject) {
      dispatch(createClassroom(newClassroomObject));
      postServiceRequest(
        `${getAppRoot()}api/getClassroom`,
        {
          email: newClassroomObject.email,
          className: newClassroomObject.className,
        },
        (_status: number, responseObject2: XMLHttpRequest) => {
          dispatch(updateClassroom(JSON.parse(responseObject2.responseText)));
          setRosterState(newSyncState);
        },
        (_errorStatus: number, _responseObject2: XMLHttpRequest) => {
          const error = new Error(
            `Student::handleSaveChanges - postServiceRequest update failed for teacher ${newClassroomObject.teachersUserId}`
          );
          logger.logException(error);
          console.error(error);
          setRosterState(RosterState.error);
          setErrorState(error);
        },
        {
          Authorization,
        }
      );
    }
  }
};

interface CreateSyncHandlerParams {
  source: SyncSource;
  classroom: NormalizedExternalSection;
  setSyncStartedCallback: (arg0: boolean) => void;
  setSyncDoneProgressCallback: (arg0: number) => void;
  setErrorCallback: (arg0: string | Error) => void;
  setSyncingClassroomCallback: (arg0: NormalizedExternalSection) => void;
  setNewClassroomObjectCallback: (arg0: ClassroomType | null) => Promise<void>;
  rosterStateRef: React.MutableRefObject<RosterState>;
}

interface CreateMergeHandlerParams extends CreateSyncHandlerParams {
  mergeIntoClassroomId: string;
}

function createMergeHandler({
  source,
  classroom,
  mergeIntoClassroomId,
  setSyncStartedCallback,
  setSyncDoneProgressCallback,
  setErrorCallback,
  setSyncingClassroomCallback,
  setNewClassroomObjectCallback,
  rosterStateRef,
}: CreateMergeHandlerParams) {
  return async () => {
    const syncingTeachersUserId =
      getStore().getState().user?.userProfile?.userId;

    if (!syncingTeachersUserId) {
      return;
    }

    setSyncingClassroomCallback(classroom);

    try {
      setSyncStartedCallback(true);
      setSyncDoneProgressCallback(0);
      const mergeParams: MergeClassroomRequest = {
        operation: 'merge',
        idP: source,
        defaultStudentPassword:
          getStore().getState().user.userProfile?.defaultStudentPassword ||
          'ilovepeekapak',
        rawClassroomData: classroom,
        teachersUserId: syncingTeachersUserId,
        mergeIntoClassroomId,
      };
      const newClassroomObject = await mergeClassroomHelper(
        mergeParams,
        setSyncDoneProgressCallback,
        rosterStateRef
      );
      setNewClassroomObjectCallback(newClassroomObject);
    } catch (error) {
      logger.logException(error as Error);
      setErrorCallback(getErrorMessage(error as JobError));
      setNewClassroomObjectCallback(null);
    } finally {
      setSyncStartedCallback(false);
    }
  };

  function getErrorMessage(jobError: JobError) {
    let messageBuilder = `${jobError.message}`;

    if (jobError.time) {
      messageBuilder = `${messageBuilder} at ${jobError.time}`;
    }

    if (jobError.requestId) {
      messageBuilder = `${messageBuilder} on ${jobError.requestId}`;
    }

    if (jobError.relatedUid) {
      messageBuilder = `${messageBuilder} on ${jobError.relatedUid}`;
    }

    return messageBuilder;
  }
}

function createSyncHandler({
  source,
  classroom,
  setSyncStartedCallback,
  setSyncDoneProgressCallback,
  setErrorCallback,
  setSyncingClassroomCallback,
  setNewClassroomObjectCallback,
}: CreateSyncHandlerParams) {
  return async () => {
    const syncingTeachersUserId =
      getStore().getState().user?.userProfile?.userId;

    if (!syncingTeachersUserId) {
      return;
    }

    setSyncingClassroomCallback(classroom);

    try {
      setSyncStartedCallback(true);
      setSyncDoneProgressCallback(0);
      const syncParams: SyncClassroomRequest = {
        operation: 'sync',
        idP: source,
        defaultStudentPassword:
          getStore().getState().user.userProfile?.defaultStudentPassword ||
          'ilovepeekapak',
        rawClassroomData: classroom,
        teachersUserId: syncingTeachersUserId,
      };
      const newClassroomObject = await syncClassroomHelper(
        syncParams,
        setSyncDoneProgressCallback
      );
      setNewClassroomObjectCallback(newClassroomObject);
    } catch (error) {
      logger.logException(error as Error);
      setErrorCallback(getErrorMessage(error as JobError));
      setNewClassroomObjectCallback(null);
    } finally {
      setSyncStartedCallback(false);
    }
  };

  function getErrorMessage(jobError: JobError) {
    let messageBuilder = `${jobError.message}`;

    if (jobError.time) {
      messageBuilder = `${messageBuilder} at ${jobError.time}`;
    }

    if (jobError.requestId) {
      messageBuilder = `${messageBuilder} on ${jobError.requestId}`;
    }

    if (jobError.relatedUid) {
      messageBuilder = `${messageBuilder} on ${jobError.relatedUid}`;
    }

    return messageBuilder;
  }
}

function mergeClassroomHelper(
  mergeParams: MergeClassroomRequest,
  setSyncDonePercentage: (arg0: number) => void,
  rosterStateRef: React.MutableRefObject<RosterState>
): Promise<ClassroomType> {
  return new Promise((resolve, reject) => {
    importClassroom(
      mergeParams,
      async (newJob: { jobId: string }) => {
        try {
          let job: {
            status: string;
            data: string;
          } = await getJob(newJob.jobId);
          setSyncDonePercentage(0);

          while (
            isJobPending(job) &&
            rosterStateRef.current !== RosterState.error
          ) {
            processPendingJob(job);
            await yieldAndWait();
            job = await getJob(newJob.jobId);
          }

          const data = JSON.parse(job.data);

          if (job.status === 'Done') {
            return resolve(data);
          }

          return reject(data);
        } catch (error) {
          return reject(error);
        }
      },
      (error) => {
        return reject(error);
      }
    );
  });

  function isJobPending(job: { status: string }) {
    return job.status !== 'Done' && job.status !== 'Error';
  }

  function processPendingJob(job: { data: string }) {
    if (job.data) {
      const data = JSON.parse(job.data);
      setSyncDonePercentage(data.completionPercent);
    }
  }
}

function syncClassroomHelper(
  syncParams: SyncClassroomRequest,
  setSyncDonePercentage: (arg0: number) => void
): Promise<ClassroomType> {
  return new Promise((resolve, reject) => {
    importClassroom(
      syncParams,
      async (newJob: { jobId: string }) => {
        try {
          let job: {
            status: string;
            data: string;
          } = await getJob(newJob.jobId);
          setSyncDonePercentage(0);

          while (isJobPending(job)) {
            processPendingJob(job);
            await yieldAndWait();
            job = await getJob(newJob.jobId);
          }

          const data = JSON.parse(job.data);

          if (job.status === 'Done') {
            return resolve(data);
          }

          return reject(data);
        } catch (error) {
          return reject(error);
        }
      },
      (error) => {
        return reject(error);
      }
    );
  });

  function isJobPending(job: { status: string }) {
    return job.status !== 'Done' && job.status !== 'Error';
  }

  function processPendingJob(job: { data: string }) {
    if (job.data) {
      const data = JSON.parse(job.data);
      setSyncDonePercentage(data.completionPercent);
    }
  }
}

function yieldAndWait(timeoutDuration = 1000): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeoutDuration);
  });
}

function noteLastErroredSync() {
  noteSyncResult('autoSyncClassroomsErrored');
}

function noteLastSuccessfulSync() {
  noteSyncResult('autoSyncClassroomsCompleted');
}

function noteSyncResult(
  name: 'autoSyncClassroomsCompleted' | 'autoSyncClassroomsErrored'
) {
  setLocalStorage(name, 'true', AUTOSYNC_CLASSROOMS_COMPLETED_OR_ERROR_TIMEOUT);
}

export default CleverRostering;
