import { Component } from 'react';
import PropTypes from 'prop-types';
import { VideoPlayer } from './VideoPlayer';
import styles from '../SCSS/VideoPlaceholder.module.scss';
import videoIcon from '../images/pkp-video-icon.svg';

export class VideoPlaceholder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowPlayer: false,
    };

    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleHide() {
    this.setState({
      isShowPlayer: false,
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      isShowPlayer: true,
    });
  }

  render() {
    return (
      <div className={styles.content}>
        <img
          alt='Thumbnail'
          src={this.props.graphicUrl}
          className={styles.videoThumbnailImage}
        />
        <img
          alt='Video'
          src={videoIcon}
          className={styles.videoIconOverlay}
          onClick={this.handleClick}
        />
        <VideoPlayer
          isShow={this.state.isShowPlayer}
          onHide={this.handleHide}
          videoUrl={this.props.videoUrl}
          videoTitle={this.props.videoTitle}
        />
      </div>
    );
  }
}

VideoPlaceholder.propTypes = {
  graphicUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  videoTitle: PropTypes.string,
};
