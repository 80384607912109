import { useState } from 'react';
import { getPublicMediaRoot } from '../serviceAgent';
import Button from '../components/Button';
import styles from './LessonHeader.module.scss';
import { Icon } from '@iconify/react';
import bxCheckCircle from '@iconify-icons/bx/bx-check-circle';

interface LessonHeaderProps {
  title: string;
  time: number;
  index: number;
  representativeImage: string;
  markComplete: () => void;
  markIncomplete: () => void;
  isDeemedCompleted: boolean;
  isComplete: boolean;
}

const LessonHeader = ({
  title,
  index,
  time,
  representativeImage,
  markComplete,
  markIncomplete,
  isComplete,
  isDeemedCompleted,
}: LessonHeaderProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false);
  const imgPath = `${getPublicMediaRoot()}images/secondary/${representativeImage}`;
  const lessonNum = index / 100;
  return (
    <div className={styles.lessonHeaderContainer}>
      <div className={styles.headerText}>
        <h3>Lesson {lessonNum}</h3>
        <h1>{title}</h1>
        {/*<p>{time} mins</p>*/}
        <div className={styles.unitButton}>
          {isComplete && (
            <Button disabled>
              <Icon
                icon={bxCheckCircle}
                width={30}
                style={{ marginRight: '0.5em' }}
              />{' '}
              LESSON COMPLETE
            </Button>
          )}
          {isDeemedCompleted && !isComplete && (
            <Button
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              onClick={markIncomplete}
              completed
            >
              <Icon
                icon={bxCheckCircle}
                width={30}
                style={{ marginRight: '0.5em' }}
              />{' '}
              {isHover ? 'MARK INCOMPLETE' : 'LESSON COMPLETE'}
            </Button>
          )}
          {!isComplete && !isDeemedCompleted && (
            <Button onClick={markComplete}>MARK LESSON AS COMPLETE</Button>
          )}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={imgPath} />
      </div>
    </div>
  );
};

export default LessonHeader;
