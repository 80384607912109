import { Component } from 'react';
import PropTypes from 'prop-types';
import { postServiceRequest } from '../../serviceAgent';
import { getAppRoot } from '../../identity';
import FlipbookAudio from './FlipbookAudio';
import { getUsersId } from '../../GlobalFunctions';
import {
  getAuthorizationToken,
  updateUserProfile,
} from '../../BackendInterface';
import styles from '../../SCSS/AudioToolBar.module.scss';
import { getStore } from '../../ApplicationState';

export class FlipbookReadAloud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repeat: false,
      volume: 0.8,
      showVolume: false,
      showControl: true,
      chevronHover: false,
    };
    this.setRepeat = this.setRepeat.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.changePinTemporarily = this.changePinTemporarily.bind(this);
    this.togglePinState = this.togglePinState.bind(this);
    this.handleVolumeToggle = this.handleVolumeToggle.bind(this);
    this.handleControlToggle = this.handleControlToggle.bind(this);
    this.toggleChevronHover = this.toggleChevronHover.bind(this);
  }

  UNSAFE_componentWillMount() {
    const sessionFlag = sessionStorage.getItem('isShowControl');
    if (sessionFlag === null) {
      this.setState({ showControl: true });
    } else {
      this.setState({ showControl: sessionFlag === 'true' });
    }
  }

  setRepeat(newRepeat) {
    this.setState({ repeat: newRepeat });
  }

  setVolume(newVolume) {
    this.setState({ volume: newVolume });
  }

  async togglePinState() {
    const newPinState = !this.state.showControl;
    this.changePinTemporarily(newPinState);
    this.handleControlToggle(newPinState);

    const userProfile = getStore().getState().user.userProfile;
    const newFlags = {
      ...userProfile.flags,
      isShowControl: newPinState,
    };

    await updateUserProfile({ newUserData: { flags: newFlags } });
  }

  handleControlToggle(newPinState) {
    this.setState({ showControl: newPinState });
  }

  handleVolumeToggle(newVolumeState) {
    this.setState({ showVolume: newVolumeState });
  }

  toggleChevronHover(isHover) {
    this.setState({ chevronHover: isHover });
  }

  changePinTemporarily(newPinState) {
    sessionStorage.setItem(`isShowControl`, newPinState ? 'true' : 'false');
  }

  render() {
    const isMobile = window.screen.width <= 1024 || window.screen.height <= 768;
    if (this.props.timeKeys != null) {
      return (
        <div className={styles['desktop-audio-control']}>
          <div
            onMouseLeave={() => {
              this.toggleChevronHover(false);
            }}
          >
            <FlipbookAudio
              {...this.props}
              id='audioplayer'
              toRepeat={this.state.repeat}
              setRepeat={this.setRepeat}
              timeKeys={this.props.timeKeys}
              volume={this.state.volume}
              setAudioVolume={this.setVolume}
              showVolume={this.state.showVolume}
              toggleShowVolume={this.handleVolumeToggle}
              showControl={this.state.showControl}
              toggleChevronHover={this.toggleChevronHover}
              chevronHover={this.state.chevronHover}
              isMobile={isMobile}
              togglePinState={this.togglePinState}
              toggleAutoPlay={this.props.toggleAutoPlay}
              pagesDisplayed={this.props.pagesDisplayed}
            />
          </div>
        </div>
      );
    }
    return <p style={{ left: '42%' }}> NO AUDIO PLAYABLE </p>;
  }
}

FlipbookReadAloud.propTypes = {
  timeKeys: PropTypes.object,
};
