import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderRedirect from '../../core/HeaderRedirect';
import { useAuth } from '../../CustomHooks';
import { LoginState } from '../../UserManagement';
import UnauthenticatedHeader from '../UnauthenticatedHeader/UnauthenticatedHeader';

export const SmartHeader = (props) => {
  // console.debug(`%cSmartHeader render`, 'background: pink; color: black');
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // console.debug(
    //   `%cCHECK if login state is unknown`,
    //   'background: pink; color: black'
    // );
    if (auth.isUserLoggedInStateUnknown()) {
      auth.reactivateUserSession();
    }
  });

  // console.debug(
  //   `%cCURRENT state ===`,
  //   'background: pink; color: black',
  //   props.loginState
  // );
  if (user?.loginState === LoginState.loggedIn) {
    return (
      <HeaderRedirect
        history={history}
        location={location}
        isUnauthenticated
        isHidePeekaville
      />
    );
  } else if (
    user?.loginState === LoginState.loggedOut ||
    user?.loginState === LoginState.expiredSession
  ) {
    return <UnauthenticatedHeader {...props} />;
  } else return null;
};
