export interface AugmentedError extends Error {
  code: string;
  data: string;
}
export class EnterpriseUserError extends Error implements AugmentedError {
  // JSON-stringified object with additional information
  constructor(
    public message: string,
    public code: string,
    public data: string
  ) {
    super(message);
    this.code = code;
    this.data = data;
  }
}
