import { useState } from 'react';
import ReactQuill from 'react-quill';
import { useParams, useHistory } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Button from '../Button';
import FormikAutosave from '../FormikAutosave';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { saveAssignment, submitAssignment } from '../../BackendInterface';
import { Icon } from '@iconify/react';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import styles from './ToDoQuestionAnswer.module.scss';
import quillStyles from '../../SCSS/customBubble.scss';
import { UrlParams } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  data: {
    question: string;
    placeholder: string;
    index: number;
    template?: string;
    isMultiline?: boolean;
    type: string;
  }[];
  studentWork: {
    userId: string;
    createdAt: number;
    work: { content: { answerText: string }[] };
  };
  updateAssignment: (assignment: any) => void;
}

interface ManualField {
  field: { name: string; value: string };
}

type FormValues = { [key: string]: string };

// export interface FormValues {}

const ToDoQuestionAnswer = ({
  data,
  studentWork,
  updateAssignment,
}: Props): JSX.Element => {
  const [isShowSpinner, setShowSpinner] = useState(false);
  const [_, setShowError] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const { activity, toDo, grade, language }: UrlParams = useParams();
  const history = useHistory();
  const isTeacher = isNaN(parseInt(toDo));
  const existingWork =
    studentWork?.work?.content !== undefined ? studentWork.work.content : null;

  const dataObj = data.map((obj) => obj.index);

  const questionDefinitions = data.reduce(
    (acc, curr) => (curr.definition ? acc.concat(curr.definition) : acc),
    [] as string[]
  );

  const formValuesFromIndices = dataObj.reduce(
    (acc, curr, currentIndex) => (
      (acc[curr] =
        existingWork !== null && existingWork[currentIndex]
          ? existingWork[currentIndex].answerText
          : ''),
      acc
    ),
    {} as FormValues
  );

  const formValuesWithTemplates = Object.keys(formValuesFromIndices).reduce(
    (acc: FormValues, curr: string, currentIndex) => (
      (acc[curr] =
        formValuesFromIndices[curr] !== '' &&
        formValuesFromIndices[curr] !== undefined
          ? formValuesFromIndices[curr]
          : data[currentIndex].template
          ? data[currentIndex].template
          : ''),
      acc
    ),
    {} as FormValues
  );

  const initialFormValues: FormValues = formValuesWithTemplates;

  const schemaValues = dataObj.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: Yup.string(),
    }),
    {}
  );

  const validationSchema = Yup.object().shape(schemaValues);

  const submitHandler = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setShowSpinner(true);
    try {
      const valueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          { index: parseInt(k), answerText: v, type: 'questionAnswer' },
        ],
        [] as { index: number; answerText: string; type: string }[]
      );
      await submitAssignment(studentWork.userId, parseInt(toDo), valueArray);
      // console.info(`result = ${JSON.stringify(saveResponse, null, 2)}`);
      console.log('submitted via submit');
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      setShowError(true);
    } finally {
      setShowSpinner(false);
      setSubmitting(false);
      history.push(
        `/studentportal/activity/${activity}/${language}/${grade}/toDo/${toDo}/confirmation`
      );
    }
  };

  const saveHandler = async (values: FormValues) => {
    try {
      const valueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          { index: parseInt(k), answerText: v, type: 'questionAnswer' },
        ],
        [] as { index: number; answerText: string; type: string }[]
      );
      await saveAssignment(studentWork.userId, parseInt(toDo), valueArray);
      updateAssignment({
        userId: studentWork.userId,
        createdAt: parseInt(toDo),
        work: { content: valueArray },
      });
      // console.info(`result = ${JSON.stringify(saveResponse, null, 2)}`);
      console.log('submitted via save');
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      setShowError(true);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    return !activeField && e.key === 'Enter' && e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      <h3>Submit your Responses</h3>
      <Formik
        key='questionAnswer'
        initialValues={initialFormValues}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {({ errors, touched, dirty, setFieldValue }) => {
          return (
            <Form key='questionAnswer form' onKeyDown={onKeyDown}>
              <ol className={styles.orderedList}>
                {data
                  ?.filter((item) => item.type === 'questionAnswer')
                  .map((questionObject, index) => {
                    const questionIndex = questionObject.index;
                    return (
                      <div key={`response ${index + 1}`}>
                        <li
                          className={styles.formList}
                          dangerouslySetInnerHTML={{
                            __html: questionObject.question,
                          }}
                        />
                        {questionObject.isMultiline ||
                        questionObject.template ? (
                          <div className={quillStyles}>
                            <Field name={questionObject.index}>
                              {({ field }: ManualField) => {
                                const { name, value } = field;
                                return (
                                  <>
                                    <ReactQuill
                                      placeholder={questionObject.placeholder}
                                      theme='bubble'
                                      value={value || ''}
                                      onChange={(value) => {
                                        setFieldValue(name, value, false);
                                      }}
                                      modules={{
                                        keyboard: { bindings: { tab: false } },
                                      }}
                                    />
                                    {errors[name] && touched[questionIndex] && (
                                      <div className={styles.errors}>
                                        <Icon
                                          icon={alertCircleFill}
                                          color='#ff6319'
                                          className={styles.icon}
                                          width={18}
                                        />
                                        <ErrorMessage name={name} />
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            </Field>
                          </div>
                        ) : (
                          <>
                            <Field
                              className={styles.singleInput}
                              name={questionIndex}
                              type='text'
                              placeholder={questionObject.placeholder}
                            />
                            {errors[questionIndex] &&
                              touched[questionIndex] && (
                                <div className={styles.oneLineErrors}>
                                  <Icon
                                    icon={alertCircleFill}
                                    color='#ff6319'
                                    className={styles.icon}
                                    width={18}
                                  />
                                  <ErrorMessage name={questionObject.index} />
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    );
                  })}
              </ol>
              {questionDefinitions.length > 0 &&
                questionDefinitions.map((def, index) => (
                  <p key={index}>{def}</p>
                ))}
              <FormikAutosave saveHandler={saveHandler} dirty={dirty} />
              <div className={styles.submitButton}>
                <Button
                  onFocus={() => setActiveField(true)}
                  onBlur={() => setActiveField(false)}
                  type='submit'
                  darkBlue
                  thinText
                  disabled={isTeacher}
                >
                  Submit Your Responses&nbsp;&nbsp;&rarr;
                </Button>
                {errors &&
                  Object.keys(touched).length === data.length &&
                  Object.keys(errors).length !== 0 && (
                    <div>
                      Unable to submit, please check your answers and try again
                    </div>
                  )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ToDoQuestionAnswer;
