import { forwardRef } from 'react';
import combineClasses from 'classnames';
import styles from './Button.module.scss';
interface Props {
  darkBlue?: boolean;
  small?: boolean;
  disabled?: boolean;
  orange?: boolean;
  red?: boolean;
  completed?: boolean;
  className?: Record<string, unknown>;
  secondary?: boolean;
  inverted?: boolean;
  white?: boolean;
  grey?: boolean;
  greyDisabled?: boolean;
  whiteBorder?: boolean;
  thinText?: boolean;
  upload?: boolean;
  textBlue?: boolean;
  children: Element[];
  [k: string]: string | unknown | undefined;
}

const Button = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    xSmall,
    small,
    disabled,
    darkBlue,
    orange,
    red,
    completed,
    className,
    secondary,
    thinText,
    inverted,
    white,
    grey,
    greyDisabled,
    whiteBorder,
    children,
    upload,
    blueBorder,
    alert,
    textBlue,
    ...otherProps
  } = props;
  const combinedClasses = combineClasses(
    {
      [`${styles.primaryButton}`]: true,
      [`${styles.small}`]: small,
      [`${styles.xSmall}`]: xSmall,
      [`${styles.disabled}`]: disabled,
      [`${styles.orange}`]: orange,
      [`${styles.red}`]: red,
      [`${styles.white}`]: white,
      [`${styles.inverted}`]: inverted,
      [`${styles.completed}`]: completed,
      [`${styles.secondary}`]: secondary,
      [`${styles.grey}`]: grey,
      [`${styles.greyDisabled}`]: greyDisabled,
      [`${styles.darkBlue}`]: darkBlue,
      [`${styles.thinText}`]: thinText,
      [`${styles.whiteBorder}`]: whiteBorder,
      [`${styles.upload}`]: upload,
      [`${styles.blueBorder}`]: blueBorder,
      [`${styles.alert}`]: alert,
      [`${styles.textBlue}`]: textBlue,
    },
    className
  );
  return (
    <div className={combinedClasses}>
      {disabled ? (
        <button {...otherProps} disabled ref={ref}>
          {children}
        </button>
      ) : (
        <button {...otherProps} ref={ref}>
          {children}
        </button>
      )}
    </div>
  );
});
export default Button;
