import { useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import HeaderNavItem from './HeaderNavItem';
import { navItems } from './navItems';
import nameLogo from '../../images/pkp-backpack-name-logo.svg';
import closeIcon from '../../images/pkp-close-ex-icon.svg';
import styles from './UnauthenticatedHeader.module.scss';
import { SignupButton, RequestInfoButton } from './HeaderButtons';

interface MobilePanelProps {
  active: boolean;
  setIsMobileMenuActive: (arg0: boolean) => void;
  history: RouteComponentProps['history'];
}

const MobilePanelMenu = ({
  setIsMobileMenuActive,
  history,
  active,
}: MobilePanelProps): JSX.Element => {
  const selfReference = useRef<HTMLDivElement>(null);

  const onClose = () => {
    setIsMobileMenuActive(false);
  };

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    const me = selfReference.current;

    if (me) {
      me.focus();
    }

    return () => {
      if (me) {
        me.blur();
      }
    };
  });

  return (
    <nav
      className={cx(styles.mobilePanelMenuWrapper, {
        [`${styles.active}`]: active,
      })}
      onKeyUp={onKeyUp}
    >
      <div className={styles.hiddenElement} tabIndex={0} ref={selfReference} />
      <ul className={styles.mobilePanelMenu}>
        <ul className={styles.mobilePanelHeader}>
          <li>
            <button className={styles.resembleLinkButton} onClick={onClose}>
              <img
                className={styles.mobilePanelLogo}
                src={nameLogo}
                alt='white backpack logo'
              />
            </button>
          </li>
          <li>
            <button className={styles.resembleLinkButton} onClick={onClose}>
              <img
                className={styles.closeIcon}
                src={closeIcon}
                alt='X closes the menu'
                onClick={onClose}
              />
            </button>
          </li>
        </ul>
        <ul className={styles.textButtons}>
          {navItems.map((c) => (
            <HeaderNavItem
              key={`HeaderNavItem-${c.name}`}
              name={c.name}
              route={c.route}
              hasSubMenu={!!c.subItems}
              subItems={c.subItems}
              history={history}
              onClickCallback={onClose}
              isMobile
            />
          ))}
        </ul>
        <ul className={styles.graphicButtons}>
          <SignupButton
            orange
            inverted
            isMobileMenuActive={active}
            setIsMobileMenuActive={setIsMobileMenuActive}
          />
          <RequestInfoButton
            white
            isMobileMenuActive={active}
            setIsMobileMenuActive={setIsMobileMenuActive}
          />
        </ul>
      </ul>
    </nav>
  );
};

export default MobilePanelMenu;
