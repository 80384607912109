import { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getDescriptiveDateFromEpoch,
  getPossessiveForm,
} from '../../GlobalFunctions';
import { setMessageMark } from '../../core/context/contextFunctions';
import { getMessages } from '../../core/context/contextHelpers';
import { loadMessages as lm } from '../../Messages';
import { AppDispatch, RootState } from '../../ApplicationState';

import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import styles from './Notifications.module.scss';
import { MessageType } from '../../../peekapak-types/DataProtocolTypes';

interface Props {
  messages: {
    list: [];
    messageMark: number;
  };
}

interface MessagesFromBackend {
  newMessages: MessageType[];
  messageMark: number;
}

const isNewMessage = (message: { date: number }, messageMark: number) =>
  message.date > messageMark;

const getEventMessage = (message: MessageType, i: number) => (
  <div key={i}>
    <div className={styles.subject}>
      <span>{message.subject && message.subject}&nbsp;</span>
      <span className={styles.normalText}>
        {getDescriptiveDateFromEpoch(parseInt(message.date, 10))}
      </span>
    </div>
    <div style={{ marginLeft: '1em' }}>
      <span className={styles.parentsName}>
        {message.parentsName
          ? message.parentsName
          : `${getPossessiveForm(message.studentsName)} parent `}
      </span>
      <span className={styles.verb}>{message.verb}&nbsp;</span>
      <span className={styles.noun}>{message.noun}&nbsp;</span>
    </div>
  </div>
);

const Notifications = (props: Props) => {
  const [isMarkButton, setIsMarkButton] = useStateWithCallbackLazy(true);
  const { list, messageMark } = useSelector((state: RootState) => ({
    list: state.messages.messages,
    messageMark: state.messages.messageMark,
  }));

  const newMessages = list.filter((message: MessageType) => {
    return isNewMessage(message, messageMark);
  });
  const messagePlural = newMessages.length === 1 ? 'message' : 'messages';

  const dispatch = useDispatch();

  const handleMarkAllMessagesRead = async () => {
    setIsMarkButton(false, () => {
      setMessageMark(
        (status, requestObject) => {
          return;
        },
        (errorStatus) => {
          return;
        }
      ).then(async () => {
        try {
          const { value }: MessagesFromBackend = await getMessages();
          dispatch(
            lm({ messages: value.newMessages, messageMark: value.messageMark })
          );
        } catch (e) {
          if (e.code !== 'NotAuthorizedException') {
            throw e;
          }
        } finally {
          setIsMarkButton(true, null);
        }
      });
    });
  };

  useEffect(() => {
    return;
  }, [props.messages]);

  return (
    <div className={styles.Notifications}>
      <div className={styles.titleContainer}>
        <div className={styles.messageCount}>
          <p>
            You have {newMessages.length} new {messagePlural}
          </p>
        </div>
        <PrimaryButton
          small
          className={styles.markAllButton}
          disabled={!isMarkButton}
          onClick={handleMarkAllMessagesRead}
        >
          {isMarkButton ? 'Mark All As Read' : 'Waiting...'}
        </PrimaryButton>
      </div>
      {list.length < 1
        ? null
        : list.map((message: MessageType, index: number) => (
            <div
              key={message.date}
              className={styles.NotificationListContainer}
            >
              <div
                className={`${styles.NotificationListItem} ${
                  isNewMessage(message, messageMark) ? styles.newMessage : ''
                }`}
                key={index}
              >
                {getEventMessage(message, index)}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Notifications;
