import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isUserHasKey } from './GlobalFunctions';
import { FlipbookReadAloud } from './core/flipbook/FlipbookReadAloud';
import Flipbook from './core/flipbook/Flipbook';
import { FlipbookFramework } from './core/flipbook/FlipbookFramework';
import FlipbookHeader from './core/flipbook/FlipbookHeader';
import { postServiceRequest } from './serviceAgent';
import { getAppRoot } from './identity';
import { StorybookChevron } from './StorybookChevron';
import { getUsersId } from './GlobalFunctions';
import { getAuthorizationToken, updateUserProfile } from './BackendInterface';
import styles from './SCSS/Flipbook.module.scss';
import { RootState } from './ApplicationState';
import { NumberLike } from 'hashids/cjs/util';

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  params: Record<string, string>;
};
interface State {
  time: string;
  pagesDisplayed: number;
  pause: boolean;
  newPage: boolean;
  autoPlay: boolean;
  manualButtonColour: string;
  autoButtonColour: string;
}
class StorybookLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      time: '0',
      pagesDisplayed: 0,
      pause: true,
      newPage: true,
      autoPlay: true,
      manualButtonColour: '#85C1E9',
      autoButtonColour: '#EAEDED',
    };
    this.setPauseState = this.setPauseState.bind(this);
    this.setNewPage = this.setNewPage.bind(this);
    this.setPagesDisplayed = this.setPagesDisplayed.bind(this);
    this.handleAutoToggle = this.handleAutoToggle.bind(this);
    this.changeAutotPlayStateTemporarilyTemporarily =
      this.changeAutotPlayStateTemporarilyTemporarily.bind(this);
    this.setAutoPlayState = this.setAutoPlayState.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (sessionStorage.isShowAutoPlay !== null) {
      this.setState({
        autoPlay: sessionStorage.getItem('isShowAutoPlay') === 'true',
      });
    }
  }

  componentDidMount() {
    document.body.classList.toggle(styles.disableScrolling, true);
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.disableScrolling);
  }

  setPauseState(newPause: boolean) {
    // console.debug( `setting pause state to ${ newPause }` );
    this.setState({ pause: newPause });
  }

  setNewPage(ifNewPage: boolean) {
    this.setState({ newPage: ifNewPage });
  }

  setPagesDisplayed(numPages: number) {
    this.setState({ pagesDisplayed: numPages });
  }

  async setAutoPlayState(itemName: string) {
    this.changeAutotPlayStateTemporarilyTemporarily(itemName);
    this.handleAutoToggle();

    const newFlags: Record<string, boolean> = {
      ...this.props.userProfile.flags,
    };

    newFlags[`isShow${itemName}`] = !this.state.autoPlay;

    await updateUserProfile({ newUserData: { flags: newFlags } });
  }

  changeAutotPlayStateTemporarilyTemporarily(itemName: string) {
    sessionStorage.setItem(`isShow${itemName}`, !this.state.autoPlay);
  }

  handleAutoToggle() {
    this.setState({ autoPlay: !this.state.autoPlay });
  }

  render() {
    const storyId = this.props.params.storyId;
    const language = this.props.params.language;
    const withAudio = isUserHasKey('KEY_STORYBOOK_AUDIO');

    return (
      <div className='flipbook'>
        <FlipbookFramework
          params={this.props.params}
          storyId={storyId}
          pagesDisplayed={this.state.pagesDisplayed}
          autoPlay={this.state.autoPlay}
          language={language}
          setPauseState={this.setPauseState}
          pauseState={this.state.pause}
        >
          <FlipbookHeader />
          <StorybookChevron direction='left' />
          <Flipbook
            setPauseState={this.setPauseState}
            pauseState={this.state.pause}
            setPagesDisplayed={this.setPagesDisplayed}
            autoPlay={this.state.autoPlay}
            withAudio={withAudio}
          />
          <StorybookChevron direction='right' />
          <FlipbookReadAloud
            isShow={withAudio}
            storyId={storyId}
            pauseState={this.state.pause}
            pauseAudio={this.setPauseState}
            pagesDisplayed={this.state.pagesDisplayed}
            autoPlay={this.state.autoPlay}
            toggleAutoPlay={this.setAutoPlayState}
          />
        </FlipbookFramework>
      </div>
    );
  }
}

export default connector(StorybookLayout);
