import { Component } from 'react';
import { Segment } from './core/core';
import {
  getLocalStorage,
  hasPathBeforeSessionExpired,
  restorePathBeforeSessionExpired,
} from './aaa';
import { logger } from './error-tracker';
import { OpenLockIcon } from './OpenLockIcon';
import NonexistentAccountDialog from './NonexistentAccountDialog';
import StatusUpdate from './components/StatusUpdate';
import ErrorDisplay from './components/ErrorDisplay';
import {
  getCleverToken,
  reactivateUserSession,
  signInUser,
  updateCleverCredentials,
  waitUntilUserLoggedInStateKnown,
} from './UserManagementHelpers';
import {
  getCleverId,
  getCleverOAuthResumePath,
  getCleverProfile,
  initiateCleverLogin,
  isCleverLoginStateValid,
  sendUserToRoleHomePage,
} from './GlobalFunctions';
import styles from './SCSS/RegistrationConfirmation.module.scss';
import utilityStyles from './SCSS/common/utilityStyles.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from './CustomHooks';
type Props = {
  setProperty: (arg0: string, arg1: string) => void;
  location: {
    pathname: string;
    state: {
      nextPathname: string;
    };
  };
  query: URLSearchParams;
  history: History;
};
type State = {
  status: string;
  autoLoginStatus: string;
  isShowNonexistentAccountDialog: boolean;
};

class AutoLogin extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'pending',
      autoLoginStatus: '',
      isShowNonexistentAccountDialog: false,
    };
  }

  signInUser = async (username: string, password: string) => {
    this.setState(
      {
        status: 'pending',
        autoLoginStatus: 'Logging in...',
      },
      submitCredentials
    );

    async function submitCredentials() {
      try {
        const u = await signInUser(username, password);
        const { location } = this.props;

        if (location.state && location.state.nextPathname) {
          this.props.history.replace(location.state.nextPathname);
        } else if (hasPathBeforeSessionExpired()) {
          restorePathBeforeSessionExpired();
        } else {
          sendUserToRoleHomePage(u, this.props.history, true);
        }
      } catch (error) {
        const message = extractErrorMessage(error);
        this.logAndSetErrorMessage(username, message);
      }
    }

    function extractErrorMessage(error) {
      if (error.message.includes('UserMigration failed')) {
        return 'Incorrect username or password.';
      } else if (error.message.includes('Failed to fetch')) {
        return 'There was a problem that occurred on the server preventing login';
      } else {
        return error.message || error;
      }
    }
  };
  logAndSetErrorMessage = (username: string, message: string) => {
    const errorObject = new Error(
      `CleverLogin failed for ${username}: ${message}`
    );
    logger.logException(errorObject);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    that.setState(
      {
        status: 'error',
        autoLoginStatus: message,
      },
      () => console.log(message)
    );
  };
  handleOpenNonexistentAccountDialog = () =>
    this.setState({
      isShowNonexistentAccountDialog: true,
    });
  handleCloseNonexistentAccountDialog = async () => {
    this.setState({
      status: 'done',
      isShowNonexistentAccountDialog: false,
    });
    this.props.history.replace('/login');
  };
  updateStatus = (newStatus: string) => {
    this.setState({
      status: 'pending',
      autoLoginStatus: newStatus,
    });
  };

  async componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const code = this.props.query.get('code');
    const scope = this.props.query.get('scope');
    const state = this.props.query.get('state');

    if (hasValidationProblem(code, scope, state)) {
      return;
    }

    try {
      reactivateUserSession();
      const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      this.updateStatus('Getting information from Clever...');
      const cleverAccessToken = (await getCleverToken(code, redirectUri))
        .access_token;
      // console.debug( `access_token = ${ cleverAccessToken }` );
      const cleverId = (await getCleverId(cleverAccessToken)).data.id;
      // console.debug( `cleverId = ${ JSON.stringify( cleverId, null, 2 ) }` );
      this.updateStatus(`Retrieving your Clever user profile...`);
      const cleverProfile = await getCleverProfile(cleverId, cleverAccessToken);
      // console.debug( `cleverProfile = ${ JSON.stringify( cleverProfile, null, 2 ) }` );
      await waitUntilUserLoggedInStateKnown();
      processSuccessfulCleverLogin(cleverId, cleverAccessToken, cleverProfile);
    } catch (error) {
      const errorMessage = error.error || error.message || error;
      logger.logException(new Error(`Clever Login failed: ${errorMessage}`));
      this.setState({
        status: 'error',
        autoLoginStatus: `There is a problem and the attempt to login via Clever has failed: ${errorMessage}`,
      });
    }

    function processSuccessfulCleverLogin(
      cleverId,
      cleverAccessToken,
      cleverProfile
    ) {
      // console.debug( `%cProcessing retrieved Clever credentials`, 'color: yellow; background: blue;' )
      updateCleverCredentials(cleverId, cleverAccessToken, cleverProfile);
      const resumePath = getLocalStorage('CleverOAuthResumePath');
      if (resumePath) that.props.history.replace(resumePath);
      else throw new Error(`Resume Path from Clever OAuth login not found`);
    }

    function hasValidationProblem(code, scope, state) {
      if (!code || !scope) {
        logger.logMessage(
          `Secondary Sign In with Clever has failed because code or scope is undefined`
        );
        that.setState({
          status: 'error',
          autoLoginStatus: `There is a problem and the attempt to login via Clever has failed`,
        });
        return true;
      }

      if (!state) {
        initiateCleverLogin(that.props.location.pathname);
        return true;
      }

      if (!isCleverLoginStateValid(state)) {
        logger.logMessage(
          `Secondary Sign In with Clever has failed because state is invalid`
        );
        that.setState({
          status: 'error',
          autoLoginStatus:
            'There is a problem and the attempt to login via Clever has failed',
        });
        return true;
      }

      return false;
    }
  }

  gotoLogin = () => {
    const resumePath = getCleverOAuthResumePath();

    if (resumePath) {
      this.props.history.replace(resumePath);
      return;
    }

    this.props.history.replace('/login');
  };
  gotoPeekaville = () =>
    (window.location.href = 'https://www.mypeekaville.com');
  renderStatusSwitch = () => {
    switch (this.state.status) {
      case 'pending': {
        return (
          <StatusUpdate
            pictureUrl='https://s3.amazonaws.com/peekaplatform/media30/images/apollo-with-whiteboard.png'
            contentTitle='Clever Login'
            contentSubtitle={this.state.autoLoginStatus}
            isShowSpinner={true}
          />
        );
      }

      case 'notTeacher': {
        return (
          <ErrorDisplay
            contentTitle='Oops!'
            contentSubtitle={`Sorry, but this app is for teachers only. Maybe you're looking for myPeekaville?`}
            buttonText='Launch myPeekaville'
            onButtonClick={this.gotoPeekaville}
          />
        );
      }

      case 'error': {
        return (
          <ErrorDisplay
            contentTitle='Oops!'
            contentSubtitle={this.state.autoLoginStatus}
            buttonText='Okay'
            onButtonClick={this.gotoLogin}
          />
        );
      }

      default:
      case 'done': {
        return null;
      }
    }
  };

  render() {
    return (
      <div>
        <div className={styles['spacer-40']} />
        <Segment className={styles.loginSegment}>
          <div className={utilityStyles.absCentre}>
            <OpenLockIcon />
          </div>
          <div className={styles['spacer-20']} />
          {this.renderStatusSwitch()}
        </Segment>
        <div className={styles['spacer-40']} />
        <NonexistentAccountDialog
          isShow={this.state.isShowNonexistentAccountDialog}
          onHide={this.handleCloseNonexistentAccountDialog}
          providerName='Clever'
        />
      </div>
    );
  }
}

function ClassFunctionWrapper(props) {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  return (
    <AutoLogin {...props} history={history} location={location} query={query} />
  );
}

export default ClassFunctionWrapper;
