import { useEffect, useState } from 'react';
import {
  setNestedObjectValues,
  ErrorMessage,
  Form,
  Formik,
  FormikHelpers,
  Field,
} from 'formik';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Button from '../Button';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { FormikOnChangeAutosave } from '../FormikAutosave';
import { saveAssignment, submitAssignment } from '../../BackendInterface';
import { Icon, InlineIcon } from '@iconify/react';

import eyeOutline from '@iconify-icons/ion/eye-outline';
import checkmarkCircleOutline from '@iconify/icons-eva/checkmark-circle-outline';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import styles from './ToDoMultipleChoice.module.scss';
import quillStyles from '../../SCSS/customBubble.scss';
import { MultipleChoice } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  data: {
    assessmentMatrix?: MultipleChoice['assessmentMatrix'];
    content: {
      choices?: {
        score: number;
        text: string;
      }[];
      content?: string;
      data?: {
        choices: {
          score: number;
          text: string;
        }[];
        prompt?: string;
      };
      correctIndicies?: number[];
      index: number;
      prompt?: string;
      type: string;
      title?: string;
    }[];
    grade: number[];
    index: number;
    title: string;
    type: string;
  };
  studentWork: {
    classroomId: string;
    createdAt: number;
    descriptor: string;
    lesson: string;
    modifiedAt: number;
    module: string;
    status: string;
    todo: string;
    unit: string;
    userId: string;
    work?: {
      content: {
        content: (
          | { answerIndex: number; index: number }
          | { answerText: string; index: number }
        )[];
        index: number;
        type: string;
      }[];
    };
  };
  textAnswer: {
    grade: number[];
    index: number;
    question: string;
    type: string;
  }[];
  updateAssignment: (assignment: any) => void;
}

interface ManualField {
  field: { name: string; value: string };
}

interface FormValues {
  [key: string]: any;
  index: number;
  value: string;
}
interface Params {
  activity: string;
  toDo: string;
  grade: string;
  language: string;
}

const ToDoMultipleChoice = ({
  data,
  studentWork,
  updateAssignment,
  textAnswer,
}: Props): JSX.Element => {
  const [isShowSpinner, setShowSpinner] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [assessmentText, setAssessmentText] = useState<{
    studentScore: number;
    scoreIndex: number | undefined;
  } | null>(null);

  const { activity, toDo, grade, language } = useParams<Params>();
  const history = useHistory();
  const isTeacher = isNaN(parseInt(toDo));
  const isQuiz = data.content.some((item) => item.type === 'quizChoices');
  const isAssessment = data.assessmentMatrix !== undefined;
  const isRelfectionQuestionAtBeginning =
    textAnswer && textAnswer.find((item) => item.index === 300) !== undefined;

  const existingWork =
    studentWork?.work?.content !== undefined
      ? studentWork.work.content[0]?.content?.filter((item) =>
          Number.isFinite(item.index)
        )
      : null;

  const studentChoices: number[] | null = !existingWork
    ? null
    : existingWork
        .map((object) => object.answerIndex)
        .filter((item) => item !== undefined);

  const initialReflectionValue =
    studentWork?.work?.content !== undefined
      ? studentWork.work.content[0]?.content?.filter(
          (item) => item.index === 300
        )[0]?.answerText
      : '';

  const initialMCValues: FormValues = data.content
    ?.filter((item) => item.type === 'choices' || item.type === 'quizChoices')
    .reduce(
      (acc, curr, currentIndex) => ({
        ...acc,
        [curr.index]:
          existingWork === null ||
          studentChoices === null ||
          studentChoices[currentIndex] === null ||
          studentChoices[currentIndex] === undefined
            ? ''
            : studentChoices[currentIndex].toString(),
      }),
      {} as FormValues
    );

  const initialTextAnswerFromMC: FormValues = data.content
    ?.filter(
      (item) => item.type === 'choices' && item.data?.input !== undefined
    )
    .reduce((acc, curr) => {
      const storedAnswer = existingWork?.find((item) => {
        return item.index === curr.data.input.index;
      });
      const hasNoStoredAnswer =
        existingWork === null ||
        studentChoices === null ||
        storedAnswer === null ||
        storedAnswer === undefined;
      return {
        ...acc,
        [curr.data.input.index]: hasNoStoredAnswer
          ? ''
          : storedAnswer.answerText,
      };
    }, {} as FormValues);

  const initialAnswerValues = isRelfectionQuestionAtBeginning
    ? {
        300: initialReflectionValue,
        ...initialMCValues,
        ...initialTextAnswerFromMC,
      }
    : { ...initialMCValues, ...initialTextAnswerFromMC };

  const initialThoughtsValue =
    studentWork?.work?.content !== undefined
      ? studentWork.work.content[0]?.content?.filter(
          (item) => item.index === 200
        )[0]?.answerText
      : '';

  const initialFormValues = isQuiz
    ? initialAnswerValues
    : { ...initialAnswerValues, 200: initialThoughtsValue };

  const hasTextBlock = data?.content?.some((item) => item.type === 'textBlock');

  const refectionSchema = isRelfectionQuestionAtBeginning && {
    '300': Yup.string().required(
      'Please enter your answer for the reflection question'
    ),
  };

  const multipleChoiceSchema = data.content
    ?.filter((item) => item.type === 'choices' || item.type === 'quizChoices')
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.index]: Yup.string().required(
          'Please pick an answer for all questions'
        ),
      }),
      {}
    );

  const answerSchema = isRelfectionQuestionAtBeginning
    ? {
        ...refectionSchema,
        ...multipleChoiceSchema,
      }
    : multipleChoiceSchema;

  const schemaValues =
    isAssessment && !isQuiz
      ? {
          ...answerSchema,
          '200': Yup.string().required(
            'Please enter your thoughts on your score before submitting'
          ),
        }
      : answerSchema;

  const validationSchema = Yup.object().shape(answerSchema);
  const extendedSchema = Yup.object().shape(schemaValues);

  const calculateScore = (
    answerArray: (
      | { index: number; answerIndex: number }
      | { index: number; answerText: string }
    )[]
  ) => {
    return data.content
      .filter((item) => item.type === 'choices' || item.type === 'quizChoices')
      .reduce((acc, curr) => {
        const thisIndex = answerArray.find((item) => item.index === curr.index);
        const thisScore =
          !thisIndex ||
          !('answerIndex' in thisIndex) ||
          isNaN(thisIndex.answerIndex)
            ? 0
            : curr.data
            ? curr.data.choices[thisIndex.answerIndex].score
            : curr.choices[thisIndex.answerIndex].score;
        return acc + thisScore;
      }, 0);
  };

  const handleDisplayAssessment = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const validationErrors = await actions.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      actions.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
    setEnableSubmit(false);
    try {
      const valueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          {
            index: parseInt(k),
            answerIndex: parseInt(v),
          },
        ],
        [] as { index: number; answerIndex: number }[]
      );
      const studentScore = calculateScore(valueArray);
      const assessmentMatrix = data.assessmentMatrix;
      const scoreIndex = assessmentMatrix?.reduce((acc, curr, currentIndex) => {
        if (studentScore >= curr.range[0] && studentScore <= curr.range[1])
          acc = currentIndex;
        return acc;
      }, 0);
      setAssessmentText({ studentScore, scoreIndex });
    } finally {
      setEnableSubmit(true);
    }
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setShowSpinner(true);

    try {
      const mcValueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          isNaN(parseInt(v))
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ],
        [] as (
          | { index: number; answerIndex: number }
          | { index: number; answerText: string }
        )[]
      );
      const multipleChoiceWork = {
        type: 'multipleChoiceAnswerGroup',
        index: data.index,
        content: mcValueArray,
      };

      const studentScore = calculateScore(mcValueArray);

      const scoreIndex = !isQuiz
        ? data.assessmentMatrix?.reduce((acc, curr, currentIndex) => {
            if (studentScore >= curr.range[0] && studentScore <= curr.range[1])
              acc = currentIndex;
            return acc;
          })
        : null;

      const metawork = isQuiz
        ? { studentScore: studentScore, totalScore: mcValueArray.length }
        : { studentScore: studentScore, scoreIndex: scoreIndex };

      const submitResponse = await submitAssignment(
        studentWork.userId,
        parseInt(toDo),
        [multipleChoiceWork],
        metawork
      );
      console.info(`result = ${JSON.stringify(submitResponse, null, 2)}`);
      console.log('submitted via submit');
      actions.setSubmitting(false);
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      setShowError(true);
    } finally {
      setShowSpinner(false);
      history.push(
        `/studentportal/activity/${activity}/${language}/${grade}/toDo/${toDo}/confirmation`
      );
    }
  };

  const handleSave = async (values: FormValues) => {
    try {
      const valueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          isNaN(parseInt(v))
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ],
        [] as (
          | { index: number; answerIndex: number }
          | { index: number; answerText: string }
        )[]
      );
      const multipleChoiceWork = {
        type: 'multipleChoiceAnswerGroup',
        index: data.index,
        content: valueArray,
      };

      await saveAssignment(studentWork.userId, parseInt(toDo), [
        multipleChoiceWork,
      ]);
      updateAssignment({
        userId: studentWork.userId,
        createdAt: parseInt(toDo),
        work: { content: [multipleChoiceWork] },
      });
      // console.info(`result = ${JSON.stringify(saveResponse, null, 2)}`);
      console.log('multiple choice submitted via save');
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      setShowError(true);
    }
  };

  useEffect(() => {
    !isAssessment && setEnableSubmit(true);
  }, [initialFormValues, isAssessment]);

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      <Formik
        key='multipleChoice'
        initialValues={initialFormValues}
        validationSchema={enableSubmit ? extendedSchema : validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          isValid,
          values,
          setFieldValue,
          setTouched,
          validateForm,
        }) => {
          return (
            <Form key='multipleChoice form'>
              {isRelfectionQuestionAtBeginning && (
                <div className={styles.reflections}>
                  <h3>Reflection</h3>
                  <div
                    className={styles.reflectionQuestion}
                    dangerouslySetInnerHTML={{
                      __html: textAnswer[0].question,
                    }}
                  />
                  <Field
                    className={styles.singleInput}
                    name='300'
                    type='text'
                  />
                  {errors['300'] && touched['300'] && (
                    <div className={styles.textErrors}>
                      <Icon
                        icon={alertCircleFill}
                        color='#ff6319'
                        className={styles.icon}
                        width={18}
                      />
                      <ErrorMessage name='300' />
                    </div>
                  )}
                </div>
              )}
              <h3>{data.title}</h3>
              {data.content.map((question, ind) => {
                const questionIndex = question.index.toString();
                return question.type === 'textBlock' ? (
                  <div
                    key={`textBlock ${ind}`}
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                      __html: question.content || '',
                    }}
                  />
                ) : (
                  <div key={`question ${hasTextBlock ? ind : ind + 1}`}>
                    <div className={styles.question}>
                      {hasTextBlock ? ind : ind + 1}.&nbsp;
                      {question.prompt
                        ? question.prompt
                        : question.data?.prompt}
                    </div>
                    <div
                      id={question.index.toString()}
                      role='group'
                      aria-labelledby={question.index.toString()}
                      className={styles.answers}
                    >
                      {question.data?.choices &&
                      question.data?.choices?.length > 0
                        ? question.data.choices?.map(
                            (choice: string, aInd: number) => {
                              return (
                                <label
                                  key={`question#: ${ind + 1} answer: ${
                                    aInd + 1
                                  }`}
                                >
                                  <Field
                                    type='radio'
                                    name={question.index}
                                    value={aInd.toString()}
                                  />
                                  &nbsp;&nbsp;{choice.text}
                                </label>
                              );
                            }
                          )
                        : question.choices?.map((choice: string, i: number) => {
                            return (
                              <label
                                key={`questionNo: ${ind + 1} answer: ${i + 1}`}
                              >
                                <Field
                                  type='radio'
                                  name={question.index}
                                  value={i.toString()}
                                />
                                &nbsp;&nbsp;{choice.text}
                              </label>
                            );
                          })}
                      {question.data?.input ? (
                        <div className={quillStyles}>
                          <Field name={question.data.input.index}>
                            {({ field }: ManualField) => {
                              const { name, value } = field;
                              return (
                                <>
                                  <ReactQuill
                                    placeholder={
                                      question.data.input.placeholder
                                    }
                                    theme='bubble'
                                    value={value || ''}
                                    onChange={(value) => {
                                      setFieldValue(name, value, false);
                                    }}
                                    modules={{
                                      keyboard: { bindings: { tab: false } },
                                    }}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {errors[questionIndex] && touched[questionIndex] && (
                      <div className={styles.errors}>
                        <Icon
                          icon={alertCircleFill}
                          color='#ff6319'
                          className={styles.icon}
                          width={18}
                        />
                        {errors[questionIndex]}
                      </div>
                    )}
                  </div>
                );
              })}
              <FormikOnChangeAutosave saveHandler={handleSave} />
              {!isQuiz && isAssessment && (
                <Button
                  type='button'
                  thinText
                  whiteBorder
                  disabled={isTeacher}
                  onClick={() =>
                    handleDisplayAssessment(values, {
                      validateForm,
                      setTouched,
                    } as FormikHelpers<FormValues>)
                  }
                >
                  <InlineIcon
                    icon={eyeOutline}
                    width={18}
                    style={{
                      marginRight: '0.5em',
                      transform: 'translateY(-2px)',
                    }}
                  />
                  Show Results
                </Button>
              )}
              <div>
                {enableSubmit && (
                  <>
                    {!isQuiz && isAssessment && (
                      <div className={styles.scoring}>
                        <div className={styles.studentScore}>
                          Your assessment results:
                        </div>
                        {data.assessmentMatrix?.map((score, index) => {
                          return (
                            <div
                              className={styles.answer}
                              style={{
                                backgroundColor:
                                  index === assessmentText?.scoreIndex
                                    ? '#e7f4f7'
                                    : '',
                              }}
                              key={`assessment score ${index}`}
                            >
                              {score.assessment}
                              {index === assessmentText?.scoreIndex && (
                                <Icon
                                  className={styles.icon}
                                  icon={checkmarkCircleOutline}
                                  color='#00afd8'
                                  width={18}
                                />
                              )}
                            </div>
                          );
                        })}
                        <div className={styles.textInput}>
                          Thoughts about your score:
                        </div>
                        <Field
                          className={styles.singleInput}
                          name='200'
                          type='text'
                        />
                        {errors['200'] && touched['200'] && (
                          <div className={styles.textErrors}>
                            <Icon
                              icon={alertCircleFill}
                              color='#ff6319'
                              className={styles.icon}
                              width={18}
                            />
                            <ErrorMessage name='200' />
                          </div>
                        )}
                      </div>
                    )}
                    <Button
                      type='submit'
                      darkBlue
                      thinText
                      disabled={isTeacher}
                    >
                      Submit Your Responses&nbsp;&nbsp;&rarr;
                    </Button>
                  </>
                )}
              </div>
              {!isValid &&
                Object.keys(touched).length ===
                  Object.keys(initialFormValues).length && (
                  <div className={styles.globalError}>
                    Unable to submit, please check your answers and try again
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ToDoMultipleChoice;
