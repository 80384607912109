import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  CoreStandardsExpandable,
  Expandable,
  ExpandableGroup,
  LearningOutcomesExpandable,
  Resources,
} from './lessonPlanComponents';
import StepHeaderButton from './StepHeaderButton';
import { getPossessiveForm } from '../GlobalFunctions';
import { printLesson } from './UtilityFunctions';
import { RootState } from '../ApplicationState';
import { ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';
import styles from '../SCSS/lpMain.module.scss';

interface Props {
  currentActivity: ElementaryUnit['value']['lessonPlan']['subUnits'][0]['lessons'][0]['activities'][0];
  isHomeActivity: boolean;
  isPreviewMode: boolean;
  onMarkStepComplete: (unitPosition: UnitPosition) => void;
  onResetStep: (unitPosition: UnitPosition) => void;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  unitPosition: UnitPosition;
}

const UnitMyPeekavilleActivityContent = ({
  currentActivity,
  isHomeActivity,
  isPreviewMode,
  onMarkStepComplete,
  onResetStep,
  unitMeta,
  unitPosition,
}: Props) => {
  const userProfile = useSelector((state: RootState) => {
    return state.user.userProfile;
  });

  const handlePrint = () => {
    const teacher = `${userProfile?.salutation ?? ''} ${getPossessiveForm(
      userProfile?.lastName ?? ''
    )}`;
    const heading = `${teacher} update on ${getStepTitle()} for ${
      currentActivity.contentLabel
    }`;
    printLesson(heading);
  };

  const getStepTitle = () => {
    return currentActivity.title;
  };

  return (
    <div className='greyBackgroundLessonViewer'>
      <div className={styles.expandableHolder}>
        <Expandable
          label='Overview'
          text={currentActivity.description.text}
          openByDefault
        />
        <Resources data={currentActivity.files} contentType='step' />
        <ExpandableGroup
          data={currentActivity.prologue}
          openByDefault={false}
        />
        <ExpandableGroup
          data={
            unitMeta.subUnits[unitPosition.subunit].lessons[unitPosition.lesson]
              .prologue
          }
          openByDefault={false}
        />
        <LearningOutcomesExpandable
          data={
            unitMeta.subUnits[unitPosition.subunit].lessons[unitPosition.lesson]
              .learningOutcomes
          }
          contentType='step'
        />
        <CoreStandardsExpandable
          label='ELA Standards'
          data={currentActivity.coreStandards}
        />
        <CoreStandardsExpandable
          label='Additional Standards'
          data={currentActivity.additionalStandards}
        />
      </div>
      <div className={styles.stepContentMenu}>
        <span className={styles.stepContentHeader}>
          {currentActivity.contentLabel}
        </span>
        <Button className={styles.stepPrintButton} onClick={handlePrint}>
          <i className='fa fa-print' aria-hidden='true' />
        </Button>
        <hr
          className={styles.underHeadingSeparator}
          style={{ marginLeft: '-10px', marginRight: '-10px' }}
        />
        <div
          className={styles.stepContentContent}
          id='stepContentContent'
          dangerouslySetInnerHTML={{
            __html: currentActivity.content.text,
          }}
        />
        <StepHeaderButton
          unitPosition={unitPosition}
          isPreviewMode={isPreviewMode}
          onMarkStepComplete={onMarkStepComplete}
          onResetStep={onResetStep}
          shareStep={isHomeActivity}
        />
      </div>
    </div>
  );
};

export default UnitMyPeekavilleActivityContent;
