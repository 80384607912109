import { useSelector } from 'react-redux';
import Header from './header/Header';
import MiddleSchoolHeader from './MiddleSchoolHeader';
import { selectClassroom } from '../Classrooms';
import { RootState } from '../ApplicationState';
import { LoginState } from '../UserManagement';
import { History, Location } from 'history';

interface Props {
  loginState: LoginState;
  isAuthenticated: boolean;
  isUnauthenticated?: boolean;
  onShowGetStartedDialog: () => void;
  history: History;
  location: Location;
  params: {
    classroomName?: string;
  };
  isHidePeekaville?: boolean;
}

const HeaderRedirect = (props: Props) => {
  const newProps = { ...props };
  const classroom = useSelector((state: RootState) => {
    const selectedClassroom = selectClassroom(state);
    return selectedClassroom;
  });
  return (
    <>
      {classroom === undefined && !props.isUnauthenticated ? null : parseInt(
          classroom?.grade
        ) > 5 ? (
        <MiddleSchoolHeader {...newProps} />
      ) : (
        <Header {...newProps} />
      )}
    </>
  );
};

export default HeaderRedirect;
