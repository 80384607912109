import { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import Spinner from 'react-spinkit';
import { updateAllFields, UpdateUserProfile } from './UserManagement';
import { activateTrial } from './BackendInterface';
import {
  canUpgrade,
  getDescriptiveDateFromEpoch,
  isUsersClassroomTrialAlreadyUsed,
} from './GlobalFunctions';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import RedeemOrPurchaseLicenseCode from './components/RedeemOrPurchaseLicenseCode/RedeemOrPurchaseLicenseCode';
import styles from './SCSS/RedeemCode.module.scss';
import libraryStyles from './SCSS/Library.module.scss';
import Images from './Images';
import { History } from 'history';
import { AppDispatch, RootState } from './ApplicationState';
import { UserProfileType } from '../peekapak-types/DataProtocolTypes';
import { selectClassroom } from './Classrooms';

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  selectedclassroom: selectClassroom(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateAllFields: (newFieldsObject: UpdateUserProfile) =>
    dispatch(updateAllFields(newFieldsObject)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history: History;
  updateAllFields: (arg0: UpdateUserProfile) => void;
  userProfile: UserProfileType;
};

interface State {
  activationState: 'pending' | 'finished' | 'error' | 'notAvailable';
  errorMessage: string;
  toApplyLicenseCharacteristics?: {
    licenseLevel: string;
    licenseExpires: number;
  };
}

const ActivateTrial = (props: Props) => {
  const [myState, setMyState] = useState<State>({
    activationState: 'pending',
    errorMessage: '',
    toApplyLicenseCharacteristics: undefined,
  });
  const { licenseLevel, licenseExpires } = props.userProfile;
  const { grade } = props.selectedclassroom;
  const licenseExpiresUnixTime = licenseExpires || 0;
  const dateOutput = getDescriptiveDateFromEpoch(licenseExpiresUnixTime);

  useEffect(() => {
    if (canUpgrade(licenseLevel) && !isUsersClassroomTrialAlreadyUsed()) {
      activateTrialForUser();
    } else {
      setMyState((prevState) => ({
        ...prevState,
        activationState: 'notAvailable',
      }));
    }

    async function activateTrialForUser() {
      setMyState((prevState) => ({
        ...prevState,
        activationState: 'pending',
      }));

      try {
        const toApplyLicenseCharacteristics = await activateTrial();
        setMyState((prevState) => ({
          ...prevState,
          activationState: 'finished',
          toApplyLicenseCharacteristics,
        }));
      } catch (error) {
        setMyState((prevState) => ({
          ...prevState,
          activationState: 'error',
          errorMessage: (error as Error).message,
        }));
      }
    }
  }, [props, licenseLevel]);

  if (myState.activationState === 'finished') {
    return (
      <div className={styles.redeemCode}>
        <div className={cx(styles.title, styles.center)}>
          Your Peekapak PRO trial has been activated!
        </div>
        <div className='standaloneImageWrapper'>
          <div>
            {grade < 6 ? (
              <img alt='Success!' src={Images.successApolloAndSebastian} />
            ) : (
              <img
                className={styles.activateTrialImage}
                alt='Success!'
                src={Images.activateTrailSecondary}
              />
            )}
          </div>
        </div>
        <PrimaryButton
          onClick={() => {
            props.updateAllFields(myState.toApplyLicenseCharacteristics || {});
            props.history.push('/library');
          }}
          style={{
            padding: '0.5em 2em',
          }}
        >
          Let&rsquo;s go!
        </PrimaryButton>
      </div>
    );
  } else if (myState.activationState === 'notAvailable') {
    if (!canUpgrade(props.userProfile.licenseLevel)) {
      return (
        <div className={styles.redeemCode}>
          <div className={cx(styles.title, styles.center)}>
            You already have the highest access level!
          </div>
          <div className='standaloneImageWrapper'>
            {grade < 6 ? (
              <img alt='Success!' src={Images.successApolloAndSebastian} />
            ) : (
              <img
                className={styles.activateTrialImage}
                alt='Success!'
                src={Images.activateTrailSecondary}
              />
            )}
          </div>
          <PrimaryButton
            onClick={() => props.history.push('/library')}
            style={{
              padding: '0.5em 2em',
            }}
          >
            Okay
          </PrimaryButton>
        </div>
      );
    } else if (
      props.userProfile.licenseLevel.toLocaleLowerCase().includes('expired') &&
      isUsersClassroomTrialAlreadyUsed()
    ) {
      return (
        <div className={styles.licenseExpiredUIContainer}>
          <h3 className={styles.licenseExpiredMessage}>
            Your PRO license expired on <strong>{dateOutput}</strong>.
          </h3>
          <RedeemOrPurchaseLicenseCode
            className={styles.redeemOrPurchaseLicenseCode}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.redeemCode}>
          <div className={cx(styles.title, styles.center)}>
            Looks like you&rsquo;ve already used your PRO&nbsp;trial.
          </div>
          <div className='standaloneImageWrapper'>
            <div>
              <img alt='Success!' src={Images.embarrassedKenji} />
            </div>
          </div>
          <PrimaryButton onClick={() => props.history.push('/features')}>
            Upgrade to Peekapak PRO
          </PrimaryButton>
          <div className={cx(styles.center, styles.vspaceBefore)}>
            <Link className={libraryStyles.textLink} to='/library'>
              or continue at your current level
            </Link>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.redeemCode}>
      <div className={styles.title}>Activating Your Trial</div>
      <div className={styles.text}>
        We are activating your Peekapak PRO trial.
      </div>
      <Spinner name='line-scale' />
    </div>
  );
};

export default connector(ActivateTrial);
