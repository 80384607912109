import Spinner from 'react-spinkit';
import TextInput from '../TextInput';
import styles from './LicenseCodeInput.module.scss';

interface Props {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isChecking: boolean;
  className?: string;
  disabled?: boolean;
}

const LicenseCodeInput = (props: Props): JSX.Element => {
  const { id, name, onChange, onBlur, value, isChecking, className, disabled } =
    props;

  return (
    <>
      <TextInput
        placeholder='Optional: license code'
        type='text'
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={className}
        disabled={disabled}
      />
      <Spinner
        name='circle'
        className={styles.positionSpinner}
        style={{ visibility: isChecking ? 'visible' : 'hidden' }}
      />
    </>
  );
};

export default LicenseCodeInput;
