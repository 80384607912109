import cx from 'classnames';
import { Modal } from 'react-bootstrap';
import styles from './ChooseRoleDialog.module.scss';
import utilityStyles from '../../SCSS/common/utilityStyles.module.scss';
import LessRoundButton from '../LessRoundButton/LessRoundButton';
import modalStyles from '../../SCSS/VideoPlayer.module.scss';
import leo from '../../images/website_ICON_02.png';

export type SelectedRole = 'Student' | 'Parent' | 'Educator' | 'SchoolLeader';

type Props = {
  isShow: boolean;
  handlerOnSelect: (arg0: SelectedRole) => void;
  onHide: () => void;
};

const ChooseRoleDialog = (props: Props): JSX.Element => {
  const { isShow, onHide } = props;
  return (
    <Modal
      dialogClassName={`${modalStyles['modal-dialog']} ${styles.chooseRoleDialog}`}
      className={modalStyles.modal}
      show={isShow}
      onHide={onHide}
    >
      <Modal.Header
        closeButton
        className={cx(utilityStyles.absCentre, styles.content)}
        id={styles.content}
      >
        <img
          src={leo}
          style={{
            width: '88px',
          }}
          alt='Leo the Hedgehog'
        />
        <div className={styles.title}>I am a...</div>
        <div className={styles.buttons}>
          <LessRoundButton onClick={() => callbackWithSelection('Educator')}>
            Educator
          </LessRoundButton>
          <LessRoundButton onClick={() => callbackWithSelection('Parent')}>
            Parent
          </LessRoundButton>
          <LessRoundButton onClick={() => callbackWithSelection('Student')}>
            Student
          </LessRoundButton>
          <LessRoundButton
            onClick={() => callbackWithSelection('SchoolLeader')}
          >
            School Leader
          </LessRoundButton>
        </div>
      </Modal.Header>
    </Modal>
  );

  function callbackWithSelection(selection: SelectedRole) {
    props.handlerOnSelect(selection);
  }
};

export default ChooseRoleDialog;
