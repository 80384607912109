import { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import {
  areAnyStepsInProgressInLesson,
  areAnyStepsNotStartedInLesson,
} from '../completionStatus';
import { getLetterGradeFromNumberGrade } from '../GlobalFunctions';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import { LockIcon } from './LockIcon';
import { getUnitPositionFromParams, openStep } from './UtilityFunctions';
import styles from '../SCSS/ActivityProgressBar.module.scss';
import whiteCheckmark from '../images/pkp-checkmark-white.svg';

// note the lock decorator as HOC call at bottom
class _ActivityNavigatorCard extends Component {
  constructor(props) {
    super(props);

    this.popoverTitle = this.popoverTitle.bind(this);
  }

  isOnCurrentLesson = () => {
    const up = getUnitPositionFromParams(this.props.params);
    return (
      this.props.subunitLength * up.subunit + up.lesson === this.props.cardIndex
    );
  };

  getOrContentAccessKeys = () => {
    const subunitData = this.props.unitMeta.subUnits[this.props.subunitIndex];
    const lessonMeta = subunitData.lessons[this.props.lessonIndex];

    if (lessonMeta?.orContentAccessKey) {
      return [lessonMeta.orContentAccessKey];
    } else if (lessonMeta?.orContentAccessKeys) {
      return lessonMeta.orContentAccessKeys;
    }

    return [];
  };

  getAndContentAccessKeys = () => {
    const subunitData = this.props.unitMeta.subUnits[this.props.subunitIndex];
    const lessonMeta = subunitData.lessons[this.props.lessonIndex];

    if (lessonMeta?.andContentAccessKey) {
      return [lessonMeta.andContentAccessKey];
    } else if (lessonMeta?.andContentAccessKeys) {
      return lessonMeta.andContentAccessKeys;
    }
    return [];
  };

  popoverTitle() {
    const subunitData = this.props.unitMeta.subUnits[this.props.subunitIndex];
    return (
      <Popover id='popover-trigger-hover-focus'>
        <Popover.Content className={styles.popover}>
          <div
            className={styles.popoverText}
            dangerouslySetInnerHTML={{
              __html:
                subunitData?.lessons[this.props.lessonIndex]?.description.text,
            }}
          />
        </Popover.Content>
      </Popover>
    );
  }

  isLessonComplete(completionStatus, subunitIndex, lessonIndex, cardIndex) {
    const stepIndex = { subunit: subunitIndex, lesson: lessonIndex };
    const isInProgress = areAnyStepsInProgressInLesson(
      completionStatus,
      stepIndex
    );
    const isNotStarted = areAnyStepsNotStartedInLesson(
      completionStatus,
      stepIndex
    );

    if (isInProgress || isNotStarted) {
      return <div className={styles.notStarted}>{cardIndex + 1}</div>;
    } else {
      return (
        <div className={styles.completed}>
          <img alt='Checkmark' src={whiteCheckmark} />
        </div>
      );
    }
  }

  openSelectedCard = () => {
    const { subunitIndex, unitMeta, classroom, lessonIndex } = this.props;
    if (!this.isLocked()) {
      const meta = unitMeta.subUnits[subunitIndex];
      const classroomName = classroom.className;
      const unitId = `${unitMeta.title}-${getLetterGradeFromNumberGrade(
        classroom.grade
      )}`;
      const targetSubunitUrl = meta.url;

      return openStep(
        classroomName,
        unitId,
        targetSubunitUrl,
        subunitIndex,
        lessonIndex,
        0
      );
    } else {
      this.props.onClickLockedContent();
    }
  };

  render() {
    const {
      cardIndex,
      completionStatus,
      subunitIndex,
      lessonIndex,
      progressBarDataArray,
    } = this.props;
    const circleStyle = progressBarDataArray[cardIndex];
    const isOnCurrentLesson = this.isOnCurrentLesson();
    if (isOnCurrentLesson) {
      circleStyle.boxShadow = '0 0 0 2pt #ff6319';
      circleStyle.borderColor = '#ff6319';
    }

    return (
      <>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement='bottom'
          overlay={this.popoverTitle()}
        >
          <div
            className={styles.activityNumberCircle}
            style={circleStyle}
            onClick={this.openSelectedCard}
          >
            {this.isLessonComplete(
              completionStatus,
              subunitIndex,
              lessonIndex,
              cardIndex,
              isOnCurrentLesson
            )}
            {this.isLocked() && (
              <div className={styles.navigatorPieLock}>
                <LockIcon styleOverride={{ left: '1em', top: '-0.5em' }} />
              </div>
            )}
          </div>
        </OverlayTrigger>
      </>
    );
  }
}

export const ActivityNavigatorCard = lockWithPreviewUnlessHasAny(
  'KEY_ALL_LESSON_PLANS',
  'KEY_MASTER_KEY'
)(_ActivityNavigatorCard);
