import { useSpring, animated } from 'react-spring';
import styles from './ToggleSlider.module.scss';

interface Props {
  isChecked: boolean;
  onToggle: () => void;
  onBackgroundColor: string;
  offBackgroundColor: string;
  switchColor: string;
}
const ToggleSlider = ({
  isChecked,
  onToggle,
  onBackgroundColor,
  offBackgroundColor,
  switchColor,
}: Props) => {
  const switchSpringProps = useSpring({
    left: isChecked ? 12 : 1,
    backgroundColor: switchColor,
  });
  return (
    <label className={styles.toggleSwitch}>
      <input type='checkbox' checked={isChecked} onChange={onToggle} />
      <span
        className={styles.switch}
        style={{
          backgroundColor: isChecked ? onBackgroundColor : offBackgroundColor,
        }}
      />
      <animated.span
        style={switchSpringProps}
        className={styles.switchPseudoBefore}
      />
    </label>
  );
};

export default ToggleSlider;
