import { Component } from 'react';
import cx from 'classnames';
import {
  getDescriptiveDateFromEpoch,
  getPossessiveForm,
} from '../../GlobalFunctions';
import styles from '../../SCSS/Header.module.scss';
import bellIcon from '../../images/pkp-bell.svg';
import { useHistory } from 'react-router-dom';
import { MessageType } from '../../../peekapak-types/DataProtocolTypes';

type Props = {
  toggle?: boolean;
  messages: MessageType[];
  messageMark: number;
  history: History;
};

class NotificationBell extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.messageListLimit = 5;

    const now = new Date();
    this.withinXDaysIsRecent = 7;
    this.fromXDaysAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - this.withinXDaysIsRecent
    );

    this.state = {
      toggle: Boolean(this.props.toggle),
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.showAllNotifications = this.showAllNotifications.bind(this);
    this.handleClickNotificationMessage =
      this.handleClickNotificationMessage.bind(this);
  }

  toggleMenu(state) {
    this.setState({ toggle: state !== undefined ? state : !this.state.toggle });
  }

  getEventMessage(message, i, computedClass) {
    return (
      <div
        className={computedClass}
        key={i}
        onClick={this.handleClickNotificationMessage}
      >
        <span className={cx(styles.notification, styles.author)}>
          {message.parentsName
            ? message.parentsName
            : `${getPossessiveForm(message.studentsName)} parent `}
          &nbsp;
        </span>
        <span className={cx(styles.notification, styles.verb)}>
          {message.verb}&nbsp;
        </span>
        <span className={cx(styles.notification, styles.noun)}>
          {message.noun}&nbsp;
        </span>
        <span className={cx(styles.notification, styles.normalText)}>
          {getDescriptiveDateFromEpoch(parseInt(message.date))}
        </span>
      </div>
    );
  }

  isNewMessage(message) {
    return message.date > this.props.messageMark;
  }

  handleClickNotificationMessage() {
    this.props.history.push('/notifications');
  }

  generateMessageList() {
    const rows = [];
    const messageArray = this.props.messages;
    const messagesToShow = messageArray.filter(
      (message) =>
        message.date > this.fromXDaysAgo ||
        message.date > this.props.messageMark
    );

    if (messagesToShow.length) {
      for (
        let i = 0;
        i < this.messageListLimit && i < messagesToShow.length;
        i++
      ) {
        const message = messagesToShow[i];
        const computedClass = cx(
          styles.tooltipBox,
          styles.notification,
          { [styles.noBorder]: i === 0 },
          { [styles.newMessage]: this.isNewMessage(message) }
        );
        rows.push(this.getEventMessage(message, i, computedClass));
      }
    } else {
      rows.push(
        // TODO: had no-border class here
        <div
          key='nomessages'
          className={cx(styles.tooltipBox, styles.notification)}
        >
          No messages from within the last {this.withinXDaysIsRecent} days
        </div>
      );
    }

    if (messageArray.length > this.messageListLimit) {
      rows.push(
        <div
          className={cx(
            styles.tooltipBox,
            styles.notification,
            styles.moreMessagesPrompt
          )}
          key={'overflow'}
          onClick={this.showAllNotifications}
        >
          <div>
            {`+ ${
              messageArray.length -
              Math.min(this.messageListLimit, messagesToShow.length)
            } older messages`}
          </div>
        </div>
      );
    }
    return rows;
  }

  showAllNotifications() {
    this.props.history.push('/notifications');
  }

  renderTooltip() {
    return (
      <div className={cx(styles.headerTooltip, styles.notification)}>
        {this.generateMessageList()}
      </div>
    );
  }

  countNewMessages() {
    if (!this.props.messages) {
      return 0;
    }

    const messagesSinceMessageMark = this.props.messages.filter(
      (message) => message.date > this.props.messageMark
    );
    return messagesSinceMessageMark.length;
  }

  render() {
    if (!this.props.messages) {
      return null;
    }

    if (this.countNewMessages() === 0) {
      return null;
    }

    return (
      <div
        className={cx(styles.headerButton, styles.HeaderNotification)}
        onClick={() => this.toggleMenu()}
        onMouseLeave={() => this.toggleMenu(false)}
      >
        <div className={styles.tooltipWrapper}>
          <div className={styles.badgeWrapper}>
            <img
              className={styles.pkpIcon}
              aria-hidden='true'
              alt='bell'
              src={bellIcon}
              style={{ width: '22px', height: '24px' }}
            />
            <span className={styles.badge} data-count={this.countNewMessages()}>
              {this.countNewMessages()}
            </span>
          </div>
          {this.state.toggle ? this.renderTooltip() : null}
        </div>
      </div>
    );
  }
}

function ClassFunctionWrapper<T>(props: T): JSX.Element {
  const history = useHistory();
  return <NotificationBell {...props} history={history} />;
}
export default ClassFunctionWrapper;
