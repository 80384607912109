import { Component, Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { openActivity, showDurationNumberStandards } from './UtilityFunctions';
import {
  fixedEncodeURIComponent,
  getLetterGradeFromNumberGrade,
} from '../GlobalFunctions';
import { BreakDownButton } from './BreakDownButton';
import styles from '../SCSS/BreakdownOverview.module.scss';
import { History } from 'history';
import {
  ClassroomType,
  ElementaryUnit,
} from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';
import { ClassroomsStateType } from '../Classrooms';

interface Props {
  classroom: ClassroomType;
  classrooms: {
    state: ClassroomsStateType;
    list: ClassroomType[];
    selectedClassroom: number;
  };
  completionStatus: ClassroomType['completionStatus'];
  history: History;
  isPreviewMode?: boolean;
  isReadAloudCard: boolean;
  onClickLockedContent: () => void;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  subunitStub: ElementaryUnit['value']['lessonPlan']['subUnits'][number];
  subunitCardIndex: number;
  unit?: unknown;
  unitId: string;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
}
interface State {
  subunitData:
    | ElementaryUnit['value']['lessonPlan']['subUnits'][number]
    | undefined;
}
export class OverViewActivityCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      subunitData: undefined,
    };

    this.getUnitUrlFor = this.getUnitUrlFor.bind(this);
    this.getButtonType = this.getButtonType.bind(this);
    this.isCompleted = this.isCompleted.bind(this);
    this.getActivityButtonLabel = this.getActivityButtonLabel.bind(this);
    this.rendermyPeekaville = this.rendermyPeekaville.bind(this);
  }

  getAndContentAccessKeys = (lessonIndex: number) => {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.subunitCardIndex].lessons[
          lessonIndex
        ];
      if (lessonMeta.andContentAccessKey) {
        return [lessonMeta.andContentAccessKey];
      } else if (lessonMeta.andContentAccessKeys) {
        return lessonMeta.andContentAccessKeys;
      }
    }

    return [];
  };

  getOrContentAccessKeys = (lessonIndex: number) => {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.subunitCardIndex].lessons[
          lessonIndex
        ];
      if (lessonMeta.orContentAccessKey) {
        return [lessonMeta.orContentAccessKey];
      } else if (lessonMeta.orContentAccessKeys) {
        return lessonMeta.orContentAccessKeys;
      }
    }

    return [];
  };

  UNSAFE_componentWillMount() {
    const subunit = this.props.subunitCardIndex;
    const subUnitData = this.props.unitMeta.subUnits[subunit];
    this.setState({
      subunitData: subUnitData,
    });
  }

  getUnitUrlFor(
    level: string,
    lessonIndex: number,
    stepIndex: number,
    contentType: string
  ) {
    const encodedClassroomName = fixedEncodeURIComponent(
      this.props.classroom.className
    );
    const unitId = `${
      this.props.unitMeta.title
    }-${getLetterGradeFromNumberGrade(this.props.classroom.grade)}`;
    if (!this.state.subunitData) {
      throw new Error('this.state.subunitData is undefined');
    }
    const subunitId = this.state.subunitData.url;

    if (contentType === 'step-teacher') {
      return (
        `/${encodedClassroomName}/lessonPlan/readAloud/${unitId}/${subunitId}/` +
        `Subunit${this.props.subunitCardIndex + 1}/Lesson${
          lessonIndex + 1
        }/Step${stepIndex + 1}`
      );
    }

    if (this.props.isReadAloudCard) {
      return (
        `/${encodedClassroomName}/lessonPlan/readAloud/${unitId}/${subunitId}/` +
        `Subunit${this.props.subunitCardIndex + 1}/Lesson${
          lessonIndex + 1
        }/Step${stepIndex + 1}`
      );
    }

    return (
      `/${encodedClassroomName}/lessonPlan/${unitId}/${subunitId}/` +
      `Subunit${this.props.subunitCardIndex + 1}/Lesson${lessonIndex + 1}/Step${
        stepIndex + 1
      }`
    );
  }

  isCompleted(
    classroom: ClassroomType,
    lessonIndex: number,
    stepIndex: number,
    unitId: string,
    contentType?: string
  ) {
    if (classroom.completionStatus) {
      if (contentType === 'step-share') {
        const lessonCompletionStatus = (
          classroom.completionStatus as Record<string, string[][][]>
        )[unitId][this.props.subunitCardIndex][lessonIndex];
        return lessonCompletionStatus.every((cs) => cs === 'Completed');
      } else {
        const myCompletionStatus = (
          classroom.completionStatus as Record<string, string[][][]>
        )[unitId][this.props.subunitCardIndex][lessonIndex][stepIndex];
        if (myCompletionStatus === 'Completed') {
          return true;
        }
      }
    }

    return false;
  }

  getButtonType(
    contentType: string,
    classroom: ClassroomType,
    lessonIndex: number,
    stepIndex: number,
    unitId: string
  ) {
    if (classroom.completionStatus) {
      const myCompletionStatus = (
        classroom.completionStatus as Record<string, string[][][]>
      )[unitId][this.props.subunitCardIndex][lessonIndex][stepIndex];

      if (myCompletionStatus === 'Completed') {
        return 'pkp-checkmark-in-circle-white.svg';
      }
    }

    if (contentType === 'step-share') {
      return 'pkp-parents-get-involved-white.svg';
    }

    if (contentType === 'step-game') {
      return '';
    }

    return 'pkp-district-white.svg';
  }

  getActivityButtonLabel(contentType: string, index: number) {
    if (contentType === 'step-share') {
      return 'Home';
    } else if (contentType === 'step' && index === 0) {
      return 'Class';
    } else if (contentType === 'step-game') {
      return 'Lesson';
    } else if (contentType === 'step-teacher') {
      return 'View';
    }

    return `Class ${index + 1}`;
  }

  rendermyPeekaville(
    lesson: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number],
    lessonIndex: number,
    unitId: string
  ) {
    const { subunitCardIndex } = this.props;
    return (
      <Card className={styles.breakdownActivityCard} key={lessonIndex}>
        <Card.Body className={styles['peekaville-panel-body']}>
          <div className={styles['breakDownMyPeekavilleContainer']}>
            <div className={styles.breakDownNumberCircle}>
              <p>
                <strong>
                  {this.props.unitMeta.subUnits[0].lessons.length *
                    subunitCardIndex +
                    lessonIndex +
                    1}
                </strong>
              </p>
            </div>
          </div>
          <div className={styles['breakdown-content-container']}>
            <div className={styles.breakdownDuration}>
              {showDurationNumberStandards(lesson)} | {lesson.activityType}
            </div>
            <h1 className={styles.breakdownTitle}>{lesson.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: lesson.description.text }}
            />
          </div>
          <div className={styles['breakdown-button-container']}>
            {lesson.activities.map((step, stepIndex) => {
              return (
                <Fragment key={`${lessonIndex}-${stepIndex}`}>
                  {this.getBreakdownButton(
                    lessonIndex,
                    step,
                    stepIndex,
                    unitId,
                    this.props.onPreviewChange,
                    subunitCardIndex
                  )}
                  <BreakDownButton
                    key={`${lessonIndex}-${stepIndex}-login`}
                    style={
                      this.isCompleted(
                        this.props.classroom,
                        lessonIndex,
                        stepIndex,
                        unitId
                      )
                        ? { backgroundColor: '#7FD7EB', cursor: 'pointer' }
                        : { backgroundColor: '#00afd8', cursor: 'pointer' }
                    }
                    gotoUrl='https://www.mypeekaville.com'
                    breakDownButtonLabel='Login'
                    breakDownButtonType='pkp-parents-get-involved-white.svg'
                    onClickLockedContent={this.props.onClickLockedContent}
                    orContentAccessKeys={this.getOrContentAccessKeys(
                      lessonIndex
                    )}
                    andContentAccessKeys={this.getAndContentAccessKeys(
                      lessonIndex
                    )}
                    isPreviewMode={this.props.isPreviewMode}
                  />
                </Fragment>
              );
            })}
          </div>
        </Card.Body>
      </Card>
    );
  }

  renderActivityCard(
    lesson: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number],
    lessonIndex: number,
    unitId: string
  ) {
    const { subunitCardIndex } = this.props;
    return (
      <Card className={styles.breakdownActivityCard} key={lessonIndex}>
        <Card.Body className={styles['panel-body']}>
          <div className={styles['breakdown-index-container']}>
            <div className={styles.breakDownNumberCircle}>
              <p style={{ color: 'white', fontSize: '12px' }}>
                <strong>
                  {this.props.unitMeta.subUnits[0].lessons.length *
                    subunitCardIndex +
                    lessonIndex +
                    1}
                </strong>
              </p>
            </div>
          </div>
          <div className={styles['breakdown-content-container']}>
            <div className={styles.breakdownDuration}>
              {showDurationNumberStandards(lesson)} | {lesson.activityType}
            </div>
            <h1 className={styles.breakdownTitle}>{lesson.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: lesson.description.text }}
            />
          </div>
          <div className={styles['breakdown-button-container']}>
            {lesson.activities.map((step, stepIndex) =>
              this.getBreakdownButton(
                lessonIndex,
                step,
                stepIndex,
                unitId,
                this.props.onPreviewChange,
                subunitCardIndex
              )
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }

  render() {
    if (!this.state.subunitData) {
      return <Card id='Loading...' title='Loading...' />;
    }

    return (
      <>
        {this.state.subunitData.lessons.map((lesson, lessonIndex) => (
          <Fragment key={lessonIndex}>
            {lesson.activityType.includes('Game') ? (
              <>
                {this.rendermyPeekaville(
                  lesson,
                  lessonIndex,
                  this.props.unitId
                )}
              </>
            ) : (
              <>
                {this.renderActivityCard(
                  lesson,
                  lessonIndex,
                  this.props.unitId
                )}
              </>
            )}
          </Fragment>
        ))}
      </>
    );
  }

  getBreakdownButton = (
    lessonIndex: number,
    step: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number]['activities'][number],
    stepIndex: number,
    unitId: string,
    propsOnPreviewChange: Props['onPreviewChange'],
    subunitCardIndex: number
  ) => {
    function onPreviewChange() {
      propsOnPreviewChange({
        subunit: subunitCardIndex,
        lesson: lessonIndex,
        step: stepIndex,
      });
    }

    return (
      <BreakDownButton
        key={`${lessonIndex}-${stepIndex}`}
        style={
          this.isCompleted(
            this.props.classroom,
            lessonIndex,
            stepIndex,
            unitId,
            step.contentType
          )
            ? { backgroundColor: '#7FD7EB', cursor: 'pointer' }
            : { backgroundColor: '#00afd8', cursor: 'pointer' }
        }
        gotoUrl={this.getUnitUrlFor(
          'step',
          lessonIndex,
          stepIndex,
          step.contentType
        )}
        breakDownButtonType={this.getButtonType(
          step.contentType,
          this.props.classroom,
          lessonIndex,
          stepIndex,
          unitId
        )}
        breakDownButtonLabel={this.getActivityButtonLabel(
          step.contentType,
          stepIndex
        )}
        onClickLockedContent={this.props.onClickLockedContent}
        orContentAccessKeys={this.getOrContentAccessKeys(lessonIndex)}
        andContentAccessKeys={this.getAndContentAccessKeys(lessonIndex)}
        isPreviewMode={this.props.isPreviewMode}
        onPreviewChange={onPreviewChange}
      />
    );
  };
}

export default OverViewActivityCard;
