/**
 * Copyright 2013 Garden City Software Corp. All rights reserved.
 * User: Mark
 * Date: 04/02/15
 * Time: 12:06 PM
 *
 */
let _appRoot = '';

export const initializeApplicationRoot = () => {
  // I cannot get a location from anywhere other than a /public/ reference
  // CC: We don't host multiple applications for now, so just use '/'
  _appRoot = '/';
};

export const getAppRoot = () => {
  return _appRoot;
};
