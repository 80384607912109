import * as React from 'react';
import cx from 'classnames';
import styles from './VisitorCollectorCard.module.scss';

interface ContentProps {
  children: React.ReactNode;
  className?: string;
  titleAdditionalStyle?: React.CSSProperties;
}

export const Title = ({
  children,
  titleAdditionalStyle,
}: ContentProps): JSX.Element => {
  return (
    <div className={styles.title} style={titleAdditionalStyle}>
      {children}
    </div>
  );
};

export const Content = ({ children, className }: ContentProps): JSX.Element => {
  return <div className={cx(styles.content, className)}>{children}</div>;
};

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactChildren;
}

const VisitorCollectorCard = ({ children, className }: Props): JSX.Element => {
  const titleChild = React.Children.map(children, (c) => {
    if (c.type === Title) return React.cloneElement(c);
    return null;
  });

  const contentChild = React.Children.map(children, (c) => {
    if (c.type === Content) return React.cloneElement(c);
    return null;
  });

  return (
    <div className={cx(styles.container, className)}>
      {titleChild}
      {contentChild}
    </div>
  );
};

export default VisitorCollectorCard;
