import * as React from 'react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ConnectedProps, connect } from 'react-redux';
import { AppDispatch, RootState } from '../../ApplicationState';
import {
  redeemLicenseCode,
  updateUserProfile,
  validateLicenseCode,
} from '../../BackendInterface';
import { UpdateUserProfile, updateAllFields } from '../../UserManagement';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Spinner from 'react-spinkit';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import TextInput from '../TextInput';
import SpinnerOverlay from '../../SpinnerOverlay';
import blueCheckmark from '../../images/pkp-checkmark-blue.svg';
import redXSymbol from '../../images/pkp-x-symbol-red.svg';
import cx from 'classnames';
import styles from './RedeemLicenseCodeForm.module.scss';

interface FormValues {
  licenseCode: string;
}

interface ValidityIconProps {
  showSpinner: boolean;
  validationState: string | null;
}

type FormProps = {
  buttonText: string;
  id?: string;
  orange?: boolean;
  placeholder?: string;
};

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateUserProfile: (partialUserProfile: UpdateUserProfile) =>
    dispatch(updateAllFields(partialUserProfile)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FormProps;

let idleTimerHandle: NodeJS.Timeout | null = null;
let spinnerTimerHandle: NodeJS.Timeout | null = null;

const initialValues: FormValues = {
  licenseCode: '',
};

const ValidityIcon = ({ validationState, showSpinner }: ValidityIconProps) => {
  if (!validationState) {
    return null;
  }

  if (validationState === 'success') {
    return (
      <div
        className={cx(styles.validityIcon, {
          [`${styles.showValidityIcon}`]: !showSpinner,
        })}
      >
        <img src={blueCheckmark} alt='checkmark' />
      </div>
    );
  }

  return (
    <div
      className={cx(styles.validityIcon, {
        [`${styles.showValidityIcon}`]: !showSpinner,
      })}
    >
      <img src={redXSymbol} alt='checkmark' />
    </div>
  );
};

const ValidationSchema = Yup.object().shape({
  licenseCode: Yup.string().min(
    14,
    'Please input a valid 12-digit license code'
  ),
});

const RedeemLicenseCodeForm = (props: Props) => {
  const { buttonText, id, orange, placeholder, ...buttonProps } = props;

  const history = useHistory();
  const [codeValidationState, setCodeValidationState] = useState<
    'success' | 'error' | null
  >(null);
  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [redeemableCode, setRedeemableCode] = useState('');
  const [codeErrorShake, setCodeErrorShake] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [isRedeemSuccessful, setIsRedeemSuccessful] = useState(false);
  const [upgradeError, setUpgradeError] = useState<string | null>(null);
  const redeemableCodeRef = useRef(redeemableCode);
  redeemableCodeRef.current = redeemableCode;

  const onStopCheckingCode = (valid: boolean | null = false) => {
    setIsCheckingCode(false);
    setCodeErrorShake(valid === false);
    setCodeValidationState(
      valid === null ? null : valid === true ? 'success' : 'error'
    );
  };

  const clearTimersIfActivated = () => {
    if (idleTimerHandle) {
      // console.debug( `clearing timer ${ this.idleTimerHandle }` );
      clearTimeout(idleTimerHandle);
      idleTimerHandle = null;
    }

    if (spinnerTimerHandle) {
      onStopCheckingCode();
      clearTimeout(spinnerTimerHandle);
      spinnerTimerHandle = null;
    }
  };

  const onCheckCode = async () => {
    setIsCheckingCode(true);

    if (!redeemableCodeRef.current.length) {
      onStopCheckingCode(null);
    } else if (redeemableCodeRef.current.length === 14) {
      const cleanedCode = redeemableCodeRef.current.replace(/-/g, '');

      try {
        const valid = await validateLicenseCode(cleanedCode);
        onStopCheckingCode(valid);
      } catch (error) {
        console.error(error);
        onStopCheckingCode(false);
      }
    } else {
      spinnerTimerHandle = setTimeout(onStopCheckingCode, 5000);
    }
  };

  const onRedeemableCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<FormValues>['setFieldValue']
  ) => {
    const { name, value } = event.target;

    const toSetValue = (function () {
      let beautified = value.toUpperCase();
      setUpgradeError(null);

      if (beautified.length > 14) {
        return;
      } else if (redeemableCodeRef.current.length < 14) {
        setCodeValidationState(null);
      } else if (beautified.length === 0) {
        setRedeemableCode('');
        setCodeValidationState(null);
        return;
      }

      beautified = beautified.replace(/-/g, '');

      if (beautified.length > 4) {
        beautified = `${beautified.substring(0, 4)}-${beautified.substring(4)}`;
      }

      if (beautified.length > 9) {
        beautified = `${beautified.substring(0, 9)}-${beautified.substring(9)}`;
      }
      setRedeemableCode(beautified);
      return beautified;
    })();
    setFieldValue(name, toSetValue);
    clearTimersIfActivated();
    idleTimerHandle = setTimeout(onCheckCode, 1000);
    // console.debug( `created timer ${ this.idleTimerHandle }` );
  };

  const submitUpgradeCode = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (values.licenseCode.length !== 14 || codeValidationState === 'error') {
      return;
    }

    try {
      const cleanedCode = values.licenseCode.replace(/-/g, '');
      setIsUpgrading(true);
      const toApplyLicenseCharacteristics = await redeemLicenseCode(
        cleanedCode
      );
      await updateUserProfile({ newUserData: toApplyLicenseCharacteristics });
      props.updateUserProfile({
        ...props.userProfile,
        ...toApplyLicenseCharacteristics,
      });
      setIsUpgrading(false);
      setIsRedeemSuccessful(true);
    } catch (error) {
      setIsUpgrading(false);
      setIsRedeemSuccessful(true);
      setCodeValidationState('error');
      if (axios.isAxiosError(error)) {
        setUpgradeError(error.response?.data?.message);
      } else if (error instanceof Error) {
        setUpgradeError(error.message);
      }
    }
    setSubmitting(false);
  };

  if (isRedeemSuccessful) {
    return (
      <div className={styles.redeemCode}>
        <div className={styles.title}>License Code Successfully Redeemed!</div>
        <PrimaryButton onClick={() => history.push('/library')}>
          Okay
        </PrimaryButton>
      </div>
    );
  }

  return (
    <div className={styles.redeemCodeForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={submitUpgradeCode}
        validationSchema={ValidationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <div
                className={cx(styles.codeWidth, styles.redeemLicenseCode, {
                  [`${styles.errorShake}`]: codeErrorShake,
                })}
              >
                <div
                  className={cx(styles.spinnerContainer, {
                    [`${styles.showSpinner}`]: isCheckingCode,
                  })}
                >
                  <Spinner name='circle' />
                </div>
                <TextInput
                  id={id || 'redeemableCode'}
                  type='text'
                  placeholder={
                    placeholder || 'Enter license code (XXXX-XXXX-XXXX)'
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onRedeemableCodeChange(event, setFieldValue)
                  }
                  value={redeemableCode}
                  name='licenseCode'
                />
                <ValidityIcon
                  validationState={codeValidationState}
                  showSpinner={isCheckingCode}
                />
              </div>
              <PrimaryButton
                className={styles.buttonStyle}
                type='submit'
                orange={orange ? orange : null}
                disabled={
                  codeValidationState === null ||
                  codeValidationState === 'error'
                }
                {...buttonProps}
              >
                {buttonText}
              </PrimaryButton>
              {upgradeError && (
                <div className={styles.errorMessage}>{upgradeError}</div>
              )}
            </Form>
          );
        }}
      </Formik>
      <SpinnerOverlay isShow={isUpgrading} />
    </div>
  );
};

export default connector(RedeemLicenseCodeForm);
