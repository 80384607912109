import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedEffect } from '../../CustomHooks';

import styles from './ExpandableStandardsCard.module.scss';
import { Icon } from '@iconify/react';
import bxsRightArrow from '@iconify-icons/bx/bxs-right-arrow';
import { UrlParams } from '../../../peekapak-types/LessonPlanTypes';

export type UnitCardProps = {
  expandable?: boolean;
  expanded: boolean;
  content: { label: string; text: string; sub: string }[];
  isLessonFlow?: boolean;
  handleNavigatorClick?: (lessonType: string, lessonId: string) => void;
  title: string;
};

const ExpandableStandardsCard = ({
  expandable,
  expanded,
  content,
  title,
}: UnitCardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [textHeight, setTextHeight] = useState('0px');
  const [isShowMore, setIsShowMore] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const { lesson }: UrlParams = useParams();

  const toggleAccordion = () => {
    if (expandable) {
      setTextHeight(
        isExpanded || contentRef.current === null
          ? '0px'
          : `${contentRef.current.scrollHeight}px`
      );
      setIsExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const handleShowMore = () => {
    setIsShowMore((prevShowMore: boolean) => !prevShowMore);
  };

  const handleResize = useCallback(() => {
    const newTextHeight =
      !isExpanded || contentRef.current === null
        ? `0px`
        : `${contentRef.current.scrollHeight}px`;
    setTextHeight(newTextHeight);
  }, [handleShowMore]);

  useDebouncedEffect(
    () => window.addEventListener('resize', handleResize),
    [handleResize],
    1000
  );

  useEffect(() => {
    if (expanded || isExpanded)
      setTextHeight(`${contentRef.current?.scrollHeight}px`);
    if (isShowMore)
      setTextHeight(
        isExpanded ? `${contentRef.current?.scrollHeight}px` : '0px'
      );
  }, [content, handleResize, lesson]);

  return (
    <div className={styles.accordionContainer}>
      <button onClick={toggleAccordion}>
        <h3>{title}</h3>
        <div>
          <Icon
            icon={bxsRightArrow}
            width={16}
            height={16}
            rotate={isExpanded ? 1 : 0}
            color='#00afd8'
          />
        </div>
      </button>
      <div
        className={styles.accordionText}
        ref={contentRef}
        style={{ maxHeight: `${textHeight}` }}
      >
        <ul>
          {content.map((standard) =>
            isShowMore ? (
              <li key={`detail ${standard.label}`}>
                {`${standard.label} - ${standard.sub}`}
                <p>{standard.text}</p>
              </li>
            ) : (
              <li key={`title ${standard.label}`}>{standard.label}</li>
            )
          )}
        </ul>
        <button className={styles.showMore} onClick={handleShowMore}>
          Show {isShowMore ? 'Less' : 'More'}&nbsp;
          <Icon
            icon={bxsRightArrow}
            width={12}
            height={12}
            rotate={isShowMore ? 3 : 0}
            color='#86939e'
          />
        </button>
      </div>
    </div>
  );
};

export default ExpandableStandardsCard;
