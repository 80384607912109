import { Component } from 'react';
import classNames from 'classnames';
import { getClassroomDescription } from './../../GlobalFunctions';
import blueCheckmark from '../../images/pkp-checkmark-blue.svg';

export class ClassroomSelectorSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: null,
    };
    this.toggleHover = this.toggleHover.bind(this);
  }

  setContainer(c) {
    this.container = c;
  }

  toggleHover(index) {
    this.setState({ hover: index });
  }

  handleClassroomChange(e, newIndex) {
    this.props.setSelectedClassroom(newIndex);
  }

  render() {
    if (!this.props.isAuthenticated) {
      return null;
    }

    const ci = this.props.classrooms.selectedClassroom;

    return (
      <div
        className='panel'
        ref={this.setContainer.bind(this)}
        style={{ backgroundColor: '#ebedef' }}
      >
        {this.props.classrooms.list.map((classroom, index) => {
          const description = getClassroomDescription(classroom);
          const isSelectedClassroom =
            index === this.props.classrooms.selectedClassroom;
          return (
            <div
              key={index}
              className={classNames(
                'tooltipBox',
                { withCheck: isSelectedClassroom },
                { darkText: isSelectedClassroom }
              )}
              onClick={(e) => {
                this.handleClassroomChange(e, index);
                this.props.closeClassroomSelector();
                this.props.toggle();
              }}
              style={
                index === ci || index === this.state.hover
                  ? { marginLeft: '2em', color: '#000000' }
                  : { marginLeft: '2em' }
              }
              onMouseEnter={() => this.toggleHover(index)}
              onMouseLeave={() => this.toggleHover(null)}
            >
              <img
                alt={isSelectedClassroom ? 'checkmark' : null}
                src={isSelectedClassroom ? blueCheckmark : null}
                style={
                  isSelectedClassroom
                    ? {
                        width: '1em',
                        height: '1em',
                        float: 'left',
                        marginLeft: '-1.5em',
                        marginTop: '0.4em',
                      }
                    : null
                }
              />
              {description}
            </div>
          );
        })}
      </div>
    );
  }
}
