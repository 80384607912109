import StatePagesTemplate from './StatePagesTemplate';
import VideoAndQuoteSection from '../components/VideoAndQuoteSection';
import { californiaFaq } from './expandableFAQContents';
import sheldon from '../images/logos/logo-sheldonisd.png';
import busd from '../images/logos/logo-busd-vertical.png';
import tulare from '../images/logos/logo-tulare.png';
import tulsa from '../images/logos/logo-tulsa.png';
import longBranch from '../images/logos/logo-longbranch-white.png';
import kipp from '../images/logos/logo-kippstl.png';
import videoCoverImage from '../images/state-california-video-cover.png';

const californiaPageData = {
  perfectMatchOneTitle:
    'When You Choose Peekapak, You Breathe Life Back Into The Classroom.',
  perfectMatchOneSubtitle: `As the administrator of your school, you want all students to succeed, but you’re seeing diverse learning needs, teacher burnout, increasing state mandates, and a lack of coping skills`,
  perfectMatchTwoTitle: 'Why Peekapak and California are a Perfect Match',
  whyChoosePeekapakLists: [
    'Fulfills California’s SEL Mandates',
    'Offers Spanish versions to reach your English Learners',
    'Aligns to CASEL Standards',
    'Engages students through game-based learning',
  ],
  schoolLogos: [sheldon, busd, tulare, tulsa, longBranch, kipp],
};

const CaliforniaPage = () => {
  return (
    <StatePagesTemplate
      state='California'
      perfectMatchOneTitle={californiaPageData.perfectMatchOneTitle}
      perfectMatchOneSubtitle={californiaPageData.perfectMatchOneSubtitle}
      perfectMatchTwoTitle={californiaPageData.perfectMatchTwoTitle}
      whyChoosePeekapakLists={californiaPageData.whyChoosePeekapakLists}
      schoolLogos={californiaPageData.schoolLogos}
      stateSpecificFaq={californiaFaq}
    >
      <VideoAndQuoteSection
        quote='“I love the fact that now [educators] have the ability to add that
      SEL component in with this digital resource [Peekapak]”'
        quoteFrom='－Deborah Lockwood, Tulare County Office of Education (CA)'
        sectionPreTitle='Feedback from California Educators'
        sectionTitle='Feedback from California Educators'
        videoCoverImageUrl={videoCoverImage}
        videoId='OIDotkyv32OEP13QTZv1v9DsMnKsYPoxljJ7ufaSA200'
        videoTitle='Feedback from California Educators'
      />
    </StatePagesTemplate>
  );
};

export default CaliforniaPage;
