import { Field, ErrorMessage } from 'formik';
import { Icon, IconifyIcon } from '@iconify/react';
import cx from 'classnames';
import TextError from './TextError';
import styles from './FormikStyles.module.scss';

interface Props {
  bottomborder?: boolean;
  className?: string;
  fullborder?: boolean;
  icon?: IconifyIcon;
  label: string;
  name: string;
  isrequired?: boolean;
}

function Input(props: Props): JSX.Element {
  const { label, name, ...rest } = props;

  return (
    <div className={cx(styles.formControl, props.className)}>
      {props.label && (
        <label
          htmlFor={name}
          className={cx({
            [`${styles.required}`]: props.isrequired,
          })}
        >
          {props.icon && <Icon icon={props.icon} />}
          {label}
        </label>
      )}
      {props.isrequired && !props.label && (
        <span className={styles.asterisk}>*</span>
      )}
      <Field
        id={name}
        name={name}
        {...rest}
        className={cx({
          [`${styles.fullBorder}`]: props.fullborder,
          [`${styles.bottomBorder}`]: props.bottomborder,
          [`${styles.leftPadding}`]: props.label,
        })}
      />
      <div className={styles.errorHintContainer}>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
}

export default Input;
