import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ReactElement } from 'react';
import useCalendly from '../../openCalendly';
import HeroBanner from '../../components/HeroBanner';
import Hero, {
  Title as HeroTitle,
  Subtitle,
  HeroCTAButton,
} from '../../components/HeroNoRolecard/Hero';
import CTASegment, { OpenCalendlyCTA } from '../../components/CTASegment';
import CTAButton from '../../components/CTAButton';
import ExpandableNoNaviContentCard from '../../components/ExpandableNoNaviContentCard';
import { Copy, Title, Prompt } from '../../components/LeftRightCard';
import { ExternalLink } from '../../components/ExternalLink';
import { sharedFaq } from '../expandableFAQContents';
import { Icon } from '@iconify/react';
import thumbsUp from '@iconify/icons-akar-icons/thumbs-up';
import busSchool from '@iconify-icons/bx/bus-school';
import fistRaised from '@iconify/icons-la/fist-raised';
import heroDesktop from '../../images/hero/hero-state-desktop.png';
import heroMobile from '../../images/hero/hero-state-mobile.png';
import selEmpowerment from '../../images/sel-empowerment.png';
import whyChoosePeekapak from '../../images/why-choose-peekapak.png';
import checkmarkCircleGrey from '../../images/pkp-checkmark-in-circle-grey.svg';
import homeStyles from '../../SCSS/Home.module.scss';
import styles from './StatePagesTemplate.module.scss';

const whyPerfectMatchInfoCards = [
  {
    icon: thumbsUp,
    title: `Effortless`,
    subtitle: `TURNKEY AND EASY`,
    paragraph: `Lessons need minimal prep to reach literacy standards in English Language Arts.`,
  },
  {
    icon: 'fa:wheelchair-alt',
    title: `Accessibility`,
    subtitle: `DIVERSE LEARNERS`,
    paragraph: `4 Guided Reading Levels & 2 Levels in Spanish means ALL students obtain vital SEL strategies.`,
  },
  {
    icon: busSchool,
    title: `Whole-Child Curriculum`,
    subtitle: `MULTI-FACETED SUPPORT`,
    paragraph: `Embraces the teacher, parent and community support of the child through research based and COPPA compliant SEL strategies.`,
  },
  {
    icon: fistRaised,
    title: `Empowering`,
    subtitle: `PROVIDES AGENCY`,
    paragraph: `Creates a shared language and takes a pro-active approach to support students universally in the classroom.`,
  },
];

interface Props {
  perfectMatchOneTitle: string;
  perfectMatchOneSubtitle: string;
  perfectMatchTwoTitle: string;
  stateSpecificFaq: ConcatArray<{ o: number; q: string; a: JSX.Element }>;
  state: string;
  stateShorthand?: string;
  whyChoosePeekapakLists: string[];
  schoolLogos: string[];
  children?: ReactElement;
}

const StatePagesTemplate = ({
  perfectMatchOneTitle,
  perfectMatchOneSubtitle,
  perfectMatchTwoTitle,
  stateSpecificFaq,
  state,
  stateShorthand,
  whyChoosePeekapakLists,
  schoolLogos,
  children,
}: Props): JSX.Element => {
  const mergedFaqList = sharedFaq
    .concat(stateSpecificFaq)
    .sort((a, b) => a.o - b.o);

  const history = useHistory();
  const openCalendly = useCalendly();

  const isSmall = useMediaQuery({ maxWidth: 820 });

  return (
    <div className={homeStyles.anonContainer}>
      <HeroBanner history={history} />
      <Hero bgImage={heroDesktop} bgImageMobile={heroMobile}>
        <HeroTitle>
          Peekapak’s Social Emotional Learning Curriculum Is Perfect For {state}{' '}
          Schools
        </HeroTitle>
        <Subtitle>
          {isSmall
            ? 'A CASEL-aligned, K-8 SEL curriculum that empowers schools to increase communication, self-regulation, and conflict-resolution skills.'
            : 'Peekapak Boosts Empathy, Respect, and Resilience In Schools Nationwide. A CASEL-aligned, K-8 SEL curriculum that empowers schools to increase communication, self-regulation, and conflict-resolution skills.'}
        </Subtitle>
        <HeroCTAButton onClick={() => openCalendly()}>
          book a free demo
        </HeroCTAButton>
      </Hero>
      <section className={styles.perfectMatchSectionOne}>
        <div className={styles.preTitle}>Perfect Match</div>
        <div className={styles.title}>{perfectMatchOneTitle}</div>
        <div className={styles.subtitle}>{perfectMatchOneSubtitle}</div>
      </section>
      <section className={styles.selEmpowermentSection}>
        <div className={styles.textContainer}>
          <div className={styles.preTitle}>social emotional learning</div>
          <div className={styles.title}>{`SEL Empowerment for ${state}`}</div>
          <div className={styles.subtitle}>
            {`With Peekapak students reinforce concepts like empathy, respect, and
            self-regulation through a series of lessons aligned to ${stateShorthand} ELA
            standards, learning games, e-books and social emotional learning
            activities.`}
          </div>
          <Prompt clickHandler={() => history.push('/elementary')}>
            Peekapak’s Solutions
          </Prompt>
          <Prompt clickHandler={() => history.push('/mypeekaville#top')}>
            Explore myPeekaville
          </Prompt>
          <ExternalLink href='https://shop.peekapak.com/' target='_blank'>
            <Prompt>Print Book Theme Sets</Prompt>
          </ExternalLink>
        </div>
        <div className={styles.imageContainer}>
          <img src={selEmpowerment} alt='sel empowerment' />
        </div>
      </section>
      <section className={styles.choosePeekapakSection}>
        <div className={styles.imageContainer}>
          <img src={whyChoosePeekapak} alt={'whyChoosePeekapak'} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.titles}>
            <div className={styles.preTitle}>choose peekapak</div>
            <Title>Why Choose Peekapak?</Title>
          </div>
          <div className={styles.bodyText}>
            <Copy>
              With over 80 lessons per grade level, Peekapak&rsquo;s leveled
              readers enhance academics. Our research-based curriculum
              integrates key SEL skills into your language instruction.
            </Copy>
            <Copy>As an added bonus, each lesson:</Copy>
            <Copy>
              {whyChoosePeekapakLists.map((list) => (
                <div className={styles.singleListContainer} key={list}>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  <p>{list}</p>
                </div>
              ))}
            </Copy>
          </div>
        </div>
      </section>
      <section className={styles.perfectMatchSectionTwo}>
        <div className={styles.titles}>
          <div className={styles.preTitle}>perfect match</div>
          <div className={styles.title}>{perfectMatchTwoTitle}</div>
          <div className={styles.subtitle}>
            A certified K-8 SEL curriculum that empowers schools with an “all
            in” atmosphere of unity, trust, and mutual respect.
          </div>
        </div>
        <div className={styles.methodCardContainer}>
          {whyPerfectMatchInfoCards.map((card) => (
            <div className={styles.card} key={card.title}>
              <Icon
                icon={card.icon}
                color='#00AFD8'
                width='3rem'
                height='3rem'
              />
              <h2>{card.title}</h2>
              <h4>{card.subtitle}</h4>
              <p>{card.paragraph}</p>
            </div>
          ))}
        </div>
      </section>
      <section className={styles.studentsLovePeekapakSection}>
        <div className={styles.titles}>
          <div className={styles.title}>Students Love Peekapak</div>
          <div className={styles.subtitle}>
            {`We proudly support 64,000+ students in ${state} & across the country
            with our CASEL-aligned stories, lessons and learning games that help
            kids develop resilience, mindfulness, and self-regulation skills.`}
          </div>
        </div>
        <div className={styles.schoolLogosContainer}>
          {schoolLogos.map((logo) => (
            <img src={logo} alt={logo.toString()} key={logo} />
          ))}
        </div>
      </section>
      {children && children}
      <CTASegment>
        <OpenCalendlyCTA />
        <CTAButton secondary onClick={() => history.push('/signup')}>
          explore
        </CTAButton>
      </CTASegment>
      <section className={styles.faqSection}>
        <div className={styles.titleContainer}>
          <div className={styles.preTitle}>Frequently Asked Questions</div>
          <div className={styles.title}>FAQs about Peekapak for {state}</div>
        </div>
        <div className={styles.faqListContainer}>
          {mergedFaqList.map((item, i) => (
            <div key={item.q}>
              <ExpandableNoNaviContentCard
                expandable
                title={item.q}
                expanded={i === 0 && true}
              >
                <div>{item.a}</div>
              </ExpandableNoNaviContentCard>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default StatePagesTemplate;
