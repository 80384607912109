import * as React from 'react';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import { FieldGroup } from '../../core/core';
import styles from './PromotionCodeInput.module.scss';
import blueCheckmark from '../../images/pkp-checkmark-blue.svg';
import redXSymbol from '../../images/pkp-x-symbol-red.svg';

interface ValidityProps {
  showSpinner: boolean;
  validationState: string | null;
}

interface LicenseCodeProps {
  onCodeChange: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  code: string;
  className: Record<string, unknown>;
  inputClassName: Record<string, unknown>;
  placeholder?: string;
  id?: string;
}

type Props = ValidityProps & LicenseCodeProps;

const ValidityIcon = ({
  validationState,
  showSpinner,
}: ValidityProps): JSX.Element | null => {
  if (!validationState) {
    return null;
  }

  if (validationState === 'success') {
    return (
      <div
        className={cx(styles.validityIcon, {
          [`${styles.showValidityIcon}`]: !showSpinner,
        })}
      >
        <img src={blueCheckmark} alt='checkmark' />
      </div>
    );
  }

  return (
    <div
      className={cx(styles.validityIcon, {
        [`${styles.showValidityIcon}`]: !showSpinner,
      })}
    >
      <img src={redXSymbol} alt='checkmark' />
    </div>
  );
};

const PromotionCodeInput = (props: Props): JSX.Element => {
  return (
    <div className={cx(props.className, styles.redeemLicenseCode)}>
      <div
        className={cx(styles.spinnerContainer, {
          [`${styles.showSpinner}`]: props.showSpinner,
        })}
      >
        <Spinner name='circle' />
      </div>
      <FieldGroup
        id={props.id || 'codeInput'}
        className={cx(styles.inputField, props.inputClassName)}
        type='text'
        placeholder={props.placeholder || 'Enter code'}
        onChange={props.onCodeChange}
        value={props.code}
      />
      <ValidityIcon
        validationState={props.validationState}
        showSpinner={props.showSpinner}
      />
    </div>
  );
};

export default PromotionCodeInput;
