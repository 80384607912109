import cx from 'classnames';
import styles from './SortDropdown.module.scss';

interface Options {
  text: string;
  value: string | number;
}

interface Props {
  className?: string;
  options: Options[];
  value: number | string;
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void;
  styleGroup?: string;
}

const SortDropdown = ({
  className,
  options,
  value,
  onChange,
  styleGroup,
}: Props): JSX.Element => {
  return (
    <div
      className={cx(
        {
          [styles.reportContainer]: styleGroup === 'reports',
          [styles.container]: styleGroup !== 'reports',
        },
        className
      )}
    >
      <div
        className={
          styleGroup === 'reports'
            ? styles.reportChevron
            : styles.chevronWrapper
        }
      >
        <span>{styleGroup !== 'reports' && 'Sort by: '}</span>
        <select value={value} onChange={onChange}>
          {options.map((x: Options) => (
            <option key={x.text} value={x.value}>
              {x.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SortDropdown;
