import { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { NormalHeader, Text } from '../core/core';
import UnitHeaderButton from './UnitHeaderButton';
import { showNumberStandards } from './UtilityFunctions';
import { selectCompletionStatus } from '../Classrooms';
import styles from '../SCSS/lpMain.module.scss';
import { RootState } from '../ApplicationState';
import {
  ClassroomType,
  ElementaryUnit,
} from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';
import { History } from 'history';

type Props = PropsFromRedux & {
  classroom: ClassroomType;
  completionStatus: ClassroomType['completionStatus'];
  headerImage: string;
  history: History;
  isPreviewMode?: boolean;
  label: string;
  numberOfStandards: number;
  onClickLockedContent: () => void;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  params: {
    classroomName: string;
    unitId: string;
  };
  title: string;
  unitId: string;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  unitPosition: UnitPosition;
};

class UnitHeader extends Component<Props, {}> {
  render() {
    // console.debug(`%cUnit Header render`, 'background: black; color: red');
    return (
      <div className={styles.unitHeader} id='unitHeader'>
        <div
          className={styles.unitHeaderImage}
          style={{
            backgroundImage: `url(${this.props.headerImage})`,
            backgroundColor: 'black',
          }}
        />
        <div className={styles.unitHeaderContent}>
          <Text className={styles.unitHeaderLabel} text={this.props.label} />
          <NormalHeader
            className={styles.unitHeaderTitle}
            text={this.props.title}
          />
          <Text
            className={styles.unitHeaderCoreStandards}
            text={showNumberStandards(this.props.numberOfStandards)}
          />
          <UnitHeaderButton
            className={styles.unitHeaderButton}
            completionStatus={this.props.completionStatus}
            unitMeta={this.props.unitMeta}
            unitPosition={this.props.unitPosition}
            classroom={this.props.classroom}
            onClickLockedContent={this.props.onClickLockedContent}
            history={this.props.history}
            isPreviewMode={this.props.isPreviewMode}
            onPreviewChange={this.props.onPreviewChange}
            params={this.props.params}
            unitId={this.props.unitId}
          >
            Begin Lessons
          </UnitHeaderButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  completionStatus: selectCompletionStatus(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UnitHeader);
