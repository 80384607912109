import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { noWhiteSpace } from '../../GlobalFunctions';
import MyHelmet from '../../MyHelmet';
import HeroBanner from '../../components/HeroBanner';
import HeroLanding, {
  HeroSubtitle,
  HeroTitle,
} from '../../anonymous/HeroLanding';
import WellnessEducationSection from './WellnessEducationSection';
import PeekapakInAbuDhabiSection from './PeekapakInAbuDhabiSection';
import EarlyChildhoodAuthoritySection from './EarlyChildhoodAuthoritySection';
import AwardSection from '../../components/AwardSection';
import UAEVideoAndQuoteSection from './UAEVideoAndQuoteSection';
import IntegrationPartners from '../IntegrationPartners';
import SelPlanSegment from '../../anonymous/SelPlanSegment';
import { ReasonsGrid } from '../../anonymous/ReasonsSegment';
import ExpertsSegment from '../../anonymous/ExpertsSegment';
import PeekapakInAction from '../PeekapakInAction';
import TestimonialsSegment from '../../anonymous/TestimonialsSegment';
import CTASegment, { OpenCalendlyCTA } from '../../components/CTASegment';
import CTAButton from '../../components/CTAButton';
import pedestrianFamily from '@iconify-icons/carbon/pedestrian-family';
import chalkboardTeacher from '@iconify/icons-la/chalkboard-teacher';
import bxBookOpen from '@iconify-icons/bx/bx-book-open';
import heroImage from '../../images/hero/hero-UAE-landing.png';
import reason01 from '../../images/wheel.png';
import reason02 from '../../images/coppa.png';
import reason03 from '../../images/video.png';
import reason04 from '../../images/mockup-k-8.png';
import reason05 from '../../images/UAELanding/student-group.png';
import reason06 from '../../images/dashboard.png';
import educatorLogoAGS from '../../images/UAELanding/ags-grammer.png';
import educatorLogoBBN from '../../images/UAELanding/bright-beginnings-nursery.png';
import educatorLogoMaplewood from '../../images/UAELanding/maplewood-canadian-international-school.png';
import homeStyles from '../../SCSS/Home.module.scss';

const heroImageSettings = {
  image: heroImage,
  backgroundPositions: {
    mobile: 'top 0 left 60%',
    ipad: 'top 0 left 0',
    ipadpro: 'top 0 left 0',
    mdpi: 'top 25% left 75%',
  },
  gradient: `rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)`,
};

const roleCards = [
  {
    title: 'Elementary (KG1-G5)',
    description: `Features levelled readers and fully-planned lessons to help students develop SEL while also building reading and writing skills.`,
    url: '/elementary',
  },
  {
    title: 'Secondary (G6-G12)',
    description: `Peekapak’s ready-to-teach units, lessons and human-interest stories help support both SEL & ELA development, while integrating into literacy standards.`,
    url: '/secondary',
  },
  {
    title: 'Administrators',
    description: `Empower educators, engage students and connect families, while collecting insights and real-time reports on your SEL initiative.`,
    url: '/districtLead',
  },
];

const infoCards = [
  {
    title: 'Wellness Data',
    subtitle: 'to understand needs and develop an improvement plan',
    icon: pedestrianFamily,
    linkText: 'Request Info',
    linkURL: '/requestInfo',
  },
  {
    title: 'Professional Development',
    subtitle: 'for Educators, on the CASEL Competencies',
    icon: chalkboardTeacher,
    linkURL: '/professionalDevelopment',
  },
  {
    title: 'Print Resources',
    subtitle: 'to Support Literacy and Your School Culture',
    icon: bxBookOpen,
    linkURL: 'https://shop.peekapak.com',
  },
];

const reasons = [
  {
    name: 'casel',
    title: 'Aligned to CASEL standards',
    copy: noWhiteSpace`Peekapak’s curriculum is aligned to CASEL’s framework of
    five social emotional learning competencies. Students develop these important skills through engaging stories, fun games and activities to continuously practice and reinforce learning.`,
    image: reason01,
    imageAlt: 'CASEL areas',
  },
  {
    name: 'coppa',
    title: 'Safe and Effective in Schools',
    copy: noWhiteSpace`Peekapak’s lessons are developed using evidence-based
    practices and have been researched in districts, Pre-K and after-school
    programs. We value student privacy and are iKeepSafe Certified (COPPA
    compliant).`,
    image: reason02,
    imageAlt: 'coppa seal',
  },
  {
    name: 'enaging',
    title: 'Engages Students',
    copy: noWhiteSpace`Peekapak gives students agency to explore a personalized learning experience where they can practice lessons learned in class.`,
    image: reason03,
    imageAlt: 'teacher in classroom',
    videoId: '4Dqq2tPjqHNQkrp9LYpk01i21uDo6IdHeHTflBPdwLuY',
  },
  {
    name: 'accessible',
    title: 'Accessible and Easily Modified',
    copy: noWhiteSpace`Use all Peekapak units and resources for ongoing
    classroom support or select the pieces that fit within your existing
    curriculum. Our levelled readers and variety of lessons provide you flexibility to meet the needs of all your students.`,
    image: reason04,
    imageAlt: 'snapshot of library view',
  },
  {
    name: 'moral',
    title: 'Aligns to Moral Education',
    copy: noWhiteSpace`Peekapak’s evidence-informed curriculum develops key social-emotional learning (SEL) skills while supporting moral education and national identity.`,
    image: reason05,
    imageAlt: 'group play',
  },
  {
    name: 'reports',
    title: 'Real-Time Reports and Insights',
    copy: noWhiteSpace`Teachers and administrators can monitor real-time
    progress, see insights on student and parent engagement, and collect social emotional data through our Mood Board.`,
    image: reason06,
    imageAlt: 'mood board',
  },
];

const educatorLogos = [
  { logo: educatorLogoAGS, link: '' },
  { logo: educatorLogoBBN, link: '' },
  { logo: educatorLogoMaplewood, link: '' },
];

const UAELanding = (): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: '/',
      search: '?variant=200',
    });
  }, []);

  return (
    <div className={homeStyles.anonContainer}>
      <MyHelmet>
        <meta name='keywords' content='Social Emotional Learning Activities' />
        <title>Peekapak Social Emotional Learning Curriculum for K-12</title>
        <meta
          name='description'
          content={`Peekapak infuses social emotional learning & well-being in K-12 classrooms. We integrate into literacy & ELA curriculums to captivate students with engaging, narrative-based content.`}
        />
      </MyHelmet>
      <HeroBanner />
      <HeroLanding
        heroImage={heroImageSettings}
        history={history}
        roleCards={roleCards}
      >
        <HeroTitle>An Integrated Approach to Well-Being Education</HeroTitle>
        <HeroSubtitle>
          Peekapak empowers nurseries and KG to Grade 12 school educators to
          embed teaching social emotional learning skills while supporting
          literacy. Peekapak helps schools teach, measure and develop skills
          like stress regulation, empathy and respect.
        </HeroSubtitle>
      </HeroLanding>
      <WellnessEducationSection
        onClickRequestQuote={() => history.push('/requestInfo')}
      />
      <PeekapakInAbuDhabiSection />
      <EarlyChildhoodAuthoritySection />
      <AwardSection title='Award Winning Learning' />
      <UAEVideoAndQuoteSection />
      <IntegrationPartners />
      <SelPlanSegment infoCards={infoCards} />
      <ReasonsGrid reasonsArr={reasons} />
      <ExpertsSegment />
      <PeekapakInAction
        clickHandler={() => history.push('/successStories')}
        logos={educatorLogos}
      />
      <TestimonialsSegment isUAE showActiveLogoOnly />
      <CTASegment>
        <OpenCalendlyCTA />
        <CTAButton secondary onClick={() => history.push('/signup')}>
          explore
        </CTAButton>
      </CTASegment>
    </div>
  );
};

export default UAELanding;
