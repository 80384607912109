import SectionHeader from '../SectionHeader';
import cx from 'classnames';
import smartMedia from '../../images/logo-award-smart-media-straight.png';
import cse from '../../images/logo-award-common-sense-education.png';
import familyChoice from '../../images/logo-award-family-choice.png';
import digitalPromiseProduct from '../../images/logo-certification-digital-promise-product.png';
import topEdTech from '../../images/logo-award-top-EdTech.png';
import styles from './AwardSection.module.scss';

const defaultProps = {
  title: 'Social Emotional Learning',
  subTitle: `Peekapak helps students learn, practice, and internalize CASEL’s five social-emotional learning competencies through our award-winning stories, lessons, and digital games.`,
  greyBackground: false,
};

interface Props {
  sectionClassName?: string;
  preTitle?: string;
  title?: string;
  subTitle?: string;
  greyBackground?: boolean;
}

const AwardSection = ({
  sectionClassName,
  preTitle,
  title,
  subTitle,
  greyBackground,
}: Props): JSX.Element => {
  return (
    <section
      className={cx(
        {
          [`${styles.greyBackground}`]: greyBackground,
          [`${sectionClassName}`]: sectionClassName,
        },
        styles.container
      )}
    >
      <SectionHeader center preTitle={preTitle} title={title} copy={subTitle} />
      <div className={styles.awards}>
        <img src={smartMedia} alt='smart media award' />
        <img src={digitalPromiseProduct} alt='digital promise product' />
        <img src={topEdTech} alt='top EdTech products' />
        <img src={cse} alt='common sense education award' />
        <img src={familyChoice} alt='family choice award' />
      </div>
    </section>
  );
};

export default AwardSection;

AwardSection.defaultProps = defaultProps;
