import { Component, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { ConnectedProps, connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import HeaderSidebar from '../header/HeaderSidebar';
import Documents from '../../Documents';
import {
  canUpgrade,
  canUpgradeToClassroom,
  canUpgradeToPro,
  getClassroomDescription,
  getLicenseLevel,
  isUserHasAnyKeys,
  isUserHasKey,
  sendUserToRoleHomePage,
  checkForPathOnClassChange,
} from './../../GlobalFunctions';
import ChooseRoleDialog from '../../components/ChooseRoleDialog/ChooseRoleDialog';
import { setSelectedClassroom } from '../../GlobalFunctions';
import { State as ClassroomsState } from '../../Classrooms';
import styles from './MiddleSchoolHeader.module.scss';
import { showIntercom } from '../../IntercomUtilityFunctions';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import NotificationBell from '../header/NotificationBell';
import backpack from '../../images/pkp-backpack-white.svg';
import chevronUp from '../../images/pkp-chevron-up-white.svg';
import chevronDown from '../../images/pkp-chevron-down-white.svg';
import { LoginState } from '../../UserManagement';
import { History } from 'history';
import { RootState } from '../../ApplicationState';
import { UserProfileType } from '../../../peekapak-types/DataProtocolTypes';

// Header Components

const headerComponentsUnauthorizedUser = [
  {
    name: 'Products',
    route: '/product',
    styleName: 'HeaderProduct',
    hasSubMenu: false, // TODO: set to true, once sub pages are ready
    subMenu: [
      {
        name: 'Curriculum',
        route: '/product#curriculum',
      },
      {
        name: 'Learning Games',
        route: '/product#learningGames',
      },
      {
        name: 'Print Books',
        route: '/product#printBooks',
      },
      {
        name: 'Training',
        route: '/product#training',
      },
      {
        name: 'After School',
        route: '/product#afterSchool',
      },
      {
        name: 'Library',
        route: '/product#library',
      },
    ],
  },
  /*
   * TODO: re-enable when the subpages are read
   *
  {
    name: `Who It's for?`,
    route: '/markets',
    styleName: 'HeaderMarkets',
    hasSubMenu: true,
    subMenu: [
      {
        name: 'Districts',
        route: '/markets#districts',
      },
      {
        name: 'Schools',
        route: '/markets#schools',
      },
      {
        name: 'Educators',
        route: '/markets#educators',
      },
      {
        name: 'Counselors',
        route: '/markets#counselors',
      },
      {
        name: 'Families',
        route: '/markets#families',
      },
    ],
  },
   *
   *
   */
  {
    name: 'Success Stories',
    route: '#testimonialVideos',
    styleName: 'HeaderSuccessStories',
  },
  {
    name: 'Research',
    route: Documents.research,
    styleName: 'HeaderResearch',
  },
  {
    name: 'Resources',
    route: '/resources',
    styleName: 'HeaderResources',
  },
];

const headerComponentsAuthorizedUser = [
  {
    name: 'Resources',
    route: '/resources',
    styleName: 'HeaderResources',
  },
];

interface HeaderComponentProps {
  key: string;
  name: string;
  route: string | (() => void);
  styleName: string;
  hasSubMenu?: boolean;
  subMenu?: {
    name: string;
    route: string;
  }[];
  history: History;
}
const HeaderComponent = ({
  name,
  route,
  styleName,
  history,
  hasSubMenu,
  subMenu,
}: HeaderComponentProps) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  if (!hasSubMenu) {
    return flatComponent();
  }

  return subMenuComponent();

  function flatComponent() {
    return (
      <div
        className={cx(styles.headerButton, styles[styleName])}
        onClick={handleOnClick}
      >
        {name}
      </div>
    );

    function handleOnClick() {
      if (typeof route === 'function') {
        route.call();
      } else if (route.includes('http')) {
        window.open(route, '_blank');
      } else {
        history.push(route);
      }
    }
  }

  function subMenuComponent() {
    return (
      <div
        className={cx(styles.headerButton, styles[styleName])}
        onClick={() => history.push(route as string)}
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
      >
        <span className={styles.description} style={{ cursor: 'pointer' }}>
          {name}
        </span>
        <ExpandableIcon menuToggle={showSubMenu} />
        {showSubMenu && renderSubMenu()}
      </div>
    );
  }

  function renderSubMenu() {
    return (
      <div className={styles.headerClassroomTooltip}>
        {subMenu?.map((sm, index) => {
          return (
            <div
              key={`submenu-${index}`}
              className={styles.tooltipBox}
              onClick={() => history.push(sm.route)}
            >
              {sm.name}
            </div>
          );
        })}
      </div>
    );
  }
};

interface HeaderSupportProps {
  history: History;
}
export class HeaderSupport extends Component<HeaderSupportProps, {}> {
  render(): JSX.Element {
    return (
      <div
        className={`${styles.headerButton} ${styles.HeaderSupport}`}
        onClick={() => {
          showIntercom();
        }}
      >
        <span style={{ paddingLeft: '0.5em' }}>Support</span>
      </div>
    );
  }
}

interface HeaderProfileProps {
  history: History;
  licenseLevel: string;
  licenseExpires: number;
  salutation: string | undefined;
  lastName: string | undefined;
  updateRequired: boolean;
  toggle: boolean;
}
interface HeaderProfileState {
  toggle: boolean;
  toggleHint?: boolean;
}
export class HeaderProfile extends Component<
  HeaderProfileProps,
  HeaderProfileState
> {
  constructor(props: HeaderProfileProps) {
    super(props);

    this.state = {
      toggle: Boolean(this.props.toggle),
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(state?: boolean) {
    this.setState({ toggle: state !== undefined ? state : !this.state.toggle });
    if (this.state.toggleHint) {
      this.toggleHintModal(false);
    }
  }

  toggleHintModal(state?: boolean) {
    if (this.props.updateRequired) {
      this.setState({
        toggleHint: state !== undefined ? state : !this.state.toggleHint,
      });
    }
  }

  renderTooltip(): JSX.Element {
    return (
      <div
        className={cx(styles.headerTooltip, styles.profileTooltip)}
        onMouseLeave={() => this.toggleMenu(false)}
      >
        <div className={styles.licenseStatus}>
          {this.renderSpecialBadge()}
          {this.renderBasicBadge()}
          {this.renderClassroomProBadge()}
          {this.renderProBadge()}
          {this.renderTrialBadge()}
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/library')}
        >
          Library
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/students')}
        >
          Students
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/classroom')}
        >
          Classroom Settings
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/reports')}
        >
          Reports
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/accountSettings')}
          onMouseEnter={() => this.toggleHintModal()}
          onMouseLeave={() => this.toggleHintModal(false)}
        >
          Account Settings
          <span
            className={`${styles.glyphicon} ${styles['glyphicon-exclamation-sign']}`}
            data-show={this.props.updateRequired}
            data-location='tab'
          />
        </div>
        <div
          className={styles.tooltipBox}
          onClick={() => this.props.history.push('/logout')}
        >
          Logout
        </div>
        {isUserHasKey('KEY_PEEKAPAK_MANAGEMENT') ? (
          <div
            className={styles.tooltipBox}
            onClick={() => this.props.history.push('/schoolEditor')}
          >
            School Editor
          </div>
        ) : null}
        {isUserHasAnyKeys(['KEY_MASTER_KEY', 'KEY_WIP']) ? (
          <div
            className={styles.tooltipBox}
            onClick={() => this.props.history.push('/version')}
          >
            Version
          </div>
        ) : null}
      </div>
    );
  }

  renderSpecialBadge(): JSX.Element | null {
    const allSpecialLicenses: Record<string, string> = {
      admin: 'ADMIN',
      classroom_administrator: 'Class Admin',
      developer: 'Dev',
      demo: 'Demo',
      district_administrator: 'District Admin',
      peekapak_sales: 'Sales',
      school: 'School',
    };

    const lcLicense = this.props.licenseLevel.toLowerCase();
    if (Object.keys(allSpecialLicenses).includes(lcLicense)) {
      return (
        <div className={styles.licenseBadge}>
          {allSpecialLicenses[lcLicense]}
        </div>
      );
    }
    return null;
  }

  renderBasicBadge(): JSX.Element | null {
    const allBasicLicenses = [
      'basic',
      'freemium',
      'expired_pro',
      'expired_classroom',
    ];

    if (allBasicLicenses.includes(this.props.licenseLevel.toLowerCase())) {
      return <div className={styles.licenseBadge}>BASIC User</div>;
    }
    return null;
  }

  renderExpiredTrialBadge(): JSX.Element | null {
    const allBasicLicenses = ['expired_trial'];

    if (allBasicLicenses.includes(this.props.licenseLevel.toLowerCase())) {
      return <div className={styles.licenseBadge}>Trial Expired</div>;
    }
    return null;
  }

  renderProBadge(): JSX.Element | null {
    const allProLicenses = ['pro', 'renewed_pro'];

    if (allProLicenses.includes(this.props.licenseLevel.toLowerCase())) {
      return <div className={styles.licenseBadge}>Teacher PRO</div>;
    }
    return null;
  }

  renderClassroomProBadge(): JSX.Element | null {
    const allClassroomLicenses = ['classroom', 'renewed_classroom'];

    if (allClassroomLicenses.includes(this.props.licenseLevel.toLowerCase())) {
      return <div className={styles.licenseBadge}>Peekapak PRO</div>;
    }
    return null;
  }

  renderTrialBadge(): JSX.Element | null {
    if (this.props.licenseLevel.toLowerCase().includes('trial')) {
      const now = Date.now();
      const daysLeft = Math.floor(
        (this.props.licenseExpires - now) / (24 * 3600 * 1000)
      );
      const isTrialExpired = daysLeft < 1;

      return (
        <div className={styles.licenseBadge}>
          {isTrialExpired ? 'Trial Expired' : `${daysLeft} trial days left`}
        </div>
      );
    }
    return null;
  }

  render(): JSX.Element {
    return (
      <div
        className={`${styles.headerButton} ${styles.HeaderProfile}`}
        onClick={() => this.toggleMenu()}
      >
        <div
          className={styles.badgeWrapper}
          data-showexclamation={this.props.updateRequired}
        >
          <span
            className={`${styles.glyphicon} ${styles['glyphicon-exclamation-sign']}`}
            data-show={this.props.updateRequired}
            data-location='header'
          />
        </div>
        <span>
          {`${this.props.salutation} ${this.props.lastName}`}
          <ExpandableIcon menuToggle={this.state.toggle} />
        </span>
        {this.state.toggle ? this.renderTooltip() : null}
      </div>
    );
  }
}

interface ClassroomSelectorProps {
  isAuthenticated: boolean;
  classrooms: MiddleSchoolHeaderProps['classrooms'];
  location: Location;
  setSelectedClassroom: (index: number) => void;
  history: History;
  toggle: boolean;
}
interface ClassroomSelectorState {
  toggle: boolean;
}
export class ClassroomSelector extends Component<
  ClassroomSelectorProps,
  ClassroomSelectorState
> {
  constructor(props: ClassroomSelectorProps) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      toggle: Boolean(this.props.toggle),
    };

    this.handleClassroomChange = this.handleClassroomChange.bind(this);
  }

  toggleMenu(state?: boolean) {
    this.setState({ toggle: state !== undefined ? state : !this.state.toggle });
  }

  getClassroomDescription = () => {
    const { state, list, selectedClassroom } = this.props.classrooms;
    if (state === ClassroomsState.loaded) {
      return getClassroomDescription(list[selectedClassroom]);
    }

    return '';
  };

  render(): JSX.Element | null {
    if (
      !this.props.isAuthenticated ||
      this.props.location.pathname.includes('/classroom')
    ) {
      return null;
    }

    const description = this.getClassroomDescription();
    const isShowChevron = this.props.classrooms.list.length > 1;

    return (
      <div
        className={`${styles.classroomSelector} ${styles.headerSubtitle}`}
        onClick={() => this.toggleMenu()}
        onMouseLeave={() => this.toggleMenu(false)}
      >
        <span
          className={styles.description}
          style={{ cursor: isShowChevron ? 'pointer' : 'default' }}
          id='classroomSelector'
        >
          {description}
        </span>
        {isShowChevron && (
          <ExpandableIcon {...this.props} menuToggle={this.state.toggle} />
        )}
        {this.state.toggle && isShowChevron ? this.renderTooltip() : null}
      </div>
    );
  }

  renderTooltip(): JSX.Element {
    return (
      <div className={styles.headerClassroomTooltip}>
        {this.props.classrooms.list.map((classroom, index) => {
          const description = getClassroomDescription(classroom);

          const isSelectedClassroom =
            index === this.props.classrooms.selectedClassroom;

          if (classroom.isArchived) {
            return null;
          }
          return (
            <div
              key={index}
              className={`${styles.tooltipBox} ${
                isSelectedClassroom ? styles.withCheck : ''
              } ${isSelectedClassroom ? styles.darkText : ''}`}
              onClick={(e) => this.handleClassroomChange(e, index)}
            >
              {description}
            </div>
          );
        })}
      </div>
    );
  }

  handleClassroomChange(e: React.MouseEvent<HTMLDivElement>, newIndex: number) {
    const oldGrade =
      this.props.classrooms.list[this.props.classrooms.selectedClassroom].grade;
    setSelectedClassroom(newIndex);
    const newPath = checkForPathOnClassChange(
      this.props.history.location.pathname,
      oldGrade,
      this.props.classrooms.list[newIndex].grade
    );
    if (newPath === '') return;
    this.props.history.push(newPath);
  }
}

/*
const HeaderButtonComponent = ( { name, newRoute, styleName, history } ) => (
  <div className={ cx( styles.headerButton, styles.headerButtonComponent ) }>
    <Button
      className={ `${ styles[ 'button-transWhite' ] }` }
      onClick={ () => history.push( newRoute ) }
    >
      { name }
    </Button>
  </div>
);
*/

/* List of props which are accepted
 * - left
 * - right
 */

/* Variations of main menu needed
 * - Anonymous Root menu
 * - Anonymous Library menu (Grade on left and signup button on right)
 * - Teacher Menu (Grade and classname on left and notification, help, and profile on right)
 */

type MiddleSchoolHeaderProps = PropsFromRedux & {
  history: History;
  isAuthenticated: boolean;
  location: Location;
  loginState: LoginState;
  onShowGetStartedDialog: () => void;
  params: Record<string, string>;
  isHidePeekaville?: boolean;
  titleOnly?: boolean;
  freeTrial?: boolean;
};
const MiddleSchoolHeader = (props: MiddleSchoolHeaderProps) => {
  const [showChooseRoleDialog, setShowChooseRoleDialog] = useState(false);
  const isAuthenticated = props.loginState === LoginState.loggedIn;

  const detectIfUpdateRequire = () => {
    if (!props.userProfile?.schoolName || !props.userProfile?.schoolName.trim())
      return true;
    if (!props.userProfile?.tel || !props.userProfile?.tel.trim()) return true;
    return false;
  };

  const navigateHome = () => {
    if (isAuthenticated && props.userProfile) {
      sendUserToRoleHomePage(props.userProfile, props.history, false, true);
    } else {
      props.history.push('/');
    }
  };

  const LicenseBadge = ({
    userProfile,
  }: {
    userProfile: MiddleSchoolHeaderProps['userProfile'];
  }) => {
    if (userProfile) {
      const text = getBadgeText(userProfile);
      const postscript = getPostscript(userProfile);

      return (
        <div className={styles.badge}>
          <div
            className={cx(styles.licenseText, {
              [`${styles.proBadgeText}`]: text === 'PRO',
            })}
          >
            {text}
          </div>
          {postscript && (
            <div className={styles.expiryNotice}>{postscript}</div>
          )}
        </div>
      );
    }

    return null;

    function getBadgeText(userProfile: UserProfileType) {
      let text = '';
      const { licenseLevel } = userProfile;

      if (licenseLevel.includes('TRIAL')) {
        text = `PRO TRIAL`;
      } else if (!canUpgrade(licenseLevel)) {
        text = 'PRO';
      } else if (
        canUpgradeToClassroom(licenseLevel) &&
        !canUpgradeToPro(licenseLevel)
      ) {
        text = 'Teacher PRO';
      } else {
        text = 'BASIC';
      }

      return text;
    }

    function getPostscript(userProfile: UserProfileType) {
      let postscript = '';
      const now = Date.now();
      const daysLeft = Math.floor(
        (userProfile.licenseExpires - now) / (24 * 3600 * 1000)
      );
      if (daysLeft < 11 && daysLeft > 0) {
        postscript = `${daysLeft} days left`;
      }

      return postscript;
    }
  };

  const renderTitle = () => (
    <div
      className={`${styles.headerTitle} ${styles.headerButton}`}
      onClick={navigateHome}
    >
      <div className={styles.headerLogoPicture}>
        <img alt='backpack' src={backpack} />
      </div>
      <div className={styles.headerLogoText}>
        <div>Peekapak</div>
        <LicenseBadge userProfile={props.userProfile} />
      </div>
    </div>
  );

  const renderAuthBar = () => {
    if (!props.userProfile) {
      throw new Error('userProfile is undefined');
    }
    return (
      <div className={styles.headerAuthBar}>
        {headerComponentsAuthorizedUser.map((c, index) => (
          <HeaderComponent
            key={`headercomponent-${index}`}
            name={c.name}
            route={c.route}
            styleName={c.styleName}
            hasSubMenu={c.hasSubMenu}
            subMenu={c.subMenu}
            history={props.history}
          />
        ))}
        <HeaderSupport history={props.history} />
        <HeaderProfile
          history={props.history}
          licenseLevel={props.userProfile.licenseLevel}
          licenseExpires={props.userProfile.licenseExpires}
          salutation={props.userProfile.salutation}
          lastName={props.userProfile.lastName}
          updateRequired={detectIfUpdateRequire()}
        />
        <NotificationBell
          messages={props.messages.list}
          messageMark={props.messages.messageMark}
          history={props.history}
        />
        <HeaderUpgradeButton
          licenseLevel={props.userProfile.licenseLevel}
          pathname={props.location.pathname}
          history={props.history}
        />
      </div>
    );
  };

  const renderAnonBar = () => {
    return (
      <div className={styles.headerAuthBar}>
        {headerComponentsUnauthorizedUser.map((c, index) => (
          <HeaderComponent
            key={`headercomponent-${index}`}
            name={c.name}
            route={c.route}
            styleName={c.styleName}
            hasSubMenu={c.hasSubMenu}
            subMenu={c.subMenu}
            history={props.history}
          />
        ))}
        <HeaderComponent
          key={`headercomponent-login`}
          name='Login'
          route={() => setShowChooseRoleDialog(true)}
          styleName='headerLogin'
          hasSubMenu={false}
          history={props.history}
        />
        <HeaderComponent
          key={`headercomponent-signup`}
          name='Sign Up'
          route={'/signup'}
          styleName='headerSignup'
          hasSubMenu={false}
          history={props.history}
        />
        {/*
        <HeaderButtonComponent
          name='Request a Demo'
          route='/requestDemo'
          styleName='requestDemo'
          history={ props.history }
        />
        */}
        <HeaderRequestInfoButton />
      </div>
    );
  };

  const onHideChooseDialog = () => setShowChooseRoleDialog(false);

  const onChooseDialogSelect = (selection: string) => {
    onHideChooseDialog();

    switch (selection) {
      case 'Student':
        window.location.href = 'https://www.mypeekaville.com';
        break;
      case 'Parent':
        props.history.push('/parents');
        break;
      default:
        props.history.push('/login');
        break;
    }
  };

  if (props.titleOnly) {
    return (
      <div className={styles.Header} id='top'>
        <div className={`${styles.headerDesktop} d-none d-lg-flex`}>
          <div className={styles.headerLeft}>{renderTitle()}</div>
          <div className={styles.headerRight} />
        </div>
        <div className={`${styles.headerMobile} d-flex d-lg-none`}>
          <div className={styles.headerLeft}>{renderTitle()}</div>
          <div className={styles.headerRight} />
        </div>
      </div>
    );
  }

  if (props.freeTrial) {
    return (
      <div className={styles.Header} id='top'>
        <div className={`${styles.headerDesktop} d-none d-lg-flex`}>
          <div className={styles.centredTitle}>
            Sign up for a free 14-day trial
          </div>
        </div>
        <div className={`${styles.headerMobile} d-flex d-lg-none`}>
          <div className={styles.centredTitle}>
            Sign up for a free 14-day trial
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Header} id='top'>
      <div className={`${styles.headerDesktop} d-none d-lg-flex`}>
        <div className={styles.headerLeft}>
          {renderTitle()}
          <ClassroomSelector
            isAuthenticated={isAuthenticated}
            classrooms={props.classrooms}
            location={props.location}
            setSelectedClassroom={setSelectedClassroom}
            history={props.history}
          />
        </div>
        <div className={styles.headerRight}>
          {isAuthenticated ? renderAuthBar() : renderAnonBar()}
        </div>
      </div>
      <div className={`${styles.headerMobile} d-flex d-lg-none`}>
        <div className={styles.headerLeft}>{renderTitle()}</div>
        <div className={styles.headerRight}>
          <HeaderSidebar
            {...props}
            setSelectedClassroom={setSelectedClassroom}
          />
        </div>
      </div>
      <ChooseRoleDialog
        isShow={showChooseRoleDialog}
        onHide={onHideChooseDialog}
        handlerOnSelect={onChooseDialogSelect}
      />
    </div>
  );
};

interface ExpandableIconProps {
  menuToggle: boolean;
}
function ExpandableIcon(props: ExpandableIconProps) {
  if (props.menuToggle) {
    return (
      <img
        alt='Chevron'
        className={styles.headerExpandable}
        src={chevronUp}
        aria-hidden='true'
      />
    );
  }
  return (
    <img
      alt='Down Chevron'
      className={styles.headerExpandable}
      src={chevronDown}
      aria-hidden='true'
    />
  );
}

interface HeaderUpgradeButtonProps {
  licenseLevel: string;
  pathname: string;
  history: History;
}
function HeaderUpgradeButton({
  licenseLevel,
  pathname,
  history,
}: HeaderUpgradeButtonProps) {
  if (!canUpgradeToClassroom(licenseLevel) || getLicenseLevel() === 'PRO') {
    return null;
  }

  if (pathname.includes('/features')) {
    return (
      <div className={`${styles.headerButton} ${styles.upgradeButton}`}>
        <Button className={` ${styles['button-transWhite']}`}>Upgrade</Button>
      </div>
    );
  }

  return (
    <div className={`${styles.headerButton} ${styles.invertedUpgradeButton}`}>
      <Button
        className={` ${styles['button-transWhite']}`}
        onClick={() => history.push('/features')}
      >
        Upgrade
      </Button>
    </div>
  );
}

function HeaderRequestInfoButton() {
  const history = useHistory();
  /*
  return (
    <div className={`${styles.headerButton} ${styles.invertedUpgradeButton}`}>
      <Button
        className={` ${styles['button-transWhite']}`}
        onClick={() => window.open(Documents.requestInfoAndQuote, '_blank')}
      >
        Request Info
      </Button>
    </div>
  );
  */
  return (
    <PrimaryButton
      className={cx(styles.headerButton, styles.invertedUpgradeButton)}
      small
      white
      onClick={() => history.push('/requestInfo')}
    >
      Request Info
    </PrimaryButton>
  );
}

const mapStateToProps = (state: RootState) => ({
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  messages: {
    state: state.messages.state,
    list: state.messages.messages,
    messageMark: state.messages.messageMark,
  },
  loginState: state.user.loginState,
  userProfile: state.user.userProfile,
  cognitoProfile: state.user.cognitoProfile,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MiddleSchoolHeader);
