import { useEffect, useState, Fragment, useReducer } from 'react';
import { setNestedObjectValues, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { getPublicMediaRoot } from '../../serviceAgent';
import Button from '../Button';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { shuffleArray } from '../../GlobalFunctions';
import { saveAssignment, submitAssignment } from '../../BackendInterface';
import { InlineIcon } from '@iconify/react';
import eyeOutline from '@iconify-icons/ion/eye-outline';
import useAutoSave from './useAutoSave';
import MultipleChoiceQuestions from './MultipleChoiceQuestions';
import TextInputQuestion from './TextInputQuestion';
import {
  QuestionAnswer,
  StrengthsEvaluationMetawork,
  UrlParams,
  Assignment,
  StrengthsEvaluationWork,
  EmbeddedContent,
  StrengthsAnswer,
} from '../../../peekapak-types/LessonPlanTypes';
import styles from './ToDoStrengthsEvaluation.module.scss';
import libraryStyles from '../../SCSS/Library.module.scss';

interface StudentWork extends Assignment {
  userId: string;
  createdAt: number;
  work: {
    content: StrengthsEvaluationWork[];
  };
  metawork: StrengthsEvaluationMetawork;
}

interface StrengthsQuestions {
  index: number;
  question1: string;
  question2: string;
  strength1: string;
  strength2: string;
}

interface StrengthsExplanation {
  strength: string;
  description: string;
}

interface StrengthsEvaluation extends EmbeddedContent {
  questions: StrengthsQuestions[];
  tiebreakerQuestions: StrengthsQuestions[];
  content: StrengthsExplanation[];
}

interface Props {
  data: StrengthsEvaluation;
  studentWork: StudentWork;
  textAnswer: QuestionAnswer[];
  updateAssignment: (assignment: Assignment) => void;
}

type FormValues = {
  // value is the index [0..3] of the answer in string form
  [index: string]: string;
};

const ToDoStrengthsEvaluation = ({
  data,
  studentWork,
  updateAssignment,
  textAnswer,
}: Props): JSX.Element => {
  const [displayQuestions, setDisplayQuestions] = useState<
    StrengthsQuestions[]
  >([]);
  const [tiebreakerDisplay, setTiebreakerDisplay] = useState<
    StrengthsQuestions[]
  >([]);
  const [isShowSpinner, setShowSpinner] = useState(false);
  const [assessmentText, setAssessmentText] = useState<StrengthsExplanation>();
  const [questionType, setQuestionType] = useState('');
  const [keyStrengths, setKeyStrengths] = useState<string[] | []>([]);
  const [filteredTiebreaks, setFilteredTiebreaks] = useState<
    StrengthsQuestions[] | []
  >([]);
  const [localStudentWork, setLocalStudentWork] =
    useState<StudentWork>(studentWork);
  const [isRadioDisabled, setIsRadioDisabled] = useState(false);
  const [delay, setDelay] = useState(0);
  const [isShowResults, setIsShowResults] = useState(false);
  const [isEnableSubmit, setIsEnableSubmit] = useState(true);

  const { activity, toDo, grade, language } = useParams<UrlParams>();
  const history = useHistory();
  const isTeacher = isNaN(parseInt(toDo));

  const UnitAndAllStrengthsFileMap: { [key: string]: string } = {
    hsidentity: 'hs-u1-strengths.pdf',
    'hsidentity-standard': 'hs-u1s-strengths.pdf',
    hsstress: 'hs-u2s-strengths.pdf',
    hsdecision: 'hs-u3s-strengths.pdf',
    hsbelonging: 'hs-u4s-strengths.pdf',
    hsempathy: 'hs-u5s-strengths.pdf',
  };

  const getAllStrengthsFileByUnit = () => {
    const standardUnit = Object.keys(UnitAndAllStrengthsFileMap).find((key) => {
      if (activity.includes(key) && activity.includes('standard')) {
        return true;
      }
    });
    const nonStandardUnit = Object.keys(UnitAndAllStrengthsFileMap).find(
      (key) => {
        if (activity.includes(key) && !activity.includes('standard')) {
          return true;
        }
      }
    );
    const unit = standardUnit ? `${standardUnit}-standard` : nonStandardUnit;
    const fileLink = unit
      ? `${getPublicMediaRoot()}resourcesHighSchool/handouts/${
          UnitAndAllStrengthsFileMap[unit]
        }`
      : '';
    return fileLink;
  };

  const calculateScore = (values: FormValues) => {
    const answerArray = Object.entries(values).reduce(
      (acc, [k, v]) => [
        ...acc,
        {
          index: parseInt(k),
          answerIndex: parseInt(v),
        },
      ],
      [] as { index: number; answerIndex: number }[]
    );
    const strengthsObject = data.questions.reduce(
      (acc: { [key: string]: number }, curr: StrengthsQuestions) => {
        const thisIndex = answerArray.find((item) => item.index === curr.index);
        if (!thisIndex || isNaN(thisIndex?.answerIndex)) return acc;
        if (thisIndex.answerIndex === 0)
          return acc?.[curr.strength1]
            ? { ...acc, [curr.strength1]: acc[curr.strength1] + 2 }
            : { ...acc, [curr.strength1]: 2 };
        if (thisIndex.answerIndex === 1)
          return acc?.[curr.strength1]
            ? { ...acc, [curr.strength1]: acc[curr.strength1] + 1 }
            : { ...acc, [curr.strength1]: 1 };
        if (thisIndex.answerIndex === 2)
          return acc?.[curr.strength2]
            ? { ...acc, [curr.strength2]: acc[curr.strength2] + 1 }
            : { ...acc, [curr.strength2]: 1 };
        if (thisIndex.answerIndex === 3)
          return acc?.[curr.strength2]
            ? { ...acc, [curr.strength2]: acc[curr.strength2] + 2 }
            : { ...acc, [curr.strength2]: 2 };
        return acc;
      },
      {} as { [key: string]: number }
    );
    const strengths = Object.keys(strengthsObject).filter((x) => {
      return (
        strengthsObject[x] ==
        Math.max.apply(null, Object.values(strengthsObject))
      );
    });

    console.debug(
      '%c',
      'color: #00e600; background-color: purple',
      '-------------------------------------------',
      new Date(Date.now()).toString()
    );
    console.debug('%c', 'color: #00e600; background-color: purple', strengths);
    console.debug(
      '%c',
      'color: #00e600; background-color: purple',
      strengthsObject
    );
    console.debug(
      '%c',
      'color: #00e600; background-color: purple',
      answerArray
    );

    return strengths;
  };

  const getQuestions = (questions, questionOrder) => {
    if (questionOrder) {
      return questionOrder.reduce((acc, curr) => {
        const question = questions.find((item) => item.index === curr);
        if (question) {
          return [...acc, question];
        }
        return acc;
      }, [] as StrengthsQuestions[]);
    }

    const shuffledQuestions = shuffleArray(questions);

    return shuffledQuestions;
  };

  const initialValues: FormValues = (() => {
    if (localStudentWork?.work?.content[0]?.content) {
      const studentWorkToValues =
        localStudentWork.work.content[0].content.reduce((acc, curr) => {
          if (curr.index === 200)
            return { ...acc, [curr.index]: curr.answerText };
          return {
            ...acc,
            [curr.index]:
              curr.answerIndex === null ? '' : curr.answerIndex.toString(),
          };
        }, {} as FormValues);
      return studentWorkToValues;
    }

    const questions = data.questions.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.index]: '',
      }),
      {} as FormValues
    );

    const tiebreakerQuestions = data.tiebreakerQuestions.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.index]: '',
      }),
      {} as FormValues
    );

    return {
      ...questions,
      ...tiebreakerQuestions,
    };
  })();

  const initialTextValue = (() => {
    if (localStudentWork?.work?.content[0]?.content) {
      const found = localStudentWork.work.content[0].content.find(
        (item) => item.index === 200
      );

      return found ? found.answerText : '';
    }

    return '';
  })();

  const initialFormValues = {
    ...initialValues,
    200: initialTextValue,
  } as FormValues;

  const getStrengthText = (keyStrength: string) =>
    data.content.find((item) => item.strength === keyStrength) || {
      strength: '',
      description: '',
    };

  const multipleChoiceSchema = data?.questions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.index]: Yup.string().required(
        'Please pick an answer for all questions'
      ),
    }),
    {}
  );

  const validationSchema = Yup.object().shape(multipleChoiceSchema);

  const handleRadioChange = async (
    values: FormValues,
    index: number,
    value: string,
    questionType: string,
    actions: FormikHelpers<FormValues>
  ) => {
    setDelay(0);
    if (questionType === 'question') {
      setQuestionType(questionType);
      dispatch({ [index]: value });
      handleCheckForTies({ ...values, [index]: value });
    }
    if (questionType === 'tiebreaker') {
      const validationErrors = await actions.validateForm();
      await actions.setFieldValue(index.toString(), value);

      if (Object.keys(validationErrors).length > 0) {
        actions.setTouched(setNestedObjectValues(validationErrors, true));
        return;
      }
      setQuestionType(questionType);
      dispatch({ [index]: value });
      handleCheckForTies({ ...values, [index]: value });
    }
  };

  const handleShowResultsClick = async (
    values,
    actions: FormikHelpers<FormValues>
  ) => {
    const validationErrors = await actions.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      actions.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
    try {
      setIsShowResults(false);
      await handleCheckForTies(values);
    } catch (e) {
      console.error('could not show results - error :: ', e);
    } finally {
      setIsShowResults(true);
    }
  };

  const handleCheckForTies = async (values: FormValues) => {
    const strengths = await calculateScore(values);
    let tiebreakers = [] as StrengthsQuestions[];
    if (keyStrengths?.length === 0) setKeyStrengths(strengths);
    try {
      setIsShowResults(false);
      setIsEnableSubmit(false);
      if (strengths.length === 1) {
        setKeyStrengths(strengths);
        return;
      } else if (strengths.length === 2) {
        const selectedStrength = await handleTiedAnswer(
          values,
          filteredTiebreaks[0],
          strengths
        );
        tiebreakers = filterStrengths(tiebreakerDisplay, strengths);
        setKeyStrengths(selectedStrength);
        return;
      } else if (strengths.length === 3) {
        const selectedStrengths = await handleTiedAnswer(
          values,
          tiebreakerDisplay[0],
          strengths
        );
        const filtered = selectedStrengths
          ? filterStrengths(tiebreakerDisplay, selectedStrengths)
          : [tiebreakerDisplay[0]];
        if (
          values[tiebreakerDisplay[0].index] === '' ||
          values[tiebreakerDisplay[0].index] === null ||
          values[tiebreakerDisplay[0].index] === undefined
        ) {
          tiebreakers = filtered;
        }
        if (
          values[tiebreakerDisplay[0].index] !== '' &&
          values[tiebreakerDisplay[0].index] !== null &&
          values[tiebreakerDisplay[0].index] !== undefined
        ) {
          setKeyStrengths(selectedStrengths);
          tiebreakers = [tiebreakerDisplay[0], ...filtered];
        }
        if (
          values[filtered[0].index] !== '' &&
          values[filtered[0].index] !== null &&
          values[filtered[0].index] !== undefined
        ) {
          const selectedStrength = await handleTiedAnswer(
            values,
            filtered[0],
            selectedStrengths
          );
          setKeyStrengths(selectedStrength);
        }
        return;
      }
    } catch {
      console.error('could not calculate scores');
    } finally {
      setFilteredTiebreaks(tiebreakers);
      const isAllQuestionsAnswered = displayQuestions.every(
        (item) => values[item.index] !== ''
      );
      const isAllTiebreakersAnswered = tiebreakers.every(
        (item) => values[item.index] !== ''
      );
      if (!tiebreakers.length && isAllQuestionsAnswered) {
        setIsEnableSubmit(true);
      } else if (tiebreakers.length > 0 && isAllQuestionsAnswered) {
        if (isAllTiebreakersAnswered) {
          setIsEnableSubmit(true);
        } else {
          setIsEnableSubmit(false);
        }
      } else {
        setIsEnableSubmit(false);
      }
    }
  };

  const handleTiedAnswer = async (
    values: FormValues,
    question: StrengthsQuestions,
    strengths: string[] | undefined = keyStrengths
  ) => {
    if (!strengths) return;
    try {
      const answerValue = Object.entries(values).find(
        (item) => parseInt(item[0]) === question.index
      );
      let tiedStrengthsCopy = [...strengths];
      if (parseInt(answerValue?.[1]) < 2) {
        tiedStrengthsCopy = tiedStrengthsCopy.filter(
          (item) => item !== question.strength2
        );
        return tiedStrengthsCopy;
      }
      if (parseInt(answerValue?.[1]) > 1) {
        tiedStrengthsCopy = tiedStrengthsCopy.filter(
          (item) => item !== question.strength1
        );
        return tiedStrengthsCopy;
      }
    } catch {
      console.error('could not resolve tie(s)');
    }
  };

  const filterStrengths = (arr: StrengthsQuestions[], strengths: string[]) => {
    const filtered = arr.filter(
      (item) =>
        strengths.includes(item.strength1) && strengths.includes(item.strength2)
    );
    return filtered;
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setShowSpinner(true);

    try {
      const mcValueArray = Object.entries(values).reduce(
        (p, [k, v]) => [
          ...p,
          parseInt(k) === 200
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ],
        [] as (StrengthsAnswer | { index: number; answerText: string })[]
      );
      const multipleChoiceWork: StrengthsEvaluationWork = {
        type: 'strengthsEvaluation',
        index: data.index,
        content: mcValueArray,
      };

      const questionOrder = displayQuestions.map((item) => item.index);
      const tiebreakerOrder = filteredTiebreaks.map((item) => item.index);

      const metawork = {
        type: 'strengthsEvaluation',
        questionOrder,
        tiebreakerOrder,
      };

      const submitResponse = await submitAssignment(
        localStudentWork.userId,
        parseInt(toDo),
        [multipleChoiceWork],
        metawork
      );
      console.info(`result = ${JSON.stringify(submitResponse, null, 2)}`);
      console.log('submitted via submit - strengths');
      actions.setSubmitting(false);
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
    } finally {
      setShowSpinner(false);
      history.push(
        `/studentportal/activity/${activity}/${language}/${grade}/toDo/${toDo}/confirmation`
      );
    }
  };

  const handleSave = async (values: FormValues) => {
    setIsRadioDisabled(true);
    await saveAndUpdate();

    async function saveAndUpdate() {
      const currentValueArray = Object.entries(values).reduce((p, [k, v]) => {
        return [
          ...p,
          parseInt(k) === 200
            ? {
                index: parseInt(k),
                answerText: v,
              }
            : {
                index: parseInt(k),
                answerIndex: parseInt(v),
              },
        ];
      }, [] as (StrengthsAnswer | { index: number; answerText: string })[]);

      let valueArray = [];
      try {
        if (questionType === 'question') {
          valueArray = currentValueArray.map((item) => {
            if (item.index > 200) {
              return {
                index: item.index,
                answerIndex: '',
              };
            } else {
              return item;
            }
          });
        } else if (questionType === 'tiebreaker') {
          valueArray = currentValueArray;
        }
        const questionOrder = displayQuestions.map((item) => item.index);
        const tiebreakerOrder = tiebreakerDisplay.map((item) => item.index);

        const multipleChoiceWork = {
          type: 'multipleChoiceAnswerGroup',
          index: data.index,
          content: valueArray,
        };
        const metawork = {
          type: 'StrengthsEvaluation',
          questionOrder,
          tiebreakerOrder,
        };

        await saveAssignment(
          localStudentWork.userId,
          parseInt(toDo),
          [multipleChoiceWork],
          metawork
        );

        console.log('submitted via save - strengths');

        updateAssignment({
          userId: localStudentWork.userId,
          createdAt: parseInt(toDo),
          work: { content: [multipleChoiceWork] },
          metawork,
        });
      } catch (error) {
        console.info(`error is ${JSON.stringify(error, null, 2)}`);
      } finally {
        setIsRadioDisabled(false);
      }
    }
  };

  const reducer = (state: FormValues, action: FormValues) => {
    return { ...state, ...action };
  };
  const [state, dispatch] = useReducer(reducer, initialFormValues);
  const savingState = useAutoSave(state, handleSave, delay);

  useEffect(() => {
    const questions = getQuestions(
      data.questions,
      localStudentWork?.metawork?.questionOrder
    );
    const questionOrder = questions.map((item) => item.index);
    const tiebreakers = getQuestions(
      data.tiebreakerQuestions,
      localStudentWork?.metawork?.tiebreakerOrder
    );
    const tiebreakerOrder = tiebreakers.map((item) => item.index);
    setDisplayQuestions(questions);
    setTiebreakerDisplay(tiebreakers);

    setLocalStudentWork({
      ...localStudentWork,
      metawork: {
        ...localStudentWork?.metawork,
        questionOrder,
        tiebreakerOrder,
      },
    });
  }, []);

  useEffect(() => {
    if (keyStrengths && keyStrengths.length === 1) {
      const text = getStrengthText(keyStrengths[0]);
      setAssessmentText(text);
    }
  }, [keyStrengths]);

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      <Formik
        key='multipleChoice'
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          touched,
          isValid,
          values,
          setTouched,
          validateForm,
          setFieldValue,
        }) => {
          return (
            <Form key='multipleChoice form'>
              <MultipleChoiceQuestions
                questions={displayQuestions}
                isRadioDisabled={isRadioDisabled}
                onRadioChange={(values, index, value) => {
                  handleRadioChange(values, index, value, 'question', {
                    validateForm,
                    setTouched,
                    setFieldValue,
                  });
                }}
              />
              {filteredTiebreaks.length > 0 && (
                <>
                  <h3>Almost Done!</h3>
                  <MultipleChoiceQuestions
                    questions={filteredTiebreaks}
                    isRadioDisabled={isRadioDisabled}
                    onRadioChange={(values, index, value) => {
                      handleRadioChange(values, index, value, 'tiebreaker', {
                        validateForm,
                        setTouched,
                        setFieldValue,
                      });
                    }}
                  />
                </>
              )}
              <Button
                type='button'
                thinText
                whiteBorder
                disabled={isTeacher}
                onClick={() =>
                  handleShowResultsClick(values, {
                    validateForm,
                    setTouched,
                  })
                }
              >
                <InlineIcon
                  icon={eyeOutline}
                  width={18}
                  style={{
                    marginRight: '0.5em',
                    transform: 'translateY(2px)',
                  }}
                />
                Show Results
              </Button>
              <div>
                {isEnableSubmit && isShowResults && assessmentText && (
                  <div className={styles.assessmentContainer}>
                    <h3>
                      <u>Your Strength is: {assessmentText?.strength}</u>
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: assessmentText?.description
                          ? assessmentText.description
                          : '',
                      }}
                    />
                    <p className={styles.allStrengthsLink}>
                      To view all strengths,{' '}
                      <a
                        className={libraryStyles.textLink}
                        href={getAllStrengthsFileByUnit()}
                        target='_blank'
                        rel='noreferrer'
                      >
                        click here
                      </a>
                      .
                    </p>
                    <div className={styles.reflections}>
                      <div className={styles.textInput}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: textAnswer[0].question,
                          }}
                        />
                      </div>
                      <TextInputQuestion
                        onInputChange={(name, value) => {
                          dispatch({ [name]: value });
                          setDelay(1000);
                        }}
                      />
                      <Button
                        type='submit'
                        darkBlue
                        thinText
                        disabled={isTeacher}
                      >
                        Submit Your Responses&nbsp;&nbsp;&rarr;
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {!isValid &&
                Object.keys(touched).length ===
                  Object.keys(initialFormValues).length && (
                  <div className={styles.globalError}>
                    Unable to submit, please check your answers and try again
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ToDoStrengthsEvaluation;
