import { useParams } from 'react-router-dom';
import { getPublicMediaRoot } from '../serviceAgent';
import MaterialCard from '../components/MaterialCard';
import styles from './ModuleContent.module.scss';

interface ActivityProps {
  title: string | undefined;
  time: string | undefined;
  teacherContent: any;
  studentContent: any;
  moduleContent: any;
  id: string;
}
interface urlParams {
  language: string;
  grade: string;
}

const ModuleContent = ({ id, title, time, teacherContent, studentContent, moduleContent }: ActivityProps) => {
  const { language, grade }: urlParams = useParams();

  const videoContent = studentContent.map((n: { content: Record<string, unknown>[] }) =>
    n.content?.find((item: Record<string, unknown>) => item.type === 'video')
  );

  const handoutContent = teacherContent.reduce(
    (acc: any, curr: { content: { type: string }[] }) =>
      curr.content[0]?.type === 'handout' ? [...acc, curr] : [...acc],
    []
  );

  const getButtonText = (item: Record<string, any>) => {
    if (item.content?.some((item: Record<string, any>) => item.type === 'slideDeck')) return 'Open Slideshow';
    if (item.content?.some((item: Record<string, any>) => item.type === 'imageCaptureBlock')) return 'View Activity';
    if (item.content?.some((item: Record<string, any>) => item.type === 'video')) return 'Watch Video';
    if (item.content?.some((item: Record<string, any>) => item.type === 'handout')) return 'View PDF';
    if (item.content?.some((item: Record<string, any>) => item.type === 'checkInTool')) return 'View Tool';
    return 'View Questions';
  };

  const getLinkURL = (item: Record<string, any>) => {
    const levelPrefix = parseInt(grade) > 8 ? 'highSchool' : 'middleSchool';

    if (item.content?.some((item: Record<string, any>) => item.type === 'slideDeck')) {
      if (item.id) {
        return `/${levelPrefix}Slides/${id}/${language}/${grade}/content/${item.id}`;
      }

      return `/${levelPrefix}Slides/${id}/${language}/${grade}`;
    }

    if (item.content?.some((item: Record<string, any>) => item.type === 'video')) {
      return item.content.find((item: Record<string, any>) => item.type === 'video').id;
    }

    if (item.content?.some((item: Record<string, any>) => item.type === 'handout')) {
      return item.fileName;
    }

    return `/studentportal/activity/${id}/${language}/${grade}/toDo/${item.id}`;
  };

  const teacherContentBasedOnGrade = teacherContent.filter(
    (item: any) => item.content.length && item.content[0].grade.includes(+grade)
  );

  const topic = teacherContentBasedOnGrade[0]?.content[0]?.topic;

  return (
    <div className={styles.container}>
      <h3>{title}</h3>
      {topic && <h4 className={styles.subheading}>Topics: {topic}</h4>}
      {time && <h4 className={styles.subheading}>Time: {time}</h4>}
      <div
        className={styles.textBlock}
        dangerouslySetInnerHTML={{
          __html: teacherContentBasedOnGrade[0]?.content[0]?.content,
        }}
      />

      <h3>Student Assignments + References Materials</h3>
      <div className={styles.materialsContainer}>
        {!!moduleContent && (
          <MaterialCard
            title={moduleContent[0]?.title}
            image={
              moduleContent.representativeImage
                ? moduleContent.representativeImage
                : `${getPublicMediaRoot()}images/secondary/ToDoList Item_Story Assignment.png`
            }
            buttonText={'Read Story'}
            linkURL={`/studentportal/activity/${id}/${language}/${grade}/toDo/${moduleContent.id}`}
            type={'textBlock'}
          />
        )}
        {studentContent.map((item: Record<string, any>, index: number) => {
          const imgPath = `${getPublicMediaRoot()}images/secondary/${item.representativeImage}`;
          return (
            <MaterialCard
              key={`${item.title} ${index}`}
              title={item.title || item.content[0]?.title}
              image={imgPath}
              buttonText={getButtonText(item)}
              linkURL={getLinkURL(item)}
              type={item.type || item.content[0]?.type}
            />
          );
        })}
        {videoContent && videoContent[0] !== undefined && (
          <MaterialCard
            title={videoContent[0]?.title}
            image={`${getPublicMediaRoot()}images/secondary/${studentContent[0].representativeImage}`}
            buttonText={'Watch Video'}
            linkURL={videoContent[0]?.id}
            type={'video'}
          />
        )}
        {handoutContent &&
          handoutContent[0] !== undefined &&
          handoutContent.map(
            (
              handout: {
                title: string;
                representativeImage: string;
                type: string;
                fileName: string;
              },
              index: number
            ) => {
              return (
                <MaterialCard
                  key={`${handout.title} ${index}`}
                  title={handout.title}
                  image={`${getPublicMediaRoot()}images/secondary/${handout.representativeImage}`}
                  buttonText={'View PDF'}
                  linkURL={getLinkURL(handout)}
                  type={'handout'}
                />
              );
            }
          )}
      </div>
    </div>
  );
};

export default ModuleContent;
