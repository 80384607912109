import ExternalProviderButton from './ExternalProviderButton';
import classLinkLogo from '../../images/logos/logo-classlink-white.png';
import styles from './ExternalProviderButton.module.scss';

interface Props {
  onClick: () => unknown;
  disable?: boolean;
}

const ClassLinkSignUpButton = (props: Props): JSX.Element => (
  <ExternalProviderButton
    className={styles.classLinkButton}
    onClick={props.onClick}
    disable={props.disable}
  >
    <img
      className={styles.logoImage}
      src={classLinkLogo}
      alt='classlink logo'
    />
    Register with ClassLink
  </ExternalProviderButton>
);

export default ClassLinkSignUpButton;
