import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum State {
  notLoaded = 'NOT_LOADED',
  loaded = 'LOADED',
  loading = 'LOADING',
}
export enum ActionType {
  LOAD = 'loadUnit',
  UNLOAD = 'unloadUnit',
  START_LOAD = 'startLoad',
}

interface LessonsState {
  state: State;
  unit: Record<string, unknown> | undefined;
}

export type LoadUnitPayload = Record<string, unknown>;

export const initialState: LessonsState = {
  state: State.notLoaded,
  unit: undefined,
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    [ActionType.LOAD]: (
      state: LessonsState,
      action: PayloadAction<LoadUnitPayload>
    ) => {
      state.state = State.loaded;
      state.unit = action.payload;
    },
    [ActionType.UNLOAD]: (state: LessonsState) => {
      state.state = State.notLoaded;
      state.unit = undefined;
    },
    [ActionType.START_LOAD]: (state: LessonsState) => {
      state.state = State.loading;
    },
  },
});

export const { loadUnit, unloadUnit, startLoad } = lessonsSlice.actions;

export default lessonsSlice.reducer;
