import { ErrorMessage, Field, useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import { Icon } from '@iconify/react';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import quillStyles from '../../SCSS/customBubble.scss';
import styles from './ToDoStrengthsEvaluation.module.scss';

interface ManualField {
  field: { name: string; value: string };
}
interface Props {
  onInputChange: (name: string, value: string) => void;
}
const TextInputQuestion = ({ onInputChange }: Props): JSX.Element => {
  const { errors, touched, setFieldValue } = useFormikContext();
  return (
    <div className={quillStyles}>
      <Field name='200'>
        {({ field }: ManualField) => {
          const { name, value } = field;
          return (
            <>
              <ReactQuill
                theme='bubble'
                value={value || ''}
                onChange={(v) => {
                  setFieldValue(name, v, false);
                  onInputChange(name, v);
                }}
                modules={{
                  keyboard: { bindings: { tab: false } },
                }}
              />
              {errors['200' as keyof typeof errors] &&
                touched['200' as keyof typeof touched] && (
                  <div className={styles.textErrors}>
                    <Icon
                      icon={alertCircleFill}
                      color='#ff6319'
                      className={styles.icon}
                      width={18}
                    />
                    <ErrorMessage name='200' />
                  </div>
                )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default TextInputQuestion;
