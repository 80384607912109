import { Component } from 'react';
import { NormalHeader, Text } from '../core/core';
import { showDurationNumberStandards } from './UtilityFunctions';
import StepHeaderButton from './StepHeaderButton';
import { SkipToNextStepButton } from './SkipToNextStepButton';
import styles from '../SCSS/lpMain.module.scss';

export class StepHeader extends Component {
  render() {
    return (
      <div className={styles.stepHeader} id='stepHeader'>
        <div
          className={styles.stepHeaderImage}
          style={{ backgroundImage: 'url(' + this.props.imgUrl + ')' }}
        />
        <div className={styles.stepHeaderContent}>
          <div className={styles.stepHeaderLabel}>{this.props.label}</div>
          <NormalHeader
            className={styles.stepHeaderTitle}
            text={this.props.title}
          />
          <Text
            className={styles.stepHeaderCoreStandards}
            text={showDurationNumberStandards(this.props)}
          />
          <StepHeaderButton {...this.props} />
          <SkipToNextStepButton {...this.props} />
        </div>
      </div>
    );
  }
}
