import { Component } from 'react';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import { connect, ConnectedProps } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getPublicMediaRoot } from '../serviceAgent';
import { Button, Col, Row } from 'react-bootstrap';
import { fixedEncodeURIComponent } from '../GlobalFunctions';
import ReadStoryButton from './ReadStoryButton';
import { StepHeader } from './StepHeader';
import { isUserHasKey } from '../GlobalFunctions';
import { getStoryUrl, openStep, printLesson } from './UtilityFunctions';
import { openResource } from './lessonPlanComponents';
import { getPossessiveForm } from '../GlobalFunctions';
import UnitClassActivityContent from './UnitClassActivityContent';
import UnitHomeActivityContent from './UnitHomeActivityContent';
import UnitMyPeekavilleActivityContent from './UnitMyPeekavilleActivityContent';
import { LockedContentMessage } from './LockedContentMessage';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import { LockIcon } from './LockIcon';
import { VideoPlaceholder } from '../anonymous/VideoPlaceholder';
import {
  selectClassroom,
  selectCompletionStatus,
  selectUnitCompletionStatus,
} from '../Classrooms';
import Analytics from '../Analytics';
import useSlideShowPointers from '../useSlideShowPointers';
import LinkDiv from '../components/LinkDiv/LinkDiv';
import activityStyles from '../SCSS/ActivityTabs.module.scss';
import styles from '../SCSS/lpMain.module.scss';
import unitPageStyles from '../SCSS/UnitPage.module.scss';
import videostyles from '../SCSS/VideoPlaceholder.module.scss';
import projectorGraphic from '../images/pkp-projector.png';
import { History } from 'history';
import { RootState } from '../ApplicationState';
import {
  ClassroomType,
  ElementaryUnit,
} from '../../peekapak-types/DataProtocolTypes';
type SlideShowButtonProps = {
  id: string;
  classroomName: string;
  history: History;
};

const SlideShowButton = ({
  id,
  classroomName,
  history,
}: SlideShowButtonProps) => {
  const [pointersLoadState, slideShowPointers] = useSlideShowPointers();

  if (!id || pointersLoadState === 'none') {
    return null;
  }

  if (pointersLoadState === 'loading') {
    return (
      <div className={styles.spinner}>
        <Spinner name='circle' />
      </div>
    );
  }

  const openSlides = (url: string) => history.push(url);

  const url = `/${encodeURIComponent(classroomName)}/lessonPlan/slides/${id}`;
  const thumbnailUrl = `https://peekaplatform.s3.amazonaws.com/media30${slideShowPointers[id].thumbnailUrl}`;
  return (
    <div className={unitPageStyles.readStoryButton}>
      <div onClick={() => openSlides(url)} role='button' tabIndex={0}>
        <img
          id={styles.slideThumbnail}
          src={thumbnailUrl}
          alt='slide thumbnail'
        />
      </div>
      <div className={unitPageStyles.readStoryButtonWrapper}>
        <Button
          className={unitPageStyles.readStoryButton}
          onClick={() => openSlides(url)}
        >
          <img
            id={styles.slideShowButton}
            src={projectorGraphic}
            alt='projector'
          />
          &nbsp; Present Slides
        </Button>
      </div>
    </div>
  );
}; // note lock decorate as HOC call at bottom
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface UnitPosition {
  lesson: number;
  subunit: number;
  step: number;
  level?: string;
}

type Props = PropsFromRedux & {
  unitPosition: UnitPosition;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  isPreviewMode?: boolean;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  history: History;
  onMarkStepComplete: (unitPosition: UnitPosition) => void;
  params: {
    classroomName: string;
    lessonNumber: string;
    stepNumber: string;
    subunitId: string;
    subunitNumber: string;
    unitId: string;
  };
  isAuthenticated: boolean;
  location: Location;
  onClickLockedContent: () => void;
  onResetStep: (unitPosition: UnitPosition) => Promise<void>;
  onSendHome: (unitPosition: UnitPosition) => void;
  unitId: string;
};
interface State {
  currentStepIsHome?: boolean;
  isShowLockedMessage: boolean;
  isStillInitializing: boolean;
  unit?: ElementaryUnit['value']['lessonPlan'];
  unitPosition?: UnitPosition & { level?: string };
}
class ActivityStepPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStillInitializing: true,
      isShowLockedMessage: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.handleActivityNavigator = this.handleActivityNavigator.bind(this);
    this.setActivityDisplay = this.setActivityDisplay.bind(this);
    this.getButtonType = this.getButtonType.bind(this);
    this.getIsCurrentStepHome = this.getIsCurrentStepHome.bind(this);
    this.getActivityButtonLabel = this.getActivityButtonLabel.bind(this);
    this.getShareStepContent = this.getShareStepContent.bind(this);
    this.getAttachment = this.getAttachment.bind(this);
    this.openStep = this.openStep.bind(this);
  }

  handlePrint() {
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const teacher = `${this.props.userProfile?.salutation} ${getPossessiveForm(
      this.props.userProfile?.lastName
    )}`;
    const heading = `${teacher} update on ${this.getStepTitle()} for ${
      steps[this.props.unitPosition.step].label
    }`;
    printLesson(heading);
  }

  getActivitySheetUrl() {
    const up = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const activityMeta = steps[up.step];

    if (activityMeta.files.length > 0) {
      return `${getPublicMediaRoot()}resources/${fixedEncodeURIComponent(
        activityMeta.files[0].actualName
      )}`;
    }

    return '';
  }

  getIsCurrentStepHome(
    activity: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number]['activities'],
    unitPosition: UnitPosition
  ) {
    if (activity) {
      if (activity[unitPosition.step].contentType === 'step-share') {
        return true;
      }
    }

    return false;
  }

  getActivitySheetDisplayName() {
    const up = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const activityMeta = steps[up.step];

    if (activityMeta.files !== undefined && activityMeta.files.length > 0) {
      return activityMeta.files[0].displayName;
    }

    return '';
  }

  componentDidUpdate(prevProps: Props) {
    const lessonId = `classroom:${this.props.params.classroomName} unit:${this.props.params.unitId} subunit:${this.props.params.subunitId} subunitNumber:${this.props.params.subunitNumber} lessonNumber:${this.props.params.lessonNumber} stepNumber:${this.props.params.stepNumber}`;
    if (this.props.unitPosition !== prevProps.unitPosition) {
      const { lesson, subunit } = this.props.unitPosition;
      const newUnit = this.props.unitMeta;
      const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
      this.setState({
        isStillInitializing: false,
        unit: newUnit,
        currentStepIsHome: this.getIsCurrentStepHome(
          steps,
          this.props.unitPosition
        ),
      });
    }
    this.sendLessonAnalytics(lessonId);
  }

  componentDidMount() {
    const lessonId = `classroom:${this.props.params.classroomName} unit:${this.props.params.unitId} subunit:${this.props.params.subunitId} subunitNumber:${this.props.params.subunitNumber} lessonNumber:${this.props.params.lessonNumber} stepNumber:${this.props.params.stepNumber}`;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;

    this.setState({
      isStillInitializing: false,
      unit: newUnit,
      currentStepIsHome: this.getIsCurrentStepHome(
        steps,
        this.props.unitPosition
      ),
    });
    this.sendLessonAnalytics(lessonId);
  }

  async sendLessonAnalytics(id: string) {
    const lessonsOpenedList = await JSON.parse(
      sessionStorage.getItem('lessonsOpenedList') || '[]'
    );
    if (!lessonsOpenedList?.includes(id)) {
      lessonsOpenedList.push(id);
      Analytics.lessonsOpenedEvent(id, this.props.userProfile!);
      sessionStorage.setItem(
        'lessonsOpenedList',
        JSON.stringify(lessonsOpenedList)
      );
    }
  }

  openStep(unitPosition: UnitPosition) {
    const { classroom } = this.props;
    const classroomName = classroom.className;
    const subunitMeta =
      this.state.unit?.subUnits[this.props.unitPosition.subunit];

    if (this.props.isPreviewMode && this.props.onPreviewChange) {
      this.props.onPreviewChange(unitPosition);
      return null;
    }

    openStep(
      classroomName,
      `${this.props.params.unitId}`,
      subunitMeta.url,
      unitPosition.subunit,
      unitPosition.lesson,
      unitPosition.step,
      this.props.isPreviewMode,
      this.props.onPreviewChange
    );
  }

  getNumberOfCoreStandards() {
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const unitPosition = this.props.unitPosition;
    const activityMeta = steps[unitPosition.step];

    if (activityMeta.coreStandards) {
      return activityMeta.coreStandards.length;
    }

    return null;
  }

  getShareStepContent() {
    const unitPosition = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const step = steps[unitPosition.step];

    if (step.classUpdate && step.activityInfo) {
      return `<h2>Class Update</h2>${step.classUpdate.text}<br/><br/><h2>Activity</h2>${step.activityInfo.text}`;
    } else if (!step.classUpdate && step.activityInfo) {
      return `<h2>Activity</h2>${step.activityInfo.text}`;
    } else if (step.classUpdate && !step.activityInfo) {
      return `<h2>Class Update</h2>${step.classUpdate.text}`;
    }

    return 'Send this home activity';
  }

  getAttachment() {
    const unitPosition = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const activityMeta = steps[unitPosition.step];

    if (activityMeta.files !== undefined && activityMeta.files[0]) {
      return (
        <div className={styles.resourcesExpandable}>
          <br />
          <h2>Attachment</h2>
          <LinkDiv
            onClick={() => openResource(activityMeta.files[0].actualName)}
            grey
          >
            <i
              className={this.getFileTypeIcon(
                activityMeta.files[0].displayName.split('.')[1]
              )}
              aria-hidden='true'
            />{' '}
            {activityMeta.files[0].displayName}
          </LinkDiv>
        </div>
      );
    }

    return null;
  }

  getFileTypeIcon(type: string) {
    const fileStuff: Record<string, string> = {
      pdf: 'fa fa-file-pdf-o',
      zip: 'fa fa-file-archive-o',
      mp3: 'fa fa-file-audio-o',
      xlsx: 'fa fa-file-excel-o',
      jpg: 'fa fa-file-image-o',
      jpeg: 'fa fa-file-image-o',
      mp4: 'fa fa-file-video-o',
      docx: 'fa fa-file-word-o',
      txt: 'fa fa-file-text-o',
      file: 'fa fa-file-o',
    };

    if (fileStuff[type]) {
      return fileStuff[type];
    }

    return fileStuff.file;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    function indicateInUnitBrowserView() {
      const updateSession = nextProps.onSessionUpdate;
      const updateObject = {
        isInUnitBrowserView: true,
      };
      updateSession(updateObject);
    }

    if (nextProps.session === undefined) {
      return;
    }

    if (
      nextProps.session.isInUnitBrowserView === undefined ||
      this.props.session === undefined ||
      this.props.session.isInUnitBrowserView === undefined ||
      nextProps.session.isInUnitBrowserView !==
        this.props.session.isInUnitBrowserView
    ) {
      indicateInUnitBrowserView();
    }

    // page has changed
    if (this.props.params !== nextProps.params) {
      const { lessonNumber, stepNumber, subunitNumber } = nextProps.params;
      const newUnitPosition = {
        subunit: subunitNumber - 1,
        lesson: lessonNumber - 1,
        step: stepNumber - 1,
        level: 'step',
      };
      const newUnit = this.props.unitMeta;
      const newActivity =
        newUnit.subUnits[newUnitPosition.subunit].lessons[
          newUnitPosition.lesson
        ].activities;
      this.setState({
        unitPosition: newUnitPosition,
        currentStepIsHome: this.getIsCurrentStepHome(
          newActivity,
          newUnitPosition
        ),
      });
    }
  }

  componentWillUnmount() {
    if (this.props.session) {
      const updateSession = this.props.onSessionUpdate;
      const updateObject = {
        isInUnitBrowserView: false,
      };
      updateSession(updateObject);
    }
  }

  getAndContentAccessKeys() {
    if (this.state.unit) {
      const lessonMeta =
        this.state.unit.subUnits[this.props.unitPosition.subunit].lessons[
          this.props.unitPosition.lesson
        ];

      if (lessonMeta.andContentAccessKey) {
        return [lessonMeta.andContentAccessKey];
      } else if (lessonMeta.andContentAccessKeys) {
        return lessonMeta.andContentAccessKeys;
      }
    }

    return [];
  }

  getOrContentAccessKeys() {
    if (this.state.unit) {
      const lessonMeta =
        this.state.unit.subUnits[this.props.unitPosition.subunit].lessons[
          this.props.unitPosition.lesson
        ];

      if (lessonMeta.orContentAccessKey) {
        return [lessonMeta.orContentAccessKey];
      } else if (lessonMeta.orContentAccessKeys) {
        return lessonMeta.orContentAccessKeys;
      }
    }

    return [];
  }

  getStepTitle() {
    const unitPosition = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    return steps[unitPosition.step].title;
  }

  getContentType() {
    const unitPosition = this.props.unitPosition;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    return steps[unitPosition.step].contentType;
  }

  getContentIcon(contentType: string) {
    if (contentType === 'step') {
      return <i className='fa fa-pencil-square-o fa-1x' />;
    } else if (contentType === 'step-share') {
      return <i className='fa fa-home fa-1x' />;
    } else if (contentType === 'step-game') {
      return <i className='fa fa-desktop' />;
    } else if (contentType === 'step-teacher') {
      return <i className='fa fa-info-circle' />;
    } else {
      return <i className='fa fa-pencil-square-o fa-1x' />;
    }
  }

  handleActivityNavigator(selectedStepIndex: number, event: Event) {
    event.preventDefault();
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const steps = newUnit.subUnits[subunit].lessons[lesson].activities;
    const step = steps[selectedStepIndex];

    if (step.contentType === 'step-share') {
      this.setState({
        currentStepIsHome: true,
      });
    } else {
      this.setState({
        currentStepIsHome: false,
      });
    }

    this.setState({
      unitPosition: {
        lesson: this.props.unitPosition.lesson,
        level: this.props.unitPosition.level,
        step: selectedStepIndex,
        subunit: this.props.unitPosition.subunit,
      },
    });
  }

  getButtonType(contentType: string, index: number) {
    if (contentType === 'step') {
      if (index === this.props.unitPosition.step) {
        return 'pkp-district-white.svg';
      }

      return 'pkp-district.svg';
    } else if (contentType === 'step-share') {
      if (index === this.props.unitPosition.step) {
        return 'pkp-parents-get-involved-white.svg';
      }

      return 'pkp-parents-get-involved.svg';
    }

    if (index === this.props.unitPosition.step) {
      return 'pkp-district-white.svg';
    }

    return 'pkp-district.svg';
  }

  getActivityButtonLabel(contentType: string, index: number) {
    if (contentType === 'step-share') {
      return 'Home Activity';
    } else if (contentType === 'step' && index === 0) {
      return 'Class Activity';
    } else if (contentType === 'step-game') {
      return 'MyPeekaville Activity';
    } else if (contentType === 'step-teacher') {
      return 'Launch Lesson';
    }

    return `Class Activity ${index + 1}`;
  }

  setBookorVideoContent(
    unitTitle: string,
    storyUrl: string,
    classroom: ClassroomType,
    currentActivity: Activity
  ) {
    if (this.state.unit?.title === 'Getting-Started') {
      if (currentActivity.videoUrl) {
        return (
          <div>
            <div className={styles.videoColumn}>
              <div className={videostyles.launchVideo}>
                <VideoPlaceholder
                  graphicUrl={currentActivity.thumbnailUrl}
                  videoUrl={currentActivity.videoUrl}
                  videoTitle={currentActivity.videoTitle}
                />
              </div>
            </div>
            <Row>
              <Col xs={12} md={12} xsOffset={3} mdOffset={3}>
                <div
                  className={styles.videoText}
                  style={{
                    fontSize: '18px',
                    color: 'black',
                    paddingTop: '15px',
                  }}
                >
                  {currentActivity.videoTitle}
                </div>
              </Col>
            </Row>
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <ReadStoryButton
        unitTitle={unitTitle}
        storyUrl={storyUrl}
        language={classroom.language}
        isPreviewMode={this.props.isPreviewMode}
      />
    );
  }

  setActivityDisplay(
    currentStep: number,
    activityMeta: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number]['activities'],
    unitPosition: UnitPosition,
    completionStatus: Props['unitCompletionStatus']
  ) {
    const contentType = this.getContentType();

    if (contentType === 'step-game') {
      return (
        <UnitMyPeekavilleActivityContent
          lessonMeta={
            this.state.unit?.subUnits[this.props.unitPosition.subunit].lessons[
              this.props.unitPosition.lesson
            ]
          }
          currentActivity={activityMeta[currentStep]}
          completionStatus={completionStatus}
          unitMeta={this.state.unit}
          unitPosition={this.props.unitPosition}
          isHomeActivity={this.state.currentStepIsHome}
          isPreviewMode={this.props.isPreviewMode}
          onMarkStepComplete={this.props.onMarkStepComplete}
          onResetStep={this.props.onResetStep}
        />
      );
    }

    if (contentType === 'step-teacher') {
      return (
        <UnitMyPeekavilleActivityContent
          lessonMeta={
            this.state.unit?.subUnits[this.props.unitPosition.subunit].lessons[
              this.props.unitPosition.lesson
            ]
          }
          currentActivity={activityMeta[currentStep]}
          completionStatus={completionStatus}
          unitMeta={this.state.unit}
          unitPosition={this.props.unitPosition}
          isHomeActivity={this.state.currentStepIsHome}
          isPreviewMode={this.props.isPreviewMode}
          onMarkStepComplete={this.props.onMarkStepComplete}
          onResetStep={this.props.onResetStep}
        />
      );
    }

    if (contentType === 'step-share') {
      return (
        <UnitHomeActivityContent
          activityMeta={activityMeta[currentStep]}
          currentActivity={activityMeta[currentStep]}
          unitPosition={this.props.unitPosition}
          completionStatus={completionStatus}
          unitMeta={this.state.unit}
          isHomeActivity={this.state.currentStepIsHome}
          onMarkStepComplete={this.props.onMarkStepComplete}
          onSendHome={this.props.onSendHome}
          onGetShareStepContent={this.getShareStepContent}
          onGetAttachment={this.getAttachment}
          isPreviewMode={this.props.isPreviewMode}
          history={this.props.history}
        />
      );
    }

    return (
      <UnitClassActivityContent
        activityMeta={activityMeta}
        currentActivity={activityMeta[currentStep]}
        unitPosition={this.props.unitPosition}
        completionStatus={completionStatus}
        unitMeta={this.state.unit}
        isHomeActivity={this.state.currentStepIsHome}
        onMarkStepComplete={this.props.onMarkStepComplete}
        onResetStep={this.props.onResetStep}
        isPreviewMode={this.props.isPreviewMode}
      />
    );
  }

  handleClickLockedContent = () => {
    this.setState({
      isShowLockedMessage: true,
    });
  };
  handleHideLockedMessage = () => {
    this.setState({
      isShowLockedMessage: false,
    });
  };
  redirectBack = () => {
    this.props.history.go(-1);
  };

  render() {
    // console.debug(
    //   `%cActivityStepPage render`,
    //   'background: blue; color: yellow',
    //   this.props.location.pathname
    // );
    if (
      !this.props.isPreviewMode &&
      (!this.props.isAuthenticated || this.state.isStillInitializing)
    ) {
      return null;
    }

    if (this.isLocked()) {
      // trying to access locked content without a pro account
      return <LockedContentMessage isShow onHide={this.redirectBack} />;
    }

    const { classroom } = this.props;
    const { lesson, subunit } = this.props.unitPosition;
    const newUnit = this.props.unitMeta;
    const activityMeta = newUnit.subUnits[subunit].lessons[lesson].activities;
    const unitPosition = this.props.unitPosition;
    if (!this.state.unit) {
      throw new Error('this.state.unit is undefined');
    }
    const unitMeta = this.state.unit;
    const storyUrl = getStoryUrl(unitMeta, classroom);
    const currentActivity = activityMeta[this.props.unitPosition.step];
    const tablistStyle = cx(activityStyles.tablist, {
      [activityStyles.singleTab]: activityMeta.length === 1,
    });
    const widthStyle = cx({
      [activityStyles.double]: activityMeta.length === 2,
      [activityStyles.triple]: activityMeta.length === 3,
    });
    const slideShowId = currentActivity.slideShowId;

    const getLockIcon = (
      activity: ElementaryUnit['value']['lessonPlan']['subUnits'][number]['lessons'][number]['activities'][number]
    ) => {
      if (activity.contentType === 'step-share') {
        if (!isUserHasKey('KEY_HOME_LESSONS')) {
          return <LockIcon />;
        }
      }

      return null;
    };

    const tabSelectHandler = (
      index: number,
      lastIndex: number,
      event: Event
    ) => {
      this.handleActivityNavigator(index, event);
      const newUnitPosition = {
        subunit: this.props.unitPosition.subunit,
        lesson: this.props.unitPosition.lesson,
        step: index,
      };
      this.openStep(newUnitPosition);
    };

    return (
      <div>
        <StepHeader
          title={this.getStepTitle()}
          shareStep={this.state.currentStepIsHome}
          onSendHome={this.props.onSendHome}
          completionStatus={this.props.unitCompletionStatus}
          onMarkStepComplete={this.props.onMarkStepComplete}
          onResetStep={this.props.onResetStep}
          unitPosition={this.props.unitPosition}
          unitId={this.props.unitId}
          duration={currentActivity.duration}
          label={currentActivity.label}
          unitMeta={this.state.unit}
          activityMeta={activityMeta}
          classroom={classroom}
          origin={activityMeta.name}
          imgUrl={
            'https://s3.amazonaws.com/peekaplatform/media30/unitHeaders/unitHeader-' +
            this.state.unit?.title +
            (this.state.unit?.title === 'Imagination' &&
            this.props.classroom.readingLevel > 2
              ? 'Station.png'
              : '.png')
          }
          onClickLockedContent={this.handleClickLockedContent}
          history={this.props.history}
          isPreviewMode={this.props.isPreviewMode}
          onPreviewChange={this.props.onPreviewChange}
        />
        <div className={styles.greyBackgroundLessonViewer}>
          <div className={styles.activityContainer}>
            <div className={styles.columnLeft}>
              <Tabs
                selectedIndex={unitPosition.step}
                selectedTabClassName={
                  activityStyles['react-tabs__tab--selected']
                }
                onSelect={tabSelectHandler}
              >
                <TabList className={tablistStyle}>
                  {activityMeta.map((activity, index) => {
                    return (
                      <Tab
                        key={index}
                        className={cx(
                          activityStyles['react-tabs__tab'],
                          widthStyle
                        )}
                      >
                        <div className={activityStyles.activityTab}>
                          {this.getContentIcon(activity.contentType)}
                          {' ' +
                            this.getActivityButtonLabel(
                              activity.contentType,
                              index
                            )}
                        </div>
                        {getLockIcon(activity)}
                      </Tab>
                    );
                  })}
                </TabList>
                {activityMeta.map((activity, index) => {
                  if (index === unitPosition.step) {
                    return (
                      <TabPanel key={index}>
                        {this.setActivityDisplay(
                          index,
                          activityMeta,
                          unitPosition,
                          this.props.unitCompletionStatus
                        )}
                      </TabPanel>
                    );
                  } else {
                    return <TabPanel key={index}></TabPanel>;
                  }
                })}
              </Tabs>
            </div>
            <div className={styles.columnRight}>
              {this.setBookorVideoContent(
                this.state.unit.title,
                storyUrl,
                classroom,
                currentActivity
              )}
              <SlideShowButton
                id={slideShowId}
                classroomName={this.props.params.classroomName}
                history={this.props.history}
              />
            </div>
          </div>
        </div>
        <LockedContentMessage
          isShow={this.state.isShowLockedMessage}
          onHide={this.handleHideLockedMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps) => ({
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  classroom: selectClassroom(state),
  completionStatus: selectCompletionStatus(state),
  unitCompletionStatus: selectUnitCompletionStatus(state),
  userProfile: state.user.userProfile,
});

const connector = connect(mapStateToProps);

export default connector(
  lockWithPreviewUnlessHasAny(
    'KEY_ALL_LESSON_PLANS',
    'KEY_MASTER_KEY'
  )(ActivityStepPage)
);
