import { useState } from 'react';
import MuxPlayer from '../MuxPlayer';
import styles from './MuxPlaceholderSplash.module.scss';
import MuxPlaceholder from '../../anonymous/MuxPlaceholder';

interface Props {
  options?: string;
  videoId: string;
  title: string;
  graphicUrl: string;
  otherThumbnailStyle?: string;
  iconCentered?: boolean;
  iconNone?: boolean;
  iconSize?: string;
}

const MuxPlaceholderSplash = ({
  videoId,
  title,
  graphicUrl,
  otherThumbnailStyle,
  iconCentered,
  iconNone,
  iconSize,
}: Props): JSX.Element => {
  const [isShowPlayer, setShowPlayer] = useState(false);

  const handleClick = () => {
    setShowPlayer(true);
  };
  const handleHide = () => {
    setShowPlayer(false);
  };

  return (
    <div className={styles.content}>
      <MuxPlaceholder
        graphicUrl={graphicUrl}
        handleClick={handleClick}
        otherThumbnailStyle={otherThumbnailStyle}
        iconCentered={iconCentered}
        iconNone={iconNone}
        iconSize={iconSize}
      />
      <MuxPlayer
        videoId={videoId}
        isShow={isShowPlayer}
        onHide={handleHide}
        title={title}
      />
    </div>
  );
};

export default MuxPlaceholderSplash;
