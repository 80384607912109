interface Props {
  condition: boolean;
  wrapper?: (ReactChildren: JSX.Element) => JSX.Element;
  children: JSX.Element;
  comment?: string;
}

const ConditionalRender = ({
  condition,
  children,
  comment,
}: Props): JSX.Element | null => (condition ? <>{children}</> : null);

export default ConditionalRender;
