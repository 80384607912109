import ToDoText from '../ToDoText';
import ReviewQuestionAnswer from '../ReviewQuestionAnswer';
import ReviewMultipleChoice from '../ReviewMultipleChoice';
import ReviewSubmittedImage from '../ReviewSubmittedImage';
import ReviewStrengthsEvaluation from '../ReviewStrengthsEvaluation';
import ReviewCheckIn from '../../CheckIn/ReviewCheckIn';
import ToDoQuestionAnswer from '../ToDoQuestionAnswer';
import ToDoMultipleChoice from '../ToDoMultipleChoice';
import ToDoStrengthsEvaluation from '../ToDoStrengthsEvaluation';
import ToDoAssets from '../ToDoAssets';
import ToDoImageUpload from '../ToDoImageUpload';
import ToDoImage from '../ToDoImage';
import ToDoSurvey from '../ToDoSurvey/ToDoSurvey';
import { Assignment } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  content: [{ type: string }];
  studentWork?: { status: string };
  goToLastTab?: () => void;
  teacherReview?: boolean;
  isMultiTab: boolean;
  assetImage?: string;
  updateAssignment: (data: Assignment) => void;
}

interface SubmittedProps {
  isSubmitted: boolean;
}

interface StrengthsEvaluationProps {
  teacherReview: boolean;
  studentWork: { status: string };
  strengths: [{ type: string }];
  questionAnswers: [{ type: string }];
  updateAssignment: (data: Assignment) => void;
}

interface QuestionAnswerProps {
  teacherReview: boolean;
  studentWork: { status: string };
  questionAnswers: [{ type: string }];
  updateAssignment: (data: Assignment) => void;
}

interface MultipleChoiceProps {
  teacherReview: boolean;
  studentWork: { status: string };
  textAnswer: [{ type: string }];
  data: { type: string };
  updateAssignment: (data: Assignment) => void;
}

interface ReflectionSurveyProps {
  data: { type: string };
  studentWork: { status: string };
  updateAssignment: (data: Assignment) => void;
}

interface ImageUploadProps {
  isSubmitted: boolean;
  image: string;
  index: number;
  teacherReview: boolean;
  studentWork: { status: string };
}

const ToDoContent = ({
  goToLastTab,
  content,
  studentWork,
  updateAssignment,
  teacherReview,
  assetImage,
  isMultiTab,
}: Props): JSX.Element => {
  const questionAnswers = content.filter(
    (obj) => obj.type === 'questionAnswer'
  );
  const strengths = content.filter((obj) => obj.type === 'strengthsEvaluation');
  const textBlocks = content.filter((obj) => obj.type === 'textBlock');
  const checkIn = content.filter((obj) => obj.type === 'checkInTool');

  const multipleChoiceSet = content.filter(
    (obj) => obj.type === 'multipleChoice'
  );
  const reflectionSurveySet = content.filter(
    (obj) => obj.type === 'reflectionSurvey'
  );

  const moduleAssets = content.filter(
    (obj) =>
      obj.type === 'slideDeck' || obj.type === 'pdf' || obj.type === 'video'
  );

  const displayImage = content.filter((obj) => obj.type === 'imageBlock');
  const imageUpload = content.filter((obj) => obj.type === 'imageCaptureBlock');

  return (
    <>
      {textBlocks.length > 0 && (
        <ToDoText
          data={textBlocks}
          goToLastTab={goToLastTab}
          hasAssets={moduleAssets.length > 0 && isMultiTab}
        />
      )}
      {checkIn.length > 0 && (
        <ReviewCheckIn
          studentWork={studentWork.work.content}
          teacherReview={teacherReview}
          timeSubmitted={studentWork.modifiedAt}
          isViewAssignmentSubmission
        />
      )}
      {displayImage.length > 0 && <ToDoImage data={displayImage} />}
      {questionAnswers.length > 0 &&
        multipleChoiceSet.length === 0 &&
        strengths.length === 0 && (
          <QuestionAnswerSwitch
            isSubmitted={studentWork?.status === 'submitted'}
            teacherReview={teacherReview}
            studentWork={studentWork}
            questionAnswers={questionAnswers}
            updateAssignment={updateAssignment}
          />
        )}
      {multipleChoiceSet.length > 0 && (
        <MultipleChoiceSwitch
          isSubmitted={studentWork?.status === 'submitted'}
          teacherReview={teacherReview}
          studentWork={studentWork}
          textAnswer={questionAnswers && questionAnswers}
          data={multipleChoiceSet[0]}
          updateAssignment={updateAssignment}
        />
      )}
      {strengths.length > 0 && (
        <StrengthsEvaluationSwitch
          data={strengths}
          isSubmitted={studentWork?.status === 'submitted'}
          teacherReview={teacherReview}
          strengths={strengths}
          studentWork={studentWork}
          updateAssignment={updateAssignment}
          questionAnswers={questionAnswers}
        />
      )}
      {reflectionSurveySet.length > 0 && (
        <ReflectionSurveySwitch
          data={reflectionSurveySet[0]}
          studentWork={studentWork}
          updateAssignment={updateAssignment}
        />
      )}
      {moduleAssets.length > 0 && (
        <ToDoAssets data={moduleAssets} assetImage={assetImage} />
      )}
      {imageUpload.length > 0 &&
        imageUpload.map((image, index) => (
          <ImageUploadSwitch
            image={image}
            isSubmitted={studentWork?.status === 'submitted'}
            index={index}
            key={`image upload ${index + 1}`}
            teacherReview={teacherReview}
            studentWork={studentWork}
          />
        ))}
    </>
  );
};

function StrengthsEvaluationSwitch({
  isSubmitted,
  teacherReview,
  strengths,
  studentWork,
  updateAssignment,
  questionAnswers,
}: SubmittedProps & StrengthsEvaluationProps) {
  if (isSubmitted) {
    return (
      <ReviewStrengthsEvaluation
        teacherReview={teacherReview}
        data={strengths[0]}
        studentWork={studentWork}
        textAnswer={questionAnswers && questionAnswers}
      />
    );
  }

  return (
    <ToDoStrengthsEvaluation
      key='multipleChoice ToDoContent'
      data={strengths[0]}
      studentWork={studentWork}
      updateAssignment={updateAssignment}
      textAnswer={questionAnswers && questionAnswers}
    />
  );
}

function QuestionAnswerSwitch({
  isSubmitted,
  teacherReview,
  studentWork,
  questionAnswers,
  updateAssignment,
}: SubmittedProps & QuestionAnswerProps): JSX.Element {
  if (isSubmitted) {
    return (
      <ReviewQuestionAnswer
        data={questionAnswers}
        studentWork={studentWork}
        teacherReview={teacherReview}
      />
    );
  }

  return (
    <ToDoQuestionAnswer
      key='questionAnswer ToDoContent'
      data={questionAnswers}
      studentWork={studentWork}
      updateAssignment={updateAssignment}
    />
  );
}

function MultipleChoiceSwitch({
  isSubmitted,
  teacherReview,
  studentWork,
  textAnswer,
  data,
  updateAssignment,
}: SubmittedProps & MultipleChoiceProps) {
  if (isSubmitted) {
    return (
      <ReviewMultipleChoice
        teacherReview={teacherReview}
        data={data}
        studentWork={studentWork}
        textAnswer={textAnswer}
      />
    );
  }

  return (
    <ToDoMultipleChoice
      key='multipleChoice ToDoContent'
      data={data}
      studentWork={studentWork}
      updateAssignment={updateAssignment}
      textAnswer={textAnswer}
    />
  );
}

function ReflectionSurveySwitch({
  data,
  studentWork,
  updateAssignment,
}: ReflectionSurveyProps) {
  return (
    <ToDoSurvey
      data={data}
      key='reflectionSurvey ToDoContent'
      studentWork={studentWork}
      updateAssignment={updateAssignment}
    />
  );
}

function ImageUploadSwitch({
  isSubmitted,
  image,
  index,
  teacherReview,
  studentWork,
}: ImageUploadProps) {
  if (isSubmitted) {
    return (
      <ReviewSubmittedImage
        teacherReview={teacherReview}
        key={`submitted image ${index}`}
        data={image}
        studentWork={studentWork}
      />
    );
  }
  return (
    <ToDoImageUpload
      key={`image ${index}`}
      data={image}
      studentWork={studentWork}
    />
  );
}

export default ToDoContent;
