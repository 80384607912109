declare var mixpanel: any;
import { parseFullName } from 'parse-full-name';
import cloneDeep from 'lodash/cloneDeep';
import {
  getConfiguration,
  getReadableUnixTimeDateOnly,
  getReadableUnixTimeTimeOnly,
} from './GlobalFunctions';
import { getStore } from './ApplicationState';
import { resetStorybookPercentageRead } from './Session';

/* global MIXPANEL_CUSTOM_LIB_URL */

/* eslint-disable */
export function initializeMixpanel() {
  (function (c, a) {
    if (!a.__SV) {
      var b = window;
      try {
        var d,
          m,
          j,
          k = b.location,
          f = k.hash;
        d = function (a, b) {
          return (m = a.match(RegExp(b + '=([^&]*)'))) ? m[1] : null;
        };
        f &&
          d(f, 'state') &&
          ((j = JSON.parse(decodeURIComponent(d(f, 'state')))),
          'mpeditor' === j.action &&
            (b.sessionStorage.setItem('_mpcehash', f),
            history.replaceState(
              j.desiredHash || '',
              c.title,
              k.pathname + k.search
            )));
      } catch (n) {}
      var l, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function (b, d, g) {
        function c(b, i) {
          var a = i.split('.');
          2 == a.length && ((b = b[a[0]]), (i = a[1]));
          b[i] = function () {
            b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var e = a;
        'undefined' !== typeof g ? (e = a[g] = []) : (g = 'mixpanel');
        e.people = e.people || [];
        e.toString = function (b) {
          var a = 'mixpanel';
          'mixpanel' !== g && (a += '.' + g);
          b || (a += ' (stub)');
          return a;
        };
        e.people.toString = function () {
          return e.toString(1) + '.people (stub)';
        };
        l =
          'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
            ' '
          );
        for (h = 0; h < l.length; h++) c(e, l[h]);
        var f = 'set set_once union unset remove delete'.split(' ');
        e.get_group = function () {
          function a(c) {
            b[c] = function () {
              var call2_args = arguments;
              var call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              e.push([d, call2]);
            };
          }
          for (
            var b = {},
              d = ['get_group'].concat(
                Array.prototype.slice.call(arguments, 0)
              ),
              c = 0;
            c < f.length;
            c++
          )
            a(f[c]);
          return b;
        };
        a._i.push([b, d, g]);
      };
      a.__SV = 1.2;
      b = c.createElement('script');
      b.type = 'text/javascript';
      b.async = !0;
      b.src =
        'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
          ? MIXPANEL_CUSTOM_LIB_URL
          : 'file:' === c.location.protocol &&
            '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
          ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
          : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
      d = c.getElementsByTagName('script')[0];
      d.parentNode.insertBefore(b, d);
    }
  })(document, window.mixpanel || []);
  const mixPanelApiToken = getConfiguration('mixpanel').apiKey;
  mixpanel.init(mixPanelApiToken);
}

/* eslint-enable */

function parsePagePath(pathname) {
  const newpath = pathname.split('/');

  if (newpath.length === 4 && newpath[2] === 'lessonPlan') {
    const output = newpath[3].split('-');
    return `${output.toString().replace(',', ' ')} Unit Page Visted`;
  } else if (newpath.length === 3 && newpath[1] === 'story') {
    const output = newpath[2].split('-');
    output.pop();
    const StoryName = output.toString().replace(',', ' ');
    return [StoryName, 'Story Book Open'];
  }

  return `${pathname.replace(/\W/g, '')} Page Visited`;
}

export function sendMixpanelPageRenderEvent(userObject) {
  const { pageTracker, storybookTracker } = getStore().getState().session;

  if (!pageTracker || !pageTracker.previousPage) {
    return;
  }

  const MixpanelEvent = parsePagePath(pageTracker.previousPage);
  const data = (() => {
    if (MixpanelEvent.length === 2) {
      return {
        'Time Spent': pageTracker.timeSpentOnPreviousPage / 1000,
        'Story Name': MixpanelEvent[0],
        'Percentage Finished': storybookTracker.percent || 0,
      };
    }

    return {
      'Time Spent': pageTracker.timeSpentOnPreviousPage / 1000,
    };
  })();

  const toLogData = addUserInformation(userObject, data);

  if (MixpanelEvent.length === 2) {
    mixpanel.track(MixpanelEvent[1], toLogData);
    getStore().dispatch(resetStorybookPercentageRead());
  } else {
    mixpanel.track(MixpanelEvent, toLogData);
  }
}

function addUserInformation(userObject, data) {
  if (userObject) {
    const newData = { ...data };

    if (userObject.type !== 'Student') {
      mixpanel.identify(userObject.email); // not necessary, but won't have neg side effects (email wont change)
      newData['Email Domain'] = userObject.email.split('@')[1];
    }

    newData['School Name'] = userObject.schoolName;
    newData['User ID'] = userObject.userId;
    newData['User Name'] = userObject.firstName + ' ' + userObject.lastName;
    newData['Type'] = userObject.type;

    if (userObject.district) {
      newData['District'] = userObject.district;
    }

    if (userObject.classroomId) {
      newData['Classroom ID'] = userObject.classroomId;
    }

    if (userObject.grade) {
      newData['Grade'] = userObject.grade;
    }

    if (userObject.tags) {
      newData['Tags'] = userObject.tags;
    }

    if (userObject.schoolId) {
      newData['School ID'] = userObject.schoolId;
    }

    if (userObject.seller) {
      newData['Seller'] = userObject.seller;
    }

    return newData;
  }
}

export function sendMixpanelPageCloseEvent(userObject) {
  const { pageTracker, storybookTracker } = getStore().getState().session;

  if (!pageTracker || !pageTracker.previousPage) {
    return;
  }
  const currentPage = parsePagePath(pageTracker.currentPage);

  const baseData = (() => {
    if (currentPage.length === 2) {
      return {
        'Time Spent': pageTracker.timeSpentOnPreviousPage / 1000,
        'Story Name': currentPage[0],
        'Percentage Finished': storybookTracker.percent || 0,
      };
    }

    return {
      'Time Spent': (Date.now() - pageTracker.timeEntered) / 1000,
    };
  })();

  const baseWithExitPageData = {
    ...baseData,
    'Exit Page': currentPage.replace(' Page Visited', ''),
    'Visited Time': pageTracker.timeCount / 1000,
  };

  const pageVisitData = addUserInformation(userObject, baseData);
  const pageExitData = addUserInformation(userObject, baseWithExitPageData);

  // NB: when a visitor leaves the peekapak website, the last page
  // they were browsing is not yet sent to mixpanel as data
  // here, we send two events, the last page visited event and
  // the leaving website event
  if (currentPage.length === 2) {
    mixpanel.track(currentPage[1], pageVisitData);
    getStore().dispatch(resetStorybookPercentageRead());
  } else {
    mixpanel.track(currentPage, pageVisitData);
  }

  mixpanel.track('Exit Website', pageExitData);
}

function getUserProfileData(userObjectInput) {
  const userObject = cloneDeep(userObjectInput);
  if (userObject) {
    if (!userObject.schoolName) {
      userObject.schoolName = '';
    }

    const data = (() => {
      if (userObject.type === 'Student') {
        return {
          'School Name': userObject.schoolName,
          'User ID': userObject.userId,
          'Login Time': getReadableUnixTimeTimeOnly(Date.now()),
          'Login Date': getReadableUnixTimeDateOnly(Date.now()),
          License: userObject.licenseLevel,
          'License Expires': userObject.licenseExpires,
          Type: userObject.type,
        };
      } else {
        return {
          Email: userObject.email,
          'Email Domain': userObject.email.split('@')[1],
          'User ID': userObject.userId,
          'School Name': userObject.schoolName,
          'Login Time': getReadableUnixTimeTimeOnly(Date.now()),
          'Login Date': getReadableUnixTimeDateOnly(Date.now()),
          License: userObject.licenseLevel,
          'License Expires': userObject.licenseExpires,
          Type: userObject.type,
        };
      }
    })();

    if (userObject.district) {
      data['District'] = userObject.district;
    }

    if (userObject.classroomId) {
      data['Classroom ID'] = userObject.classroomId;
    }

    if (userObject.grade) {
      data['Grade'] = userObject.grade;
    }

    if (userObject.tags) {
      data['Tags'] = userObject.tags;
    }

    if (userObject.schoolId) {
      data['School ID'] = userObject.schoolId;
    }

    if (userObject.seller) {
      data['Seller'] = userObject.seller;
    }

    if (userObject.username.includes('Clever')) {
      data['Login Method'] = 'Clever';
    } else if (userObject.username.includes('Google')) {
      data['Login Method'] = 'Google';
    } else {
      data['Login Method'] = 'Password';
    }

    return data;
  }

  return {};
}

export function sendMixpanelLoginEvent(userObjectInput) {
  const userObjectData = getUserProfileData(userObjectInput);

  if (userObjectInput.type !== 'Student') {
    mixpanel.identify(userObjectInput.email);
  }

  mixpanel.track('User Login', userObjectData);
}

export function sendMixpanelReactivateEvent(userObjectInput) {
  const userObjectData = getUserProfileData(userObjectInput);

  if (userObjectInput.type !== 'Student') {
    mixpanel.identify(userObjectInput.email);
  }

  mixpanel.track('User Reactivated', userObjectData);
}

export function sendMixpanelSignupEvent(userObject) {
  if (userObject) {
    const nameElements = parseFullName(userObject.name);
    mixpanel.identify(userObject.email);

    if (userObject.type === 'Student') {
      mixpanel.track('New User Sign Up', {
        Type: 'Student',
        'School Name': userObject.schoolName,
        'User Name': nameElements.first + ' ' + nameElements.last,
        'Sign Up Date': getReadableUnixTimeTimeOnly(Date.now()),
        'Sign Up Time': getReadableUnixTimeDateOnly(Date.now()),
      });
    } else {
      mixpanel.track('New User Sign Up', {
        'User Type': 'Teacher',
        Email: userObject.email,
        'Email Domain': userObject.email.split('@')[1],
        'School Name': userObject.schoolName,
        'User Name': nameElements.first + ' ' + nameElements.last,
        'Sign Up Date': getReadableUnixTimeTimeOnly(Date.now()),
        'Sign Up Time': getReadableUnixTimeDateOnly(Date.now()),
        Type: 'Teacher',
      });
    }
  }
}
