import { createBrowserHistory, History } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import Amplify, { Hub } from '@aws-amplify/core';
import { getConfiguration } from './GlobalFunctions';
import user from './UserManagement';
import classrooms from './Classrooms';
import messages from './Messages';
import lessons from './Lessons';
import { session } from './Session';
import events, { push, AuthEvent } from './AuthenticationEvents';
import audioPlayer from './components/AudioPlayer/AudioPlayer';

const store = configureStore({
  reducer: {
    audioPlayer,
    user,
    classrooms,
    messages,
    lessons,
    session,
    events,
  },
  // CAUTION: we are explicitly turning off the warnings about putting in
  // non-serizliable objects into the store. This is because we are using
  // the CognitoUserProfileType which is an object with functions that we want
  // access to such as getSignInUserSession.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const myBrowserHistory: History = createBrowserHistory();
export const cognitoConfiguration = getConfiguration('cognito');
export function initializeApplicationState() {
  // console.debug(`Entered initializeApplicationState()`);

  configureAmplify();
  // console.debug(`Exiting initializeApplicationState()`);
}

function configureAmplify(newSignInCallbackPath?: string) {
  const callbackUrlRoot = `${document.location.protocol}//${document.location.host}`;
  const signInCallback = `${callbackUrlRoot}${
    newSignInCallbackPath || cognitoConfiguration.hostedUISignInCallbackPath
  }`;
  const signOutCallback = `${callbackUrlRoot}${cognitoConfiguration.hostedUISignOutCallbackPath}`;
  console.info(`%c Configuring amplify`, 'background: #222; color: #bada55');

  Amplify.configure({
    Analytics: {
      disabled: true,
    },
    Auth: {
      identityPoolId: cognitoConfiguration.identityPool,
      region: cognitoConfiguration.region,
      userPoolId: cognitoConfiguration.userPool,
      userPoolWebClientId: cognitoConfiguration.clientId,
    },
    oauth: {
      domain: cognitoConfiguration.hostedUICognitoDomain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: signInCallback,
      redirectSignOut: signOutCallback,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  });

  Hub.listen('auth', pushEventIntoQueue);
}

function pushEventIntoQueue({ payload }: { payload: AuthEvent }) {
  console.info(
    `%c OauthLogin pushing event `,
    'background: #222; color: #bada55',
    payload
  );

  getStore().dispatch(push(payload));
}

export function getStore() {
  return store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
