import { Children, Component, cloneElement } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { updateUserProfile } from '../BackendInterface';
import { ElementaryCard } from '../components/LibraryCard/cardData';

import rightArrow from '../images/pkp-rightarrow.svg';
import whiteRightArrow from '../images/pkp-rightarrow-white.svg';
import checkmarkInCircle from '../images/pkp-checkmark-in-circle.svg';
import {
  ClassroomType,
  UserProfileType,
} from '../../peekapak-types/DataProtocolTypes';

// Define generic react components

type ClassNameProp = {
  className: string;
};
type TextProp = {
  text: string;
};

export class LargeHeader extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'LargeHeader', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

export class NormalHeader extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'NormalHeader', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

export class SubHeader extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'SubHeader', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

export class Title extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'Title', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

export class LargeText extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'LargeText', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

export class Text extends Component<TextProp & ClassNameProp> {
  render() {
    return (
      <div className={classNames('text', 'Text', this.props.className)}>
        {this.props.text}
      </div>
    );
  }
}

interface SegmentProps {
  [k: string]: unknown;
  className?: string;
  id?: string;
  outerClass?: string;
  innerClass?: string;
  children?: React.ReactNode;
}

export class Segment extends Component<SegmentProps> {
  removeList: Record<string, boolean>;

  constructor(props: SegmentProps) {
    super(props);
    this.removeList = {
      className: true,
      children: true,
      innerClass: true,
      outerClass: true,
    };
  }

  generateFilteredProps() {
    const filteredProps: Record<string, any> = {};
    for (const key in this.props) {
      if (
        Object.prototype.hasOwnProperty.call(this.props, key) &&
        !this.removeList[key]
      ) {
        filteredProps[key] = this.props[key];
      }
    }
    return filteredProps;
  }

  render() {
    return (
      <div
        className={classNames('Segment', this.props.outerClass)}
        id={this.props.id}
      >
        <div
          className={classNames(
            'clearfix',
            'innerSegment',
            this.props.innerClass,
            this.props.className
          )}
        >
          {Children.map(this.props.children, (child) =>
            cloneElement(
              child,
              this.generateFilteredProps(),
              child.props.children
            )
          )}
        </div>
      </div>
    );
  }
}

export class Card extends Component<SegmentProps> {
  render() {
    return (
      <div className={classNames('thumbnail', 'Card', this.props.className)}>
        <div className='segment'>{this.props.children}</div>
      </div>
    );
  }
}

type RateProps = {
  amount: number;
  unit?: string;
};

export class Rate extends Component<RateProps> {
  render() {
    return (
      <div className='text Rate'>
        <strong className='NormalHeader'>{this.props.amount}</strong>
        <strong className='SubHeader'>
          {this.props.unit ? ` / ${this.props.unit}` : null}
        </strong>
      </div>
    );
  }
}

type EmsProp = {
  ems: number;
};

export const VerticalSpacer = ({ ems }: EmsProp) => (
  <div style={{ paddingTop: `${ems}em`, width: `100%` }} />
);

export class CheckedCircle extends Component<SegmentProps> {
  render() {
    return (
      <div className={classNames('CheckedCircle', this.props.className)}>
        <div className={classNames('circle', 'circle-inactive')}>
          <span>
            <i className='fa fa-check' aria-hidden='true' />
          </span>
        </div>
        <span className={classNames('circle-label', 'circle-label-inactive')}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

export function striping(number: number) {
  return number % 2 === 0 ? 'background-lightgrey' : 'background-white';
}

export const showRightArrowIcon = () => (
  <img
    alt='Right arrow'
    className='pkp-icon pkp-rightarrow'
    src={rightArrow}
    style={{ width: '17px', height: '16px' }}
    aria-hidden='true'
  />
);

export const showRightArrowWhiteIcon = () => (
  <img
    alt='Right arrow'
    className='pkp-icon pkp-rightarrow'
    src={whiteRightArrow}
    style={{ width: '17px', height: '16px' }}
    aria-hidden='true'
  />
);

export const showCheckmarkInCircleIcon = () => (
  <img
    alt='Checkmark'
    className='pkp-icon pkp-checkmark-circle'
    src={checkmarkInCircle}
    style={{ width: '18px', height: '18px' }}
    aria-hidden='true'
  />
);

type FieldGroupProps = {
  id: string;
  label?: string;
  help?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isInvalid?: boolean;
  inputClassName?: string;
  [k: string]: unknown;
};

export const FieldGroup = ({
  id,
  label,
  help,
  inputRef,
  isInvalid,
  inputClassName,
  ...props
}: FieldGroupProps) => (
  <Form.Group controlId={id}>
    {label && (
      <Form.Label className='formLabel' style={{ paddingLeft: '5px' }}>
        {label}
      </Form.Label>
    )}
    <Form.Control
      ref={inputRef}
      className={classNames({ [`${inputClassName}`]: !!inputClassName })}
      isInvalid={isInvalid}
      {...props}
    />
    {help && (
      <Form.Control.Feedback className='d-block' type='invalid'>
        {help}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const validatePassword = (password) =>
  // let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})/;
  // return re.test( password );
  password.length > 8;

export function dismissTemporarily(itemName) {
  sessionStorage.setItem(`isShow${itemName}`, false);
}

export async function dismissForever(itemName, existingFlags) {
  dismissTemporarily(itemName);

  const newFlags = { ...existingFlags };
  newFlags[`isShow${itemName}`] = false;

  await updateUserProfile({ newUserData: { flags: newFlags } });
}

/**
 * Determines whether a hint item should be shown
 * given different factors
 */
export function isShouldShow(userProfile, itemName) {
  const flagName = `isShow${itemName}`;
  const userFlagsExists = userProfile && userProfile.flags;
  const itemFlagExists =
    userFlagsExists && userProfile.flags[flagName] !== undefined;
  const flag = itemFlagExists && userProfile.flags[flagName];

  const sessionFlag = sessionStorage.getItem(flagName);
  const sessionFlagExists = !!sessionFlag;

  // sessionFlag takes 1st priority
  if (sessionFlagExists) {
    return sessionFlag === 'true';
  } else {
    if (itemFlagExists) {
      return !!flag;
    }

    return true;
  }
}

export function createOrUpdateUserProfileFlag(
  flag:
    | 'isShowLibraryBanner'
    | 'isShowConnectParentsHint'
    | 'isShowControl'
    | 'isShowAutoPlay'
    | 'isSkipAutoSync',
  value: boolean,
  userProfile: UserProfileType
) {
  if (userProfile && !userProfile.flags) {
    return {
      ...userProfile,
      flags: { [flag]: value },
    };
  }
  if (userProfile && userProfile.flags) {
    return {
      ...userProfile,
      flags: { ...userProfile.flags, [flag]: value },
    };
  }

  throw new Error(
    `createOrUpdateUserProfileFlag: input user profile doesn't exist`
  );
}

export function createOrUpdatePeekavilleUnitLocks(
  value: boolean,
  unit: string,
  classroom: ClassroomType,
  elementaryUnits: ElementaryCard[]
) {
  const newClassroom: ClassroomType = (() => {
    const duplicateClassroom =
      classroom.peekavilleUnitLocks !== undefined
        ? classroom
        : { ...classroom, peekavilleUnitLocks: {} };
    const populatedLocks = elementaryUnits.reduce((acc, elementaryUnit) => {
      const id = elementaryUnit.unit.replace('-', ' ');
      if (elementaryUnit.hasPeekavilleContent) {
        return Object.prototype.hasOwnProperty.call(
          duplicateClassroom.peekavilleUnitLocks,
          id
        )
          ? { ...acc, [id]: classroom.peekavilleUnitLocks?.[id] }
          : { ...acc, [id]: true };
      } else {
        return acc;
      }
    }, {});
    const classroomWithPopulatedLocks = {
      ...classroom,
      peekavilleUnitLocks: populatedLocks,
    };
    return {
      ...classroomWithPopulatedLocks,
      peekavilleUnitLocks: {
        ...classroomWithPopulatedLocks.peekavilleUnitLocks,
        [unit]: value,
      },
    };
  })();
  return newClassroom;
}
