import { Link } from 'react-router-dom';
import { Prompt } from '../components/LeftRightCard';
import SectionHeader from '../components/SectionHeader';
import logoElizabeth from '../images/logos/logo-elizabeth.png';
import logoKippstl from '../images/logos/logo-kippstl.png';
import logoChicago from '../images/logos/logo-chicago.png';
import styles from './PeekapakInAction.module.scss';

const defaultLogos = [
  { logo: logoElizabeth, link: '/successStories/elizabethPS' },
  { logo: logoKippstl, link: '/successStories/kippStLouis' },
  { logo: logoChicago, link: '/successStories/chicagoPS' },
];

interface Prop {
  clickHandler: () => void;
  logos?: { logo: string; link?: string }[];
}

const PeekapakInAction = ({
  logos = defaultLogos,
  clickHandler,
}: Prop): JSX.Element => {
  return (
    <section className={styles.peekapakInActionSection}>
      <SectionHeader
        preTitle='impact'
        title='Peekapak in Action'
        copy='Peekapak works with a variety of schools and districts globally to help make an impact on student mental health and well-being.  Discover how other educators are using Peekapak in their classrooms to implement SEL into their curriculum by clicking on the following logos.'
      />
      <div className={styles.logosContainer}>
        {logos.map((school, index) => (
          <Link to={school.link || ''} key={`${school.link}${index}`}>
            <img src={school.logo} alt={school.logo.toString()} />
          </Link>
        ))}
      </div>
      <Prompt clickHandler={clickHandler}>Read More Case Studies</Prompt>
    </section>
  );
};

export default PeekapakInAction;
