import { forwardRef, ReactNode } from 'react';
import { Field } from 'formik';
import styles from './TitledCheckbox.module.scss';

interface TitleProps {
  // expect 1 child only
  children: ReactNode;
}

interface Props {
  children: ReactNode;
  id: string;
}

export const Title = ({ children }: TitleProps): JSX.Element => {
  return <span className={styles.title}>{children}</span>;
};

const TitledCheckbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, id, ...rest } = props;
  return (
    <div className={styles.control}>
      <Field
        ref={ref}
        name='checked'
        className={styles.checkbox}
        type='checkbox'
        id={id}
        {...rest}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
});

export default TitledCheckbox;
