import { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import combineClasses from 'classnames';
import styles from './PrimaryButton.module.scss';
interface Props {
  small?: boolean;
  disabled?: boolean;
  orange?: boolean;
  completed?: boolean;
  className?: string;
  secondary?: boolean;
  alert?: boolean;
  alertSecondary?: boolean;
  inverted?: boolean;
  white?: boolean;
  dummy1?: boolean;
  dummy2?: boolean;
  capitalize?: boolean;
  children: Element[];
  [k: string]: string | unknown | undefined;
}
const PrimaryButton = forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    const {
      small,
      disabled,
      orange,
      completed,
      className,
      secondary,
      alert,
      alertSecondary,
      inverted,
      dummy1,
      dummy2,
      white,
      capitalize,
      children,
      ...otherProps
    } = props;
    const combinedClasses = combineClasses(
      {
        [`${styles.primaryButton}`]: true,
        [`${styles.small}`]: small,
        [`${styles.disabled}`]: disabled,
        [`${styles.orange}`]: orange,
        [`${styles.white}`]: white,
        [`${styles.inverted}`]: inverted,
        [`${styles.completed}`]: completed,
        [`${styles.secondary}`]: secondary,
        [`${styles.alert}`]: alert,
        [`${styles.alertSecondary}`]: alertSecondary,
        [`${styles.dummy1}`]: dummy1,
        [`${styles.dummy2}`]: dummy2,
        [`${styles.capitalize}`]: capitalize,
      },
      className
    );

    return (
      <div className={combinedClasses}>
        {disabled ? (
          <Button {...otherProps} ref={ref} disabled>
            {children}
          </Button>
        ) : (
          <Button {...otherProps}>{children}</Button>
        )}
      </div>
    );
  }
);

PrimaryButton.displayName = 'PrimaryButton';
export default PrimaryButton;
