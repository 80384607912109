import styles from './SCSS/StandalonePageWrapper.module.scss';

interface Props {
  children: React.ReactNode;
  [k: string]: unknown;
}

const StandalonePageWrapper = (props: Props) => (
  <div className={styles.standalonePage}>{props.children}</div>
);

export default StandalonePageWrapper;
