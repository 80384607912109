import { ComponentPropsWithoutRef, forwardRef } from 'react';
import TitledCheckbox, { Title } from '../TitledCheckbox';

const EmailOptin = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'>
>((props, ref) => {
  return (
    <TitledCheckbox {...props} ref={ref} id='emailCheckbox'>
      <span>
        <Title>Email Updates:</Title>Keep me in the loop on the best practices
        and case studies on social-emotional learning.
      </span>
    </TitledCheckbox>
  );
});

export default EmailOptin;
