import Auth from '@aws-amplify/auth';
import { getContentRequest } from '../../serviceAgent';
import { getAppRoot } from '../../identity';
import { logoutIntercomUser } from '../../IntercomUtilityFunctions';
import { logger } from '../../error-tracker';
import { getStore } from '../../ApplicationState';
import { getAuthorizationToken } from '../../BackendInterface';
import { getUsersId } from '../../GlobalFunctions';
import { logout } from '../../UserManagement';

export const setMessageMark = async (role, successCallback, errorCallback) => {
  // Marks all messages as read
  const Authorization = await getAuthorizationToken();
  const userId = getUsersId();
  getContentRequest(
    `${getAppRoot()}api/setmessagemark?userId=${encodeURIComponent(
      userId
    )}&daysAgo=7`,
    (status, requestObject) => {
      if (status === 200) {
        if (successCallback !== undefined) {
          successCallback(status, requestObject);
        }
      } else if (errorCallback !== undefined) {
        errorCallback(status, requestObject);
      }
    },
    {
      Authorization,
    }
  );
};

export const cognitoSignOut = () => {
  Auth.signOut()
    .then((data) => {
      getStore().dispatch(logout());
      logoutIntercomUser();
    })
    .catch((error) => {
      logger.logException(error);
    });
};

export const unionArray = (...subArray) => {
  let union = [];
  for (const array of subArray) {
    union = [...new Set([...union, ...(array || [])])];
  }
  return union;
};

export const differenceArray = (diffArray, ...subArray) => {
  let difference = [];
  for (const array of subArray) {
    difference = (array || []).filter((x) => diffArray.indexOf(x) < 0);
  }
  return difference;
};

export const numericGradeToString = {
  '-1': 'Pre-Kindergarten',
  0: 'Kindergarten',
  1: 'Grade 1',
  2: 'Grade 2',
  3: 'Grade 3',
};
