import { Component, createRef } from 'react';
import { Form } from 'react-bootstrap';
import CheckButtonEvent from '../../types';
import EmailOptin from '../EmailOptin';
import TOUCheckbox from '../TOUCheckbox';
import styles from './SignUpLegalese.module.scss';
type Props = {
  onOptInChange: (event: CheckButtonEvent) => void;
  onTouPpChange: (event: CheckButtonEvent) => void;
  touPpValidationHint: string;
  currentOptInState: boolean;
  currentTouPpState: boolean;
};

class SignUpLegalese extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.checkboxElement = createRef();
    this.formGroupElement = createRef();
  }

  render(): JSX.Element {
    return (
      <div
        className={styles.signUpLegalese}
        style={{
          position: 'relative',
        }}
      >
        <Form.Group ref={this.formGroupElement}>
          <EmailOptin
            id='optin'
            name='optin'
            onChange={this.props.onOptInChange}
            defaultChecked={this.props.currentOptInState}
          />
          <TOUCheckbox
            ref={this.checkboxElement}
            id='toupp'
            name='toupp'
            defaultChecked={this.props.currentTouPpState}
            onChange={this.props.onTouPpChange}
            errortooltip={this.props.touPpValidationHint}
          />
        </Form.Group>
      </div>
    );
  }
}

export default SignUpLegalese;
