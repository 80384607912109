import { Component, ReactNode } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  areAnyLessonsEntirelyNotStarted,
  areAnyLessonsInProgress,
  areAnySubunitsInProgress,
  computeCompletionStatus,
  findFirstInProgressLesson,
  findFirstNotStartedLesson,
} from '../completionStatus';
import {
  getBeginLessonButton,
  getLockedSubunitButton,
  getResumeLessonButton,
} from './UtilityFunctions';
import { selectUnitCompletionStatus } from '../Classrooms';
import { showCheckmarkInCircleIcon } from '../core/core';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import {
  ClassroomType,
  ElementaryUnit,
} from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';
import { History } from 'history';
import { RootState } from '../ApplicationState';

type Props = PropsFromRedux & {
  children: ReactNode;
  className: string;
  completionStatus: ClassroomType['completionStatus'];
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  unitPosition: UnitPosition;
  classroom: ClassroomType;
  onClickLockedContent: () => void;
  history: History;
  isPreviewMode?: boolean;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  params: {
    classroomName: string;
    unitId: string;
  };
  unitId: string;
};

class UnitHeaderButton extends Component<Props, {}> {
  getAndContentAccessKeys() {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.unitPosition.subunit].lessons[
          this.props.unitPosition.lesson
        ];
      if (lessonMeta.andContentAccessKey) {
        return [lessonMeta.andContentAccessKey];
      } else if (lessonMeta.andContentAccessKeys) {
        return lessonMeta.andContentAccessKeys;
      }
    }

    return [];
  }

  getOrContentAccessKeys() {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.unitPosition.subunit].lessons[
          this.props.unitPosition.lesson
        ];
      if (lessonMeta.orContentAccessKey) {
        return [lessonMeta.orContentAccessKey];
      } else if (lessonMeta.orContentAccessKeys) {
        return lessonMeta.orContentAccessKeys;
      }
    }

    return [];
  }

  render() {
    const { unitCompletionStatus, unitMeta, unitPosition } = this.props;
    const subunitData = unitMeta.subUnits[unitPosition.subunit];

    if (!subunitData) {
      return null;
    }

    const myCompletionStatus = computeCompletionStatus(
      'unit',
      unitCompletionStatus,
      unitPosition
    );

    const subunitType = subunitData.contentType;
    if (this.isLocked() && subunitType !== 'readAloud') {
      return getLockedSubunitButton(
        unitMeta,
        subunitData,
        unitPosition,
        this.props.onClickLockedContent
      );
    }

    let lesson_not_started: { subunit: number; lesson: number };

    try {
      lesson_not_started = findFirstNotStartedLesson(unitCompletionStatus);
    } catch (error) {
      if (
        (error as Error).message ===
        'findFirstNotStartedLesson: There are no NotStarted lessons'
      ) {
        lesson_not_started = { subunit: 0, lesson: 0 };
      } else {
        throw error;
      }
    }

    const computeInProgressElement = () => {
      const lesson_in_progress =
        findFirstInProgressLesson(unitCompletionStatus);

      if (areAnyLessonsEntirelyNotStarted(unitCompletionStatus)) {
        if (areAnyLessonsInProgress(unitCompletionStatus)) {
          if (
            lesson_not_started.subunit < lesson_in_progress.subunit &&
            lesson_not_started.lesson < lesson_in_progress.subunit
          ) {
            return getBeginLessonButton(
              this.props.classroom,
              unitMeta,
              lesson_not_started,
              this.props.isPreviewMode,
              this.props.onPreviewChange
            );
          }
          return getResumeLessonButton(
            this.props.classroom,
            unitMeta,
            subunitData,
            lesson_in_progress
          );
        }

        return getBeginLessonButton(
          this.props.classroom,
          unitMeta,
          lesson_not_started,
          this.props.isPreviewMode,
          this.props.onPreviewChange
        );
      } else if (areAnySubunitsInProgress(unitCompletionStatus)) {
        const lesson_in_progress =
          findFirstInProgressLesson(unitCompletionStatus);

        return getResumeLessonButton(
          this.props.classroom,
          unitMeta,
          subunitData,
          lesson_in_progress
        );
      }
    };

    switch (myCompletionStatus) {
      case 'InProgress':
        return computeInProgressElement();
      case 'Completed':
        return (
          <PrimaryButton small completed>
            {showCheckmarkInCircleIcon()} Completed
          </PrimaryButton>
        );
      default:
        return getBeginLessonButton(
          this.props.classroom,
          unitMeta,
          lesson_not_started,
          this.props.isPreviewMode,
          this.props.onPreviewChange
        );
    }
  }
}

const mapStateToProps = (state: RootState, ownProps) => ({
  unitCompletionStatus: selectUnitCompletionStatus(state, ownProps.unitId),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  lockWithPreviewUnlessHasAny(
    'KEY_ALL_LESSON_PLANS',
    'KEY_MASTER_KEY'
  )(UnitHeaderButton)
);
