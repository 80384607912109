import { CSSProperties } from 'react';
import Spinner from 'react-spinkit';
import ConditionalWrapper from '../ConditionalWrapper';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './StatusUpdate.module.scss';

interface Props {
  pictureUrl?: string | null;
  contentTitle?: string;
  contentSubtitle?: (() => void) | string;
  subtitleStyle?: CSSProperties;
  isShowSpinner?: boolean;
  buttonText?: string | null;
  onButtonClick?: () => void;
  imageClassName?: string;
  pkToEightBackground?: boolean;
}

const NewSignUpStatusUpdate = ({ ...props }: Props): JSX.Element => {
  return (
    <ConditionalWrapper
      condition={true}
      wrapper={(children) =>
        props.pkToEightBackground ? (
          <div className={styles.statusContentContainer}>{children}</div>
        ) : (
          <div className={styles.occupyWholePage}>{children}</div>
        )
      }
    >
      <div className={styles.content}>
        <div className={styles.contentPicture}>
          {props.pictureUrl && (
            <img
              alt='Progress status'
              src={props.pictureUrl}
              className={props.imageClassName}
            />
          )}
        </div>
        <div className={styles.contentText}>
          <div className={styles.contentTitle}>{props.contentTitle}</div>
          <div className={styles.contentSubtitle} style={props.subtitleStyle}>
            {typeof props.contentSubtitle === 'function'
              ? props.contentSubtitle()
              : props.contentSubtitle}
            {props.isShowSpinner && <Spinner />}
          </div>
          {props.buttonText && (
            <div className={styles.buttonContainer}>
              <PrimaryButton onClick={props.onButtonClick}>
                {props.buttonText}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default NewSignUpStatusUpdate;
