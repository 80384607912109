import CTAButton from '../../components/CTAButton';
import LeftRightCard, {
  ImagePart,
  TextPart,
} from '../../components/LeftRightCard';
import SectionHeader from '../../components/SectionHeader';
import logoOne from '../../images/UAELanding/early-childhood-authority-logo-en.png';
import logoTwo from '../../images/UAELanding/early-childhood-authority-logo-ar.png';
import styles from './EarlyChildhoodAuthoritySection.module.scss';

const EarlyChildhoodAuthoritySection = (): JSX.Element => {
  return (
    <section className={styles.earlyChildhoodAuthoritySection}>
      <LeftRightCard imageLeadEven>
        <ImagePart>
          <div className={styles.logosContainer}>
            <img
              className={styles.enLogo}
              src={logoOne}
              alt='Abu Dhabi Early Childhood Authority logo - en'
            />
            <img
              className={styles.arLogo}
              src={logoTwo}
              alt='Abu Dhabi Early Childhood Authority logo - ar'
            />
          </div>
        </ImagePart>
        <TextPart>
          <SectionHeader
            alignLeft
            preTitle='ANJAL Z TECHSTARS FOUNDER CATALYST'
            title='Early Childhood Authority'
          />
          <p>
            Peekapak is 1 of 6 global companies selected in 2023 by the Abu
            Dhabi Early Childhood Authority (ECA) for their Anjal Z program to
            localize their solutions to critical ECD challenges across Abu
            Dhabi, the UAE and the Middle East.
          </p>
          <p>
            Are you an innovative school interested in piloting Peekapak in the
            UAE? Let’s talk!
          </p>
          <div className={styles.ctaButtonsContainer}>
            <CTAButton
              className={styles.ctaButton}
              onClick={() =>
                window.open(
                  'https://calendly.com/amishah/peekapak-uae',
                  '_blank'
                )
              }
            >
              Connect with us
            </CTAButton>
            <CTAButton
              secondary
              className={styles.ctaButton}
              onClick={() =>
                window.open(
                  'https://www.zawya.com/en/press-release/government-news/abu-dhabi-early-childhood-authority-launches-anjal-z-techstars-founder-catalyst-program-wla4e64n',
                  '_blank'
                )
              }
            >
              Press release
            </CTAButton>
          </div>
        </TextPart>
      </LeftRightCard>
    </section>
  );
};

export default EarlyChildhoodAuthoritySection;
