import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { selectClassroom } from '../Classrooms';
import { SpinnerOverlay } from '../SpinnerOverlay';
import {
  getCoreStandardsObject,
  CoreStandard,
} from '../core/context/contextHelpers';
import UnitHeader from './UnitHeader';
import UnitNavigator from './UnitNavigator';
import ExpandableContentCard from '../components/ExpandableContentCard';
import ExpandableStandardsCard from '../components/ExpandableStandardsCard';
import ExpandableLinkCard from '../components/ExpandableLinkCard';
import LessonInfoCard from './LessonInfoCard';
import { RootState } from '../ApplicationState';
import { getMiddleSchoolUnit } from '../BackendInterface';
import PrimaryButton from '../components/PrimaryButton';
import { Icon } from '@iconify/react';
import checkInReminder from '../images/checkin-reminder.png';
import styles from './MiddleSchoolUnitPage.module.scss';
import {
  Lesson,
  StandardsList,
  TextAndFile,
  TextBlock,
  UnitData,
} from '../../peekapak-types/LessonPlanTypes';

interface urlParams {
  language: string;
  unitId: string;
  grade: string;
}

const MiddleSchoolUnitPage = (): JSX.Element => {
  const classroom = useSelector((state: RootState) => {
    const selectedClassroom = selectClassroom(state);
    return selectedClassroom;
  });

  const [unitData, setUnitData] = useState<UnitData | {}>({});
  const [isLoading, setIsLoading] = useState(false);
  const [expandableText, setExpandableText] = useState<TextBlock[]>([]);
  const [expandableLink, setExpandableLink] = useState<TextAndFile[]>([]);
  const [unitStandards, setUnitStandards] = useState<StandardsList[]>([]);
  const { unitId, language, grade }: urlParams = useParams();
  const history = useHistory();
  const mapApplicableCoreStandards = (
    standards: StandardsList[],
    library: { [key: string]: CoreStandard }
  ): (CoreStandard | '')[] => {
    const standardsArray = standards[0].content.map((standard) => {
      return library[standard] !== undefined ? library[standard] : '';
    });
    return standardsArray;
  };

  const getUnit = useCallback(async () => {
    setIsLoading(true);
    try {
      const unitPayload = await getMiddleSchoolUnit(unitId, language, grade);
      setUnitData(unitPayload.data);
      const coreStandards = await getCoreStandardsObject();
      if (
        unitPayload.data.overview.content.some(
          (item) => item.type === 'standardsList'
        )
      ) {
        const applicableStandards = unitPayload.data.overview.content.filter(
          (item) => item.type === 'standardsList'
        ) as StandardsList[];
        const standardsToRender = [...applicableStandards];
        const mappedStandards = mapApplicableCoreStandards(
          applicableStandards,
          coreStandards
        );
        standardsToRender[0].content = mappedStandards;
        applicableStandards && setUnitStandards(standardsToRender);
      }
      const textData = unitPayload.data.overview.content.filter(
        (item) => item.type === 'textBlock'
      ) as TextBlock[];
      const linkData = unitPayload.data.overview.content.filter(
        (item) => item.type === 'link'
      ) as TextAndFile[];

      const sortedTextData = textData.toSorted((a, b) => a.index - b.index);
      setExpandableText(sortedTextData);

      const sortedLinkData = linkData.toSorted((a, b) => a.index - b.index);
      setExpandableLink(sortedLinkData);
    } catch (error) {
      console.error(`error = ${JSON.stringify(error, null, 2)}`);
    }
    setIsLoading(false);
  }, [language, unitId, grade]);

  const {
    caselCaption,
    displayName,
    lessons,
    number,
    featuredImage1,
    featuredImage2,
  } = unitData as UnitData;

  const sortedLessonContent = lessons?.content?.toSorted(
    (a, b) => a.index - b.index
  );
  const sortedLessons = {
    ...lessons,
    content: sortedLessonContent,
  };

  const unitTime = () => {
    const totalMins = sortedLessons?.content?.reduce(
      (a: number, b: { time: number }) => a + b.time,
      0
    ) as unknown as number;
    const hrs = Math.floor(totalMins / 60);
    const mins = totalMins % 60;
    return hrs >= 1 ? `${hrs} h ${mins} mins` : `${mins} mins`;
  };

  const handleNavigatorClick = (lessonType: string, activityId: string) => {
    history.push(
      `/lessonPlan/${unitId}/${language}/${grade}/lesson/${lessonType}/activity/${activityId}`
    );
  };

  useEffect(() => {
    getUnit();
  }, [grade]);

  return (
    <div className='applicationLayout'>
      <SpinnerOverlay isShow={isLoading} />
      <UnitNavigator
        handleNavigatorClick={handleNavigatorClick}
        title={displayName}
        lessonItems={sortedLessons?.content}
        completionStatus={classroom?.completionStatus?.[unitId]}
      />
      <UnitHeader
        featuredImage1={featuredImage1}
        featuredImage2={featuredImage2}
        displayName={displayName}
        number={parseInt(number)}
        caselInf={caselCaption}
        unitTime={unitTime()}
      />
      <div className={styles.unitContainer}>
        <h2>Unit Overview</h2>
        <div className={styles.contentContainer}>
          <div className={styles.unitInfo}>
            {expandableText?.map((x: TextBlock) => (
              <ExpandableContentCard
                key={`overview ${x.title}`}
                expandable={x.expandable}
                expanded={x.expanded || false}
                title={x.title}
                content={x.content}
              />
            ))}
            {unitStandards.length > 0 &&
              unitStandards.map((x: StandardsList) => (
                <ExpandableStandardsCard
                  key={`standards ${x.title}`}
                  expandable={x.expandable}
                  expanded={x.expanded || false}
                  title={x.title}
                  content={x.content as CoreStandard[]}
                />
              ))}
            {expandableLink?.map((x: TextAndFile) => (
              <ExpandableLinkCard
                key={`link ${x.title}`}
                expandable={x.expandable}
                expanded={x.expanded || false}
                title={x.title}
                files={x.files}
              />
            ))}
          </div>
          {!isLoading && (
            <div className={styles.checkInContainer}>
              <img src={checkInReminder} alt='check in wheel' />
              <PrimaryButton
                secondary
                className={styles.checkInButton}
                onClick={() => history.push('/studentportal/checkin')}
              >
                <Icon className={styles.icon} icon='tabler:mood-check' />
                check in
              </PrimaryButton>
            </div>
          )}
        </div>
        <h2>Lessons</h2>
        <div className={styles.lessonContainer}>
          <div className={styles.lesson}>
            {sortedLessons?.content?.map((x: Lesson, i: number) => (
              <LessonInfoCard
                key={`infocard ${x.title}`}
                index={i}
                lessonId={x.lessonId}
                title={x.title}
                time={999}
                overview={x.overview}
                lessonFlow={x.lessonFlow}
                completionStatus={
                  classroom?.completionStatus &&
                  classroom?.completionStatus[unitId]
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleSchoolUnitPage;
