import Table from 'react-bootstrap/Table';
import cx from 'classnames';
import styles from './TierPriceDisplay.module.scss';

export const products = {
  classroompro: {
    billingAmount: 19900,
    name: 'Classroom PRO',
    cost: '$159',
    term: 'year',
    limits: 'For up to 30 students',
    subscriptionPlan: '2020-classroom-pro',
    licenseName: 'CLASSROOM',
    offering:
      'Peekapak’s digital storybooks, curriculum, family activities and learning games',
  },
  teacherpro: {
    billingAmount: 9900,
    name: 'Teacher PRO',
    cost: '$99',
    term: 'year',
    limits: 'For up to 30 students',
    subscriptionPlan: 'Teacher-PRO-2020-New-Pricing',
    licenseName: 'PRO',
    offering:
      'Peekapak’s full set of digital storybooks and classroom lesson plans for in-person or remote learning',
  },
};

export const getTierName = (tier: string) => products[tier].name;
export const getTierOffering = (tier: string) => products[tier].offering;

export const TierDescriptionTextOnly = ({
  tier,
}: Partial<TierPriceDisplayProps>) => {
  return (
    <article className={styles.tierDescription}>
      <h1>{products[tier].name}</h1>
      <p>
        <span className={styles.priceText}>{products[tier].limits}</span>
      </p>
    </article>
  );
};

interface TierPriceDisplayProps {
  tier: string;
  invoicePreview: InvoicePreview;
  isInvoicePreviewReady: string;
}

interface InvoicePreview {
  discount: {
    coupon: {
      name: string;
      percent_off: number;
    };
  };
  currency: string;
  amount_due: number;
  lines: {
    data: [
      {
        price: {
          unit_amount: number;
        };
        quantity: number;
        tax_rates: [
          {
            display_name: string;
            jurisdiction: string;
            percentage: number;
          }
        ];
        tax_amounts: [
          {
            amount: number;
          }
        ];
      }
    ];
  };
  total_discount_amounts: [
    {
      amount: number;
    }
  ];
}

/* eslint-disable complexity */
export const TierInvoicePreview = ({
  tier,
  invoicePreview,
  isInvoicePreviewReady,
}: TierPriceDisplayProps) => {
  if (!invoicePreview) {
    return (
      <Table size='sm' className={styles.previewTable}>
        <thead>
          <tr>
            <th>Description</th>
            <th className={styles.alignCenter}>Qty</th>
            <th className={styles.alignCenter}>Unit Price</th>
            <th className={styles.alignCenter}>Tax</th>
            <th className={cx(styles.alignRight, styles.totalColumn)}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {isInvoicePreviewReady === 'error' ? (
              <td className={styles.error}>
                There was an error getting pricing information
              </td>
            ) : (
              <td>&nbsp;</td>
            )}
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td
              colSpan='4'
              className={cx(styles.alignRight, styles.totalAmount)}
            >
              &nbsp;
            </td>
            <td
              className={cx(
                styles.alignRight,
                styles.totalAmount,
                styles.number
              )}
            >
              &nbsp;
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  const lineItem = invoicePreview.lines.data[0];
  const unitPrice = lineItem.price.unit_amount / 100;

  // determine sales tax(es)
  const salesTaxApplies = lineItem.tax_rates.length > 0;
  const salesTaxes = (() => {
    if (!salesTaxApplies) return [];

    const taxObjects = [];

    let i = 0;
    while (i < lineItem.tax_rates.length) {
      const amount = lineItem.tax_amounts[i].amount / 100;
      const name = `${lineItem.tax_rates[i].display_name} ${lineItem.tax_rates[i].jurisdiction}`;
      const percentage = `${lineItem.tax_rates[i].percentage}`;

      taxObjects.push({ amount, name, percentage });
      i += 1;
    }

    return taxObjects;
  })();

  const salesTaxPercentage = (() => {
    if (!salesTaxApplies) return 0;
    return lineItem.tax_rates.reduce((acc, rate) => acc + rate.percentage, 0);
  })();
  /*
  const salesTaxAmount = salesTaxApplies
    ? lineItem.tax_amounts[0].amount / 100
    : 0;
  const salesTaxDescription = salesTaxApplies
    ? `${lineItem.tax_rates[0].display_name} ${lineItem.tax_rates[0].jurisdiction}`
    : '-';
    */

  // determine discounts
  const discountApplies =
    invoicePreview.discount && invoicePreview.discount.coupon;
  const discountAmount =
    discountApplies && invoicePreview.total_discount_amounts[0].amount;
  const discountCouponName = (() => {
    let name = '';

    if (discountApplies) {
      if (invoicePreview.discount.coupon.name) {
        name = `${name}${invoicePreview.discount.coupon.name} `;
      }

      if (invoicePreview.discount.coupon.percent_off) {
        name = `${name} ${invoicePreview.discount.coupon.percent_off}%`;
      }
    }

    if (name) return name;
    return 'Discount';
  })();

  return (
    <Table size='sm' className={styles.previewTable}>
      <thead>
        <tr>
          <th>Description</th>
          <th className={styles.alignCenter}>Qty</th>
          <th className={styles.alignCenter}>Unit Price</th>
          <th className={styles.alignCenter}>Tax</th>
          <th className={cx(styles.alignRight, styles.totalColumn)}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {products[tier].name} {products[tier].limits}
          </td>
          <td className={cx(styles.alignCenter, styles.number)}>
            {lineItem.quantity}
          </td>
          <td className={cx(styles.alignCenter, styles.number)}>
            {unitPrice.toFixed(2)}
          </td>
          <td className={cx(styles.alignCenter, styles.number)}>
            {salesTaxApplies ? salesTaxPercentage : '--'}
            {salesTaxApplies && '%'}
          </td>
          <td className={cx(styles.alignRight, styles.number)}>
            {(lineItem.quantity * unitPrice).toFixed(2)}
          </td>
        </tr>
        {discountApplies && (
          <tr className={styles.adjustmentLines}>
            <td colSpan='4' className={styles.alignRight}>
              {discountCouponName}
            </td>
            <td className={cx(styles.alignRight, styles.number)}>
              -{(discountAmount / 100).toFixed(2)}
            </td>
          </tr>
        )}
        {salesTaxApplies &&
          salesTaxes.map((tax) => (
            <tr className={styles.adjustmentLines} key={tax.name}>
              <td colSpan='4' className={styles.alignRight}>
                {tax.name} {tax.percentage}%
              </td>
              <td
                className={cx(styles.alignRight, styles.number)}
                style={{ color: 'initial' }}
              >
                {tax.amount.toFixed(2)}
              </td>
            </tr>
          ))}
        <tr>
          <td colSpan='4' className={cx(styles.alignRight, styles.currency)}>
            {invoicePreview.currency.toUpperCase()}
          </td>
          <td
            className={cx(styles.alignRight, styles.totalAmount, styles.number)}
          >
            ${(invoicePreview.amount_due / 100).toFixed(2)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const TierDescription = ({ tier }: Partial<TierPriceDisplayProps>) => {
  const title = products[tier].name;
  const perYearPrice = products[tier].cost;
  const term = products[tier].term;
  const limits = products[tier].limits;
  return (
    <article className={styles.tierDescription}>
      <h1>{title}</h1>
      <p>
        <span className={styles.perYearPrice}>{perYearPrice}</span>
        <span className={styles.priceText}>/{term}</span>
      </p>
      <p className={styles.priceText}>{limits}</p>
    </article>
  );
};

export default TierDescription;
