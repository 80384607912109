import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../CustomHooks';
import YesNoDialog from '../YesNoDialog/YesNoDialog';
import styles from './ExternalLink.module.scss';

interface Props {
  href?: string;
  target?: string;
  children: (string | JSX.Element) | (string | JSX.Element)[];
  className?: string;
}

export const ExternalLink = ({
  href,
  target,
  children,
  className,
}: Props): JSX.Element => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const auth = useAuth();
  const history = useHistory();

  return (
    <>
      <a
        href={href}
        target={target}
        onClick={handleOnClick}
        className={className}
        rel='noopener noreferrer'
      >
        {children}
      </a>
      <YesNoDialog
        cancelButtonLabel='Back'
        continueButtonLabel='Log Out and Go'
        onYesResponse={handleOnNavigate}
        onNoResponse={handleOnCancel}
        onHide={handleOnCancel}
        isShow={isShowModal}
        title='Are you sure you want to continue?'
      >
        <p className={styles.subtitle}>You are about to leave Peekapak.</p>
      </YesNoDialog>
    </>
  );

  function handleOnClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!auth.isUserLoggedIn()) {
      return window.open(href, target);
    }

    setIsShowModal(true);
  }

  function handleOnCancel() {
    setIsShowModal(false);
  }

  function handleOnNavigate() {
    history.push('/logout');
    window.open(href, target, 'noopener,noreferrer');
  }
};
